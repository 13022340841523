const MASTER_CHEF_ABIS = 'MasterChefAbis';

export const setMasterChefAbis = async (abis: string) => {
  await localStorage.setItem(MASTER_CHEF_ABIS, abis);
};

export const getMasterChefAbis = async () => {
  const _abis = await localStorage.getItem(MASTER_CHEF_ABIS);
  return _abis || '';
};
