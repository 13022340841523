import {useWeb3React} from '@web3-react/core';
import {Button} from 'src/components/Buttons';
import RewardTokenItem from 'src/components/Tokens/RewardTokenItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {YieldBox} from 'src/components/YieldBox';
import {outputTokens} from 'src/constants/harvest';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type HarvestMethodProps = {
  rewardToken?: IWhitelistToken;
  rewardsInUSD?: number;
  loading?: boolean;
  outputTokens?: outputTokens;
  onContinue: (mode: number) => void;
  harvestError?: string;
  selectedIndex?: number;
  setSelectedIndex?: (idx: number) => void;
  swapToLiq?: boolean;
  swapToUSDT?: boolean;
};

export const HarvestMethod = ({
  rewardToken,
  rewardsInUSD,
  loading,
  onContinue,
  outputTokens,
  harvestError,
  selectedIndex,
  setSelectedIndex,
  swapToLiq,
  swapToUSDT,
}: HarvestMethodProps) => {
  const {chainId} = useWeb3React();
  const liqToken = outputTokens.NEW_LIQ_GLOBAL_NAME;
  const usdtToken = outputTokens.USDT_GLOBAL_NAME;

  return (
    <Wrapper>
      <YieldBox value={rewardsInUSD} />
      <StyledCol>
        <BodyVariant color={COLORS.PRIMARY}>Harvest method:</BodyVariant>
        <RewardTokenItem
          token={rewardToken}
          name='Keep reward token'
          label={rewardToken?.symbol}
          selected={selectedIndex === 0}
          onSelect={() => setSelectedIndex(0)}
        />
        {chainId === 56 && (
          <>
            <RewardTokenItem
              token={liqToken}
              name='Harvest & Swap to LIQ'
              label={liqToken?.symbol}
              selected={selectedIndex === 1}
              onSelect={() => setSelectedIndex(1)}
              disabled={swapToLiq}
            />
            <RewardTokenItem
              token={usdtToken}
              name='Harvest & Swap to USDT'
              label={usdtToken?.symbol}
              selected={selectedIndex === 2}
              onSelect={() => setSelectedIndex(2)}
              disabled={swapToUSDT}
            />
          </>
        )}
      </StyledCol>
      <ButtonWrapper>
        <Button
          color={COLORS.PRIMARY}
          isLoading={loading}
          title={harvestError ? 'Retry' : 'Harvest'}
          onClick={() => onContinue(selectedIndex)}
        />
        {harvestError ? (
          <>
            <StyledParagraph color={COLORS.WARNING}>{harvestError}</StyledParagraph>
          </>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
`;
