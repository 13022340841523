import React, {useEffect, useState} from 'react';

import {Modal} from 'src/components/Modals';
import {COLORS} from 'src/styles';
import {IPlatform} from 'src/types';
import styled from 'styled-components';

import {DetailedCriteriaScore} from './DetailedCriteriaScore';
import {PlatformContract} from './PlatformContract';
import {SecurityRating} from './SecurityRating';

type SecurityRatingModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  platform: IPlatform;
};

export const SecurityRatingModal = ({isOpen, onDismiss, platform}: SecurityRatingModalProps) => {
  const [state, setState] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setState(0);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        {state === 0 && (
          <SecurityRating platform={platform} onContracts={() => setState(1)} onLearnMore={() => setState(2)} />
        )}
        {state === 1 && <PlatformContract platform={platform} onBack={() => setState(0)} />}
        {state === 2 && <DetailedCriteriaScore platform={platform} onBack={() => setState(0)} />}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: auto;
  scrollbar-color: ${COLORS.GRAY_BORDER} transparent;

  ::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GRAY_BORDER};
    border-radius: 4px;
  }
`;
