import React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';
import {BODY_FONT_ENUM, BodyFontScale, BodyFontScaleType, DEVICE_ENUM} from 'src/styles/size';
import styled from 'styled-components';

export interface BodyVariantType extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: BODY_FONT_ENUM;
  mobile?: BODY_FONT_ENUM;
  color?: ColorsTypes;
  children: string | React.ReactNode;
}

export const BodyVariant = ({
  size = BODY_FONT_ENUM.MEDIUM,
  mobile = BODY_FONT_ENUM.MEDIUM_MOBILE,
  children,
  color = COLORS.BLACK,
  ...props
}: BodyVariantType) => {
  return (
    <StyledBodyVariant color={color} size={BodyFontScale[size]} mobile={BodyFontScale[mobile]} {...props}>
      {children}
    </StyledBodyVariant>
  );
};

export default BodyVariant;

const StyledBodyVariant = styled.p<{size: BodyFontScaleType; mobile: BodyFontScaleType; color: ColorsTypes}>`
  ${(props) => `
    font-family: Montserrat;
    color: ${props.color};
    font-size: ${props.size.fontSize}px;
    line-height: ${props.size.lineHeight}px;
    font-weight: ${props.size.fontWeight};
    letter-spacing: ${props.size?.letterSpacing ?? 0}em;

    @media (max-width: ${DEVICE_ENUM.md}) {
      font-size: ${props.mobile.fontSize}px;
      line-height: ${props.mobile.lineHeight}px;
      font-weight: ${props.mobile.fontWeight};  
    }
  `}
  margin: 0;
`;
