import ICON_1INCH from 'src/assets/images/tokens/svg/1INCH.svg';
import ICON_AAVE from 'src/assets/images/tokens/svg/AAVE.svg';
import ICON_ALGO from 'src/assets/images/tokens/svg/ALGO.svg';
import ICON_AVAX from 'src/assets/images/tokens/svg/AVAX.svg';
import ICON_BAND from 'src/assets/images/tokens/svg/BAND.svg';
import ICON_BAT from 'src/assets/images/tokens/svg/BAT.svg';
import ICON_BIFI from 'src/assets/images/tokens/svg/BIFI.svg';
import ICON_BNB from 'src/assets/images/tokens/svg/BNB.svg';
import ICON_BNT from 'src/assets/images/tokens/svg/BNT.svg';
import ICON_BSW from 'src/assets/images/tokens/svg/BSW.svg';
import ICON_BTCB from 'src/assets/images/tokens/svg/BTCB.svg';
import ICON_BTT from 'src/assets/images/tokens/svg/BTT.svg';
import ICON_BUSD from 'src/assets/images/tokens/svg/BUSD.svg';
import ICON_CAKE from 'src/assets/images/tokens/svg/CAKE.svg';
import ICON_ADA from 'src/assets/images/tokens/svg/Cardano.svg';
import ICON_COMP from 'src/assets/images/tokens/svg/COMP.svg';
import ICON_Cosmos from 'src/assets/images/tokens/svg/Cosmos.svg';
import ICON_CRO from 'src/assets/images/tokens/svg/Cronos.svg';
import ICON_CURVE from 'src/assets/images/tokens/svg/Curve.svg';
import ICON_DAI from 'src/assets/images/tokens/svg/DAI.svg';
import ICON_DOGE from 'src/assets/images/tokens/svg/DOGE.svg';
import ICON_Elrond from 'src/assets/images/tokens/svg/Elrond.svg';
import ICON_Enjin from 'src/assets/images/tokens/svg/Enjin.svg';
import ICON_EOS from 'src/assets/images/tokens/svg/EOS.svg';
import ICON_ETH from 'src/assets/images/tokens/svg/ETH.svg';
import ICON_Fantom from 'src/assets/images/tokens/svg/Fantom.svg';
import ICON_Filecoin from 'src/assets/images/tokens/svg/Filecoin.svg';
import ICON_GENERAL from 'src/assets/images/tokens/svg/GENERAL.svg';
import ICON_Harmony from 'src/assets/images/tokens/svg/Harmony.svg';
import ICON_Huobi from 'src/assets/images/tokens/svg/Huobi.svg';
import ICON_ICP from 'src/assets/images/tokens/svg/ICP.svg';
import ICON_KSM from 'src/assets/images/tokens/svg/KSM.svg';
import ICON_LINK from 'src/assets/images/tokens/svg/LINK.svg';
import ICON_LIQ from 'src/assets/images/tokens/svg/LIQ.svg';
import ICON_Litecoin from 'src/assets/images/tokens/svg/Litecoin.svg';
import ICON_Maker from 'src/assets/images/tokens/svg/Maker.svg';
import ICON_MATIC from 'src/assets/images/tokens/svg/MATIC.svg';
import ICON_MMF from 'src/assets/images/tokens/svg/MMF.svg';
import ICON_NEAR from 'src/assets/images/tokens/svg/NEAR.svg';
import ICON_PAXG from 'src/assets/images/tokens/svg/PAXG.svg';
import ICON_Polkadot from 'src/assets/images/tokens/svg/Polkadot.svg';
import ICON_Shiba from 'src/assets/images/tokens/svg/Shiba.svg';
import ICON_Solana from 'src/assets/images/tokens/svg/Solana.svg';
import ICON_Stellar from 'src/assets/images/tokens/svg/Stellar.svg';
import ICON_SUSHI from 'src/assets/images/tokens/svg/SUSHI.svg';
import ICON_Synthetix from 'src/assets/images/tokens/svg/Synthetix.svg';
import ICON_Tezos from 'src/assets/images/tokens/svg/Tezos.svg';
import ICON_Tron from 'src/assets/images/tokens/svg/Tron.svg';
import ICON_TUSD from 'src/assets/images/tokens/svg/TUSD.svg';
import ICON_Uniswap from 'src/assets/images/tokens/svg/Uniswap.svg';
import ICON_USDC from 'src/assets/images/tokens/svg/USDC.svg';
import ICON_USDT from 'src/assets/images/tokens/svg/USDT.svg';
import ICON_VVS from 'src/assets/images/tokens/svg/VVS.svg';
import ICON_WBNB from 'src/assets/images/tokens/svg/WBNB.svg';
import ICON_WBTC from 'src/assets/images/tokens/svg/WBTC.svg';
import ICON_WCRO from 'src/assets/images/tokens/svg/WCRO.svg';
import ICON_WETH from 'src/assets/images/tokens/svg/WETH.svg';
import ICON_WMATIC from 'src/assets/images/tokens/svg/WMATIC.svg';
import ICON_XRP from 'src/assets/images/tokens/svg/XRP.svg';
import ICON_YFI from 'src/assets/images/tokens/svg/YFI.svg';
import ICON_Zilliqa from 'src/assets/images/tokens/svg/Zilliqa.svg';

export const ICON_TOKEN_LIST = [
  {
    token: 'BNB',
    icon: ICON_BNB,
  },
  {
    token: 'ETH',
    icon: ICON_ETH,
  },
  {
    token: 'CRO',
    icon: ICON_CRO,
  },
  {
    token: 'MATIC',
    icon: ICON_MATIC,
  },
  {
    token: 'ADA',
    icon: ICON_ADA,
  },
  {
    token: 'LIQ',
    icon: ICON_LIQ,
  },
  {
    token: 'LTC',
    icon: ICON_Litecoin,
  },
  {
    token: 'BTC',
    icon: ICON_BTCB,
  },
  {
    token: '1INCH',
    icon: ICON_1INCH,
  },
  {
    token: 'AAVE',
    icon: ICON_AAVE,
  },
  {
    token: 'ALGO',
    icon: ICON_ALGO,
  },
  {
    token: 'AVAX',
    icon: ICON_AVAX,
  },
  {
    token: 'BNT',
    icon: ICON_BNT,
  },
  {
    token: 'BAND',
    icon: ICON_BAND,
  },
  {
    token: 'BAT',
    icon: ICON_BAT,
  },
  {
    token: 'BIFI',
    icon: ICON_BIFI,
  },
  {
    token: 'BSW',
    icon: ICON_BSW,
  },
  {
    token: 'BTCB',
    icon: ICON_BTCB,
  },
  {
    token: 'BTT',
    icon: ICON_BTT,
  },
  {
    token: 'BUSD',
    icon: ICON_BUSD,
  },
  {
    token: 'Cake',
    icon: ICON_CAKE,
  },
  {
    token: 'Cardano',
    icon: ICON_ADA,
  },
  {
    token: 'LINK',
    icon: ICON_LINK,
  },
  {
    token: 'COMP',
    icon: ICON_COMP,
  },
  {
    token: 'ATOM',
    icon: ICON_Cosmos,
  },
  {
    token: 'CRV',
    icon: ICON_CURVE,
  },
  {
    token: 'DAI',
    icon: ICON_DAI,
  },
  {
    token: 'DOGE',
    icon: ICON_DOGE,
  },
  {
    token: 'EGLD',
    icon: ICON_Elrond,
  },
  {
    token: 'ENJ',
    icon: ICON_Enjin,
  },
  {
    token: 'EOS',
    icon: ICON_EOS,
  },
  {
    token: 'FTM',
    icon: ICON_Fantom,
  },
  {
    token: 'FIL',
    icon: ICON_Filecoin,
  },
  {
    token: 'ONE',
    icon: ICON_Harmony,
  },
  {
    token: 'HT',
    icon: ICON_Huobi,
  },
  {
    token: 'ICP',
    icon: ICON_ICP,
  },
  {
    token: 'MKR',
    icon: ICON_Maker,
  },
  {
    token: 'KSM',
    icon: ICON_KSM,
  },
  {
    token: 'MMF',
    icon: ICON_MMF,
  },
  {
    token: 'NEAR',
    icon: ICON_NEAR,
  },
  {
    token: 'PAXG',
    icon: ICON_PAXG,
  },
  {
    token: 'DOT',
    icon: ICON_Polkadot,
  },
  {
    token: 'SHIB',
    icon: ICON_Shiba,
  },
  {
    token: 'SOL',
    icon: ICON_Solana,
  },
  {
    token: 'XLM',
    icon: ICON_Stellar,
  },
  {
    token: 'SUSHI',
    icon: ICON_SUSHI,
  },
  {
    token: 'SNX',
    icon: ICON_Synthetix,
  },
  {
    token: 'XTZ',
    icon: ICON_Tezos,
  },
  {
    token: 'TRX',
    icon: ICON_Tron,
  },
  {
    token: 'TUSD',
    icon: ICON_TUSD,
  },
  {
    token: 'UNI',
    icon: ICON_Uniswap,
  },
  {
    token: 'USDC',
    icon: ICON_USDC,
  },
  {
    token: 'VVS',
    icon: ICON_VVS,
  },
  {
    token: 'USDT',
    icon: ICON_USDT,
  },
  {
    token: 'WBNB',
    icon: ICON_WBNB,
  },
  {
    token: 'WBTC',
    icon: ICON_WBTC,
  },
  {
    token: 'WCRO',
    icon: ICON_WCRO,
  },
  {
    token: 'WETH',
    icon: ICON_WETH,
  },
  {
    token: 'WMATIC',
    icon: ICON_WMATIC,
  },
  {
    token: 'XRP',
    icon: ICON_XRP,
  },
  {
    token: 'YFI',
    icon: ICON_YFI,
  },
  {
    token: 'ZIL',
    icon: ICON_Zilliqa,
  },
  {
    token: 'All',
    icon: ICON_GENERAL,
  },
];

export const ICON_TOKEN_LIST_GLOBAL_NAME = [
  {
    token: 'BNB Smart Chain',
    icon: ICON_BNB,
  },
  {
    token: 'Ethereum',
    icon: ICON_ETH,
  },
  {
    token: 'Cronos',
    icon: ICON_CRO,
  },
  {
    token: 'Polygon',
    icon: ICON_MATIC,
  },
  {
    token: 'LIQ',
    icon: ICON_LIQ,
  },
  {
    token: 'Litecoin',
    icon: ICON_Litecoin,
  },
  {
    token: '1INCH',
    icon: ICON_1INCH,
  },
  {
    token: 'AAVE',
    icon: ICON_AAVE,
  },
  {
    token: 'ALGO', // Not whitelisted
    icon: ICON_ALGO,
  },
  {
    token: 'AVAX',
    icon: ICON_AVAX,
  },
  {
    token: 'Bancor', // Not whitelisted
    icon: ICON_BNT,
  },
  {
    token: 'BAND',
    icon: ICON_BAND,
  },
  {
    token: 'BAT',
    icon: ICON_BAT,
  },
  {
    token: 'BIFI',
    icon: ICON_BIFI,
  },
  {
    token: 'BSW',
    icon: ICON_BSW,
  },
  {
    token: 'BTCB',
    icon: ICON_BTCB,
  },
  {
    token: 'BTT',
    icon: ICON_BTT,
  },
  {
    token: 'BUSD',
    icon: ICON_BUSD,
  },
  {
    token: 'CAKE',
    icon: ICON_CAKE,
  },
  {
    token: 'Cardano',
    icon: ICON_ADA,
  },
  {
    token: 'Chainlink',
    icon: ICON_LINK,
  },
  {
    token: 'Compound',
    icon: ICON_COMP,
  },
  {
    token: 'Cosmos',
    icon: ICON_Cosmos,
  },
  {
    token: 'Curve',
    icon: ICON_CURVE,
  },
  {
    token: 'DAI',
    icon: ICON_DAI,
  },
  {
    token: 'DOGE',
    icon: ICON_DOGE,
  },
  {
    token: 'Elrond',
    icon: ICON_Elrond,
  },
  {
    token: 'Enjin',
    icon: ICON_Enjin,
  },
  {
    token: 'EOS',
    icon: ICON_EOS,
  },
  {
    token: 'Fantom',
    icon: ICON_Fantom,
  },
  {
    token: 'Filecoin',
    icon: ICON_Filecoin,
  },
  {
    token: 'Harmony',
    icon: ICON_Harmony,
  },
  {
    token: 'Huobi',
    icon: ICON_Huobi,
  },
  {
    token: 'ICP',
    icon: ICON_ICP,
  },
  {
    token: 'Maker',
    icon: ICON_Maker,
  },
  {
    token: 'KSM', // Not whitelisted
    icon: ICON_KSM,
  },
  {
    token: 'MMF',
    icon: ICON_MMF,
  },
  {
    token: 'NEAR',
    icon: ICON_NEAR,
  },
  {
    token: 'PAXG',
    icon: ICON_PAXG,
  },
  {
    token: 'Polkadot',
    icon: ICON_Polkadot,
  },
  {
    token: 'Shiba',
    icon: ICON_Shiba,
  },
  {
    token: 'Solana',
    icon: ICON_Solana,
  },
  {
    token: 'Stellar',
    icon: ICON_Stellar,
  },
  {
    token: 'SUSHI',
    icon: ICON_SUSHI,
  },
  {
    token: 'Synthetix',
    icon: ICON_Synthetix,
  },
  {
    token: 'Tezos',
    icon: ICON_Tezos,
  },
  {
    token: 'Tron',
    icon: ICON_Tron,
  },
  {
    token: 'TUSD',
    icon: ICON_TUSD,
  },
  {
    token: 'Uniswap',
    icon: ICON_Uniswap,
  },
  {
    token: 'USDC',
    icon: ICON_USDC,
  },
  {
    token: 'USDT',
    icon: ICON_USDT,
  },
  {
    token: 'VVS',
    icon: ICON_VVS,
  },
  {
    token: 'WBNB',
    icon: ICON_WBNB,
  },
  {
    token: 'WBTC',
    icon: ICON_WBTC,
  },
  {
    token: 'WCRO',
    icon: ICON_WCRO,
  },
  {
    token: 'WETH',
    icon: ICON_WETH,
  },
  {
    token: 'WMATIC',
    icon: ICON_WMATIC,
  },
  {
    token: 'XRP',
    icon: ICON_XRP,
  },
  {
    token: 'YFI',
    icon: ICON_YFI,
  },
  {
    token: 'Zilliqa',
    icon: ICON_Zilliqa,
  },
  {
    token: 'All',
    icon: ICON_GENERAL,
  },
];

export const ICON_PLATFORM_LIST = [
  {
    platform: 'PancakeSwap',
    icon: ICON_CAKE,
  },
  {
    platform: 'BiSwap',
    icon: ICON_BSW,
  },
  {
    platform: 'MM Finance',
    icon: ICON_MMF,
  },
  {
    platform: 'VVS Finance',
    icon: ICON_VVS,
  },
  {
    platform: 'UniSwap V2',
    icon: ICON_Uniswap,
  },
  {
    platform: 'SushiSwap',
    icon: ICON_SUSHI,
  },
  {
    platform: 'Liquidus',
    icon: ICON_LIQ,
  },
  {
    platform: 'All',
    icon: ICON_LIQ,
  },
];
