import {useCallback, useEffect, useMemo, useState} from 'react';

import {TransactionResponse} from '@ethersproject/providers';
import {Currency, CurrencyAmount, Token, TokenAmount} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract, ethers} from 'ethers';
import ERC20_ABI from 'src/abis/ERC20.json';
import {getChainInfo} from 'src/constants';
import {TRANSACTION_TYPE} from 'src/types';
import {Aggregator} from 'src/utils/swap/aggregator';
import {calculateGasMargin, computeSlippageAdjustedAmounts} from 'src/utils/swap/kyber-swap';

import {Field} from './constant';
import {useHasPendingApproval, useTransactionAdder} from '../transactions';

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

export const useTokenContract = (tokenAddress?: string) => {
  const {account, provider} = useWeb3React();
  if (provider && tokenAddress) {
    const walletSigner = provider.getSigner(account).connectUnchecked();
    const contract = new Contract(tokenAddress, ERC20_ABI, walletSigner);
    return contract;
  }
};

function useTokenAllowance(token?: Token, owner?: string, spender?: string): TokenAmount | undefined {
  const {provider} = useWeb3React();
  const pendingApproval = useHasPendingApproval(token?.address, spender);
  const [allowance, setAllowance] = useState<BigNumber>();
  const fetchAllowance = useCallback(
    async (address?: string) => {
      if (address && owner && spender) {
        const walletSigner = provider.getSigner(owner).connectUnchecked();
        const contract = new Contract(address, ERC20_ABI, walletSigner);
        const result = await contract.allowance(owner, spender);
        setAllowance(result);
      }
    },
    [owner, provider, spender],
  );

  useEffect(() => {
    fetchAllowance(token?.address);
  }, [fetchAllowance, token?.address, pendingApproval]);

  return useMemo(
    () => (token && allowance ? TokenAmount.fromRawAmount(token, allowance.toString()) : undefined),
    [token, allowance],
  );
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount<Currency>,
  account?: string,
  spender?: string,
): [ApprovalState, () => Promise<TransactionResponse>] {
  const token = amountToApprove?.currency.wrapped;
  const currentAllowance = useTokenAllowance(token, account, spender);
  const pendingApproval = useHasPendingApproval(token?.address, spender);
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) {
      return ApprovalState.UNKNOWN;
    }
    if (amountToApprove.currency.isNative) {
      return ApprovalState.APPROVED;
    }
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) {
      return ApprovalState.UNKNOWN;
    }

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, pendingApproval, spender]);
  const tokenContract = useTokenContract(token?.address);
  const addTransaction = useTransactionAdder();
  const approve = useCallback(async (): Promise<TransactionResponse> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily');
      return;
    }
    if (!token) {
      console.error('no token');
      return;
    }
    if (!tokenContract) {
      console.error('tokenContract is null');
      return;
    }
    if (!amountToApprove) {
      console.error('missing amount to approve');
      return;
    }
    if (!spender) {
      console.error('no spender');
      return;
    }
    let useExact = false;
    const estimatedGas = await tokenContract.estimateGas.approve(spender, ethers.constants.MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true;
      return tokenContract.estimateGas.approve(spender, amountToApprove.quotient.toString());
    });
    try {
      const response = await tokenContract.approve(
        spender,
        useExact ? amountToApprove.quotient.toString() : ethers.constants.MaxUint256,
        {
          gasLimit: calculateGasMargin(estimatedGas),
        },
      );
      console.log('Approved');
      addTransaction({
        hash: response.hash,
        type: TRANSACTION_TYPE.APPROVE,
        extraInfo: {
          tokenSymbol: token.symbol ?? '',
          tokenAddress: token.address,
          contract: spender,
        },
      });
      return response;
    } catch (e) {
      console.error('Failed to approve token', e);
      throw e;
    }
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction]);

  return [approvalState, approve];
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(trade?: Aggregator | null, allowedSlippage = 0, feePercent = 0) {
  const {account, chainId} = useWeb3React();
  const chainInfo = getChainInfo(chainId);
  const amountToApprove = useMemo(
    () => (trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage, feePercent)[Field.INPUT] : undefined),
    [trade, allowedSlippage, feePercent],
  );

  return useApproveCallback(amountToApprove, account, chainInfo?.kyberSwapLiqAddress);
}
