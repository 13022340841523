import React from 'react';

import ReactDOM from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import {ErrorBoundary} from './components/ErrorBoundary';
import {AppContextProvider} from './contexts/AppContext';
import {LigHoldingsContextProvider} from './contexts/LiqHoldingsContext';
import {SidebarProvider} from './contexts/SidebarContext';
import {BlockNumberProvider} from './providers/BlockNumberProvider';
import {GraphQLProvider} from './providers/GraphqlProvider';
import {Web3Provider} from './providers/Web3Provider';
import reportWebVitals from './reportWebVitals';
import {store} from './state';
import TransactionUpdater from './state/transactions/updater';

// Your buffer setup
window.Buffer = window.Buffer || Buffer;

function Updaters() {
  return <TransactionUpdater />;
}

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.error = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.debug = () => {};
}

const RootComponent = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const handleLoginSuccess = () => {
  //   setIsAuthenticated(true);
  // };

  // if (!isAuthenticated) {
  //   return <LoginForm onLoginSuccess={handleLoginSuccess} />;
  // }

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <GraphQLProvider>
            <Web3Provider>
              <AppContextProvider>
                <LigHoldingsContextProvider>
                  <BlockNumberProvider>
                    <Updaters />
                    <ErrorBoundary>
                      <SidebarProvider>
                        <App />
                      </SidebarProvider>
                    </ErrorBoundary>
                  </BlockNumberProvider>
                </LigHoldingsContextProvider>
              </AppContextProvider>
            </Web3Provider>
          </GraphQLProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>,
);

reportWebVitals();
