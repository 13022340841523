import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ChevronDownIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ChevronDownIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: ChevronDownIconType) => (
  <svg width={size} height={size} viewBox='0 0 30 30' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15 19L15.624 18.466L21 13.858L19.752 12.4L15 16.474L10.248 12.4L9 13.858L14.376 18.466L15 19Z'
      fill={color}
    />
  </svg>
);

export default ChevronDownIcon;
