import React from 'react';

import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {ImportPoolTokenItem} from 'src/components/ImportPoolTokenItem';
import {PlusIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type NoPairProps = {
  token0: IWhitelistToken;
  token1?: IWhitelistToken;
  account?: string;
  loading?: boolean;
  onConnectWallet?: () => void;
  onToken0Select?: (token: IWhitelistToken) => void;
  onToken1Select?: (token: IWhitelistToken) => void;
  onCreatePair?: () => void;
};

export const NoPair = ({
  token0,
  onToken0Select,
  onToken1Select,
  token1,
  account,
  onConnectWallet,
  onCreatePair,
  loading,
}: NoPairProps) => {
  return (
    <Body>
      <StyledCol>
        <ImportPoolTokenItem selectedToken={token0} onTokenSelect={onToken0Select} />
        <StyledSwitchButton>
          <PlusIcon />
        </StyledSwitchButton>
        <ImportPoolTokenItem selectedToken={token1} onTokenSelect={onToken1Select} />
      </StyledCol>
      <StyledCol marginTop={50}>
        <PoolText color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
          No pair found.
        </PoolText>
      </StyledCol>
      <ButtonWrapper>
        {account ? (
          <StyledButton
            width='fit-content'
            radius
            background
            borderColor={COLORS.SECONDARY}
            color={COLORS.PRIMARY}
            size={BUTTON_SIZE_ENUM.SMALL}
            title='Create a pair'
            onClick={onCreatePair}
            isLoading={loading}
          />
        ) : (
          <Button color={COLORS.PRIMARY} title='Connect Wallet' onClick={onConnectWallet} />
        )}
      </ButtonWrapper>
    </Body>
  );
};

const StyledSwitchButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 12px 0px;
`;

const StyledCol = styled.div<{gap?: number; marginTop?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const PoolText = styled(BodyParagraph)`
  width: 100%;
  text-align: center;
`;

const Body = styled.div`
  padding: 16px 24px;
`;

const StyledButton = styled(Button)<{width: string; background?: boolean; radius?: boolean}>`
  background: ${(props) => props.background && 'transparent'};
  border-radius: ${(props) => props.radius && '6px'};
  margin-top: 10px;
  width: ${(props) => props.width};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 10px;
`;
