//hot-farms.tsx

import {useContext, useMemo, useState} from 'react';

import {HotFarmItem, HotSingleFarmItem} from 'src/components/Farms';
import {Spinner} from 'src/components/Spinner';
import {AppContext} from 'src/contexts/AppContext';
import {useHotFarms} from 'src/hooks';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {FarmType} from 'src/types';
import styled from 'styled-components';

type FilterType = 'all' | 'eth' | 'btc' | '30%' | '60%';

export const HotFarms = ({...props}) => {
  const {hotFarmsSorted} = useHotFarms();
  const {farmLoading} = useContext(AppContext);
  const [filter] = useState<FilterType>('all');
  const filteredHotFarms = useMemo(() => {
    if (filter === 'eth') {
      return hotFarmsSorted.filter(
        (farm) =>
          farm.liquidityPool?.token0Symbol === 'ETH' ||
          farm.liquidityPool?.token0Symbol === 'WETH' ||
          farm.liquidityPool?.token1Symbol === 'ETH' ||
          farm.liquidityPool?.token1Symbol === 'WETH' ||
          farm.rewardTokenChainSpecifics?.symbol === 'ETH' ||
          farm.rewardTokenChainSpecifics?.symbol === 'WETH',
      );
    } else if (filter === 'btc') {
      return hotFarmsSorted.filter(
        (farm) =>
          farm.liquidityPool?.token0Symbol === 'BTCB' ||
          farm.liquidityPool?.token0Symbol === 'WBTC' ||
          farm.liquidityPool?.token1Symbol === 'BTCB' ||
          farm.liquidityPool?.token1Symbol === 'WBTC' ||
          farm.rewardTokenChainSpecifics?.symbol === 'BTCB' ||
          farm.rewardTokenChainSpecifics?.symbol === 'WBTC',
      );
    } else if (filter === '30%') {
      return hotFarmsSorted.filter((farm) => farm.apy >= 30);
    } else if (filter === '60%') {
      return hotFarmsSorted.filter((farm) => farm.apy >= 60);
    }
    return hotFarmsSorted;
  }, [filter, hotFarmsSorted]);

  return (
    <Wrapper {...props}>
      {farmLoading ? (
        <StyledFullWrapper>
          <Spinner size={32} color={COLORS.SECONDARY} />
        </StyledFullWrapper>
      ) : (
        <HotFarmsWrapper>
          {filteredHotFarms.map((item, index) =>
            item.type === FarmType.FARM_SINGLE ? (
              <HotSingleFarmItem key={index} data={item} />
            ) : (
              <HotFarmItem key={index} data={item} />
            ),
          )}
        </HotFarmsWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: auto;
  margin: 0 -16px;
  padding: 16px;
  padding-right: 0px;
  mask-image: linear-gradient(to right, transparent 0%, black 16px, black calc(100% - 16px), transparent 100%);

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 16px;
  }
`;

const StyledFullWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    min-height: 300px;
  }
`;

const HotFarmsWrapper = styled.div`
  display: flex;
  width: fit-content;
  gap: 16px;
`;
