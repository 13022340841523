import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {paths} from 'src/constants';
import {useToken} from 'src/hooks/useToken';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {FarmType, IYieldFarm, POOL_TYPE} from 'src/types';
import {convertKMPrice} from 'src/utils/text-util';
import {formatBigNumber, getTokenUSDPrice, getVestingInMonth} from 'src/utils/token-util';
import styled from 'styled-components';

import {GuardIcon} from '../Svgs';
import {TokenIcon} from '../TokenIcon';
import {BodyParagraph, BodyVariant} from '../Typography';

type LiquidityFarmItemProps = {
  type?: number;
  data: IYieldFarm;
  selected?: boolean;
  disabled?: boolean;
  size?: 'default' | 'small';
};

export const LiquidityFarmItem = ({type, data, selected, disabled, size = 'default'}: LiquidityFarmItemProps) => {
  const navigate = useNavigate();
  const {getGlobalTokenItemByAddress} = useToken();
  const lpBalance = getTokenUSDPrice(
    formatBigNumber(data?.balance, data?.liquidityPool?.decimals || 18),
    data?.liquidityPool?.lpTokenPriceUSD,
  );

  const securityRating = data?.platform?.securityRating?.securityRating;

  const vestingInMonth = getVestingInMonth(data?.vestingTimeInDays);

  const name = `${data?.liquidityPool?.token0Symbol} - ${data?.liquidityPool?.token1Symbol}${
    vestingInMonth ? `  ${vestingInMonth} Month` : ''
  }`;
  const tvlPrice = data?.tvlNumber;

  const chainId =
    data?.type === FarmType.FARM_GRIZLYFI ? data?.contractAddress?.chainId : data?.liquidityPool?.address.chainId;

  const token0 = getGlobalTokenItemByAddress(data?.liquidityPool?.token0Hash, chainId);
  const token1 = getGlobalTokenItemByAddress(data?.liquidityPool?.token1Hash, chainId);

  const handleNavigation = () => {
    const address =
      data.type === FarmType.FARM_MASTERCHEF ? data.liquidityPool?.address.hash : data.contractAddress?.hash;
    if (address) {
      navigate(`${paths.poolDetail}?type=${POOL_TYPE.FARMING_POOL}&address=${address}&farm-type=${data.type}`);
    } else {
      toast('The Farm info is invalid');
    }
  };

  return (
    <StyledTokenBox disabled={disabled} selected={selected} onClick={handleNavigation}>
      <StyledLeftWrapper>
        <TokenIconWrapper size={size}>
          <TokenIcon width={24} height={24} token={token0} />
          <SecondTokenIcon width={24} height={24} token={token1} />
        </TokenIconWrapper>
        <StyledTokenNameWrapper>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            {name}
          </BodyParagraph>
          <StyledPlatformLabel>
            <BodyParagraph color={COLORS.GRAY_LIGHT}>{data?.platform?.name}</BodyParagraph>
            {securityRating && (
              <SecurityRatingWrapper>
                <GuardIcon size={24} />
                <SecuriityRatingLabel color={COLORS.PRIMARY}>{securityRating.toFixed(0)}</SecuriityRatingLabel>
              </SecurityRatingWrapper>
            )}
          </StyledPlatformLabel>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      {type ? (
        <StyledRightWrapper>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            {data?.apy?.toFixed(1)}%
          </BodyParagraph>
          <StyledTokenPrice color={COLORS.SECONDARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
            ${convertKMPrice(tvlPrice || 0)} TVL
          </StyledTokenPrice>
        </StyledRightWrapper>
      ) : (
        <StyledRightWrapper>
          <StyledTokenLabel color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL_MOBILE}>
            ${lpBalance.toFixed(2)}
          </StyledTokenLabel>
          <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {data?.apy?.toFixed(1)}% APY
          </StyledTokenPrice>
        </StyledRightWrapper>
      )}
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.button<{selected?: boolean}>`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const TokenIconWrapper = styled.div<{size?: 'default' | 'small'}>`
  display: flex;
  margin-right: ${(props) => (props.size == 'default' ? 24 : 12)}px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const SecondTokenIcon = styled(TokenIcon)<{size?: 'default' | 'small'}>`
  margin-left: -${(props) => (props.size == 'default' ? 20 : 10)}px;
  margin-top: ${(props) => (props.size == 'default' ? 20 : 10)}px;
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const StyledTokenNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const StyledPlatformLabel = styled.div`
  display: flex;
  text-align: left;
`;

const StyledTokenLabel = styled(BodyVariant)`
  text-align: right;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;

const SecurityRatingWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SecuriityRatingLabel = styled(BodyParagraph)`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  font-size: 10px;
`;
