import React from 'react';

import TokenLogoImage from 'src/assets/images/banners/ALLBRIDGE.svg';
import ArticleBgShape from 'src/assets/images/banners/ArticleBgShapeABR.svg';
import {ABR_GLOBAL_NAME} from 'src/constants';
import {DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {Swap} from '../Home/components/swap';

export const ArticleView = () => {
  return (
    <ArticleContainer>
      <ArticleWrapper>
        <ArticleHeader>
          <HeaderContent>
            <TitleContainer>
              <ArticleLogo alt='Token logo' src={TokenLogoImage} />
              <Title>$ABR Featured Project</Title>
            </TitleContainer>
            <SubTitle className='subTitle'>
              Allbridge: Pioneering Cross-Chain Solutions in the Blockchain Space with its ABR Token
            </SubTitle>
          </HeaderContent>
          <ApyContainer>
            <Apy>103.2% APY</Apy>
            <NoFarms>No Farms</NoFarms>
          </ApyContainer>
        </ArticleHeader>
        <ArticleContent>
          <ArticleDescription>
            Allbridge is a cross-chain bridging solution provider, focusing on facilitating asset transfers between EVM
            and non-EVM blockchains. They prioritize user experience and continuous innovation in the cross-chain
            market, making it more accessible, especially for newcomers. Allbridge&apos;s ecosystem includes products
            like Allbridge Core and Allbridge Classic, aimed at enabling stablecoin swaps and versatile bridging
            solutions. Their commitment to security is evident through collaborations with cybersecurity firms for
            audits. The company&apos;s mission and strategic partnerships suggest a strong foundation for growth in the
            blockchain space.
            <br />
            <br />
            The Allbridge token (ABR) is an intriguing aspect of the Allbridge ecosystem for several reasons. The ABR
            token is the native token of Allbridge, utilized within its ecosystem primarily for paying bridge fees and
            for staking purposes.
          </ArticleDescription>
          <UseCaseContainer>
            <UseCaseTitle>Utility and Use Cases:</UseCaseTitle>
            <UseCase>
              <UseCaseItem>
                Bridge Fees: ABR tokens are used to pay for the bridge fees within the Allbridge ecosystem.
              </UseCaseItem>
              <UseCaseItem>
                Staking: Users can stake ABR tokens to receive discounts on bridging fees. This staking mechanism is a
                critical aspect of the Allbridge ecosystem, as it encourages token holding and contributes to the
                stability of the platform.
              </UseCaseItem>
              <UseCaseItem>
                Subscription Fees: Projects utilizing Allbridge for their operations pay a subscription fee in ABR
                tokens. Tokenomics:
              </UseCaseItem>
              <UseCaseItem>Total Supply: The total supply of ABR tokens is capped at 100,000,000.</UseCaseItem>
              <UseCaseItem>
                Allocation: The token allocation includes ecosystem DAO, liquidity mining, development incentives, and
                more.
              </UseCaseItem>
            </UseCase>
          </UseCaseContainer>
          <ArticleDescription>
            As of writing, the Allbridge token (ABR) is trading at around $0.4013 with a market cap of approximately
            $3.47 million.
            <br />
            <br />
            Allbridge has been audited by Hacken, a leader in blockchain security, which found its code well-secured,
            adding credibility to the platform and its token.
            <br />
            <br />
            Demand in DeFi: The growing need for cross-chain bridges in the DeFi space suggests a potentially increasing
            demand for such services, which could positively impact the ABR token. Allbridge is ranking as a top 5
            result for the search term &apos;Bridge Solana&apos;, giving it a special SEO position. With the expected
            surge in the use and utility of the Solana ecosystem the term is to be expected to archive high traffic.
            Compared to other bridges like Wormhole and cBridge, Allbridge has shown a competitive position in terms of
            total bridged value and the number of supported chains. Conclusion
            <br />
            <br />
            As with any investment in the crypto space, it&apos;s crucial to conduct thorough research and consider the
            inherent risks.
          </ArticleDescription>
        </ArticleContent>
        <ArticleExchangeDisclaimer>
          <Exchange>
            <Swap swapBool={true} articleToken={ABR_GLOBAL_NAME} />
          </Exchange>
          <Disclaimer>
            <DisclaimerHeader>Disclaimer</DisclaimerHeader>
            <DisclaimerMsg>
              This article expresses a viewpoint of the author that may not reflect the one of the company Liquidus Defi
              Technology Systems Ltd. The content of this article is purely for educational & entertainment purposes and
              is not financial advice. The author may or may not hold the mentioned assets. Do your own research (DYOR)
              before buying any digital asset as a loss of funds may occur. Liquidus or the authors are not responsible
              for third-party content or any financial loss that occurred from the mentioned assets specifically.
            </DisclaimerMsg>
          </Disclaimer>
        </ArticleExchangeDisclaimer>
      </ArticleWrapper>
    </ArticleContainer>
  );
};

const ArticleContainer = styled.main`
  padding: 3rem;

  @media screen and (max-width: ${DEVICE_ENUM.sm}) {
    padding: 1rem;
  }
`;

const ArticleWrapper = styled.section`
  color: white;
  padding: 7rem;
  border-radius: 12px;
  background-color: #133737;
  background-image: url(${ArticleBgShape});
  background-position: bottom;
  background-size: 70rem;
  background-repeat: no-repeat;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    padding: 1rem;
  }
`;

const ArticleHeader = styled.header`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 0.75rem;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    flex-direction: column;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${DEVICE_ENUM.xl}) {
    width: 80%;
  }

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    flex-direction: column;
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: ${DEVICE_ENUM.sm}) {
    align-items: center;
  }
`;

const ArticleLogo = styled.img`
  margin-right: 2rem;
  width: 50px;
  height: 50px;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    margin-right: 0.75rem;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;

  @media screen and (max-width: ${DEVICE_ENUM.sm}) {
    font-size: 1.5rem;
  }
`;

const SubTitle = styled.h2`
  font-size: 1rem;
  margin-left: 5.5rem;
  width: 35rem;

  @media screen and (max-width: 1440px) {
    width: 24rem;
  }

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    margin-left: 4rem;
    width: 80%;
  }

  @media screen and (max-width: ${DEVICE_ENUM.sm}) {
    font-size: 0.85rem;
    margin: 1rem 0 0 0;
    width: 100%;
  }
`;

const ApyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-left: auto;
  gap: 2rem;
  width: 13rem;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    justify-content: flex-end;
  }

  @media screen and (max-width: ${DEVICE_ENUM.sm}) {
    gap: 1rem;
    justify-content: center;
  }

  @media screen and (max-width: 375px) {
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }
`;

const Apy = styled.p`
  font-size: 2rem;
  font-weight: 700;
  color: #17e7d6;
  margin: 0;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    font-size: 1.5rem;
  }
`;

const NoFarms = styled.span`
  background-color: black;
  width: 15rem;
  text-align: center;
  font-size: 1.5rem;
  padding: 0.75rem 3rem;
  color: white;
  border-radius: 10px;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    font-size: 1rem;
    width: fit-content;
    padding: 0.5rem 2.5rem;
  }
`;

const ArticleContent = styled.section`
  font-weight: 400;
  margin: 3.5rem 0 5rem;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    margin: 2rem 0;
  }
`;

const ArticleDescription = styled.p`
  margin: 0;
`;

const UseCaseContainer = styled.div`
  margin: 1rem 0;
`;

const UseCaseTitle = styled.h3`
  font-size: 1rem;
`;

const UseCase = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1.5rem 0;
`;

const UseCaseItem = styled.li``;

const ArticleExchangeDisclaimer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  gap: 3.5rem;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    flex-direction: column;
    padding: 0;
    gap: 0;
  }

  @media screen and (max-width: ${DEVICE_ENUM.md}) {
    padding: 1.75rem;
  }
  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 0;
  }
`;

const Exchange = styled.div`
  width: 500px;
  background: white;
  padding: 1rem;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));
  background-color: white;
  border-radius: 12px;

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    width: 100%;
    border-radius: 6px;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 24px 20px;
  }
`;

const Disclaimer = styled.div`
  width: 80%;

  @media screen and (max-width: ${DEVICE_ENUM.xl}) {
    width: 25rem;
  }

  @media screen and (max-width: ${DEVICE_ENUM.lg}) {
    width: 100%;
  }
`;

const DisclaimerHeader = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  padding: 1rem 0;
  margin: 0;
`;

const DisclaimerMsg = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
`;

export default ArticleView;
