import {IChainSpecific, ITokenAddress} from './token';

export enum PlatformSettingsMode {
  PLATFORM_SETTINGS_HOLDING = 'holdingSorting',
  PLATFORM_SETTINGS_FARM = 'farmSorting',
}

export enum PlatformSettingsSection {
  PLATFORM_SETTINGS_FARM = 'farmPlatform',
  PLATFORM_SETTINGS_HOLDING = 'holdingsPlatform',
}

export type PlatformSettings = {
  platform: IPlatform;
  [PlatformSettingsSection.PLATFORM_SETTINGS_FARM]: IPlatform;
  [PlatformSettingsSection.PLATFORM_SETTINGS_HOLDING]: IPlatform;
  [PlatformSettingsMode.PLATFORM_SETTINGS_HOLDING]?: PlatformSorting;
  [PlatformSettingsMode.PLATFORM_SETTINGS_FARM]?: PlatformSorting;
};

export enum PlatformSorting {
  SORT_BY_ALPHABET,
  SORT_BY_ALPHABET_DESC,
  SORT_BY_APY,
  SORT_BY_USER_DEPOSIT,
  SORT_BY_TVL,
  SORT_BY_SECURITY_RATING,
}

export type ISecurityRating = {
  projectStart?: string;
  projectAgeInDays?: number;
  governanceTokenMarketCap?: number;
  tvl?: number;
  rugdocRiskLevel?: number;
  certikTrustScore?: number;
  cerRating?: number;
  securityRating?: number;
  projectAgeRating?: number;
  tvlRating?: number;
  marketCapRating?: number;
};

export type IPlatformChainSpecific = {
  chainId?: number;
};

export type IPlatform = {
  name: string;
  slug?: string;
  enabled?: boolean;
  factoryAddresses?: ITokenAddress[];
  masterChefAddresses?: ITokenAddress[];
  routerAddresses?: ITokenAddress[];
  securityRating?: ISecurityRating;
  governanceTokenChainSpecifics?: IChainSpecific;
  chainId?: number;
  chainSpecifics?: IPlatformChainSpecific[];
  iconUrl?: string;
  isFilter?: boolean;
};

export type IPlatformData = {
  platforms: IPlatform[];
};
