import {PlatformSettingsMode, PlatformSorting} from 'src/types';

export const PLATFORM_SORTING_LIST = {
  [PlatformSettingsMode.PLATFORM_SETTINGS_HOLDING]: [
    {id: PlatformSorting.SORT_BY_ALPHABET, name: 'A-Z'},
    {id: PlatformSorting.SORT_BY_APY, name: 'Average Percentage Yield (APY)'},
    {id: PlatformSorting.SORT_BY_USER_DEPOSIT, name: 'User Deposit'},
  ],
  [PlatformSettingsMode.PLATFORM_SETTINGS_FARM]: [
    {id: PlatformSorting.SORT_BY_ALPHABET, name: 'A-Z'},
    {id: PlatformSorting.SORT_BY_APY, name: 'Average Percentage Yield (APY)'},
    {id: PlatformSorting.SORT_BY_TVL, name: 'Total Value Locked (TVL)'},
    {id: PlatformSorting.SORT_BY_SECURITY_RATING, name: 'Security Rating'},
  ],
};

export const TOLERANCE_ITEMS = [
  {name: '0.1%', value: 0.1},
  {name: '0.5%', value: 0.5},
  {name: '1%', value: 1.0},
  {name: 'Custom', value: -1},
];
