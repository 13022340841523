import {IWhitelistToken} from 'src/types';

export enum harvestErrorCodes {
  GASERROR = 'UNPREDICTABLE_GAS_LIMIT',
}

export enum harvestErrorReasons {
  NATIVEBALANCEERROR = 'transfer amount exceeds balance',
}

export type outputTokens = {[name: string]: IWhitelistToken};
