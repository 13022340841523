import {gql} from '@apollo/client';

export const FARMS = gql`
  query farms($platformSlug: String, $chainId: Float) {
    farms(platformSlug: $platformSlug, chainId: $chainId) {
      ... on SingleFarm {
        contractAddress {
          chainId
          hash
        }
        platform {
          name
          slug
          iconUrl
          securityRating {
            projectStart
            projectAgeInDays
            governanceTokenMarketCap
            tvl
            rugdocRiskLevel
            certikTrustScore
            cerRating
            updatedAt
            securityRating
            projectAgeRating
            tvlRating
            marketCapRating
          }
          governanceTokenChainSpecifics {
            name
            symbol
            decimals
          }
        }
        rewardPerBlock
        rewardTokenChainSpecifics {
          symbol
          decimals
          priceUSD {
            value
            decimals
          }
          address {
            hash
            chainId
          }
        }
        annualRewards {
          value
          decimals
        }
        baseAPR
        stakedTokenChainSpecifics {
          symbol
          decimals
          priceUSD {
            value
            decimals
          }
          address {
            hash
            chainId
          }
        }
        stakedTokenSupply
        tvl {
          value
          decimals
        }
        apy
        vestingTimeInDays
        harvestFn {
          signature
          arguments {
            name
            type
          }
        }
        depositFn {
          signature
          arguments {
            name
            type
          }
        }
        withdrawFn {
          signature
          arguments {
            name
            type
          }
        }
        hotness
      }
      ... on LpFarm {
        contractAddress {
          chainId
          hash
        }
        platform {
          name
          slug
          iconUrl
          securityRating {
            projectStart
            projectAgeInDays
            governanceTokenMarketCap
            tvl
            rugdocRiskLevel
            certikTrustScore
            cerRating
            updatedAt
            securityRating
            projectAgeRating
            tvlRating
            marketCapRating
          }
          governanceTokenChainSpecifics {
            name
            symbol
            decimals
          }
        }
        liquidityPool {
          platform {
            name
            slug
            iconUrl
            securityRating {
              projectStart
              projectAgeInDays
              governanceTokenMarketCap
              tvl
              rugdocRiskLevel
              certikTrustScore
              cerRating
              updatedAt
              securityRating
              projectAgeRating
              tvlRating
              marketCapRating
            }
            governanceTokenChainSpecifics {
              name
              symbol
              decimals
            }
            routerAddresses {
              hash
              chainId
            }
          }
          reserve0
          reserve1
          token0Symbol
          token1Symbol
          token0Hash
          token1Hash
          totalSupply
          decimals
          lpTokenPriceUSD {
            value
            decimals
          }
          address {
            hash
            chainId
          }
          poolRewards
          poolApy
          pastVolumes
        }
        rewardTokenChainSpecifics {
          symbol
          decimals
          priceUSD {
            value
            decimals
          }
          address {
            hash
            chainId
          }
        }
        annualRewards {
          value
          decimals
        }
        baseAPR
        tvl {
          value
          decimals
        }
        apy
        vestingTimeInDays
        harvestFn {
          signature
          arguments {
            name
            type
          }
        }
        depositFn {
          signature
          arguments {
            name
            type
          }
        }
        withdrawFn {
          signature
          arguments {
            name
            type
          }
        }
        hotness
      }
    }
  }
`;
