import React, {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import QRCode from 'react-qr-code';
import {toast} from 'react-toastify';
import {Button} from 'src/components/Buttons';
import {ChevronRightIcon, CopyPlusIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {getChainInfo} from 'src/constants';
import {useModals} from 'src/contexts/modals';
import {useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type AcceptedNetworkProps = {
  chainId?: number;
};

const AcceptedNetwork = ({chainId}: AcceptedNetworkProps) => {
  const chain = getChainInfo(chainId);
  const {getTokenBySymbol} = useToken();
  const nativeToken = getTokenBySymbol(chain?.symbol, false);

  if (!chain) {
    return null;
  }

  return (
    <StyledRow gap={6}>
      <TokenIcon token={nativeToken} width={20} height={20} icon={!nativeToken && chain.icon} />
      <BodyParagraph color={COLORS.PRIMARY}>{chain?.symbol}</BodyParagraph>
    </StyledRow>
  );
};

type TokenReceiveViewProps = {
  token: IWhitelistToken;
  onBack?: () => void;
};

export const TokenReceiveView = ({token, onBack}: TokenReceiveViewProps) => {
  const {account} = useWeb3React();
  const {dispatch} = useModals();

  const onCopyClipboard = useCallback(() => {
    navigator.clipboard.writeText(account);
    toast.success('Wallet address copied to the clipboard', {autoClose: 2000, hideProgressBar: true});
  }, [account]);

  const onConnectWallet = () => {
    const payload = {isOpen: true, next: 'updateTokenReceiveModal'};
    dispatch({type: 'updateWalletConnectModal', payload});
  };

  return (
    <Body>
      <StyledCol>
        <BodyVariant color={COLORS.PRIMARY}>Token</BodyVariant>
        <TokenInfoView onClick={onBack}>
          <StyledRow gap={8}>
            <TokenIcon token={token} />
            <BodyVariant color={COLORS.GRAY_LIGHT}>{token.symbol}</BodyVariant>
            <BodyVariant color={COLORS.PRIMARY}>{token.name}</BodyVariant>
          </StyledRow>
          <ChevronRightIcon />
        </TokenInfoView>
      </StyledCol>
      <StyledCol marginTop={24}>
        <BodyVariant color={COLORS.PRIMARY}>Accepted Networks</BodyVariant>
        <StyledRow marginTop={12} gap={16}>
          {token.chainSpecifics ? (
            token.chainSpecifics.map((chain) => (
              <AcceptedNetwork key={chain.address.hash} chainId={chain.address.chainId} />
            ))
          ) : (
            <AcceptedNetwork chainId={token.chainId} />
          )}
        </StyledRow>
      </StyledCol>
      {account && (
        <QRCodeWrapper>
          <QRCode size={160} value={`ethereum:${account}`} />
        </QRCodeWrapper>
      )}
      {account && (
        <StyledButton onClick={onCopyClipboard}>
          <StyledRow marginTop={16} gap={8}>
            <BodyParagraph color={COLORS.GRAY_LIGHT}>{account}</BodyParagraph>
            <CopyPlusIcon color={COLORS.SECONDARY} size={30} />
          </StyledRow>
        </StyledButton>
      )}
      <ButtonWrapper>
        {account ? (
          <Button color={COLORS.PRIMARY} title='Share' onClick={onCopyClipboard} />
        ) : (
          <Button color={COLORS.PRIMARY} title='Connect Wallet' onClick={onConnectWallet} />
        )}
      </ButtonWrapper>
    </Body>
  );
};

const TokenInfoView = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledCol = styled.div<{gap?: number; marginTop?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const QRCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const Body = styled.div`
  padding: 16px 24px;
`;

const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
