import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {ArrowDownIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {IPlatform, IWhitelistToken} from 'src/types';
import {getPlatformIcon} from 'src/utils/icon-util';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type SuccessTopupProps = {
  outputToken1?: IWhitelistToken;
  outputToken2?: IWhitelistToken;
  totalUsdAmount?: number;
  platform?: IPlatform;
  apy?: number;
  onDone?: () => void;
};

export const SuccessTopup = ({
  outputToken1,
  outputToken2,
  totalUsdAmount,
  apy,
  platform,
  onDone,
}: SuccessTopupProps) => {
  const iconUrl = platform?.iconUrl || getPlatformIcon(platform?.name);

  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        <StyledRow>
          <TokenBox>
            <TokenIcon width={24} height={24} token={outputToken1} />
            {outputToken2 && <SecondTokenIcon width={24} height={24} token={outputToken2} />}
          </TokenBox>
          <BodyVariant color={COLORS.PRIMARY}>
            {outputToken1?.symbol}-{outputToken2?.symbol}
          </BodyVariant>
        </StyledRow>
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledFullRow>
          <StyledRow>
            <StyledImage src={iconUrl} />
            <BodyVariant color={COLORS.PRIMARY}>{platform?.name}</BodyVariant>
          </StyledRow>
          <BodyVariant color={COLORS.PRIMARY}>{`${apy?.toFixed(1) ?? 0}% APY`}</BodyVariant>
        </StyledFullRow>
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Done' onClick={onDone} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const TokenInfoBox = styled.div`
  padding: 22px 16px 16px;
`;

const TokenBox = styled.div`
  display: flex;
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledImage = styled.img`
  width: 36px;
  height: 36px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;
