import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type PlusIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const PlusIcon = ({size = 20, color = COLORS.PRIMARY, ...props}: PlusIconType) => (
  <svg width={size} height={size} viewBox='0 0 19 12' fill={color} {...props}>
    <path
      d='M12.046 4.4v2.4H7.798V11H5.23V6.8H.982V4.4H5.23V.2h2.568v4.2h4.248z'
      stroke={color}
      strokeWidth='0.5'
      strokeLinecap='round'
    />
  </svg>
);

export default PlusIcon;
