import React, {useContext, useEffect, useState} from 'react';

import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {harvestErrorCodes, harvestErrorReasons} from 'src/constants/harvest';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useHarvest, useToken} from 'src/hooks';
import {ApprovalState, useApproveCallbackFromTrade, useSwapCallback, useSwapInfo} from 'src/hooks/kyber-swap';
import {useAppSelector} from 'src/state/hooks';
import {COLORS} from 'src/styles';
import {IYieldFarm} from 'src/types';
import {getTokenAmountFromUSD, getTokenUSDPrice, parseBigNumber} from 'src/utils/token-util';
import {defaultSlippageTolerance} from 'src/utils/zap-utils';
import styled from 'styled-components';

import {ApproveRewardTokens} from './ApproveRewardTokens';
import {HarvestMethod} from './HarvestMethod';
import {HarvestSuccessful} from './HarvestSuccessful';
import {SwapRewardTokens} from './SwapRewardTokens';

export type HarvestModalProps = {
  isOpen: boolean;
  onDismiss?: (stakeLiqFarm?: IYieldFarm) => void;
  farm?: IYieldFarm;
  priceUsedInPool?: number;
  rewardsEarned?: number;
};

export const HarvestModal = ({isOpen = false, onDismiss, farm, priceUsedInPool, rewardsEarned}: HarvestModalProps) => {
  const {getTokenByAddress} = useToken();
  const {harvestOutputTokens} = useHarvest();
  const {refreshFarmsAndLPs, refreshTokens} = useContext(AppContext);
  const {feePercent} = useLiqHoldings();
  const {swapSettings} = useAppSelector((state) => state.user);
  const slippageAmount = swapSettings?.slippage?.value * 100 || defaultSlippageTolerance;
  const outputTokens = harvestOutputTokens();
  const rewardToken = getTokenByAddress(farm?.rewardTokenChainSpecifics.address.hash);
  const {harvest} = useHarvest([farm]);

  const [title, setTitle] = useState('Harvest');

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [preferredOutput, setPreferredOutput] = useState(outputTokens.NEW_LIQ_GLOBAL_NAME);
  const [harvestError, setHarvestError] = useState<harvestErrorReasons>(undefined);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [swapPossible, setSwapPossible] = useState(false);
  const rewardsEarnedBN = parseBigNumber(rewardsEarned?.toString(), rewardToken?.decimals);
  const {v2Trade, onUpdateCallback} = useSwapInfo(false, rewardsEarnedBN, rewardToken, preferredOutput, slippageAmount);
  const {callback} = useSwapCallback(v2Trade);
  const [approval, approveCallback] = useApproveCallbackFromTrade(v2Trade, slippageAmount, feePercent);

  const outputAmount = getTokenAmountFromUSD(
    v2Trade?.amountOutUsd,
    preferredOutput?.priceUSD,
    preferredOutput?.decimals,
  );

  const rewardsInUSD = priceUsedInPool
    ? priceUsedInPool
    : getTokenUSDPrice(farm?.pendingReward, farm?.rewardTokenChainSpecifics.priceUSD);

  useEffect(() => {
    if (rewardToken?.balance.gt(rewardsEarnedBN) && rewardsInUSD > 0) {
      setSwapPossible(true);
    }
  }, [rewardToken, rewardsEarnedBN, rewardsInUSD, preferredOutput]);

  const liqSwapDisabled = rewardToken?.address === outputTokens.NEW_LIQ_GLOBAL_NAME?.address;
  const usdtSwapDisabled = rewardToken?.address === outputTokens.USDT_GLOBAL_NAME?.address;

  useEffect(() => {
    if (isOpen) {
      setStep(0);
      setSelectedIndex(liqSwapDisabled || (liqSwapDisabled && usdtSwapDisabled) ? 0 : 1);
      setHarvestError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setPreferredOutput(selectedIndex === 2 ? outputTokens.USDT_GLOBAL_NAME : outputTokens.NEW_LIQ_GLOBAL_NAME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const setErrorMsg = (e: Error) => {
    // @ts-expect-error: e obj includes reason
    e.reason !== zapKnownErrorReasons.USERREJECTION
      ? // @ts-expect-error: e obj includes message
        setHarvestError(e.message.split('(')[0])
      : setHarvestError(undefined);
    console.error({e});
  };

  const handleHarvestMethod = async (mode: number) => {
    setLoading(true);
    setHarvestError(undefined);
    if (swapPossible) onUpdateCallback(false, 0);
    try {
      const harvestTransactions = await harvest();
      if (harvestTransactions.length > 0) {
        await Promise.all(harvestTransactions.map((tx) => tx.transaction.wait()));
        if ([1, 2].includes(mode) && swapPossible) {
          setTitle(`Harvest & Swap ${mode === 1 ? 'LIQ' : 'USDT'}`);
          setStep(approval !== ApprovalState.NOT_APPROVED ? 2 : 1);
        } else {
          setSwapPossible(false);
          setStep(3);
        }
      }
    } catch (error) {
      setErrorMsg(error as Error);
    }
    setLoading(false);
  };

  const handleRewardTokenApproval = async () => {
    setHarvestError(undefined);
    try {
      await approveCallback();
      setStep(2);
    } catch (error) {
      setErrorMsg(error as Error);
    }
  };

  const handleSwapTokens = async () => {
    setHarvestError(undefined);
    setLoading(true);
    try {
      if (callback) {
        const swapTx = await callback();
        await swapTx.wait();
        setTitle('Harvest Successful');
        if (!swapPossible) setSwapPossible(true);
      } else {
        setSwapPossible(false);
      }
      setStep(3);
    } catch (error) {
      setErrorMsg(error as Error);
    }
    setLoading(false);
  };

  const handleSwitchSelected = (idx: number) => setSelectedIndex(idx);

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={() => onDismiss()}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <HarvestMethod
            loading={loading}
            rewardToken={rewardToken}
            rewardsInUSD={rewardsInUSD}
            onContinue={handleHarvestMethod}
            harvestError={harvestError && `${harvestErrorCodes.GASERROR} - ${harvestError}`}
            outputTokens={outputTokens}
            selectedIndex={selectedIndex}
            setSelectedIndex={handleSwitchSelected}
            swapToLiq={liqSwapDisabled}
            swapToUSDT={usdtSwapDisabled}
          />
        )}
        {step === 1 && (
          <ApproveRewardTokens
            rewardToken={rewardToken}
            loading={loading}
            onConfirm={handleRewardTokenApproval}
            errorMsg={harvestError}
          />
        )}
        {step === 2 && (
          <SwapRewardTokens
            rewardToken={rewardToken}
            loading={loading}
            onConfirm={handleSwapTokens}
            outputToken={preferredOutput}
            inputAmount={rewardsEarned}
            outputAmount={outputAmount ?? 0}
            usdAmount={priceUsedInPool}
            errorMsg={harvestError}
          />
        )}
        {step === 3 && (
          <HarvestSuccessful
            rewardToken={rewardToken}
            outputToken={preferredOutput}
            totalRewards={farm?.pendingReward}
            rewardsInUSD={rewardsInUSD}
            inputAmount={rewardsEarned}
            outputAmount={selectedIndex !== 0 ? outputAmount : undefined}
            onConfirm={() => {
              refreshFarmsAndLPs();
              refreshTokens(true);
              onDismiss();
            }}
            title={[1, 2].includes(selectedIndex) ? title : 'Keep reward token'}
            swapMsg={!swapPossible}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
