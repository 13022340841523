import {useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {Link, useNavigate} from 'react-router-dom';
import LIQLogoIcon from 'src/assets/images/tokens/LIQIcon.svg';
import {ArrowLeftIcon} from 'src/components/Svgs';
import {paths} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useToken, useWindowDimensions} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import styled from 'styled-components';

import {Spinner} from '../../components/Spinner';
import {TokenIcon} from '../../components/TokenIcon';

type TokenPosition = {
  top: number;
  left: number;
  delay: number;
};
export const YieldScan = () => {
  const {farmLoading} = useContext(AppContext);

  const {width, height} = useWindowDimensions();
  const {account} = useWeb3React();
  const {connectedWallets} = useAppSelector((state) => state.wallets);
  const currentWallet = useMemo(
    () => connectedWallets.find((item) => item.account === account),
    [account, connectedWallets],
  );
  const [tokens, setTokens] = useState<IWhitelistToken[]>([]);
  const [tokenPositions, setTokenPositions] = useState<TokenPosition[]>([]);
  const [animateCounts, setAnimateCounts] = useState<number>(0);
  const heightsArray = Array.from({length: Math.floor((width - 200) / 150) + 2}, (_, index) => 200 + index * 150);
  const {sortedTokens} = useToken();

  useMemo(() => {
    const calculateToken = [...sortedTokens];
    const nativeCoinList: string[] = ['ETH', 'BNB', 'CRO', 'USDT', 'BUSD', 'BTC', 'USDC', 'MATIC'];

    const topList = calculateToken
      .filter((item) => getTokenUSDPrice(formatBigNumber(item.balance, item.decimals), item.priceUSD) > 0)
      .sort(
        (a, b) =>
          getTokenUSDPrice(formatBigNumber(b.balance, b.decimals), b.priceUSD) -
          getTokenUSDPrice(formatBigNumber(a.balance, a.decimals), a.priceUSD),
      )
      .slice(0, 5);
    const bottomList = calculateToken.filter((item) => nativeCoinList.includes(item.symbol));

    const combinedList = [...topList, ...bottomList];
    const filteredItems: IWhitelistToken[] = [];
    const seenGlobalNames = new Set();

    combinedList.forEach((item) => {
      const globalName = item.globalName;

      if (!seenGlobalNames.has(globalName)) {
        seenGlobalNames.add(globalName);
        filteredItems.push(item);
      }
    });
    const tokenData = filteredItems.slice(0, 5);

    let check = 0;

    const initialTokenPositions = tokenData.map((_, index) => {
      const space = (index / tokenData.length) * 4000;
      const delay = Math.random() * (300 + index * 100) + space;
      const randomAngle = (delay / 100) * 9;
      const radians = (randomAngle * Math.PI) / 180;
      const limitRadius = Math.sqrt(
        Math.pow(Math.sin(radians) * 120, 2) + Math.pow(190 - Math.cos(radians) * 170 - (height * 0.8 + 10) / 2, 2),
      );
      let addDistance = 0;
      if (limitRadius < 120) {
        addDistance = 120 - limitRadius;
      }
      const randomX = Math.sin(radians) * 120 + (height * 0.8 + 10) / 2 + addDistance;
      const randomY = 190 - Math.cos(radians) * 170 + addDistance;

      let xPoint = randomX;
      let yPoint = randomY;
      if (randomX > 236 && randomX < 305 && randomY > 236 && randomY < 305) {
        xPoint = 235;
        yPoint = 235;
      }
      if (randomX > 236 && randomX < 305 && randomY > 305 && randomY < 380) {
        xPoint = 235;
        yPoint = 380;
      }
      if (randomX > 305 && randomX < 380 && randomY > 305 && randomY < 380) {
        xPoint = 380;
        yPoint = 380;
      }
      if (randomX > 305 && randomX < 380 && randomY > 235 && randomY < 305) {
        xPoint = 380;
        yPoint = 235;
      }
      const oneObj = {
        top: check < 3 ? yPoint * 1.3 : yPoint * 2.5,
        left: check < 3 ? xPoint * 1.2 : xPoint / 1.8,
        delay: delay / 1000,
      };
      check++;
      return oneObj;
    });
    setTokenPositions(initialTokenPositions);
    setTokens(tokenData);
  }, [sortedTokens, height]);

  const navigate = useNavigate();

  useEffect(() => {
    if (animateCounts === tokens.length + 1) {
      navigate(`${paths.yieldResult}`);
    }
  }, [animateCounts, tokens, navigate]);

  if (farmLoading) {
    return (
      <Container>
        <SpinnerWrapper>
          <Spinner color={COLORS.SECONDARY} />
        </SpinnerWrapper>
      </Container>
    );
  } else {
    return (
      <Container>
        <Header>
          <Link to={paths.home} style={{height: '24px'}}>
            <ArrowLeftIcon size={24} color={COLORS.WHITE} />
          </Link>
          <WalletName>{currentWallet?.accountName ?? 'Your account'}</WalletName>
        </Header>
        <RadarWrapper>
          <Radar
            onAnimationEndCapture={() => {
              setAnimateCounts((prev) => prev + 1);
            }}
          >
            {tokens.map((token, index) => {
              return (
                <TokenWrapper {...tokenPositions[index]} key={token.globalName + index}>
                  <TokenIcon width={36} height={36} token={token} />
                </TokenWrapper>
              );
            })}
            <LogoContainer>
              <StyledImage src={LIQLogoIcon} />
            </LogoContainer>
            {heightsArray.map((i) => (
              <RingWave key={i} height={i} />
            ))}
          </Radar>
          {heightsArray.map((i) => (
            <RingWave key={i} height={i} />
          ))}
          <ScannerText>Scanning tokens & farms…</ScannerText>
        </RadarWrapper>
      </Container>
    );
  }
};

const Container = styled.div`
  background-color: #112455;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 2;
`;
const WalletName = styled.p`
  color: ${COLORS.WHITE};
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
const RadarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const TokenWrapper = styled.div<{top: number; left: number; delay: number}>`
  ${(props) => `
  position: absolute;
  z-index: 2;
  top: ${props.top}px;
  left: ${props.left}px;
  animation: fadeIn 0.4s ease-in ${props.delay - 0.2}s forwards;
  visibility: hidden;
  @keyframes fadeIn {
    from {
      scale:0
    }
    to {
      visibility: visible;
      scale: 1;
    }
  }
 `}
`;
const RingWave = styled.div<{height: number}>`
  ${(props) => `
  position: absolute;
  height: ${props.height}px;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid ${COLORS.SECONDARY};
  border-radius: 100%;
  background: transparent;
  `}
`;

const Radar = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  height: 80%;
  margin: auto;
  background: #146a84 content-box;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: conic-gradient(transparent 95%, ${COLORS.SECONDARY_INACTIVE});
    border-radius: 50%;
    animation: spin 3.5s linear;
  }

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
`;

const LogoContainer = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${COLORS.SECONDARY};
  border-radius: 100%;
  z-index: 2;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: none;
`;

const ScannerText = styled.p`
  color: ${COLORS.WHITE};
  font-size: 18px;
  position: absolute;
  bottom: 20px;
  margin: auto;
  transform: translateX(-50%);
  left: 50%;
}
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
