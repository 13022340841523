import React from 'react';

import {CloseIcon} from 'src/components/Svgs';
import {SUPPORTED_NETWORKS} from 'src/constants';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

import {Modal} from './Modal';
import {Button} from '../Buttons';

export type SwitchNetworkModalProps = {
  isOpen: boolean;
  onSwtich?: (chain: number) => void;
  onDismiss?: () => void;
};

export const SwitchNetworkModal = ({isOpen = false, onSwtich, onDismiss}: SwitchNetworkModalProps) => {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Supported Networks</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          <>
            {Object.values(SUPPORTED_NETWORKS).map((item, idx) => {
              return (
                <SectionWrapper key={idx} marginTop={24}>
                  <StyledSectionTitle>{item?.displayName}</StyledSectionTitle>
                  <ButtonWrapper>
                    <Button
                      color={COLORS.PRIMARY}
                      title={`Switch to ${item.name}`}
                      onClick={() => onSwtich(item.chainId)}
                    />
                  </ButtonWrapper>
                </SectionWrapper>
              );
            })}
          </>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const StyledSectionTitle = styled.h4`
  color: ${COLORS.GRAY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin: 0;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
