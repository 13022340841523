import {gql} from '@apollo/client';

export const PLATFORMS = gql`
  query platforms {
    platforms {
      name
      slug
      securityRating {
        projectStart
        projectAgeInDays
        governanceTokenMarketCap
        tvl
        rugdocRiskLevel
        certikTrustScore
        cerRating
        updatedAt
        securityRating
        projectAgeRating
        tvlRating
        marketCapRating
      }
      governanceTokenChainSpecifics {
        name
        symbol
        decimals
      }
      factoryAddresses {
        hash
        chainId
      }
      masterChefAddresses {
        hash
        chainId
      }
      routerAddresses {
        hash
        chainId
      }
      chainSpecifics {
        chainId
      }
      iconUrl
      isFilter
    }
  }
`;
