// discover-farm.tsx

import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {useNavigate} from 'react-router';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {TokenSelectModal} from 'src/components/Modals';
import {Spinner} from 'src/components/Spinner';
import {RenderItemInfo, TableView} from 'src/components/TableView';
import {TokenIcon} from 'src/components/TokenIcon';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {paths} from 'src/constants';
import {minTVL, thenaSlug} from 'src/constants/farms';
import {AppContext} from 'src/contexts/AppContext';
import {useToken} from 'src/hooks';
import {farmSlice} from 'src/state/farm/reducer';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {FarmType, Field, IWhitelistToken, IYieldFarm, POOL_TYPE, PlatformSorting} from 'src/types';
import {getVestingInMonth, sortBy, useYearlyRevenue} from 'src/utils/token-util';
import {getVolume, kFormatter} from 'src/utils/utils';
import styled from 'styled-components';

export const DiscoverFarm = ({...props}) => {
  const navigate = useNavigate();
  const {getTokenByAddress} = useToken();
  const {getYearlyRevenue} = useYearlyRevenue();
  const {farmLoading, farmingPools, farms, gauges, grizzlyFarms} = useContext(AppContext);
  const {chainId} = useWeb3React();
  const appDispatch = useAppDispatch();
  const farmState = useAppSelector((state) => state.farm);
  const {platformSettings} = useAppSelector((state) => state.user);
  const [showInputTokenSelectModal, setShowInputTokenSelectModal] = useState(false);
  const [showOutputTokenSelectModal, setShowOutputTokenSelectModal] = useState(false);
  const {getTokenByGlobalName} = useToken();
  const token0 = getTokenByGlobalName(farmState.INPUT.tokenGlobalName, true);
  const token1 = getTokenByGlobalName(farmState.OUTPUT.tokenGlobalName, true);

  const filteredFarmingPools = useMemo(() => {
    let token0Symbol = token0?.symbol;
    let token1Symbol = token1?.symbol;
    // check native token
    if (token0?.isNative) {
      token0Symbol = `W${token0Symbol}`;
    }
    if (token1?.isNative) {
      token1Symbol = `W${token1Symbol}`;
    }
    if (farmingPools && farms && gauges && grizzlyFarms) {
      const _lpFarms = farms?.filter((item) => item.contractAddress?.chainId === chainId);
      const v2FarmingPools = farmingPools.filter((item) => {
        if (
          item?.masterChefAddress?.chainId === chainId &&
          item.version !== 1 &&
          item?.platform?.name !== 'MM Finance'
        ) {
          const {...newItem} = item;
          return newItem;
        }
        return null;
      });
      const mergedFarms: IYieldFarm[] = [...v2FarmingPools, ..._lpFarms, ...gauges, ...grizzlyFarms];

      let _farmingPools = mergedFarms?.filter((item) => {
        return (item.apy || 0) > 0.1 && item?.tvlNumber > minTVL && item?.platform?.slug !== thenaSlug;
      });

      if (token0) {
        _farmingPools = _farmingPools?.filter((item) => {
          if (item.type === FarmType.FARM_SINGLE) {
            // TODO: Maybe do filtering by address (but take care that native tokens filtering still show farms)
            return item.rewardTokenChainSpecifics?.symbol === token0Symbol;
          }
          return item.liquidityPool?.token0Symbol === token0Symbol || item.liquidityPool?.token1Symbol === token0Symbol;
        });
      }
      if (token1) {
        _farmingPools = _farmingPools?.filter((item) => {
          if (item.type === FarmType.FARM_SINGLE) {
            return item.rewardTokenChainSpecifics?.symbol === token1Symbol;
          }
          return item.liquidityPool?.token0Symbol === token1Symbol || item.liquidityPool?.token1Symbol === token1Symbol;
        });
      }

      _farmingPools = sortBy(_farmingPools || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);

      return _farmingPools;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token0, token1, farmingPools, farms, gauges, grizzlyFarms, chainId, platformSettings]);

  useEffect(() => {
    if (chainId) {
      appDispatch(farmSlice.actions.resetToken());
    }
  }, [appDispatch, chainId]);

  const handleToken0Select = useCallback(
    (token?: IWhitelistToken) => {
      appDispatch(farmSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token?.globalName}));
    },
    [appDispatch],
  );

  const handleToken1Select = useCallback(
    (token?: IWhitelistToken) => {
      appDispatch(farmSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: token?.globalName}));
    },
    [appDispatch],
  );

  const columns = [
    {name: 'Farm', align: 'left'},
    {name: 'TVL', align: 'right'},
    {name: 'Volume 7D', align: 'right'},
    {name: 'Platform', align: 'right'},
    {name: 'APY', align: 'right'},
    {name: 'Earn up to', align: 'right'},
  ];

  const RenderItem = ({item, index}: RenderItemInfo<IYieldFarm>) => {
    if (item?.apy <= 0.1 || item?.tvlNumber < minTVL) {
      return;
    }
    const symbol = item.stakedTokenChainSpecifics?.symbol;
    const vestingInMonth = getVestingInMonth(item.vestingTimeInDays);
    const singleFarmTitle = `${symbol} - ${vestingInMonth} Month`;
    const name = `${item?.liquidityPool?.token0Symbol} - ${item?.liquidityPool?.token1Symbol}${
      vestingInMonth ? `  ${vestingInMonth} Month` : ''
    }`;

    const {yearlyRevenue} = getYearlyRevenue(item);
    const tvlPrice = kFormatter(item?.tvlNumber);
    const initialVolume = getVolume(item?.liquidityPool);
    const volume = kFormatter(initialVolume);
    const platform = item?.platform?.name;

    const singleFarmToken = getTokenByAddress(item.stakedTokenChainSpecifics?.address.hash);
    const lpFarmToken0 = getTokenByAddress(item.liquidityPool?.token0Hash);
    const lpFarmToken1 = getTokenByAddress(item.liquidityPool?.token1Hash);

    const handleNavigation = () => {
      if (item.type === FarmType.FARM_SINGLE) {
        navigate(
          `${paths.poolDetail}?type=${POOL_TYPE.SINGLE_FARM}&address=${item.contractAddress.hash}&discover-farm=true`,
        );
      } else {
        const address =
          item.type === FarmType.FARM_MASTERCHEF ? item.liquidityPool.address.hash : item.contractAddress.hash;
        navigate(
          `${paths.poolDetail}?type=${POOL_TYPE.FARMING_POOL}&address=${address}&farm-type=${item.type}&discover-farm=true`,
        );
      }
    };

    return (
      <StyledTableRow index={index} key={index} onClick={handleNavigation}>
        <StyledTableData>
          <StyledRow className='farmColumn'>
            <TokenIconWrapper>
              <TokenIcon
                width={24}
                height={24}
                token={item.type !== FarmType.FARM_SINGLE ? lpFarmToken0 : singleFarmToken}
              />
              {item.type !== FarmType.FARM_SINGLE && <SecondTokenIcon width={24} height={24} token={lpFarmToken1} />}
            </TokenIconWrapper>
            <BodyParagraph color={COLORS.BLACK_TEXT}>
              {item.type !== FarmType.FARM_SINGLE ? name : singleFarmTitle}
            </BodyParagraph>
          </StyledRow>
        </StyledTableData>
        <StyledTvlTableData index={index}>
          <BodyParagraph color={COLORS.BLACK_TEXT} style={{textAlign: 'right'}}>
            ${tvlPrice ?? 'N/A'}
          </BodyParagraph>
        </StyledTvlTableData>
        <StyledTableData>
          <BodyParagraph
            className='otherColumn'
            color={volume ? COLORS.BLACK_TEXT : COLORS.GRAY_LIGHT}
            style={{textAlign: 'right'}}
          >
            {volume ? `$${volume}` : 'N/A'}
          </BodyParagraph>
        </StyledTableData>
        <StyledTableData>
          <BodyParagraph className='otherColumn' color={COLORS.BLACK_TEXT} style={{textAlign: 'right'}}>
            {platform ?? 'N/A'}
          </BodyParagraph>
        </StyledTableData>
        <StyledTableData>
          <BodyParagraph
            className='otherColumn'
            color={COLORS.BLACK_TEXT}
            style={{textAlign: 'right'}}
            size={PARAGRAPH_FONT_ENUM.BIG}
          >
            {item.apy.toFixed(1) ?? 'N/A'}%
          </BodyParagraph>
        </StyledTableData>
        <StyledTableData>
          <BodyParagraph
            color={COLORS.BLACK_TEXT}
            style={{display: 'flex', justifyContent: 'flex-end'}}
            className='earnColumn'
          >
            {yearlyRevenue > 0.01 && (
              <Button
                title={`$${yearlyRevenue.toFixed(2)} / year`}
                type='button'
                size={BUTTON_SIZE_ENUM.HOME_TABLE}
                homeFarm={true}
                onClick={handleNavigation}
              />
            )}
          </BodyParagraph>
        </StyledTableData>
      </StyledTableRow>
    );
  };

  return (
    <Wrapper {...props}>
      <StyledFullRow>
        <HeaderWrapper>
          <Header color={COLORS.PRIMARY} size={BODY_FONT_ENUM.BUTTON} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
            Farms
          </Header>
          <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.GRAY_LIGHT}>
            Stake and earn
          </BodyParagraph>
        </HeaderWrapper>
        <TokenPairWrapper>
          <TokenSelectWrapper>
            <TokenSelectItem size='small' token={token0} onSelect={() => setShowInputTokenSelectModal(true)} />
          </TokenSelectWrapper>
          <TokenSelectWrapper>
            <TokenSelectItem size='small' token={token1} onSelect={() => setShowOutputTokenSelectModal(true)} />
          </TokenSelectWrapper>
        </TokenPairWrapper>
      </StyledFullRow>
      <StyledBody>
        <StyledFarms>
          {farmLoading ? (
            <StyledFullWrapper>
              <Spinner size={32} color={COLORS.SECONDARY} />
            </StyledFullWrapper>
          ) : (
            <TableView columns={columns} data={filteredFarmingPools} renderItem={RenderItem} />
          )}
        </StyledFarms>
      </StyledBody>
      {handleToken0Select && (
        <TokenSelectModal
          selectedToken={token0}
          showClearToken={true}
          onTokenSelect={handleToken0Select}
          isOpen={showInputTokenSelectModal}
          onDismiss={() => setShowInputTokenSelectModal(false)}
        />
      )}
      {handleToken1Select && (
        <TokenSelectModal
          selectedToken={token1}
          showClearToken={true}
          onTokenSelect={handleToken1Select}
          isOpen={showOutputTokenSelectModal}
          onDismiss={() => setShowOutputTokenSelectModal(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 372px;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 16px;
  }
`;

const Header = styled(BodyVariant)`
  font-weight: 500;
`;

const TokenPairWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
`;

const TokenSelectWrapper = styled.div`
  min-width: 100px;
  padding: 4px 4px 4px 8px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BASE_40};

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: auto;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 100%;
    align-items: flex-start;
  }
`;

const StyledBody = styled.div`
  flex: 1;
  overflow: auto;
  // mask-image: linear-gradient(to right, transparent 0%, black 16px, black calc(100% - 36px), transparent 100%);
`;

const StyledFarms = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    flex-direction: column;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledFullWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    min-height: 200px;
  }
`;

const StyledTableRow = styled.tr<{index?: number}>`
  border-bottom: 1px solid ${COLORS.GRAY_BORDER};
  background-color: ${(props) => (props.index % 2 === 1 ? '#fcfcfc' : 'transparent')};
  cursor: pointer;
`;

const StyledTableData = styled.td`
  border-bottom: 1px solid ${COLORS.GRAY_BORDER};
  padding: 8px 12px;
`;

const StyledTvlTableData = styled(StyledTableData)<{index?: number}>`
  background-color: ${(props) => props.index % 2 === 1 && COLORS.GRAY_BG} !important;
`;

const TokenIconWrapper = styled.div`
  display: flex;
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -4px;
`;
