import {BUTTON_FONT_ENUM} from 'src/styles';

export type ButtonSizeType = {
  height: number;
  labelSize: BUTTON_FONT_ENUM;
  iconSize: number;
  iconMargin: number;
  borderRadius: number;
};

export enum BUTTON_SIZE_ENUM {
  LARGE = 'LARGE',
  DEFAULT = 'DEFAULT',
  NORMAL = 'NORMAL',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
  XSMALL_BOLD = 'XSMALL_BOLD',
  HOME_TABLE = 'HOME_TABLE',
}

export const ButtonSize: Record<BUTTON_SIZE_ENUM, ButtonSizeType> = {
  [BUTTON_SIZE_ENUM.LARGE]: {
    height: 64,
    labelSize: BUTTON_FONT_ENUM.LARGE,
    iconSize: 24,
    iconMargin: 8,
    borderRadius: 56,
  },
  [BUTTON_SIZE_ENUM.DEFAULT]: {
    height: 48,
    labelSize: BUTTON_FONT_ENUM.MEDIUM,
    iconSize: 24,
    iconMargin: 4,
    borderRadius: 6,
  },
  [BUTTON_SIZE_ENUM.NORMAL]: {
    height: 40,
    labelSize: BUTTON_FONT_ENUM.NORMAL,
    iconSize: 20,
    iconMargin: 4,
    borderRadius: 6,
  },
  [BUTTON_SIZE_ENUM.SMALL]: {
    height: 32,
    labelSize: BUTTON_FONT_ENUM.SMALL,
    iconSize: 20,
    iconMargin: 4,
    borderRadius: 12,
  },
  [BUTTON_SIZE_ENUM.XSMALL]: {
    height: 32,
    labelSize: BUTTON_FONT_ENUM.XSMALL,
    iconSize: 20,
    iconMargin: 4,
    borderRadius: 12,
  },
  [BUTTON_SIZE_ENUM.XSMALL_BOLD]: {
    height: 32,
    labelSize: BUTTON_FONT_ENUM.XSMALL_BOLD, // Assuming this is defined in your BUTTON_FONT_ENUM with bold font weight
    iconSize: 20,
    iconMargin: 4,
    borderRadius: 12,
  },
  [BUTTON_SIZE_ENUM.HOME_TABLE]: {
    height: 32,
    labelSize: BUTTON_FONT_ENUM.XSMALL, // Assuming this is defined in your BUTTON_FONT_ENUM with bold font weight
    iconSize: 20,
    iconMargin: 4,
    borderRadius: 6,
  },
};
