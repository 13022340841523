import {useEffect} from 'react';

import {Connector} from '@web3-react/types';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';

import {Connection, networkConnection, useGetConnection} from '../connectors';

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate();
    }
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`);
  }
}

export default function useEagerlyConnect() {
  const dispatch = useAppDispatch();

  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);
  const getConnection = useGetConnection();

  let selectedConnection: Connection | undefined;
  if (selectedWallet) {
    try {
      selectedConnection = getConnection(selectedWallet);
    } catch {
      dispatch(userSlice.actions.updateSelectedWallet({wallet: undefined}));
    }
  }

  useEffect(() => {
    connect(networkConnection.connector);

    if (selectedConnection) {
      connect(selectedConnection.connector);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
