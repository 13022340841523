import BLUE_001 from 'src/assets/images/avatars/Blue_001.svg';
import BLUE_002 from 'src/assets/images/avatars/Blue_002.svg';
import BLUE_003 from 'src/assets/images/avatars/Blue_003.svg';
import BLUE_004 from 'src/assets/images/avatars/Blue_004.svg';
import BLUE_005 from 'src/assets/images/avatars/Blue_005.svg';
import DARK_001 from 'src/assets/images/avatars/Dark_001.svg';
import DARK_002 from 'src/assets/images/avatars/Dark_002.svg';
import DARK_003 from 'src/assets/images/avatars/Dark_003.svg';
import DARK_004 from 'src/assets/images/avatars/Dark_004.svg';
import DARK_005 from 'src/assets/images/avatars/Dark_005.svg';
import GREEN_001 from 'src/assets/images/avatars/Green_001.svg';
import GREEN_002 from 'src/assets/images/avatars/Green_002.svg';
import GREEN_003 from 'src/assets/images/avatars/Green_003.svg';
import GREEN_004 from 'src/assets/images/avatars/Green_004.svg';
import GREEN_005 from 'src/assets/images/avatars/Green_005.svg';
import PURPLE_001 from 'src/assets/images/avatars/Purple_001.svg';
import PURPLE_002 from 'src/assets/images/avatars/Purple_002.svg';
import PURPLE_003 from 'src/assets/images/avatars/Purple_003.svg';
import PURPLE_004 from 'src/assets/images/avatars/Purple_004.svg';
import PURPLE_005 from 'src/assets/images/avatars/Purple_005.svg';
import RED_001 from 'src/assets/images/avatars/Red_001.svg';
import RED_002 from 'src/assets/images/avatars/Red_002.svg';
import RED_003 from 'src/assets/images/avatars/Red_003.svg';
import RED_004 from 'src/assets/images/avatars/Red_004.svg';
import RED_005 from 'src/assets/images/avatars/Red_005.svg';

export const AVATARS = [
  [GREEN_001, BLUE_001, PURPLE_001, DARK_001, RED_001],
  [GREEN_002, BLUE_002, PURPLE_002, DARK_002, RED_002],
  [GREEN_003, BLUE_003, PURPLE_003, DARK_003, RED_003],
  [GREEN_004, BLUE_004, PURPLE_004, DARK_004, RED_004],
  [GREEN_005, BLUE_005, PURPLE_005, DARK_005, RED_005],
];
