import React from 'react';

import {COLORS} from 'src/styles';
import styled from 'styled-components';

export const History = () => {
  return (
    <ComingSoonContainer>
      <ComingSoon>Coming Soon</ComingSoon>
    </ComingSoonContainer>
  );
};

const ComingSoonContainer = styled.main`
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

const ComingSoon = styled.h1`
  font-size: 1.5rem;
  color: ${COLORS.GRAY_TEXT};
  margin: 0;
`;

export default History;
