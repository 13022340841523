import React, {useState} from 'react';

import {CloseIcon} from 'src/components/Svgs';
import {useModals} from 'src/contexts/modals';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

import {TokenReceiveView} from './TokenReceiveView';
import {TokenSelectView} from './TokenSelectView';
import {Modal} from '../Modal';

type TokenReceiveModalProps = {
  isOpen: boolean;
};

export const TokenReceiveModal = ({isOpen}: TokenReceiveModalProps) => {
  const modalContext = useModals();
  const [step, setStep] = useState(0);
  const [selectedToken, setSelectedToken] = useState<IWhitelistToken>();

  const onConfirm = (token: IWhitelistToken) => {
    setSelectedToken(token);
    setStep(1);
  };

  const onDismiss = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateTokenReceiveModal', payload});
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Receive</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        {step === 0 && <TokenSelectView onConfirm={onConfirm} onDismiss={onDismiss} />}
        {step === 1 && <TokenReceiveView token={selectedToken} onBack={() => setStep(0)} />}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
