import {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract} from 'ethers';
import ERC20_ABI from 'src/abis/ERC20.json';
import PANCAKEPAIR_ABI from 'src/abis/IPancakePair.json';
import {getChainInfo} from 'src/constants';
import {ApprovalState, getLpToken, useApproveCallback} from 'src/hooks/kyber-swap';
import {useAppSelector} from 'src/state/hooks';
import {ILiquidityPool, IWhitelistToken, IYieldFarm, SwapSettings} from 'src/types';
import {AddLiquidityCall, ZapOutCall} from 'src/types/topup';
import {getSwapInfo} from 'src/utils/farm-util';
import {calculateGasMargin, tryParseAmount} from 'src/utils/swap/kyber-swap';
import {defaultSlippageTolerance} from 'src/utils/zap-utils';

import {useWithdrawFarmCallback} from './useWithdrawFarm';
import {useZapOutCallback} from './useZapOut';

export const useWithdraw = (
  lpToken1?: IWhitelistToken,
  lpToken2?: IWhitelistToken,
  lpToken1Amount?: number,
  lpToken2Amount?: number,
  liqudityPool?: ILiquidityPool,
  farmItem?: IYieldFarm,
  lpTokensAmount?: BigNumber,
  toToken?: IWhitelistToken,
) => {
  const {account, chainId, provider} = useWeb3React();
  const {zapSettings} = useAppSelector((state) => state.user);
  const chainInfo = getChainInfo(chainId);
  const liquidusAutoLPFarmOutAddress = chainInfo?.liquidusAutoLPFarmOutAddress;

  const lpTokenCurrency = getLpToken(liqudityPool?.address?.hash, chainId);
  const lpTokenCurrencyOutputAmount = tryParseAmount(lpTokensAmount, lpTokenCurrency, false);

  const {estimateGas, callback} = useZapOutCallback();
  const {estimateGasForWithdrawFarm, withdrawFarmCallback} = useWithdrawFarmCallback(farmItem);

  const [lpTokenApprovalState, lpTokenApproveCallback] = useApproveCallback(
    lpTokenCurrencyOutputAmount,
    account,
    liquidusAutoLPFarmOutAddress,
  );

  const createZapOutCall = useCallback(
    async (
      lp: ILiquidityPool,
      lpToken1?: IWhitelistToken,
      lpToken2?: IWhitelistToken,
      chainId?: number,
      lpToken1Amount?: number,
      lpToken2Amount?: number,
      lpTokensAmount?: BigNumber,
      toToken?: IWhitelistToken,
      swapSettings?: SwapSettings,
    ) => {
      const routerAddress = lp.platform.routerAddresses?.find((address) => address.chainId === chainId);
      if (!routerAddress || !lpToken1Amount || !lpToken2Amount || !lp.address?.hash || !lpToken1) {
        throw new Error('createZapOutCall function does not have all needed variables!');
      }
      if (!lpToken1?.address || !lpToken2?.address || !lpTokensAmount) {
        throw new Error('One of lp tokens is not defined!');
      }
      const token1contract = new Contract(lpToken1.address, ERC20_ABI, provider);
      const token2contract = new Contract(lpToken2.address, ERC20_ABI, provider);

      const balance1 = await token1contract.balanceOf(lp.address.hash);
      const balance2 = await token2contract.balanceOf(lp.address.hash);

      const pairContract = new Contract(lp.address.hash, PANCAKEPAIR_ABI, provider);

      const totalSupply = await pairContract.totalSupply();

      // const token1OutAmount = lpTokensAmount?.mul(balance1).div(totalSupply).mul(1000).div(1001);
      // const token2OutAmount = lpTokensAmount?.mul(balance2).div(totalSupply).mul(1000).div(1001);

      const token1Amount = balance1?.mul(lpTokensAmount)?.div(totalSupply);
      const token2Amount = balance2?.mul(lpTokensAmount)?.div(totalSupply);

      // 1% default slippage
      const token1MinAmount = token1Amount?.mul(10000 - defaultSlippageTolerance)?.div(10000);
      const token2MinAmount = token2Amount?.mul(10000 - defaultSlippageTolerance)?.div(10000);

      const addLiquidityCall: AddLiquidityCall = {
        _router: routerAddress?.hash,
        _amount0Min: token1MinAmount,
        _amount1Min: token2MinAmount,
      };

      if (lpToken1?.address && lpToken2?.address && !toToken) {
        const zapOutCall: ZapOutCall = {
          _toTokens: [lpToken1?.address, lpToken2?.address],
          swaps: [],
          addLiquidityCall: addLiquidityCall,
          _liquidity: lpTokensAmount,
          _lpAddress: lp.address?.hash,
        };

        return {zapOutCall};
      } else {
        if (!toToken?.address || !lpToken1?.address || !lpToken2?.address) {
          throw new Error('To token or lp tokens are not defined!');
        }
        const swapsInfo = [];
        const swapInfo1 = await getSwapInfo(chainId, token1MinAmount, lpToken1, toToken, swapSettings, false);
        if (swapInfo1) {
          swapsInfo.push(swapInfo1);
        }
        const swapInfo2 = await getSwapInfo(chainId, token2MinAmount, lpToken2, toToken, swapSettings, false);
        if (swapInfo2) {
          swapsInfo.push(swapInfo2);
        }
        const swaps = swapsInfo.map((swap) => {
          return {
            _swapToken: swap.inputAmount.currency.wrapped.address,
            _router: swap.routerAddress,
            apiData: swap.encodedSwapData,
          };
        });
        const zapOutCall: ZapOutCall = {
          _toTokens: [toToken.address],
          swaps: swaps,
          addLiquidityCall: addLiquidityCall,
          _liquidity: lpTokensAmount,
          _lpAddress: lp.address?.hash,
        };
        return {zapOutCall, swapsInfo};
      }
    },
    [provider],
  );

  const withdrawLpTokensCallback = useCallback(async () => {
    if (!lpTokensAmount) {
      throw new Error('lpTokensAmount is not defeined!!');
    }
    const gasEstimation = await estimateGasForWithdrawFarm(lpTokensAmount);
    if (gasEstimation) {
      return await withdrawFarmCallback(calculateGasMargin(gasEstimation), lpTokensAmount);
    }
  }, [estimateGasForWithdrawFarm, lpTokensAmount, withdrawFarmCallback]);

  const approveLpTokensCallback = useCallback(async () => {
    if (lpTokenApprovalState === ApprovalState.NOT_APPROVED) {
      return await lpTokenApproveCallback();
    }
  }, [lpTokenApprovalState, lpTokenApproveCallback]);

  const zapOutCallback = useCallback(async () => {
    if (!liqudityPool || !lpTokensAmount) {
      throw new Error('LiqudityPool or lpTokensAmount is not defined!');
    }
    const {zapOutCall, swapsInfo} = await createZapOutCall(
      liqudityPool,
      lpToken1,
      lpToken2,
      chainId,
      lpToken1Amount,
      lpToken2Amount,
      lpTokensAmount,
      toToken,
      zapSettings,
    );

    if (callback && zapOutCall && estimateGas) {
      const gasEstimation = await estimateGas(zapOutCall);
      return {
        zapOutResult: gasEstimation ? await callback(zapOutCall) : undefined,
        swaps: swapsInfo,
        zapOutCall: zapOutCall,
      };
    } else {
      throw new Error('Callback or zapInCall is not availiable!');
    }
  }, [
    liqudityPool,
    lpTokensAmount,
    createZapOutCall,
    lpToken1,
    lpToken2,
    chainId,
    lpToken1Amount,
    lpToken2Amount,
    toToken,
    zapSettings,
    callback,
    estimateGas,
  ]);

  const swapInfo = useCallback(async () => {
    if (!liqudityPool || !lpTokensAmount) {
      throw new Error('LiqudityPool or lpTokensAmount is not defined!');
    }
    const {swapsInfo} = await createZapOutCall(
      liqudityPool,
      lpToken1,
      lpToken2,
      chainId,
      lpToken1Amount,
      lpToken2Amount,
      lpTokensAmount,
      toToken,
      zapSettings,
    );

    if (swapInfo) {
      return {swapsInfo: swapsInfo};
    } else {
      throw new Error('swap data is not available');
    }
  }, [
    liqudityPool,
    lpTokensAmount,
    createZapOutCall,
    lpToken1,
    lpToken2,
    chainId,
    lpToken1Amount,
    lpToken2Amount,
    toToken,
    zapSettings,
  ]);

  return {
    isLPTokenApproved: lpTokenApprovalState === ApprovalState.APPROVED,
    approveLpTokensCallback,
    zapOutCallback,
    swapInfo,
    withdrawLpTokensCallback,
  };
};
