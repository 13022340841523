export enum COLORS {
  PRIMARY = '#112455',
  SECONDARY = '#17E7D6',
  SECONDARY_INACTIVE = '#17E7D64C',
  SECONDARY_TRANSPARENT = '#17E7D61A',
  WHITE = '#ffffff',
  BLACK = '#000000',
  GREEN = '#11D179',
  GREEN_LIGHT = '#05F600',
  GRAY = '#415077',
  GRAY_LIGHT = '#9AA6CF',
  GRAY_LIGHTER = '#cdd3e7',
  GRAY_LIGHTEST = '#F7F7F7',
  GRAY_TEXT = '#888D94',
  GRAY_BORDER = '#E7E7E7',
  GRAY_BG = '#FAFAFA',
  GRAY_BASE_40 = '#D7DCEC',
  BLACK_TEXT = '#111315',
  STONE = '#DCDCDC',
  OVERLAY = '#41507782',
  DISABLED = '#F9F9FB',
  WARNING = '#F76379',
  ORANGE = '#F79863',
  BLUE_LIGHTEST = '#EBF0FF',
}

export type ColorsTypes = COLORS;
