import {useContext, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {Button} from 'src/components/Buttons';
import {StakLiqItem} from 'src/components/Tokens/StakeLiqItem';
import {BodyVariant} from 'src/components/Typography';
import {NEW_LIQ_GLOBAL_NAME} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IFarm, IYieldFarm, PlatformSorting} from 'src/types';
import {sortBy} from 'src/utils/token-util';
import styled from 'styled-components';

type StakeSelectLIQProps = {
  onConfirm: (farm: IYieldFarm) => void;
};

export const StakeSelectLIQ = ({onConfirm}: StakeSelectLIQProps) => {
  const {chainId} = useWeb3React();
  const {farms: liqFarms} = useContext(AppContext);
  const {getTokenByGlobalName} = useToken();
  const [selectedLiqFarm, setSelectedLiqFarm] = useState<IFarm>();
  const liqToken = getTokenByGlobalName(NEW_LIQ_GLOBAL_NAME);

  const singleLiqFarms = useMemo(() => {
    let _singleTokens = liqFarms?.filter(
      (item) =>
        item.contractAddress?.chainId === chainId &&
        !item.liquidityPool &&
        item.vestingTimeInDays !== 0 &&
        item.rewardTokenChainSpecifics.symbol === 'LIQ',
    );
    _singleTokens = sortBy(_singleTokens || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);
    return _singleTokens;
  }, [liqFarms, chainId]);

  const onSelectLiqFarm = (item: IFarm) => {
    setSelectedLiqFarm(item);
  };

  return (
    <Wrapper>
      <StyledCol>
        <BodyVariant color={COLORS.PRIMARY}>Stake & lock LIQ to a farm</BodyVariant>
        {singleLiqFarms?.map((item, index) => (
          <StakLiqItem
            key={index}
            liqToken={liqToken}
            item={item}
            selected={selectedLiqFarm === item}
            type='1'
            onPress={onSelectLiqFarm}
          />
        ))}
      </StyledCol>
      <ButtonWrapper>
        <Button
          color={COLORS.PRIMARY}
          disabled={!selectedLiqFarm}
          title='Confirm Lock Duration'
          onClick={() => onConfirm(selectedLiqFarm)}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;
