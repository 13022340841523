import * as React from 'react';

import {COLORS} from 'src/styles/colors';
import {IconType} from 'src/types';

export const RemoveIcon = ({size = 25, color = COLORS.WARNING, ...props}: IconType) => (
  <svg width={size} height={size} viewBox='0 0 14 17' fill='none' {...props}>
    <path
      d='M11.634 14.5823C11.6342 14.659 11.6193 14.735 11.59 14.8059C11.5607 14.8768 11.5176 14.9412 11.4633 14.9953C11.409 15.0494 11.3445 15.0923 11.2735 15.1214C11.2025 15.1504 11.1265 15.1651 11.0498 15.1646H2.8842C2.72976 15.1646 2.58164 15.1033 2.47243 14.9941C2.36323 14.8849 2.30188 14.7368 2.30188 14.5823V4.08186H11.634V14.5823ZM4.6349 1.7507C4.6349 1.59609 4.69619 1.44778 4.80534 1.33828C4.91449 1.22877 5.06261 1.167 5.21722 1.16651H8.71675C8.79354 1.16626 8.86961 1.18121 8.9406 1.21048C9.01159 1.23975 9.07609 1.28277 9.13039 1.33707C9.18468 1.39136 9.2277 1.45586 9.25697 1.52685C9.28624 1.59784 9.30119 1.67391 9.30094 1.7507V2.91721H4.6349V1.7507ZM13.3828 2.91721H10.4675V1.7507C10.4675 1.28639 10.283 0.841088 9.95469 0.512768C9.62637 0.184448 9.18107 0 8.71675 0H5.21722C4.75323 0.00049466 4.30841 0.185162 3.98049 0.513429C3.65257 0.841695 3.46839 1.28671 3.46839 1.7507V2.91721H0.551176C0.402192 2.92564 0.26209 2.99077 0.159598 3.09922C0.0571058 3.20768 0 3.35124 0 3.50047C0 3.64969 0.0571058 3.79325 0.159598 3.90171C0.26209 4.01017 0.402192 4.07529 0.551176 4.08372H1.13537V14.5823C1.13537 15.0461 1.31961 15.491 1.64758 15.8189C1.97555 16.1469 2.42038 16.3312 2.8842 16.3312H11.0498C11.2796 16.3314 11.5072 16.2863 11.7196 16.1986C11.932 16.1108 12.125 15.982 12.2876 15.8196C12.4502 15.6572 12.5792 15.4643 12.6672 15.252C12.7552 15.0397 12.8005 14.8121 12.8005 14.5823V4.08186H13.3828C13.4622 4.08635 13.5416 4.07459 13.6163 4.04731C13.691 4.02003 13.7593 3.9778 13.817 3.92319C13.8748 3.86859 13.9209 3.80277 13.9523 3.72976C13.9838 3.65676 14 3.5781 14 3.4986C14 3.4191 13.9838 3.34044 13.9523 3.26744C13.9209 3.19443 13.8748 3.12861 13.817 3.07401C13.7593 3.01941 13.691 2.97717 13.6163 2.94989C13.5416 2.92261 13.4622 2.91085 13.3828 2.91534V2.91721Z'
      fill={color}
    />
    <path
      d='M8.71513 6.41676C8.56069 6.41676 8.41258 6.47811 8.30337 6.58732C8.19416 6.69653 8.13281 6.84464 8.13281 6.99908V12.2493C8.14124 12.3983 8.20637 12.5384 8.31483 12.6409C8.42329 12.7434 8.56685 12.8005 8.71607 12.8005C8.86529 12.8005 9.00885 12.7434 9.11731 12.6409C9.22576 12.5384 9.29089 12.3983 9.29932 12.2493V6.99908C9.29957 6.92239 9.28462 6.8464 9.25533 6.77551C9.22604 6.70463 9.18297 6.64025 9.12865 6.5861C9.07433 6.53196 9.00982 6.48911 8.93884 6.46005C8.86787 6.43098 8.79183 6.41627 8.71513 6.41676Z'
      fill={color}
    />
    <path
      d='M5.21538 6.41676C5.06094 6.41676 4.91282 6.47811 4.80361 6.58732C4.69441 6.69653 4.63306 6.84464 4.63306 6.99908V12.2493C4.64149 12.3983 4.70662 12.5384 4.81507 12.6409C4.92353 12.7434 5.06709 12.8005 5.21631 12.8005C5.36553 12.8005 5.50909 12.7434 5.61755 12.6409C5.72601 12.5384 5.79114 12.3983 5.79957 12.2493V6.99908C5.79982 6.92239 5.78487 6.8464 5.75557 6.77551C5.72628 6.70463 5.68322 6.64025 5.6289 6.5861C5.57458 6.53196 5.51007 6.48911 5.43909 6.46005C5.36811 6.43098 5.29208 6.41627 5.21538 6.41676Z'
      fill={color}
    />
  </svg>
);

export default RemoveIcon;
