import {BigNumber} from 'ethers';

import {IPlatform} from './platform';
import {IPrice, ITokenAddress} from './token';

export enum POOL_TYPE {
  FARMING_POOL = 'farming-pool',
  LIQUIDITY_POOL = 'liquidity-Pool',
  SINGLE_FARM = 'single-farm',
}

export type ILiquidityPoolData = {
  liquidityPools: ILiquidityPool[];
};

export type ILiquidityPool = {
  // id: number;
  poolRewards: number;
  address?: ITokenAddress;
  platform: IPlatform;
  token0Hash?: string;
  token0Symbol: string;
  token0PriceUSD?: IPrice;
  reserve0: string;
  token1Hash?: string;
  token1Symbol: string;
  token1PriceUSD?: IPrice;
  reserve1: string;
  lpValueUSD?: IPrice;
  totalSupply?: string;
  decimals: number;
  lastPriceUpdate?: string;
  lpTokenPriceUSD: IPrice;
  balance?: BigNumber;
  poolApy?: number;
  pastVolumes: number[];
};
