import {useNavigate} from 'react-router-dom';
import {paths} from 'src/constants';
import {useToken} from 'src/hooks/useToken';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {ILiquidityPool, POOL_TYPE} from 'src/types';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph, BodyVariant} from '../Typography';

type PoolItemProps = {
  data?: ILiquidityPool;
  size?: 'default' | 'small';
  isPlatformLiquidity?: boolean;
};

export const PoolItem = ({data, size = 'default', isPlatformLiquidity}: PoolItemProps) => {
  const navigate = useNavigate();
  const {getGlobalTokenItemByAddress} = useToken();
  const lpBalance = getTokenUSDPrice(formatBigNumber(data?.balance, data?.decimals), data?.lpTokenPriceUSD);
  const name = `${data?.token0Symbol} - ${data?.token1Symbol}`;
  const token0 = getGlobalTokenItemByAddress(data.token0Hash, data.address.chainId);
  const token1 = getGlobalTokenItemByAddress(data.token1Hash, data.address.chainId);

  const handleNavigation = () => {
    const platform = isPlatformLiquidity ? `&platform=${data?.platform?.name}` : '';
    navigate(`${paths.poolDetail}?type=${POOL_TYPE.LIQUIDITY_POOL}&address=${data.address.hash}${platform}`);
  };

  return (
    <StyledTokenBox onClick={handleNavigation}>
      <StyledLeftWrapper>
        <TokenIconWrapper>
          <TokenIcon width={size === 'default' ? 40 : 24} height={size === 'default' ? 40 : 24} token={token0} />
          <SecondTokenIcon width={size === 'default' ? 40 : 24} height={size === 'default' ? 40 : 24} token={token1} />
        </TokenIconWrapper>
        <StyledTokenNameWrapper>
          <BodyVariant
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            {name}
          </BodyVariant>
          <StyledPlatformLabel color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {data?.platform?.name}
          </StyledPlatformLabel>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <StyledTokenLabel
          color={COLORS.PRIMARY}
          size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
        >
          ${lpBalance.toFixed(2)}
        </StyledTokenLabel>
        <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          {data?.poolApy ? `${data?.poolApy?.toFixed(1)}% APY` : ''}
        </StyledTokenPrice>
      </StyledRightWrapper>
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.button<{selected?: boolean}>`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const TokenIconWrapper = styled.div<{size?: 'default' | 'small'}>`
  display: flex;
  margin-right: ${(props) => (props.size == 'default' ? 24 : 12)}px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const SecondTokenIcon = styled(TokenIcon)<{size?: 'default' | 'small'}>`
  margin-left: -${(props) => (props.size == 'default' ? 20 : 10)}px;
  margin-top: ${(props) => (props.size == 'default' ? 20 : 10)}px;
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTokenNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPlatformLabel = styled(BodyParagraph)`
  text-align: left;
`;

const StyledTokenLabel = styled(BodyVariant)`
  text-align: right;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;
