import {useCallback, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, ethers} from 'ethers';
import {toast} from 'react-toastify';
import {SUPPORTED_NETWORKS} from 'src/constants';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {TokenValidationStatus} from 'src/types';
import {IWhitelistToken} from 'src/types/token';
import {getTokenInfo} from 'src/utils/token-util';

export const useTokenValidation = (contractAddress: string) => {
  const {provider, account, chainId} = useWeb3React();
  const [tokenInfo, setTokenInfo] = useState<IWhitelistToken | null>(null);
  const [status, setStatus] = useState<TokenValidationStatus>(TokenValidationStatus.IDLE);
  const {customTokens} = useAppSelector((state) => state.user);

  const appDispatch = useAppDispatch();

  const supportedNetworks = Object.values(SUPPORTED_NETWORKS);
  const currentNetwork = useMemo(
    () => supportedNetworks.find((item) => item.chainId === chainId),
    [chainId, supportedNetworks],
  );

  const validateToken = useCallback(async () => {
    if (!ethers.utils.isAddress(contractAddress)) {
      setStatus(TokenValidationStatus.INVALID);
      return;
    }

    if (!provider || !currentNetwork || !account) {
      setStatus(TokenValidationStatus.NOT_CONNECTED);
      return;
    }

    setStatus(TokenValidationStatus.LOADING);

    try {
      const tokenItem = {
        globalName: '',
        name: '',
        symbol: '',
        chainId: 0,
        address: contractAddress,
        decimals: 18,
        interfaceDecimals: 4,
        balance: BigNumber.from(0),
        customToken: true,
      };

      const _tokenInfo = await getTokenInfo(provider, tokenItem, account);
      if (_tokenInfo && _tokenInfo[0] && _tokenInfo[1] && _tokenInfo[2]) {
        const [name, symbol, decimals] = _tokenInfo;
        tokenItem.globalName = name;
        tokenItem.name = name;
        tokenItem.symbol = symbol;
        tokenItem.decimals = decimals;
        tokenItem.chainId = currentNetwork.chainId;

        setTokenInfo(tokenItem);
        setStatus(TokenValidationStatus.VALID);
      } else {
        setStatus(TokenValidationStatus.INVALID);
      }
    } catch (error) {
      toast.error('Cannot read token information from blockchain. Please contact support.');
      setStatus(TokenValidationStatus.INVALID);
    }
  }, [contractAddress, provider, currentNetwork, account]);

  useEffect(() => {
    if (contractAddress.trim()) {
      validateToken();
    } else {
      setStatus(TokenValidationStatus.IDLE);
    }
  }, [contractAddress, validateToken]);

  const saveCustomToken = useCallback(
    (newCustomTokens: IWhitelistToken[]) => {
      // Dispatch to Redux store
      appDispatch(userSlice.actions.updateCustomTokenImport({customTokens: newCustomTokens}));
    },
    [appDispatch],
  );

  const removeCustomToken = (token: IWhitelistToken) => {
    const updatedCustomTokens = customTokens.filter((customToken) => token?.address !== customToken?.address);
    saveCustomToken(updatedCustomTokens);
  };

  return {tokenInfo, status, saveCustomToken, removeCustomToken};
};
