import {gql} from '@apollo/client';

export const GET_T_AND_C_AGREEMENT = gql`
  query GetTandCAgreement($accountAddress: String!) {
    getTandCsAgreement(accountAddress: $accountAddress) {
      accountHash
      signDate
      signature
      originalMessage
    }
  }
`;
