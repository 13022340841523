import React from 'react';

import {Button} from 'src/components/Buttons';
import {LiquidityInputItem} from 'src/components/Liquidity/LiquidityInputItem';
import {TickIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type RemoveLiquidityProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  liquidity: number;
  token0Amount: number;
  token1Amount: number;
  slippage: number;
  totalInputValue: number;
  loading?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  onSettings?: () => void;
  onConfirm?: () => void;
  onFail?: () => void;
};

export const RemoveLiquidity = ({
  token0,
  token1,
  liquidity,
  token0Amount,
  token1Amount,
  slippage,
  totalInputValue,
  loading,
  disabled,
  errorMsg,
  onSettings,
  onFail,
  onConfirm,
}: RemoveLiquidityProps) => {
  return (
    <Body>
      <StepsContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve LP Tokens</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Remove Liquidity</BodyVariant>
        </StyledRow>
      </StepsContainer>
      <TokenInfoBox>
        <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          You will receive
        </BodyParagraph>
        <LiquidityInputItem
          token0={token0}
          token1={token1}
          token0BigNumberFormatted={token0Amount}
          token1BigNumberFormatted={token1Amount}
        />
        <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          = ${totalInputValue.toFixed(2)}
        </BodyParagraph>
        <StyledRowContainer marginTop={30}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            {token0?.symbol} / {token1?.symbol} LP Tokens Burned
          </BodyParagraph>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {liquidity.toFixed(7)}
          </BodyParagraph>
        </StyledRowContainer>
        <StyledRowContainer marginTop={20}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            Slippage
          </BodyParagraph>
          <BodyParagraph
            color={COLORS.SECONDARY}
            size={PARAGRAPH_FONT_ENUM.SMALL}
            cursor={'pointer'}
            onClick={onSettings}
          >
            {slippage ?? 0.1}%
          </BodyParagraph>
        </StyledRowContainer>
        <StyledRowContainer marginTop={20}>
          <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.XSMALL}>
            Output amount is estimated if the price changes more than your slippage settings it will revert
          </BodyParagraph>
        </StyledRowContainer>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title={errorMsg ? 'Try Again' : 'Remove Liquidity'}
          onClick={errorMsg ? onFail : onConfirm}
        />
        {errorMsg ? (
          <StyledParagraph color={COLORS.WARNING}>{errorMsg}</StyledParagraph>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StepsContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 16px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;
