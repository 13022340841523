import {DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {Center} from './center';
import {Header} from './header';

export const Home = () => {
  return (
    <Wrapper>
      <Container>
        <Header />
        <Center />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 36px;
  background-color: #f9f9f9;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 16px;
  }
`;

const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;
