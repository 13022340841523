import {useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {ConnectWalletButton} from 'src/components/ConnectWalletButton';
import {FarmItem, PoolItem, TokenDepositItem} from 'src/components/Holdings';
import NoHoldings from 'src/components/Holdings/NoHoldings';
import {Spinner} from 'src/components/Spinner';
import {FilterIcon} from 'src/components/Svgs';
import {BodyVariant} from 'src/components/Typography';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {FarmType, IYieldFarm, PlatformSettingsMode, PlatformSettingsSection, PlatformSorting} from 'src/types';
import {formatBigNumber, getTokenUSDPrice, sortBy} from 'src/utils/token-util';
import styled from 'styled-components';

type FilterIndicatorProps = {
  isActive?: boolean;
};

export const Holdings = () => {
  const {farmingPools, liquidityPools, farms, gauges, grizzlyFarms, lastHoldingsLoad} = useContext(AppContext);
  const {platformSettings} = useAppSelector((state) => state.user);
  const {chainId, account} = useWeb3React();
  const modalContext = useModals();
  const platformSlug = platformSettings?.holdingsPlatform?.slug ?? platformSettings?.platform?.slug;

  const [yieldPoolsLength, setYieldPoolsLength] = useState(0);
  const [liquidityPoolsLength, setLiquidityPoolsLength] = useState(0);
  const [tokenDepositPoolsLength, setTokenDepositPoolsLength] = useState(0);
  const [areFiltersActive, setAreFiltersActive] = useState(false);

  const filteredFarmingPools = useMemo(() => {
    if (farmingPools && farms && gauges && grizzlyFarms) {
      const _lpFarms = farms?.filter(
        (item) =>
          item.contractAddress?.chainId === chainId &&
          item.liquidityPool &&
          getTokenUSDPrice(formatBigNumber(item.balance, 18), item.liquidityPool?.lpTokenPriceUSD) > 0.1,
      );
      const mergedFarms: IYieldFarm[] = [...farmingPools, ..._lpFarms, ...gauges, ...grizzlyFarms];

      let _farmingPools = mergedFarms?.filter((item: IYieldFarm) => {
        return item.balance && item.balance.gt(0) && (platformSlug === 'all' || item.platform.slug === platformSlug);
      });

      setYieldPoolsLength(
        mergedFarms?.filter((item: IYieldFarm) => {
          return item.balance && item.balance.gt(0);
        }).length,
      );

      if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_ALPHABET) {
        _farmingPools = sortBy(
          _farmingPools || [],
          (item) => item.liquidityPool?.token0Symbol ?? '',
          PlatformSorting.SORT_BY_ALPHABET,
        );
      } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_APY) {
        _farmingPools = sortBy(_farmingPools || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);
      } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_USER_DEPOSIT) {
        _farmingPools = sortBy(
          _farmingPools || [],
          (item) => getTokenUSDPrice(formatBigNumber(item.balance, 18), item.liquidityPool?.lpTokenPriceUSD),
          PlatformSorting.SORT_BY_USER_DEPOSIT,
        );
      }
      return _farmingPools;
    }
  }, [farmingPools, farms, gauges, grizzlyFarms, platformSettings?.holdingSorting, chainId, platformSlug]);

  const filteredLiquidityPools = useMemo(() => {
    let _liquidityPools = liquidityPools?.filter(
      (item) =>
        item.balance &&
        getTokenUSDPrice(formatBigNumber(item.balance, 18), item.lpTokenPriceUSD) > 0.1 &&
        (platformSlug === 'all' || item.platform.slug === platformSlug),
    );

    setLiquidityPoolsLength(
      liquidityPools?.filter(
        (item) => item.balance && getTokenUSDPrice(formatBigNumber(item.balance, 18), item.lpTokenPriceUSD) > 0.1,
      ).length,
    );

    if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_ALPHABET) {
      _liquidityPools = sortBy(_liquidityPools || [], (item) => item.token0Symbol, PlatformSorting.SORT_BY_ALPHABET);
    } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_USER_DEPOSIT) {
      _liquidityPools = sortBy(
        _liquidityPools || [],
        (item) => formatBigNumber(item.balance, item.decimals),
        PlatformSorting.SORT_BY_USER_DEPOSIT,
      );
    }
    return _liquidityPools;
  }, [liquidityPools, platformSettings?.holdingSorting, platformSlug]);

  const tokenDepositData = useMemo(() => {
    let _singleTokens = farms?.filter(
      (item) =>
        item.contractAddress?.chainId === chainId &&
        !item.liquidityPool &&
        formatBigNumber(item.balance, 18) > 1 &&
        (platformSlug === 'all' || item.platform.slug === platformSlug),
    );

    setTokenDepositPoolsLength(
      farms?.filter(
        (item) =>
          item.contractAddress?.chainId === chainId && !item.liquidityPool && formatBigNumber(item.balance, 18) > 0.1,
      ).length,
    );

    if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_ALPHABET) {
      _singleTokens = sortBy(
        _singleTokens || [],
        (item) => item.vestingTimeInDays || 0,
        PlatformSorting.SORT_BY_ALPHABET,
      );
    } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_APY) {
      _singleTokens = sortBy(_singleTokens || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);
    } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_USER_DEPOSIT) {
      _singleTokens = sortBy(
        _singleTokens || [],
        (item) => formatBigNumber(item.balance, 18),
        PlatformSorting.SORT_BY_USER_DEPOSIT,
      );
    }
    return _singleTokens;
  }, [chainId, farms, platformSettings?.holdingSorting, platformSlug]);

  const handleOpenFilters = () => {
    const payload = {
      isOpen: true,
      type: PlatformSettingsMode.PLATFORM_SETTINGS_HOLDING,
      section: PlatformSettingsSection.PLATFORM_SETTINGS_HOLDING,
    };
    modalContext.dispatch({type: 'updatePlatformSettingsModal', payload});
  };

  const [isHoldingsEmpty, setIsHoldingsEmpty] = useState<boolean>(false);

  useEffect(() => {
    const hasNoHoldings = yieldPoolsLength === 0 && liquidityPoolsLength === 0 && tokenDepositPoolsLength === 0;
    const isIndividualHoldingsEmpty =
      yieldPoolsLength === 0 || liquidityPoolsLength === 0 || tokenDepositPoolsLength === 0;
    const isHoldingsEmpty = hasNoHoldings && isIndividualHoldingsEmpty;

    setIsHoldingsEmpty(isHoldingsEmpty);

    platformSettings?.[PlatformSettingsSection.PLATFORM_SETTINGS_HOLDING]?.slug !== 'all'
      ? setAreFiltersActive(true)
      : setAreFiltersActive(false);
  }, [yieldPoolsLength, liquidityPoolsLength, tokenDepositPoolsLength, platformSettings]);

  return (
    <Wrapper>
      <Container>
        {account ? (
          lastHoldingsLoad ? (
            <StyledSpinner>
              <Spinner size={32} color={COLORS.SECONDARY} />
            </StyledSpinner>
          ) : isHoldingsEmpty ? (
            <NoHoldings heading='No Holdings' />
          ) : (
            <>
              <StyledSection>
                <StyledFullRow>
                  <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
                    Yield Farming
                  </BodyVariant>
                  <StyledIconButton onClick={handleOpenFilters}>
                    <FilterIcon color={areFiltersActive ? COLORS.SECONDARY : COLORS.PRIMARY} />
                    <FilterIndicator isActive={areFiltersActive} />
                  </StyledIconButton>
                </StyledFullRow>
                {filteredFarmingPools?.length > 0 ? (
                  filteredFarmingPools.map((item, index) => {
                    return item.type !== FarmType.FARM_SINGLE ? (
                      <FarmItem key={index} data={item} />
                    ) : (
                      <TokenDepositItem key={index} data={item} />
                    );
                  })
                ) : (
                  <CenterText fontType={PARAGRAPH_FONT_ENUM.BIG}>
                    You don&apos;t have any deposits here, yet.
                  </CenterText>
                )}
              </StyledSection>
              <StyledSection>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
                  Liquidity Pools
                </BodyVariant>
                {filteredLiquidityPools?.length > 0 ? (
                  filteredLiquidityPools.map((item, index) => <PoolItem key={index} data={item} />)
                ) : (
                  <CenterText fontType={PARAGRAPH_FONT_ENUM.BIG}>
                    You don&apos;t have any deposits here, yet.
                  </CenterText>
                )}
              </StyledSection>
              <StyledSection>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
                  Token Deposits
                </BodyVariant>
                {tokenDepositData?.length > 0 ? (
                  tokenDepositData.map((item, index) => <TokenDepositItem key={index} data={item} />)
                ) : (
                  <CenterText fontType={PARAGRAPH_FONT_ENUM.BIG}>
                    You don&apos;t have any deposits here, yet.
                  </CenterText>
                )}
              </StyledSection>
            </>
          )
        ) : (
          <ConnectWalletButton />
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
`;

const Container = styled.div`
  width: 712px;
  min-height: calc(100vh - 81px);
  box-sizing: border-box;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));
  padding: 80px 0;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 24px 16px;
  }
`;

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconButton = styled.div`
  margin-right: 24px;
  display: flex;
  cursor: pointer;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const StyledSpinner = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const CenterText = styled.p<{fontType: PARAGRAPH_FONT_ENUM}>`
  text-align: center;
  color: ${COLORS.PRIMARY};
  margin-bottom: 20px;
`;

const FilterIndicator = styled.div<FilterIndicatorProps>`
  width: 9px;
  height: 9px;
  flex-shrink: 0;
  border-radius: 10px;
  background: ${(props) => (props.isActive ? '#654CEC' : 'transparent')};
`;
