import {useCallback, useEffect, useMemo, useState} from 'react';

import {Currency, CurrencyAmount, NativeCurrency, Token} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {ZERO_ADDRESS, aggregatorReturnValue, getChainInfo} from 'src/constants';
import {useAppSelector} from 'src/state/hooks';
import {IWhitelistToken} from 'src/types';
import {Aggregator} from 'src/utils/swap/aggregator';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import {formatBigNumber, parseBigNumber} from 'src/utils/token-util';
// import useDebounce from './useDebounce';
import {getTokenAmountFromUSD} from 'src/utils/token-util';

import {WrapType} from './constant';
import {useWrapCallback} from './useWrap';

export function getToken(whitelistToken?: IWhitelistToken): Token | undefined {
  if (whitelistToken && whitelistToken.chainId && !whitelistToken.isNative) {
    const token = new Token(
      whitelistToken.chainId,
      whitelistToken.address,
      whitelistToken.decimals,
      whitelistToken.symbol,
      whitelistToken.name,
    );
    return token;
  }
  return undefined;
}

export function getLpToken(tokenAddress?: string, chainId?: number): Token | undefined {
  if (tokenAddress && chainId) {
    const token = new Token(chainId, tokenAddress, 18);
    return token;
  }
  return undefined;
}

export const getCurrency = (whitelistToken?: IWhitelistToken): Currency | Token | undefined => {
  const isNativeToken = whitelistToken?.isNative;
  const token = getToken(whitelistToken);
  return isNativeToken
    ? new NativeCurrency(
        whitelistToken?.chainId || 1,
        whitelistToken?.decimals || 18,
        whitelistToken?.symbol,
        whitelistToken?.name,
      )
    : token;
};

export const useSwapInfo = (
  usdMode: boolean,
  typedValue: BigNumber,
  selectedToken1?: IWhitelistToken,
  selectedToken2?: IWhitelistToken,
  slippageAmount?: number,
  // usdcToken?: IWhitelistToken,
) => {
  const {chainId} = useWeb3React();
  const inputCurrency = getCurrency(selectedToken1);
  const outputCurrency = getCurrency(selectedToken2);
  // const bestTradeUSD = useTradeExactIn(
  //   currentNetwork?.chainId,
  //   usdMode ? typedValue : undefined,
  //   usdcToken,
  //   selectedToken1,
  //   account,
  //   slippageAmount,
  // );
  const convertedTypeValue = useMemo(() => {
    if (typedValue) {
      const _typedValue = formatBigNumber(typedValue, selectedToken1?.decimals);
      const _convertedValue = getTokenAmountFromUSD(
        _typedValue,
        selectedToken1?.priceUSD,
        selectedToken1?.priceDecimals,
      );
      return parseBigNumber(_convertedValue.toFixed(selectedToken1?.decimals), selectedToken1?.decimals);
    }
    return BigNumber.from(0);
  }, [typedValue, selectedToken1]);

  const parsedAmount = tryParseAmount(usdMode ? convertedTypeValue : typedValue, inputCurrency, false);
  const {
    trade: bestTradeExactIn,
    onUpdateCallback,
    hasTimerStarted,
    loading,
    aggregatorResponse,
    setAggregatorResponse,
  } = useTradeExactIn(
    chainId,
    usdMode ? convertedTypeValue : typedValue,
    selectedToken1,
    selectedToken2,
    slippageAmount,
  );
  const v2Trade = bestTradeExactIn;
  const outputAvailable = !!typedValue && selectedToken1 && selectedToken2;
  return {
    convertedTypeValue,
    parsedAmount,
    inputCurrency,
    outputCurrency,
    v2Trade: outputAvailable ? v2Trade : undefined,
    onUpdateCallback,
    hasTimerStarted,
    aggregatorResponse,
    setAggregatorResponse,
    loading,
  };
};

export const useTradeExactIn = (
  chainId?: number,
  typedValue?: BigNumber,
  selectedToken1?: IWhitelistToken,
  selectedToken2?: IWhitelistToken,
  slippageAmount?: number,
) => {
  const [trade, setTrade] = useState<Aggregator | null>(null);
  const [loading, setLoading] = useState(false);

  const inputCurrency = getCurrency(selectedToken1);
  const outputCurrency = getCurrency(selectedToken2);

  const {wrapType} = useWrapCallback(inputCurrency, outputCurrency);

  const wrapNotApplicable: boolean = wrapType === WrapType.NOT_APPLICABLE;

  const currencyAmountIn = tryParseAmount(typedValue, inputCurrency, false);
  const currencyOut = outputCurrency;

  const debounceCurrencyAmountIn = currencyAmountIn as CurrencyAmount<Currency>;
  const [hasTimerStarted, setHasTimerStarted] = useState(false);
  const [aggregatorResponse, setAggregatorResponse] = useState(aggregatorReturnValue.NOT_TRIGGERED);

  const ttl = 60 * 120;
  const allowedSlippage = slippageAmount ?? 50;
  const chainInfo = getChainInfo(chainId);

  const stopAggregatorUpdates = useAppSelector((state) => state.swap.stopAggregatorUpdates);

  /**
   * Callback function to trigger aggregator get request.
   *
   * @param resetRoute - sets previous aggregator response to null when true.
   * @param minimumLoadingTime - indicates the delay for the get request.
   */
  const onUpdateCallback = useCallback(
    async (resetRoute: boolean, minimumLoadingTime: number) => {
      if (
        debounceCurrencyAmountIn &&
        debounceCurrencyAmountIn.quotient.toString() !== '0' &&
        currencyOut &&
        (debounceCurrencyAmountIn.currency as Token)?.address !== (currencyOut as Token)?.address
      ) {
        if (resetRoute || !chainInfo) {
          setTrade(null);

          return;
        }
        setLoading(true);
        const to = chainInfo.kyberSwapLiqAddress ?? ZERO_ADDRESS;
        const deadline = Math.round(Date.now() / 1000) + ttl;
        if (aggregatorResponse === aggregatorReturnValue.FAILED_REQUEST) return;
        try {
          const [state1, state2] = await Promise.all([
            Aggregator.bestTradeExactIn(
              chainInfo.routerUri,
              debounceCurrencyAmountIn,
              currencyOut,
              true,
              '',
              allowedSlippage,
              deadline,
              to,
              undefined,
              minimumLoadingTime,
            ),
            Aggregator.bestTradeExactIn(
              chainInfo.routerUri,
              debounceCurrencyAmountIn,
              currencyOut,
              false,
              '',
              allowedSlippage,
              deadline,
              to,
              undefined,
              minimumLoadingTime,
            ),
          ]);

          if (state1 === null && state2 === null) {
            setAggregatorResponse(aggregatorReturnValue.FAILED_REQUEST);
            return;
          } else if (state1 && state2) {
            const state = state1.receivedUsd > state2.receivedUsd ? state1 : state2;

            setTrade((prev) => {
              try {
                if (JSON.stringify(prev) != JSON.stringify(state)) {
                  return state;
                }
              } catch (e) {
                return state;
              }
              return prev;
            });
            setLoading(false);
          } else {
            setTrade(null);
          }
        } catch (error) {
          console.error(error);
          setTrade(null);
        }
        setLoading(false);
        // if (!signal.aborted && state) {
        //   const swap = await state.solana?.swap
        //   if (swap) setTrade(state)
        // }
      } else {
        setTrade(null);
      }
    },

    [aggregatorResponse, debounceCurrencyAmountIn, currencyOut, ttl, chainInfo, allowedSlippage],
  );

  // useEffect to trigger onUpdateCallback every few seconds
  useEffect(() => {
    const triggerUpdateCallback = async () => {
      // Call onUpdateCallback with updated inputs
      if (stopAggregatorUpdates == false && wrapNotApplicable && typedValue.gt(BigNumber.from(0))) {
        setHasTimerStarted(true);
        onUpdateCallback(false, 0);
      } else {
        setHasTimerStarted(false);
      }
    };

    // Start the timer if trade is defined
    const interval = setInterval(triggerUpdateCallback, 8000);

    // Cleanup function to clear the timer
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdateCallback, trade]);

  return {
    trade,
    onUpdateCallback,
    loading,
    hasTimerStarted,
    aggregatorResponse,
    setAggregatorResponse,
  };
};
