import {headerPos} from 'src/constants/home';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

import {BodyParagraph, BodyVariant} from './Typography';

type BalanceItemProps = {
  value?: number;
  label?: string;
  active?: boolean;
  customBG?: boolean;
  pos?: headerPos;
  posRadius?: number;
};

export const BalanceItem = ({value, label, active, customBG, pos, posRadius}: BalanceItemProps) => {
  return (
    <StyledRow gap={8} itemsCenter={true} bg={customBG} pos={pos} radius={posRadius}>
      <StyledCol>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.XSMALL} color={COLORS.PRIMARY}>
          {label}
        </BodyParagraph>
        <BodyVariant color={active ? COLORS.SECONDARY : COLORS.PRIMARY}>${value?.toFixed(2)}</BodyVariant>
      </StyledCol>
    </StyledRow>
  );
};

const StyledRow = styled.div<{
  marginTop?: number;
  gap?: number;
  itemsCenter?: boolean;
  bg?: boolean;
  pos?: string;
  radius?: number;
}>`
  width: 170px;
  background-color: ${(props) => (props.bg ? COLORS.SECONDARY : 'unset')};
  border-radius: ${(props) =>
    props.pos === headerPos.FIRST
      ? `${props.radius}px 0 0 ${props.radius}px`
      : props.pos === headerPos.LAST
      ? `0 ${props.radius}px ${props.radius}px 0`
      : 'unset'};
  display: flex;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  margin-top: ${(props) => props.marginTop ?? 0}px;
  padding: 0 24px;

  @media (max-width: ${DEVICE_ENUM.LAPTOP}px) {
    width: 150px;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 120px;
  }

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 150px;
    border-radius: ${(props) =>
      props.pos === headerPos.FIRST
        ? `${props.radius}px 0 0 0`
        : props.pos === headerPos.LAST
        ? `0 0 ${props.radius}px 0`
        : 'unset'};
  }
`;

const StyledCol = styled.div<{gap?: number; itemsCenter?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  gap: ${(props) => props.gap ?? 0}px;
`;
