import {useWeb3React} from '@web3-react/core';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types/token';
import styled from 'styled-components';

import {ChevronRightIcon} from '../Svgs';
import {TokenIcon} from '../TokenIcon';
import {BodyVariant} from '../Typography';

interface TokenItemProps {
  token: IWhitelistToken;
  value?: number | string;
  price?: number | string;
  onPress?: () => void;
}

export const TokenItem = ({token, value, price, onPress, ...props}: TokenItemProps) => {
  const {account} = useWeb3React();
  const content = (
    <StyledRowContainer>
      <TokenIcon token={token} />
      <BodyVariant color={COLORS.GRAY_LIGHT} size={BODY_FONT_ENUM.SMALL}>
        {token?.symbol}
      </BodyVariant>
      {!!value && (
        <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL}>
          {value}
        </BodyVariant>
      )}
      {account && price && (
        <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL}>
          (${price})
        </BodyVariant>
      )}
    </StyledRowContainer>
  );
  return (
    <Wrapper onClick={() => onPress && onPress()} {...props}>
      {content}
      {onPress && <ChevronRightIcon />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
