import React, {useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract, ethers} from 'ethers';
import {toast} from 'react-toastify';
import TokenMigrationModel from 'src/components/Modals/TokenMigrationModal';
import {CLASSIC_LIQ_GLOBAL_NAME} from 'src/constants';
import {useToken} from 'src/hooks';
import {calculatePrice} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';

import ArrowRightIcon from '../../assets/images/arrow-right.svg';
import Logo from '../../assets/images/liq-logo.svg';
import BinanceIcon from '../../assets/images/tokens/BNB.png';
import CronosIcon from '../../assets/images/tokens/CRONOS.png';
import EthIcon from '../../assets/images/tokens/ETH.png';
import classic_liq from '../../assets/images/tokens/LIQ.png';
import MaticIcon from '../../assets/images/tokens/MATIC.png';
import new_liq from '../../assets/images/tokens/svg/NewLIQ.svg';
import {
  CLASSIC_LIQ_CONTRACT,
  CLASSIC_LIQ_CRONOS_CONTRACT,
  CLASSIC_LIQ_ETH_CONTRACT,
  CLASSIC_LIQ_POLYGON_CONTRACT,
  V2_MIGRATE_LIQ_CONTRACT,
} from '../../constants/contracts';
import {makeBNumber} from '../../utils/utils';

import './styles.css';

export default function TokenMigration() {
  const [openTokenMigrationPopup, setOpenTokenMigrationPopup] = useState(false);
  const [coins, setCoins] = useState(0.0);
  const [rawBalance, setRawBalance] = useState(null);
  const [approvalRequired, setApprovalRequired] = useState(true);
  const [migrationProcess, setMigrationProcess] = useState(false);
  const [classicLiqPrice, setClassicLiqPrice] = useState(0.8);
  const {getTokenByGlobalName} = useToken();

  const {account, provider, chainId} = useWeb3React();
  const {check} = useAgreementCheck();

  const walletSigner = provider.getSigner(account);

  const decimals = 18;

  const oldLiq = getTokenByGlobalName(CLASSIC_LIQ_GLOBAL_NAME);
  const oldLiqPrice = calculatePrice(oldLiq?.priceUSD, oldLiq?.decimals);

  async function approveCoins() {
    const contractInfo = CLASSIC_LIQ_CONTRACT;
    const classicLiqContract = new Contract(contractInfo.singleTokenAddress, contractInfo.tokenAbi, walletSigner);
    try {
      const approve_amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
      const approvalResponse = await classicLiqContract.approve(
        V2_MIGRATE_LIQ_CONTRACT.singleTokenAddress,
        approve_amount,
      );

      await approvalResponse.wait();

      if (approvalResponse) {
        setApprovalRequired(false);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async function migrateTokensToBSC(amount: BigNumber) {
    let contractInfo;
    if (chainId === 56) {
      contractInfo = new Contract(CLASSIC_LIQ_CONTRACT.singleTokenAddress, CLASSIC_LIQ_CONTRACT.tokenAbi, walletSigner);
    } else if (chainId === 1) {
      contractInfo = new Contract(
        CLASSIC_LIQ_ETH_CONTRACT.singleTokenAddress,
        CLASSIC_LIQ_ETH_CONTRACT.tokenAbi,
        walletSigner,
      );
    } else if (chainId === 25) {
      contractInfo = new Contract(
        CLASSIC_LIQ_CRONOS_CONTRACT.singleTokenAddress,
        CLASSIC_LIQ_CRONOS_CONTRACT.tokenAbi,
        walletSigner,
      );
    } else if (chainId === 137) {
      contractInfo = new Contract(
        CLASSIC_LIQ_POLYGON_CONTRACT.singleTokenAddress,
        CLASSIC_LIQ_POLYGON_CONTRACT.tokenAbi,
        walletSigner,
      );
    }
    try {
      const transferResponse = await contractInfo.transfer('0xAf967C1A979D4600AffCE6BffBaeACFd165A1a2A', amount);
      await transferResponse.wait();

      if (transferResponse) {
        setMigrationProcess(true);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async function migrateTokens() {
    if (chainId !== 56) return migrateTokensToBSC(rawBalance);

    const contractInfo = V2_MIGRATE_LIQ_CONTRACT;
    const migrateLiqContract = new Contract(contractInfo.singleTokenAddress, contractInfo.tokenAbi, walletSigner);

    try {
      const swapResponse = await migrateLiqContract.deposit(rawBalance);
      await swapResponse.wait();
      if (swapResponse) {
        setMigrationProcess(true);
        return true;
      }
    } catch (e) {
      console.error(e);
    }
    return false;
  }

  async function maxBalance(input?: string) {
    setMigrationProcess(false);
    let contractInfo;
    if (chainId === 56) {
      contractInfo = new Contract(CLASSIC_LIQ_CONTRACT.singleTokenAddress, CLASSIC_LIQ_CONTRACT.tokenAbi, walletSigner);
    } else if (chainId === 1) {
      contractInfo = new Contract(
        CLASSIC_LIQ_ETH_CONTRACT.singleTokenAddress,
        CLASSIC_LIQ_ETH_CONTRACT.tokenAbi,
        walletSigner,
      );
    } else if (chainId === 25) {
      contractInfo = new Contract(
        CLASSIC_LIQ_CRONOS_CONTRACT.singleTokenAddress,
        CLASSIC_LIQ_CRONOS_CONTRACT.tokenAbi,
        walletSigner,
      );
    } else if (chainId === 137) {
      contractInfo = new Contract(
        CLASSIC_LIQ_POLYGON_CONTRACT.singleTokenAddress,
        CLASSIC_LIQ_POLYGON_CONTRACT.tokenAbi,
        walletSigner,
      );
    }
    try {
      const balance = await contractInfo.balanceOf(account);
      // const { web3 } = wallet;
      const initialValue = parseFloat(ethers.utils.formatUnits(balance, decimals)).toFixed(2);
      const parsedBalance = parseFloat(initialValue);
      if (input === 'max') {
        setRawBalance(balance);
        setCoins(parsedBalance);
      }
      return parsedBalance;
    } catch (e) {
      console.log(e);
    }
  }

  const networkIcons = {
    1: {
      name: 'ETH',
      icon: EthIcon,
    },
    56: {
      name: 'BSC',
      icon: BinanceIcon,
    },
    25: {
      name: 'CRO',
      icon: CronosIcon,
    },
    137: {
      name: 'POL',
      icon: MaticIcon,
    },
  };

  useEffect(() => {
    setClassicLiqPrice(oldLiqPrice);
  }, [oldLiqPrice]);

  useEffect(() => {
    if (openTokenMigrationPopup) document.body.style.overflow = 'hidden';
    else document.body.style.removeProperty('overflow');
  });

  async function checkAllowance() {
    const contractInfo = new Contract(
      CLASSIC_LIQ_CONTRACT.singleTokenAddress,
      CLASSIC_LIQ_CONTRACT.tokenAbi,
      walletSigner,
    );

    try {
      const allowanceInfo = await contractInfo.allowance(account, V2_MIGRATE_LIQ_CONTRACT.singleTokenAddress);

      const inputCoinAmount = makeBNumber(coins, decimals);
      const decimalCoinValue = parseInt(inputCoinAmount.toString());
      const decimalValueofAllowance = parseInt(allowanceInfo.toString());
      if (decimalCoinValue > decimalValueofAllowance) setApprovalRequired(true);
      else setApprovalRequired(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function checkIfInputCoinsExceedsBalance() {
    const balanceAvailable = await maxBalance();
    if (parseInt(coins.toString()) > parseInt(balanceAvailable.toString())) {
      setCoins(balanceAvailable);
      const rawCoinBalance = makeBNumber(balanceAvailable, decimals).toString();
      setRawBalance(rawCoinBalance);
      toast.error(`Coins are exceeding your max balance: ${balanceAvailable}`, {
        autoClose: 5000,
        theme: 'colored',
        position: 'top-center',
      });
      return true;
    }
    return false;
  }

  async function startMigrationProcess() {
    setMigrationProcess(false);
    const checkBalance = await checkIfInputCoinsExceedsBalance();
    if (checkBalance) return;

    if (chainId === 56) {
      await checkAllowance();
    } else {
      setApprovalRequired(false);
    }

    account
      ? coins !== 0
        ? check(() => setOpenTokenMigrationPopup(true))
        : toast.warn('Input some amount', {
            autoClose: 5000,
            theme: 'colored',
            position: 'top-center',
          })
      : toast.error('Please connect your wallet', {
          autoClose: 5000,
          theme: 'colored',
          position: 'top-center',
        });
  }

  function setBalance(e: React.ChangeEvent<HTMLInputElement>) {
    setMigrationProcess(false);
    const coinValue = e.target.value;
    setCoins(Number(coinValue));
    const rawCoinBalance = makeBNumber(Number(coinValue), decimals).toString();
    setRawBalance(rawCoinBalance);
  }

  return (
    <div id='token-migration'>
      <section className='token-migration-hero'>
        <div className='background-picture-token-migration'>
          <div className='hero-content'>
            <div>
              <h4 className='vertical-text'>Migrate your tokens</h4>
            </div>
            <div className='hero-headings'>
              <h1 style={{color: '#112455'}}>LIQ Token Migration</h1>
              <p>
                Liquidus is undergoing a token migration. Every LIQ holder of the initial Liquidus token, now referred
                to as “Classic LIQ” should migrate their tokens to the New LIQ token.
              </p>
              <div className='convertion-calculator'>
                <div className='convertion-calculator-field'>
                  <div className='coin-name-chip'>
                    <img src={classic_liq} alt='' />
                    <p>Classic LIQ</p>
                    <div style={{flex: 1}} />
                    {chainId !== 56 && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                          justifySelf: 'end',
                        }}
                      >
                        {/* @ts-expect-error: inferred types */}
                        <b>From {networkIcons[chainId]?.name}</b>
                        <img
                          //@ts-expect-error: inferred types
                          src={networkIcons[chainId]?.icon}
                          alt=''
                          style={{
                            //@ts-expect-error: inferred types
                            backgroundColor: networkIcons[chainId]?.name === 'CRO' ? 'black' : 'transparent',
                            //@ts-expect-error: inferred types
                            borderRadius: networkIcons[chainId]?.name === 'CRO' ? '50%' : 'initial',
                          }}
                        />
                      </span>
                    )}
                  </div>
                  <div className='convertion-calculator-field-text max-div'>
                    <input
                      type='number'
                      value={migrationProcess ? 0 : coins}
                      placeholder='0.0'
                      onChange={(e) => {
                        setBalance(e);
                      }}
                      className='hide-arrows-number-field'
                      name='toconvert'
                      id='toconvert'
                    />
                    <button onClick={() => maxBalance('max')} className='max_btn'>
                      MAX
                    </button>
                  </div>
                  <p>
                    =$
                    {migrationProcess ? 0 : coins === 0 ? '' : (coins * classicLiqPrice).toFixed(2)}
                  </p>
                </div>
                <span className='arrow-icon'>
                  <img src={ArrowRightIcon} alt='' />
                </span>
                <div className='convertion-calculator-field'>
                  <div className='coin-name-chip'>
                    <img src={new_liq} alt='' />
                    <p>New LIQ</p>
                    <div style={{flex: 1}} />
                    {chainId !== 56 && (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                          justifySelf: 'end',
                        }}
                      >
                        <b>To {networkIcons[56]?.name}</b>
                        <img src={networkIcons[56]?.icon} alt='' />
                      </span>
                    )}
                  </div>
                  <div className='convertion-calculator-field-text'>
                    <input
                      type='text'
                      value={migrationProcess ? 0 : coins / 5.0}
                      disabled
                      name='converted'
                      id='converted'
                    />
                  </div>
                  <p style={{opacity: 0}}>
                    =$
                    {migrationProcess ? 0 : ((coins / 1.0) * classicLiqPrice).toFixed(2)}
                  </p>
                </div>
                <button
                  className='primary-button-token-migration'
                  style={{
                    fontFamily: "'Playfair Display', serif",
                    color: '#112455',
                  }}
                  onClick={startMigrationProcess}
                >
                  Migrate Tokens
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='text-section'>
        <p>
          Liquidus is undergoing a token migration. Every LIQ holder of the initial Liquidus token, now referred to as
          “Classic LIQ” should migrate their tokens to the New LIQ token. The migration ratio is set to 5:1, which means
          for 5 Classic LIQ, you’ll receive 1 New LIQ. Liquidity for New LIQ was also added at a 5:1 ratio. At the time
          of adding liquidity, 1 New LIQ was worth exactly 5 Classic LIQ.
        </p>
        <h2>
          Migrate your Classic LIQ tokens now by using the interface above. You will receive your New LIQ within one
          month from the time of your migration.
        </h2>
        <p>
          If you are holding Classic LIQ on another blockchain, such as Ethereum, Polygon or Cronos, read the article
          below to learn more about how to migrate your tokens.
        </p>
        <p>
          Migration is open for a period of <b>12 months</b> . Starting from 09th November, 2023.
        </p>
        <a href='https://blog.liquidus.finance/' rel='noreferrer' target='_blank'>
          Read the Full Article here
        </a>
        {chainId !== 56 && (
          <div className='migrate_cross'>
            <h3>Cross Chain Migration Info</h3>
            <p>You are going to receive your New LIQ tokens on the Binance Smart Chain (BSC) network.</p>
            <p>
              You are NOT receiving your tokens immediately. There are specific dates on when the tokens on BSC get
              airdropped to everyone who migrated the tokens in the meantime. Make sure to read the latest
              announcements.
            </p>
          </div>
        )}
      </section>
      <hr />
      <footer className='token-migration-footer'>
        <img src={Logo} alt='' />
        <p style={{opacity: 0.6}}>2023 Liquidus DeFi Technology Systems Ltd, DIFC, Dubai All Rights Reserved</p>
      </footer>
      {openTokenMigrationPopup && (
        <TokenMigrationModel
          approveCoins={approveCoins}
          migrateTokens={migrateTokens}
          coins={coins}
          setOpenPopup={setOpenTokenMigrationPopup}
          netId={chainId}
          approvalRequired={approvalRequired}
        />
      )}
    </div>
  );
}
