import React, {PropsWithChildren, createContext, useCallback, useContext, useEffect, useState} from 'react';

import {DEVICE_ENUM} from 'src/styles';

interface SidebarContextType {
  isOpen?: boolean;
  toggleSidebar?: () => void;
  handleResize?: () => void;
  overrideOutsideClick?: () => void;
  overrideSidebarToggle?: () => void;
}

export const SidebarContext = createContext<SidebarContextType>({
  isOpen: true,
  toggleSidebar: () => null,
  overrideOutsideClick: () => null,
  overrideSidebarToggle: () => null,
});

SidebarContext.displayName = 'SidebarContext';

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = (props: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  const overrideSidebarToggle = () => {
    setIsOpen(false);
  };

  const overrideOutsideClick = () => {
    setIsOpen(true);
  };

  const handleResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    const isNotMobile = screenWidth > parseInt(DEVICE_ENUM.md);
    if (isOpen && isNotMobile) {
      setIsOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <SidebarContext.Provider value={{isOpen, toggleSidebar, handleResize, overrideOutsideClick, overrideSidebarToggle}}>
      {props.children}
    </SidebarContext.Provider>
  );
};
