import {YieldScanner} from 'src/components/Banners';
import {WhitelistTokens} from 'src/components/WhitelistTokens/WhitelistTokens';
import {DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {DiscoverFarm} from './components/discover-farm';
import {HotFarms} from './components/hot-farms';
import {Swap} from './components/swap';

export const Center = () => {
  return (
    <>
      <StyledWrapper>
        <HotFarms />
        <StyledColumnWrapper>
          <StyledWhitelistTokens />
          <StyledDiscoverFarm />
          <StyledCenterWrapper>
            <YieldScanner />
            <Swap />
          </StyledCenterWrapper>
        </StyledColumnWrapper>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

const StyledColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const StyledWhitelistTokens = styled(WhitelistTokens)`
  height: 560px;
  min-width: 305px;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    flex: 1;
  }
`;

const StyledCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 560px;
  min-width: 305px;
  flex: 1;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    width: 100%;
    display: grid;
    gap: 0;
    grid-template-columns: 40% 60%;
    min-height: fit-content;
    flex: none;
  }

  @media (max-width: ${DEVICE_ENUM.LAPTOP}px) {
    width: 100%;
    display: flex;
    gap: 16px;
  }
`;

const StyledDiscoverFarm = styled(DiscoverFarm)`
  width: 760px;
  min-height: 560px;
  overflow-x: scroll;

  @media (max-width: ${DEVICE_ENUM.xxl}) {
    width: 600px;
  }

  @media (max-width: ${DEVICE_ENUM.xl}) {
    flex: 2;
  }

  @media (max-width: ${DEVICE_ENUM.DESKTOP}px) {
    flex: 1.5;
  }

  @media (max-width: ${DEVICE_ENUM.LAPTOP}px) {
    width: 100%;
    order: -1;
    flex: none;
  }
`;
