import {gql} from '@apollo/client';

export const ADD_CONTACT = gql`
  mutation addContact($accountAddress: String!, $contact: ContactInput!) {
    addContact(accountAddress: $accountAddress, contact: $contact) {
      hash
      contacts {
        name
        hash
      }
    }
  }
`;
