import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type GuardIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const GuardIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: GuardIconType) => (
  <svg width={size} height={size} viewBox='0 0 22 28' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.230713 15.7949V3.94872L10.9999 0L21.7692 3.94872V15.7949C21.7692 20.8683 16.0478 24.4103 10.9999 28C5.95205 24.4103 0.230713 20.8683 0.230713 15.7949Z'
      fill={color}
    />
  </svg>
);

export default GuardIcon;
