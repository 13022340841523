import {useModals} from 'src/contexts/modals';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

import {BUTTON_SIZE_ENUM, Button} from './Buttons';

export const ConnectWalletButton = ({...props}) => {
  const {dispatch} = useModals();
  const onConnectWallet = () => {
    const payload = {isOpen: true};
    dispatch({type: 'updateWalletConnectModal', payload});
  };

  return (
    <StyledButton
      color={COLORS.PRIMARY}
      size={BUTTON_SIZE_ENUM.DEFAULT}
      title='Connect Wallet'
      onClick={onConnectWallet}
      {...props}
    />
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;
