import ICON_GENERAL from 'src/assets/images/tokens/svg/GENERAL.svg';
import {ICON_PLATFORM_LIST, ICON_TOKEN_LIST, ICON_TOKEN_LIST_GLOBAL_NAME} from 'src/constants/icons';

export const getTokenIcon = (token?: string) => {
  const icon = ICON_TOKEN_LIST.find((item) => item.token === token);
  return icon?.icon || ICON_GENERAL;
};

export const getTokenIconByGlobalName = (globalName?: string) => {
  const icon = ICON_TOKEN_LIST_GLOBAL_NAME.find((item) => item.token === globalName);
  return icon?.icon || ICON_GENERAL;
};

export const getPlatformIcon = (platform?: string) => {
  const icon = ICON_PLATFORM_LIST.find((item) => item.platform === platform);
  return icon?.icon || ICON_GENERAL;
};
