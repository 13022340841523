import React, {useEffect} from 'react';

import {useNavigate} from 'react-router';
import WarningImg from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

export const ErrorContent = () => {
  const navigate = useNavigate();
  const html = document.querySelector('html');
  const onErrorReturn = () => {
    html.style.removeProperty('overflow');
    navigate('/home');
    window.location.reload();
  };

  useEffect(() => {
    html.style.overflow = 'hidden';
  });

  return (
    <ErrorBoundaryWrapper>
      <ErrorBoundaryContentContainer>
        <WarningIcon src={WarningImg} />
        <ErrorHeader>Oops! Something Went Wrong</ErrorHeader>
        <ErrorMsg>
          We&apos;re sorry, but an unexpected error has occurred.Our team has been notified and is working hard to
          resolve this issue. Please try refreshing the page or come back later.
        </ErrorMsg>
        <ButtonWrapper>
          <Button color={COLORS.PRIMARY} title='Back to Home' onClick={onErrorReturn} />
        </ButtonWrapper>
      </ErrorBoundaryContentContainer>
    </ErrorBoundaryWrapper>
  );
};

const ErrorBoundaryWrapper = styled.main`
  font-family: Montserrat;
  display: flex;
  height: 100%;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  color: ${COLORS.PRIMARY};
`;

const ErrorBoundaryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WarningIcon = styled.img`
  width: 50px;
  height: 47.3px;
`;

const ErrorHeader = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 1.2rem;
  }
`;

const ErrorMsg = styled.p`
  font-size: 1.125rem;
  width: 505px;
  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 1rem;
  }

  @media screen and (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 90%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 342px;

  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 250px;
  }
`;
