import {useContext, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import {FilterIcon} from 'src/components/Svgs';
import {NEW_LIQ_GLOBAL_NAME} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useToken} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import {FarmType, IYieldFarm, PlatformSorting, TokenShowMode, TokenSorting} from 'src/types';
import {formatBigNumber, getTokenUSDPrice, sortBy} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenItem} from './TokenItem';
import {FarmItem, PoolItem, TokenDepositItem} from '../Holdings';
import {Spinner} from '../Spinner';
import {BodyVariant} from '../Typography';

export const FarmHoldings = () => {
  const {farmingPools, liquidityPools, farms, gauges, grizzlyFarms} = useContext(AppContext);
  const {platformSettings} = useAppSelector((state) => state.user);
  const {chainId, account} = useWeb3React();

  const filteredFarmingPools = useMemo(() => {
    if (farmingPools && farms && gauges && grizzlyFarms) {
      const _lpFarms = farms?.filter(
        (item) =>
          item.contractAddress?.chainId === chainId &&
          item.liquidityPool &&
          getTokenUSDPrice(formatBigNumber(item.balance, 18), item.liquidityPool?.lpTokenPriceUSD) > 0.1,
      );
      const mergedFarms: IYieldFarm[] = [...farmingPools, ..._lpFarms, ...gauges, ...grizzlyFarms];
      let _farmingPools = mergedFarms?.filter((item: IYieldFarm) => item.balance && item.balance.gt(0));
      if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_ALPHABET) {
        _farmingPools = sortBy(
          _farmingPools || [],
          (item) => item.liquidityPool?.token0Symbol ?? '',
          PlatformSorting.SORT_BY_ALPHABET,
        );
      } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_APY) {
        _farmingPools = sortBy(_farmingPools || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);
      } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_USER_DEPOSIT) {
        _farmingPools = sortBy(
          _farmingPools || [],
          (item) => getTokenUSDPrice(formatBigNumber(item.balance, 18), item.liquidityPool?.lpTokenPriceUSD),
          PlatformSorting.SORT_BY_USER_DEPOSIT,
        );
      }
      return _farmingPools;
    }
  }, [farmingPools, farms, gauges, grizzlyFarms, platformSettings?.holdingSorting, chainId]);

  const filteredLiquidityPools = useMemo(() => {
    let _liquidityPools = liquidityPools?.filter(
      (item) => item.balance && getTokenUSDPrice(formatBigNumber(item.balance, 18), item.lpTokenPriceUSD) > 0.1,
    );
    if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_ALPHABET) {
      _liquidityPools = sortBy(_liquidityPools || [], (item) => item.token0Symbol, PlatformSorting.SORT_BY_ALPHABET);
    } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_USER_DEPOSIT) {
      _liquidityPools = sortBy(
        _liquidityPools || [],
        (item) => formatBigNumber(item.balance, item.decimals),
        PlatformSorting.SORT_BY_USER_DEPOSIT,
      );
    }
    return _liquidityPools;
  }, [liquidityPools, platformSettings?.holdingSorting]);

  const tokenDepositData = useMemo(() => {
    let _singleTokens = farms?.filter(
      (item) =>
        item.contractAddress?.chainId === chainId && !item.liquidityPool && formatBigNumber(item.balance, 18) > 1,
    );
    if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_ALPHABET) {
      _singleTokens = sortBy(
        _singleTokens || [],
        (item) => item.vestingTimeInDays || 0,
        PlatformSorting.SORT_BY_ALPHABET,
      );
    } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_APY) {
      _singleTokens = sortBy(_singleTokens || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);
    } else if (platformSettings?.holdingSorting === PlatformSorting.SORT_BY_USER_DEPOSIT) {
      _singleTokens = sortBy(
        _singleTokens || [],
        (item) => formatBigNumber(item.balance, 18),
        PlatformSorting.SORT_BY_USER_DEPOSIT,
      );
    }
    return _singleTokens;
  }, [chainId, farms, platformSettings?.holdingSorting]);

  return (
    <>
      {account && (
        <>
          {filteredFarmingPools?.length > 0 && (
            <StyledSection marginBottom={20}>
              <StyledFullRow>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL}>
                  Yield Farming
                </BodyVariant>
              </StyledFullRow>
              {filteredFarmingPools?.map((item, index) => {
                if (item.type !== FarmType.FARM_SINGLE) {
                  return <FarmItem key={index} data={item} size='small' />;
                }
                return <TokenDepositItem key={index} data={item} size='small' />;
              })}
            </StyledSection>
          )}
          {filteredLiquidityPools?.length > 0 && (
            <StyledSection marginBottom={20}>
              <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL}>
                Liquidity Pools
              </BodyVariant>
              {filteredLiquidityPools?.map((item, index) => (
                <PoolItem key={index} data={item} size='small' />
              ))}
            </StyledSection>
          )}
          {tokenDepositData?.length > 0 && (
            <StyledSection marginBottom={20}>
              <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL}>
                Token Deposits
              </BodyVariant>
              {tokenDepositData?.map((item, index) => (
                <TokenDepositItem key={index} data={item} size='small' />
              ))}
            </StyledSection>
          )}
        </>
      )}
    </>
  );
};

export const WhitelistTokens = ({...props}) => {
  const {tokenSortSettings, customTokens} = useAppSelector((state) => state.user);
  const {sortedTokens} = useToken();
  const {dispatch} = useModals();
  const {farmLoading} = useContext(AppContext);

  const currentWhiteListTokens = useMemo(() => {
    if (!sortedTokens) return [];

    let count = 0; // Keep track of tokens for TOP_20_TOKENS mode
    const seen = new Set(); // Track seen tokens to avoid duplication

    const topList = sortedTokens.filter((item) => {
      const hasBalanceOrSpecial =
        getTokenUSDPrice(formatBigNumber(item.balance, item.decimals), item.priceUSD) >= 0.01 ||
        item.customToken === true;
      const isTopCandidate =
        item.isNative || (item.globalName === NEW_LIQ_GLOBAL_NAME && !item.isNative) || hasBalanceOrSpecial;
      if (isTopCandidate && !seen.has(item.address)) {
        seen.add(item.address); // Mark this token as seen to avoid being added to the bottom list
        return true;
      }
      return false;
    });

    let bottomList = sortedTokens.filter((item) => {
      if (!seen.has(item.address) && item.address !== undefined) {
        const hasNoBalance = getTokenUSDPrice(formatBigNumber(item.balance, item.decimals), item.priceUSD) < 0.01;
        const isNotSpecial = !item.customToken;
        const isBottomCandidate = item.globalName !== NEW_LIQ_GLOBAL_NAME && hasNoBalance && isNotSpecial;

        if (tokenSortSettings?.showMode === TokenShowMode.HIDE_ZERO_BALANCE && hasNoBalance) {
          return false; // Do not include tokens with zero balance if hiding them
        }

        if (tokenSortSettings?.showMode === TokenShowMode.TOP_20_TOKENS) {
          if (count >= 20) {
            return false; // Stop adding more tokens if the top 20 limit has been reached
          }
          if (isBottomCandidate) {
            count++; // Count this token
            seen.add(item.address); // Mark this token as seen
            return true;
          }
        } else {
          return isBottomCandidate;
        }
      }
      return false;
    });

    if (
      tokenSortSettings?.showMode === TokenShowMode.TOP_20_TOKENS &&
      tokenSortSettings?.sortMode === TokenSorting.SORT_BY_ALPHABET
    ) {
      bottomList = bottomList.sort((a, b) => a.name.localeCompare(b.name));
    }

    return [...topList, ...bottomList];
  }, [sortedTokens, tokenSortSettings]);

  const handleTokenFilter = () => {
    const payload = {isOpen: true};
    dispatch({type: 'updateTokenSortingModal', payload});
  };

  return (
    <Wrapper {...props}>
      <StyledHeader>
        <Header color={COLORS.PRIMARY} size={BODY_FONT_ENUM.BUTTON} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
          Your Holdings
        </Header>
        <StyledIconButton onClick={handleTokenFilter}>
          <FilterIcon color={COLORS.PRIMARY} />
        </StyledIconButton>
      </StyledHeader>
      <StyledBody>
        <StyledTokensWrapper>
          {farmLoading || currentWhiteListTokens?.length <= customTokens?.length ? (
            <StyledFullWrapper>
              <Spinner size={32} color={COLORS.SECONDARY} />
            </StyledFullWrapper>
          ) : (
            currentWhiteListTokens?.map((token) => <TokenItem key={token.globalName} token={token} />)
          )}
        </StyledTokensWrapper>
      </StyledBody>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
  padding: 21px 0 0;
  box-sizing: border-box;
`;

const Header = styled(BodyVariant)`
  font-weight: 500;
  margin-bottom: 16px;
`;

const StyledHeader = styled.div`
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 0 24px;
  }
`;

const StyledBody = styled.div`
  overflow: auto;
`;

const StyledTokensWrapper = styled.div`
  padding: 0 10px;
  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 0 20px;
  }
`;

const StyledSection = styled.div<{marginBottom?: number}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconButton = styled.div`
  display: flex;
  cursor: pointer;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }
`;

const StyledFullWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    min-height: 200px;
  }
`;
