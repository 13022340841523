export enum zapKnownErrorCodes {
  GASERROR = 'UNPREDICTABLE_GAS_LIMIT',
}

export enum zapKnownErrorReasons {
  TRANSACTION_FAILED = 'transaction failed',
  SWAPERROR = 'Swap failed',
  ADDLIQUIDITYERROR_A = 'INSUFFICIENT_A_AMOUNT',
  ADDLIQUIDITYERROR_B = 'INSUFFICIENT_B_AMOUNT',
  USERREJECTION = 'user rejected transaction',
}
