import {gql} from '@apollo/client';

export const LIQUIDITY_POOLS = gql`
  query liquidityPools($skip: Float, $limit: Float, $chainId: Float, $platformSlug: String) {
    liquidityPools(
      pagination: {skip: $skip, limit: $limit}
      isWhitelisted: true
      chainId: $chainId
      platformSlug: $platformSlug
    ) {
      address {
        hash
        chainId
      }
      poolRewards
      poolApy
      pastVolumes
      platform {
        name
        slug
        iconUrl
        securityRating {
          projectStart
          projectAgeInDays
          governanceTokenMarketCap
          tvl
          rugdocRiskLevel
          certikTrustScore
          cerRating
          updatedAt
          securityRating
          projectAgeRating
          tvlRating
          marketCapRating
        }
        governanceTokenChainSpecifics {
          name
          symbol
          decimals
        }
        factoryAddresses {
          hash
          chainId
        }
        masterChefAddresses {
          hash
          chainId
        }
        routerAddresses {
          hash
          chainId
        }
        chainSpecifics {
          chainId
        }
      }
      token0Hash
      token0Symbol
      token0PriceUSD {
        decimals
        value
      }
      reserve0
      token1Hash
      token1Symbol
      token1PriceUSD {
        decimals
        value
      }
      reserve1
      lpValueUSD {
        decimals
        value
      }
      totalSupply
      decimals
      lpTokenPriceUSD {
        decimals
        value
      }
      isWhitelisted
    }
  }
`;
