import React, {useEffect} from 'react';

import {useSidebar} from 'src/contexts/SidebarContext';
import {DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {SidebarContent} from './SidebarContent';

export const Sidebar = () => {
  const {isOpen, handleResize} = useSidebar();

  useEffect(() => {
    handleResize();
  }, [handleResize, isOpen]);

  return (
    <Wrapper open={isOpen}>
      <SidebarContent />
    </Wrapper>
  );
};

const Wrapper = styled.nav<{open: boolean}>`
  position: fixed;
  height: calc(100vh - 81px);
  top: 81px;
  display: flex;
  overflow-y: auto;
  width: 254px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    top: 60px;
    height: calc(100vh - 60px);
    z-index: 999;
    // height: 100%;
    transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    // transform: translateX(-100%);
  }
`;
