import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {Modal} from 'src/components/Modals/Modal';
import {CloseIcon, SearchIcon} from 'src/components/Svgs';
import {ClearTokenItem, TokenItem} from 'src/components/WhitelistTokens/TokenItem';
import {useLifiSwap} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken, NetworkType} from 'src/types';
import styled from 'styled-components';

type ReceiveTokenSelectModalProps = {
  isOpen: boolean;
  selectedToken?: IWhitelistToken;
  showClearToken?: boolean;
  onDismiss: () => void;
  onTokenSelect: (token?: IWhitelistToken) => void;
  userSelectedNetwork: NetworkType;
};

export const ReceiveTokenSelectModal = ({
  isOpen,
  selectedToken,
  showClearToken,
  userSelectedNetwork,
  onDismiss,
  onTokenSelect,
}: ReceiveTokenSelectModalProps) => {
  const {tokenListWithChainId, tokenList} = useLifiSwap();
  const [value, setValue] = useState('');

  useEffect(() => {
    if (userSelectedNetwork) {
      const {chainId} = userSelectedNetwork;
      tokenList(chainId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedNetwork]);

  const filteredTokens = useMemo(() => {
    return tokenListWithChainId.filter(
      (token) =>
        token.symbol.toLowerCase().includes(value.toLowerCase()) ||
        token.name.toLowerCase().includes(value.toLowerCase()),
    );
  }, [tokenListWithChainId, value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleTokenSelect = useCallback(
    (token: IWhitelistToken) => {
      onTokenSelect(token);
      onDismiss();
    },
    [onTokenSelect, onDismiss],
  );

  const handleClearToken = useCallback(() => {
    onTokenSelect(undefined);
    onDismiss();
  }, [onDismiss, onTokenSelect]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Select token</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
          <SearchView>
            <StyledSearchIcon />
            <Input value={value} onChange={onChange} placeholder='Search token' />
          </SearchView>
        </Header>
        <Body>
          {showClearToken && <ClearTokenItem onPress={handleClearToken} />}
          {filteredTokens?.map((token: IWhitelistToken) => (
            <TokenItem
              key={Math.random()}
              selected={selectedToken?.symbol === token.symbol}
              token={token}
              onSelect={handleTokenSelect}
            />
          ))}
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 76vh;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const SearchView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  height: 32px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 23px;
  padding: 0 8px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  flex: 1;
  margin-left: 8px;
  color: ${COLORS.BLACK_TEXT};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
`;

const Body = styled.div`
  padding: 0 16px 16px;
  flex: 1;
  overflow: auto;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
