import {Link, useNavigate} from 'react-router-dom';
import {samePagePaths} from 'src/constants';
import {useModals} from 'src/contexts/modals';
import {DEVICE_ENUM} from 'src/styles';
import {COLORS} from 'src/styles/colors';
import styled from 'styled-components';

import {SETTINGS_DATA} from './data';
import {SettingsSubItemDataType} from './data';
import {ItemContainer, ItemWrapper, ItemsList} from './SidebarStyles';
import appVersion from '../../../package.json';
import commitHash from '../../gitData.json';
import {ArrowLeftIcon, ChevronRightIcon} from '../Svgs';
import {BodyParagraph} from '../Typography';

type SettingsContentProps = {
  onClose?: () => void;
  open: boolean;
};

export const SettingsContent = ({open, onClose}: SettingsContentProps) => {
  const navigate = useNavigate();
  const {dispatch} = useModals();
  const handleMenuItem = (subItem: SettingsSubItemDataType) => {
    if (subItem.modal) {
      const payload = {isOpen: true};
      dispatch({type: subItem.modal, payload});
    } else if (subItem.url) {
      navigate(subItem.url);
    }
  };

  return (
    <Wrapper open={open}>
      <TopWrapper>
        <TopContentWrapper>
          <StyledButton onClick={onClose}>
            <ArrowLeftIcon size={18} />
          </StyledButton>
          <StyledTitle>Settings</StyledTitle>
          <div />
        </TopContentWrapper>
      </TopWrapper>
      {SETTINGS_DATA.map((itemData) => (
        <div key={itemData.group}>
          <ItemsList>
            <StyledGroupTitle>{itemData.group}</StyledGroupTitle>
            {itemData.data.map((subItem, index) =>
              subItem.url ? (
                <Link key={index} to={subItem.url} target={samePagePaths.includes(subItem.url) ? '' : '_blank'}>
                  <ItemContainer>
                    <ItemWrapper alignSpaceBetween>
                      <BodyParagraph color={COLORS.PRIMARY}>{subItem.name}</BodyParagraph>
                      <ChevronRightIcon />
                    </ItemWrapper>
                  </ItemContainer>
                </Link>
              ) : (
                <ItemContainer key={index} onClick={() => handleMenuItem(subItem)}>
                  <ItemWrapper alignSpaceBetween>
                    <BodyParagraph color={COLORS.PRIMARY}>{subItem.name}</BodyParagraph>
                    <ChevronRightIcon />
                  </ItemWrapper>
                </ItemContainer>
              ),
            )}
          </ItemsList>
          <StyledSeparator />
        </div>
      ))}
      <ItemsList alignBottom={true}>
        <VersionContainer>
          <BodyParagraph color={COLORS.PRIMARY}>Version: {appVersion.version}</BodyParagraph>
          {process.env.NODE_ENV === 'development' && commitHash && (
            <BodyParagraph color={COLORS.PRIMARY}>Commit_Id: {commitHash.gitCommitHash}</BodyParagraph>
          )}
        </VersionContainer>
      </ItemsList>
    </Wrapper>
  );
};

const Wrapper = styled.div<{open: boolean}>`
  position: fixed;
  // top: 81px;
  display: flex;
  flex-direction: column;
  width: 254px;
  // height: 100%;
  height: calc(100vh - 81px);
  background: #ffffff;
  overflow-y: auto;
  z-index: 999;

  @media (max-width: ${DEVICE_ENUM.md}) {
    // top: 0px;
    z-index: 1000;
    // transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    height: calc(100vh - 60px);
    // height: 100%;
  }
`;

const TopWrapper = styled.div`
  padding: 24px 31px;
  display: flex;
  align-items: center;
`;

export const StyledTitle = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: ${COLORS.PRIMARY};
`;

const StyledGroupTitle = styled.p`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  color: ${COLORS.GRAY_LIGHT};
`;

const TopContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const StyledSeparator = styled.div`
  height: 1px;
  background-color: ${COLORS.GRAY_BASE_40};
`;

const StyledButton = styled.div`
  cursor: pointer;
`;

const VersionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
`;
