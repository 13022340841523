//SecondaryButton.tsx

import {COLORS} from 'src/styles';
import styled from 'styled-components';

interface SecondaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
}

export const SecondaryButton = ({title, ...props}: SecondaryButtonProps) => (
  <StyledButton {...props}>{title}</StyledButton>
);

const StyledButton = styled.button`
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: ${COLORS.PRIMARY};
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.WHITE};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
`;
