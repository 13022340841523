import React from 'react';

import WarningIcon from 'src/assets/images/warning2.png';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

interface WarningProps {
  description?: string;
  bgColor?: string;
}

const Warningbox: React.FC<WarningProps> = ({description, bgColor}) => {
  return (
    <Container bgColor={bgColor}>
      <Image src={WarningIcon} alt='Warning Icon' />
      <Description>{description}</Description>
    </Container>
  );
};

const Container = styled.div<{bgColor?: string}>`
  display: flex;
  align-items: center;
  padding: 14px 16px;
  background-color: ${({bgColor}) => bgColor || '#ffcc00'};
  border-radius: 9px;
`;

const Description = styled.p`
  flex: 1;
  font-size: 15px;
  color: ${COLORS.PRIMARY};
  padding-left: 12px;
  margin: 0;
`;

const Image = styled.img`
  width: 19px;
  height: 17px;
`;

export default Warningbox;
