import {useCallback} from 'react';

import {BigNumber} from 'ethers';
import {ApprovalState, useApproveCallbackFromTrade, useSwapCallback, useSwapInfo} from 'src/hooks/kyber-swap';
import {useAppSelector} from 'src/state/hooks';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {calculateGasMargin} from 'src/utils/swap/kyber-swap';

import {useWithdrawFarmCallback} from './useWithdrawFarm';

export const useSingleFarmWithdraw = (
  withdrawToken?: IWhitelistToken,
  toToken?: IWhitelistToken,
  withdrawTokenAmount?: BigNumber,
  farmItem?: IYieldFarm,
) => {
  const {swapSettings} = useAppSelector((state) => state.user);

  const slippageAmount = (swapSettings.slippage?.value || 0.5) * 100;

  const {v2Trade} = useSwapInfo(
    false,
    withdrawTokenAmount || BigNumber.from(0),
    withdrawToken,
    toToken,
    slippageAmount,
  );

  const [swapTokenApproveState, swapTokenApproveCallback] = useApproveCallbackFromTrade(v2Trade, slippageAmount);

  const {estimateGas, callback} = useSwapCallback(v2Trade);

  const {estimateGasForWithdrawFarm, withdrawFarmCallback} = useWithdrawFarmCallback(farmItem);

  const withdrawTokensCallback = useCallback(async () => {
    if (!withdrawTokenAmount) {
      throw new Error('lpTokensAmount is not defeined!!');
    }
    const gasEstimation = await estimateGasForWithdrawFarm(withdrawTokenAmount);
    if (gasEstimation) {
      return await withdrawFarmCallback(calculateGasMargin(gasEstimation), withdrawTokenAmount);
    }
  }, [estimateGasForWithdrawFarm, withdrawTokenAmount, withdrawFarmCallback]);

  return {
    swapCallback: callback,
    estimateGasForSwap: estimateGas,
    isSwapTokenApproved: swapTokenApproveState === ApprovalState.APPROVED,
    swapTokenApproveCallback,
    withdrawTokensCallback,
    estimateGasForWithdrawFarm,
    trade: v2Trade,
  };
};
