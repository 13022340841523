export const LIQ_HOLDING_CONTRACTS = [
  {
    netId: 56,
    address: '0xE8f9981a90f26d07aDd03F5Cac49Ccc2E23F8487',
  },
  {
    netId: 25,
    address: '0x31248f375263Eaa3B3DBdfcd8EAE60890360cD75',
  },
  {
    netId: 1,
    address: '0x31248f375263Eaa3B3DBdfcd8EAE60890360cD75',
  },
  {
    netId: 137,
    address: '0x31248f375263Eaa3B3DBdfcd8EAE60890360cD75',
  },
];
