import React, {ChangeEvent, useCallback, useMemo, useState} from 'react';

import {CloseIcon, SearchIcon} from 'src/components/Svgs';
import {useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {getTokenUSDPriceFromBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import {Modal} from './Modal';
import {ClearTokenItem, TokenItem} from '../WhitelistTokens/TokenItem';

type TokenSelectModalProps = {
  isOpen: boolean;
  selectedToken?: IWhitelistToken;
  showClearToken?: boolean;
  showBalanceOnly?: boolean;
  hideNativeToken?: boolean;
  onDismiss: () => void;
  onTokenSelect: (token?: IWhitelistToken) => void;
  nativeSwitch?: boolean;
  networkTokens?: IWhitelistToken[];
};

export const TokenSelectModal = ({
  isOpen,
  selectedToken,
  showClearToken,
  showBalanceOnly,
  hideNativeToken,
  onDismiss,
  onTokenSelect,
  nativeSwitch,
  networkTokens,
}: TokenSelectModalProps) => {
  const {sortedTokens} = useToken();
  const [value, setValue] = useState('');
  const filteredTokens = useMemo(() => {
    let _sortedTokens = hideNativeToken ? sortedTokens.filter((token) => !token.isNative) : sortedTokens;
    if (showBalanceOnly) {
      _sortedTokens = _sortedTokens.filter((token) => getTokenUSDPriceFromBigNumber(token.balance, token) >= 0.01);
    }
    return _sortedTokens.filter(
      (token) =>
        token.symbol.toLowerCase().includes(value.toLowerCase()) ||
        token.name.toLowerCase().includes(value.toLowerCase()),
    );
  }, [hideNativeToken, sortedTokens, showBalanceOnly, value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleTokenSelect = useCallback(
    (token: IWhitelistToken) => {
      onTokenSelect(token);
      onDismiss();
    },
    [onTokenSelect, onDismiss],
  );

  const handleClearToken = useCallback(() => {
    onTokenSelect(undefined);
    onDismiss();
  }, [onDismiss, onTokenSelect]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>{nativeSwitch ? 'Select Network token' : 'Select token'}</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
          {!nativeSwitch && (
            <SearchView>
              <StyledSearchIcon />
              <Input value={value} onChange={onChange} placeholder='Search token' />
            </SearchView>
          )}
        </Header>
        <Body>
          {showClearToken && !nativeSwitch && <ClearTokenItem onPress={handleClearToken} />}
          {nativeSwitch
            ? networkTokens?.map((token) => (
                <TokenItem
                  key={token.globalName}
                  selected={selectedToken?.globalName === token.globalName}
                  token={token}
                  onSelect={handleTokenSelect}
                />
              ))
            : filteredTokens?.map((token) => (
                <TokenItem
                  key={token.globalName}
                  selected={selectedToken?.globalName === token.globalName}
                  token={token}
                  onSelect={handleTokenSelect}
                />
              ))}
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 76vh;
  background-color: white;
  border-radius: 16px;
  overflow: auto;
  position: relative;
  z-index: 300;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  padding: 24px 24px 12px;
  background-color: white;
  z-index: 10; // above the content, but not above the tooltip when hovered over a list item
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const SearchView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  height: 32px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 23px;
  padding: 0 8px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  flex: 1;
  margin-left: 8px;
  color: ${COLORS.BLACK_TEXT};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
`;

const Body = styled.div`
  padding: 0 16px 16px;
  flex-grow: 1;
  overflow-y: visible;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
