import {createSlice} from '@reduxjs/toolkit';
import {Field, FieldValues} from 'src/types';

export interface SwapState {
  [Field.INPUT]: {tokenGlobalName: string | undefined};
  [Field.OUTPUT]: {tokenGlobalName: string | undefined};
  [Field.ARTICLEINITIAL_INPUT]: {tokenGlobalName: string | undefined};
  [FieldValues.INPUT_VALUE]: number | undefined;
  stopAggregatorUpdates: boolean;
}

const initialState: SwapState = {
  [Field.INPUT]: {tokenGlobalName: undefined},
  [Field.OUTPUT]: {tokenGlobalName: undefined},
  [Field.ARTICLEINITIAL_INPUT]: {tokenGlobalName: undefined},
  [FieldValues.INPUT_VALUE]: undefined,
  stopAggregatorUpdates: false,
};

export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    selectToken(state, {payload: {field, tokenGlobalName}}) {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT;
      if (state[otherField].tokenGlobalName === tokenGlobalName) {
        state[otherField] = state[field as Field];
      }
      state[field as Field] = {tokenGlobalName};
    },
    switchTokens(state) {
      return {
        ...state,
        [Field.INPUT]: {tokenGlobalName: state[Field.OUTPUT].tokenGlobalName},
        [Field.OUTPUT]: {tokenGlobalName: state[Field.INPUT].tokenGlobalName},
      };
    },
    resetToken() {
      return initialState;
    },
    toggleAggregatorUpdates(state) {
      state.stopAggregatorUpdates = !state.stopAggregatorUpdates;
    },
    setStopAggregatorUpdates(state, action) {
      state.stopAggregatorUpdates = action.payload;
    },
    setValues(state, {payload: input}) {
      return {
        ...state,
        [FieldValues.INPUT_VALUE]: input,
      };
    },
    resetValues(state) {
      return {...state, [FieldValues.INPUT_VALUE]: undefined};
    },
  },
});
