import React from 'react';

import {ContactItem} from 'src/components/ContactItem';
import {SearchIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IAddressBook} from 'src/types';
import styled from 'styled-components';

type ContactBookProps = {
  addressBook: IAddressBook[];
  value: string;
  setValue: (value: string) => void;
  onAdd: () => void;
  onClickContact?: (contact: IAddressBook) => void;
};

export const ContactBook = ({addressBook, value, setValue, onAdd, onClickContact}: ContactBookProps) => {
  return (
    <>
      <SectionWrapper marginTop={8}>
        <StyledRow gap={8}>
          <SearchView>
            <SearchIcon color={COLORS.PRIMARY} />
            <Input value={value} onChange={(e) => setValue(e.target.value)} placeholder='Search contacts' />
          </SearchView>
          <IconButton onClick={onAdd}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.SECONDARY}>
              ADD
            </BodyParagraph>
          </IconButton>
        </StyledRow>
      </SectionWrapper>
      <SectionWrapper marginTop={16}>
        {addressBook?.map((item) => (
          <ContactItem contact={item} border={true} key={item.address} onPress={onClickContact} />
        ))}
      </SectionWrapper>
    </>
  );
};

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const SearchView = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 6px;
  padding: 0 8px;
`;

const Input = styled.input`
  flex: 1;
  margin-left: 4px;
  color: ${COLORS.BLACK_TEXT};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
