import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon, TickIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type SwapRewardTokensProps = {
  rewardToken?: IWhitelistToken;
  outputToken?: IWhitelistToken;
  inputAmount?: number;
  outputAmount?: number;
  usdAmount?: number;
  loading?: boolean;
  onConfirm: () => void;
  errorMsg?: string;
};

export const SwapRewardTokens = ({
  rewardToken,
  loading,
  onConfirm,
  outputToken,
  inputAmount,
  outputAmount,
  usdAmount,
  errorMsg,
}: SwapRewardTokensProps) => {
  return (
    <Body>
      <StepsContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Reward Token</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Swap Tokens</BodyVariant>
        </StyledRow>
      </StepsContainer>
      <TokenInfoBox>
        <ItemsWrapper>
          <TokenItem token={rewardToken} value={inputAmount.toFixed(rewardToken?.interfaceDecimals)} />
          <ArrowBox>
            <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
          </ArrowBox>
          <TokenItem token={outputToken} value={outputAmount.toFixed(outputToken.interfaceDecimals)} />
          <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${usdAmount.toFixed(2)}</StyledOutputUsdPrice>
        </ItemsWrapper>
      </TokenInfoBox>

      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={false}
          title={errorMsg ? 'Retry' : 'Swap Tokens'}
          onClick={onConfirm}
        />
        {errorMsg ? (
          <StyledParagraph color={COLORS.WARNING}>{errorMsg}</StyledParagraph>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StepsContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 16px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 10px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 20px;
`;
