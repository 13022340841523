export const ETHER_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const KYBERSWAP_SOURCE = JSON.stringify({source: 'Liquidus.Finance Wallet App'});
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const ZERO_ADDRESS_SOLANA = 'zeroooooooooooooooooooooooooooooooooooooooo';

export enum aggregatorReturnValue {
  FAILED_REQUEST = 'failed',
  SUCCESSFUL_REQUEST = 'success',
  NOT_TRIGGERED = 'not triggered',
}
