import {COLORS, ColorsTypes} from './colors';

export enum DEVICE_ENUM {
  TABLET = '768px',
  MOBILE = '480px',
  LAPTOP = '1024',
  DESKTOP = '1200',
  sm = '600px',
  md = '900px',
  lg = '1200px',
  exlg = '1300px',
  xl = '1536px',
  xxl = '1650px',
}

export type HeadingFontScaleType = {
  tag: string;
  fontFamily: string;
  fontSize: number;
  lineHeight: number;
  fontWeight: number;
  color?: string;
};

export enum HEADING_FONT_ENUM {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
}
export const HeadingFontScale: Record<HEADING_FONT_ENUM, HeadingFontScaleType> = {
  [HEADING_FONT_ENUM.H1]: {
    tag: 'h1',
    fontFamily: 'Montserrat',
    fontSize: 42,
    lineHeight: 51,
    fontWeight: 500,
  },
  [HEADING_FONT_ENUM.H2]: {
    tag: 'h2',
    fontFamily: 'Montserrat',
    fontSize: 42,
    lineHeight: 51,
    fontWeight: 500,
  },
  [HEADING_FONT_ENUM.H3]: {
    tag: 'h3',
    fontFamily: 'Montserrat',
    fontSize: 32,
    lineHeight: 41,
    fontWeight: 400,
  },
  [HEADING_FONT_ENUM.H4]: {
    tag: 'h4',
    fontFamily: 'Montserrat',
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    color: COLORS.PRIMARY,
  },
};

export type ParagraphFontScaleType = {
  fontFamily: string;
  fontSize: number;
  lineHeight: number;
  fontWeight: number;
  color?: ColorsTypes;
};

export enum PARAGRAPH_FONT_ENUM {
  XLARGE = 'XLARGE',
  LARGE = 'LARGE',
  BIG = 'BIG',
  BIG_Paragraph = 'BIG_Paragraph',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
}
export const ParagraphFontScale: Record<PARAGRAPH_FONT_ENUM, ParagraphFontScaleType> = {
  [PARAGRAPH_FONT_ENUM.XLARGE]: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    lineHeight: 15,
    fontWeight: 600,
  },
  [PARAGRAPH_FONT_ENUM.LARGE]: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 700,
  },
  [PARAGRAPH_FONT_ENUM.BIG]: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 600,
  },
  [PARAGRAPH_FONT_ENUM.BIG_Paragraph]: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    lineHeight: 25.2,
    fontWeight: 400,
  },
  [PARAGRAPH_FONT_ENUM.MEDIUM]: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 500,
  },
  [PARAGRAPH_FONT_ENUM.SMALL]: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    lineHeight: 15,
    fontWeight: 400,
  },
  [PARAGRAPH_FONT_ENUM.XSMALL]: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    lineHeight: 15,
    fontWeight: 500,
  },
};

export enum BODY_FONT_ENUM {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  BUTTON = 'BUTTON',
  LARGE_MOBILE = 'LARGE_MOBILE',
  MEDIUM_MOBILE = 'MEDIUM_MOBILE',
  SMALL_MOBILE = 'SMALL_MOBILE',
}

export type BodyFontScaleType = {
  fontSize: number;
  lineHeight: number;
  fontWeight: number;
  letterSpacing?: number;
};

export const BodyFontScale: Record<BODY_FONT_ENUM, BodyFontScaleType> = {
  [BODY_FONT_ENUM.BUTTON]: {
    fontSize: 18,
    lineHeight: 25,
    fontWeight: 700,
  },
  [BODY_FONT_ENUM.LARGE]: {
    fontSize: 24,
    lineHeight: 29,
    fontWeight: 600,
  },
  [BODY_FONT_ENUM.LARGE_MOBILE]: {
    fontSize: 20,
    lineHeight: 25,
    fontWeight: 600,
  },
  [BODY_FONT_ENUM.MEDIUM]: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: 600,
  },
  [BODY_FONT_ENUM.MEDIUM_MOBILE]: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 600,
  },
  [BODY_FONT_ENUM.SMALL]: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: 400,
  },
  [BODY_FONT_ENUM.SMALL_MOBILE]: {
    fontSize: 14,
    lineHeight: 22,
    fontWeight: 400,
  },
};

export enum BUTTON_FONT_ENUM {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  MEDIUM_BTN_TXT = 'MEDIUM_BTN_TXT',
  NORMAL = 'NORMAL',
  SMALL = 'SMALL',
  XSMALL = 'XSMALL',
  XSMALL_BOLD = 'XSMALL_BOLD',
}

export type ButtonFontScaleType = {
  fontFamily: string;
  fontSize: number;
  fontWeight: number;
  lineHeight: number;
};

export const ButtonFontScale: Record<BUTTON_FONT_ENUM, ButtonFontScaleType> = {
  [BUTTON_FONT_ENUM.LARGE]: {
    fontFamily: 'Montserrat',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 28,
  },
  [BUTTON_FONT_ENUM.MEDIUM]: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 20,
  },
  [BUTTON_FONT_ENUM.MEDIUM_BTN_TXT]: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  [BUTTON_FONT_ENUM.NORMAL]: {
    fontFamily: 'Montserrat',
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 18,
  },
  [BUTTON_FONT_ENUM.SMALL]: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 15,
  },
  [BUTTON_FONT_ENUM.XSMALL]: {
    fontFamily: 'Montserrat',
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 15,
  },
  [BUTTON_FONT_ENUM.XSMALL_BOLD]: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 15,
  },
};
