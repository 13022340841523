import React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';
import {PARAGRAPH_FONT_ENUM, ParagraphFontScale, ParagraphFontScaleType} from 'src/styles/size';
import styled from 'styled-components';

export interface BodyParagraphType extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: PARAGRAPH_FONT_ENUM;
  color?: ColorsTypes;
  cursor?: string;
  children: string | React.ReactNode;
}

export const BodyParagraph = ({
  size = PARAGRAPH_FONT_ENUM.MEDIUM,
  children,
  color = COLORS.BLACK,
  cursor,
  ...props
}: BodyParagraphType) => {
  return (
    <StyledBodyParagraph color={color} size={ParagraphFontScale[size]} cursor={cursor} {...props}>
      {children}
    </StyledBodyParagraph>
  );
};

export default BodyParagraph;

const StyledBodyParagraph = styled.p<{size: ParagraphFontScaleType; color: ColorsTypes; cursor?: string}>`
  ${(props) => `
    font-family: Montserrat;
    color: ${props.color};
    font-size: ${props.size.fontSize}px;
    line-height: ${props.size.lineHeight}px;
    font-weight: ${props.size.fontWeight};
    cursor: ${props.cursor ?? 'unset'};
  `}
  margin: 0;
`;
