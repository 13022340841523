import {useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {DuplicateFarmItem} from 'src/components/YieldScanner';
import {AppContext} from 'src/contexts/AppContext';
import {COLORS} from 'src/styles';
import {IFarmingPool, ILiquidityPool, IYieldFarm} from 'src/types';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import styled from 'styled-components';

interface EarnMoreFarmsType {
  [key: string]: IYieldFarm[];
}
interface LpType {
  [key: string]: ILiquidityPool;
}
interface HighestAPYFarmType {
  [key: string]: IYieldFarm;
}

export const EarnMoreOnHoldings = ({...props}) => {
  const {chainId} = useWeb3React();
  const {allFarms} = props;
  const {farmLoading, farmingPools, farms, gauges, grizzlyFarms, liquidityPools} = useContext(AppContext);

  const [availYield, setAvailYield] = useState<HighestAPYFarmType>({});
  const [availLp, setAvailLp] = useState<LpType>({});
  const [combinedHighAPYFarms, setCombinedHighAPYFarms] = useState<HighestAPYFarmType>({});

  const filteredFarmings = useMemo(() => {
    if (farmingPools && farms && gauges && grizzlyFarms) {
      const _lpFarms = farms?.filter(
        (item) =>
          item.contractAddress?.chainId === chainId &&
          item.liquidityPool &&
          getTokenUSDPrice(formatBigNumber(item.balance, 18), item.liquidityPool?.lpTokenPriceUSD) > 0.1,
      );
      const mergedFarms: IYieldFarm[] = [...farmingPools, ..._lpFarms, ...gauges, ...grizzlyFarms];
      const _farmingPools = mergedFarms?.filter((item: IYieldFarm) => item.balance && item.balance.gt(0));
      return _farmingPools;
    }
  }, [farmingPools, farms, gauges, grizzlyFarms, chainId]);

  useEffect(() => {
    if (allFarms) {
      const userAvailYieldFarms: HighestAPYFarmType = {};
      const userAvailLp: LpType = {};
      const earnMoreFarms: EarnMoreFarmsType = {};
      const highAPYFarms: HighestAPYFarmType = {};

      const yieldFarmPairList = filteredFarmings?.map((pool: IFarmingPool) => {
        const {liquidityPool} = pool;
        const tokenPair = `${liquidityPool?.token1Symbol}-${liquidityPool?.token0Symbol}`;
        if (!userAvailYieldFarms[tokenPair]) {
          userAvailYieldFarms[tokenPair] = pool;
        } else if (userAvailYieldFarms[tokenPair].apy > pool.apy) {
          userAvailYieldFarms[tokenPair] = pool;
        }
        return tokenPair;
      });

      const liquidityPoolPairs = liquidityPools?.map((pool) => {
        const tokenPair = `${pool?.token1Symbol}-${pool?.token0Symbol}`;
        if (!userAvailLp[tokenPair]) {
          userAvailLp[tokenPair] = pool;
        } else if (userAvailLp[tokenPair].poolApy > pool.poolApy) {
          userAvailLp[tokenPair] = pool;
        }
        return tokenPair;
      });

      allFarms
        ?.filter((farm: IYieldFarm) => farm.type !== 4 && farm)
        .map((pool: IFarmingPool) => {
          const {liquidityPool} = pool;
          const tokenPair = `${liquidityPool?.token1Symbol}-${liquidityPool?.token0Symbol}`;
          if (yieldFarmPairList?.includes(tokenPair) || liquidityPoolPairs?.includes(tokenPair)) {
            if (earnMoreFarms[tokenPair]) {
              earnMoreFarms[tokenPair].push(pool);
            } else {
              earnMoreFarms[tokenPair] = [pool];
            }
          }
        });

      // get max APY
      Object.keys(earnMoreFarms).filter((key) => {
        const max = earnMoreFarms[key].reduce((prev, current) => {
          return prev && prev.apy > current.apy ? prev : current;
        });
        if (!highAPYFarms[key]) {
          highAPYFarms[key] = max;
        }
        if (userAvailLp[key]?.address?.hash === max?.liquidityPool?.address?.hash) {
          delete userAvailLp[key];
        }
      });

      setAvailYield(userAvailYieldFarms);
      setAvailLp(userAvailLp);
      setCombinedHighAPYFarms(highAPYFarms);
    }
  }, [allFarms, filteredFarmings, liquidityPools]);

  if (Object.keys(combinedHighAPYFarms).length > 0 && !farmLoading) {
    const availFarm = Object.values(combinedHighAPYFarms).filter((item) => {
      const tokenPair = `${item?.liquidityPool?.token1Symbol}-${item?.liquidityPool?.token0Symbol}`;
      const highestAPYPool = combinedHighAPYFarms[tokenPair];
      const userFarm = availYield[tokenPair]?.apy !== highestAPYPool?.apy ? availYield[tokenPair] : undefined;
      const lp = availLp[tokenPair] ? availLp[tokenPair] : undefined;

      const diffAPY = highestAPYPool?.apy - (userFarm?.apy ?? lp?.poolApy);

      const liquidityPool = userFarm?.liquidityPool ?? lp;

      const poolBalance = formatBigNumber(userFarm?.balance ?? lp?.balance, liquidityPool?.decimals);
      const yourWorth = getTokenUSDPrice(poolBalance, liquidityPool?.lpTokenPriceUSD);
      const yearlyRevenue = (yourWorth * diffAPY) / 100;
      return yearlyRevenue >= 0.1;
    });

    if (availFarm?.length < 1) {
      return null;
    }

    return (
      <FarmWrapper>
        <FarmWrapperHeader>Earn more on your existing farms</FarmWrapperHeader>
        <FarmWrapperSubTitle>Deposit them to another farm to earn more.</FarmWrapperSubTitle>
        <FarmItemWrapper>
          {availFarm?.slice(0, 10).map((item, index) => {
            const tokenPair = `${item?.liquidityPool?.token1Symbol}-${item?.liquidityPool?.token0Symbol}`;
            const lp = availLp[tokenPair];
            const userFarm = availYield[tokenPair];
            return (
              <DuplicateFarmItem
                key={index}
                highestAPYFarm={item}
                userFarm={userFarm}
                lp={userFarm ? undefined : lp}
                isLp={!userFarm && lp ? true : false}
              />
            );
          })}
        </FarmItemWrapper>
      </FarmWrapper>
    );
  } else {
    return null;
  }
};

const FarmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc((100% - 90px) / 4);
  min-width: 360px;
  @media screen and (min-width: 1920px) {
    flex-basis: calc((100% - 360px) / 4);
    max-width: calc(25% - 1rem);
  }

  @media screen and (max-width: 1712px) and (min-width: 1367px) {
    flex-basis: calc((100% - 300px) / 3);
    min-width: 360px;
    max-width: calc(33.33% - 1rem);
  }
  /* Mid-wide screens: 2 columns */
  @media screen and (max-width: 1368px) and (min-width: 1023px) {
    flex-basis: calc((100% - 240px) / 3);
    min-width: 360px;
    max-width: calc(50% - 1rem);
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    flex-basis: calc((100% - 240px) / 2);
    min-width: 360px;
    max-width: calc(50% - 1rem);
  }

  /* Small screens and mobile: 1 column */
  @media screen and (max-width: 767px) {
    flex-basis: 100%;
    min-width: 360px;
    max-width: 450px;
  }

  @media screen and (max-width: 465px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const FarmWrapperHeader = styled(BodyVariant)`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 29.26px;
  color: ${COLORS.PRIMARY};
`;

const FarmWrapperSubTitle = styled(BodyParagraph)`
  text-align: center;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 16px;
  color: ${COLORS.PRIMARY};
`;

const FarmItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 1rem;
`;
