import {useCallback, useContext, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {failHandleEnum, migrationAddress, nullAddress} from 'src/constants/migrator';
import {AppContext} from 'src/contexts/AppContext';
import {useDepositCallback, useGetBalance, useWithdraw} from 'src/hooks';
import {ApprovalState, getLpToken, useApproveCallback} from 'src/hooks/kyber-swap';
import {useMigrateCallback} from 'src/hooks/useMigrate';
import {COLORS} from 'src/styles';
import {ILiquidityPool, IYieldFarm} from 'src/types';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import styled from 'styled-components';

import {ApproveLPDeposit} from './ApproveLPDeposit';
import {ApproveLPMigration} from './ApproveLPMigration';
import {DepositLP} from './DepositLP';
import {FailedMigrate} from './FailedMigrate';
import {MigrateLP} from './MigrateLiquidity';
import {SuccessMigrate} from './SuccessMigrate';
import {WithdrawLPTokens} from './WithdrawLP';

export type MigrationModalProps = {
  isOpen: boolean;
  yearlyRevenue: number;
  highestAPYPool: IYieldFarm;
  lowestAPYPool: IYieldFarm;
  name: string;
  iconUrl: string;
  lowestPoolInfo: string;
  lowestPooltvl: string;
  hightestPooltvl: string;
  highIconUrl: string;
  isLp: boolean;
  lp?: ILiquidityPool;
  lpInfo?: string;
  onDismiss?: () => void;
};

export const MigrationModal = ({
  isOpen,
  yearlyRevenue,
  highestAPYPool,
  lowestAPYPool,
  name,
  iconUrl,
  lowestPoolInfo,
  lowestPooltvl,
  hightestPooltvl,
  highIconUrl,
  isLp,
  lp,
  lpInfo,
  onDismiss,
}: MigrationModalProps) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('Earn More');
  const [failHandle, setFailHandle] = useState<failHandleEnum>();

  const {refreshFarmsAndLPs} = useContext(AppContext);

  const {account, chainId} = useWeb3React();

  const farm = lowestAPYPool;

  const oldLpAddress = lowestAPYPool?.liquidityPool?.address?.hash;
  const newLpAddress = highestAPYPool?.liquidityPool?.address?.hash;
  const lpAddress = lp?.address?.hash;

  const oldLpBal = lowestAPYPool?.balance;

  const isMigration = highestAPYPool?.liquidityPool?.address.hash !== lp?.address.hash;

  const {withdrawLpTokensCallback} = useWithdraw(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    farm,
    oldLpBal,
    undefined,
  );

  const {estimateGasForDeposit, deposit} = useDepositCallback(highestAPYPool);

  const oldLpToken = getLpToken(isLp ? lpAddress : oldLpAddress, chainId);
  const newLpToken = getLpToken(newLpAddress, chainId);

  const oldRouter = isLp
    ? lp?.platform?.routerAddresses[0]?.hash
    : lowestAPYPool?.platform?.routerAddresses?.[0]?.hash ??
      lowestAPYPool?.liquidityPool?.platform?.routerAddresses?.[0]?.hash;
  const newRouter =
    highestAPYPool?.platform?.routerAddresses?.[0].hash ??
    highestAPYPool?.liquidityPool?.platform?.routerAddresses[0].hash;

  const oldLpCurrencyAmount = tryParseAmount(isLp ? lp?.balance : lowestAPYPool?.balance, oldLpToken, false);
  const newLpCurrencyAmount = tryParseAmount(ethers.constants.MaxUint256, newLpToken, false);

  const [approveOldState, approveOld] = useApproveCallback(oldLpCurrencyAmount, account, migrationAddress);
  const [approveNewState, approveNew] = useApproveCallback(
    newLpCurrencyAmount,
    account,
    highestAPYPool?.masterChefAddress?.hash ?? highestAPYPool?.contractAddress?.hash ?? highestAPYPool?.address?.hash,
  );

  useEffect(() => {
    setStep(isLp ? (isMigration ? 1 : 3) : 0);
  }, [isLp, isMigration]);

  useEffect(() => {
    switch (step) {
      case 1:
        if (approveOldState === ApprovalState.APPROVED) setStep(2);
        break;
      case 3:
        if (approveNewState === ApprovalState.APPROVED) setStep(4);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const [migrateTx] = useMigrateCallback(
    oldRouter,
    newRouter,
    isLp ? lpAddress : oldLpAddress,
    nullAddress,
    nullAddress,
    nullAddress,
  );

  const onWithdrawLP = useCallback(async () => {
    let withdrawLpTokensResult;
    setLoading(true);
    if (lowestAPYPool) {
      try {
        withdrawLpTokensResult = await withdrawLpTokensCallback();
        await withdrawLpTokensResult.wait();
      } catch (e: unknown) {
        setFailHandle(failHandleEnum.WITHDRAW);
        setStep(6);
        setTitle('Earn More Failed');
        console.log(e);
        setLoading(false);
        return;
      }
    }
    setStep(1);
    setLoading(false);
  }, [lowestAPYPool, withdrawLpTokensCallback]);

  const onApproveLPMigration = useCallback(async () => {
    setLoading(true);
    if (approveOldState !== ApprovalState.APPROVED) {
      try {
        const approveTx = await approveOld();
        await approveTx.wait();
      } catch (e: unknown) {
        setFailHandle(failHandleEnum.APPROVESPEND);
        setStep(6);
        setTitle('Earn More Failed');
        console.log(e);
        setLoading(false);
        return;
      }
    }
    setStep(2);
    setLoading(false);
  }, [approveOldState, approveOld]);

  const onMigrateLP = async () => {
    setLoading(true);
    try {
      const migration = await migrateTx();
      await migration.wait();
    } catch (e: unknown) {
      setFailHandle(failHandleEnum.MIGRATE);
      setStep(6);
      setTitle('Earn More Failed');
      console.log(e);
      setLoading(false);
      return;
    }
    setStep(3);
    setLoading(false);
  };

  const onApproveLPDeposit = useCallback(async () => {
    setLoading(true);
    if (approveNewState !== ApprovalState.APPROVED) {
      try {
        const approveTx = await approveNew();
        await approveTx.wait();
      } catch (e: unknown) {
        setFailHandle(failHandleEnum.APPROVESPEND);
        setStep(6);
        setTitle('Earn More Failed');
        console.log(e);
        setLoading(false);
        return;
      }
    }
    setStep(4);
    setLoading(false);
  }, [approveNewState, approveNew]);

  const {getLpTokenBalance} = useGetBalance();

  const onDepositLP = useCallback(async () => {
    setLoading(true);
    const lpTokenBal = await getLpTokenBalance(newLpAddress);
    const gasEstimation = await estimateGasForDeposit(lpTokenBal);
    if (gasEstimation) {
      try {
        const depositResult = await deposit(gasEstimation, lpTokenBal);
        await depositResult.wait();
      } catch (e: unknown) {
        setFailHandle(failHandleEnum.DEPOSIT);
        setStep(6);
        setTitle('Earn More Failed');
        console.log(e);
        setLoading(false);
        return;
      }
      setTitle('Earn More Successful');
      setStep(5);
    }
    setLoading(false);
  }, [estimateGasForDeposit, getLpTokenBalance, newLpAddress, deposit]);

  const onHandleDismiss = () => {
    refreshFarmsAndLPs();
    return onDismiss();
  };

  const onFail = async () => {
    switch (failHandle) {
      case failHandleEnum.WITHDRAW:
        return onWithdrawLP();
      case failHandleEnum.APPROVESPEND:
        return onApproveLPMigration();
      case failHandleEnum.MIGRATE:
        return;
      case failHandleEnum.APPROVEDEPOSIT:
        return onApproveLPDeposit();
      case failHandleEnum.DEPOSIT:
        return onDepositLP;
      default:
        break;
    }
  };

  if (yearlyRevenue >= 0.1) {
    return (
      <Modal isOpen={isOpen}>
        <Wrapper>
          <Header>
            <StyledTitle>{title}</StyledTitle>
            <IconButton onClick={onHandleDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </Header>
          {step === 0 && (
            <WithdrawLPTokens
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={lowestPoolInfo}
              lowestPooltvl={lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onWithdrawLP}
            />
          )}
          {step === 1 && (
            <ApproveLPMigration
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={isLp ? lpInfo : lowestPoolInfo}
              lowestPooltvl={isLp ? undefined : lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onApproveLPMigration}
            />
          )}
          {step === 2 && (
            <MigrateLP
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={isLp ? lpInfo : lowestPoolInfo}
              lowestPooltvl={isLp ? undefined : lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onMigrateLP}
            />
          )}
          {step === 3 && (
            <ApproveLPDeposit
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={isLp ? lpInfo : lowestPoolInfo}
              lowestPooltvl={isLp ? undefined : lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onApproveLPDeposit}
            />
          )}
          {step === 4 && (
            <DepositLP
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={isLp ? lpInfo : lowestPoolInfo}
              lowestPooltvl={isLp ? undefined : lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onDepositLP}
            />
          )}
          {step === 5 && (
            <SuccessMigrate
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={isLp ? lpInfo : lowestPoolInfo}
              lowestPooltvl={isLp ? undefined : lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onHandleDismiss}
            />
          )}
          {step === 6 && (
            <FailedMigrate
              highestAPYPool={highestAPYPool}
              name={name}
              yearlyRevenue={yearlyRevenue}
              iconUrl={iconUrl}
              lowestPoolInfo={isLp ? lpInfo : lowestPoolInfo}
              lowestPooltvl={isLp ? undefined : lowestPooltvl}
              hightestPooltvl={hightestPooltvl}
              highIconUrl={highIconUrl}
              loading={loading}
              disabled={loading}
              onConfirm={onFail}
            />
          )}
        </Wrapper>
      </Modal>
    );
  } else {
    return null;
  }
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
