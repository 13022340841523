import React from 'react';

import {BigNumber} from 'ethers';
import {Button} from 'src/components/Buttons';
import {LiquidityInputItem} from 'src/components/Liquidity/LiquidityInputItem';
import {LiquidityReceiveItem} from 'src/components/Liquidity/LiquidityReceiveItem';
import {TickIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type AddLiquidityProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  liquidity: number;
  share: number;
  shareDecimals: number;
  token0Amount: BigNumber;
  token1Amount: BigNumber;
  rate: string[];
  slippage: number;
  totalInputValue: number;
  loading?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  onConfirm?: () => void;
  onFail?: () => void;
};

export const AddLiquidity = ({
  token0,
  token1,
  liquidity,
  share,
  shareDecimals,
  token0Amount,
  token1Amount,
  rate,
  slippage,
  totalInputValue,
  loading,
  disabled,
  errorMsg,
  onFail,
  onConfirm,
}: AddLiquidityProps) => {
  return (
    <Body>
      <StepsContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Token 1</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Token 2</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>3</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Add Liquidity</BodyVariant>
        </StyledRow>
      </StepsContainer>
      <TokenInfoBox>
        <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          You will receive
        </BodyParagraph>
        <LiquidityReceiveItem
          token0={token0}
          token1={token1}
          lpReceived={liquidity}
          poolShare={share}
          poolShareDecimals={shareDecimals}
        />
        <StyledInputHeader color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          Input
        </StyledInputHeader>
        <LiquidityInputItem token0={token0} token1={token1} token0Amount={token0Amount} token1Amount={token1Amount} />
        <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          = ${totalInputValue.toFixed(2)}
        </BodyParagraph>
        <StyledRowContainer marginTop={20}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            Rates
          </BodyParagraph>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
            1 {token0?.symbol} = {rate[0]} {token1?.symbol}
          </BodyParagraph>
        </StyledRowContainer>
        <StyledRowContainer marginTop={10}>
          <SecondRateInfo color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
            1 {token1?.symbol} = {rate[1]} {token0?.symbol}
          </SecondRateInfo>
        </StyledRowContainer>
        <StyledRowContainer marginTop={20}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            Slippage
          </BodyParagraph>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {slippage ?? 0.1}%
          </BodyParagraph>
        </StyledRowContainer>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title={errorMsg ? 'Try Again' : 'Confirm Supply'}
          onClick={errorMsg ? onFail : onConfirm}
        />
        {errorMsg ? (
          <StyledParagraph color={COLORS.WARNING}>{errorMsg}</StyledParagraph>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StepsContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const SecondRateInfo = styled(BodyParagraph)`
  margin-left: auto;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 16px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledInputHeader = styled(BodyParagraph)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
