import {ChangeEvent, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {TokenSelectModal} from 'src/components/Modals';
import {SingleWithdrawModal, SingleWithdrawModalProps} from 'src/components/Modals/SingleWithdraw';
import {ArrowDownIcon} from 'src/components/Svgs';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {useToken} from 'src/hooks';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {formatBigNumber, getTokenAmountFromUsdInBigNumber} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {ContentProps} from '..';

export const SingleWithdraw = ({token0Detail, yourWorth, farmItem}: ContentProps) => {
  const {chainId} = useWeb3React();
  const {check} = useAgreementCheck();
  const {getTokenByAddress, getWrappedNativeToken} = useToken();
  const [receiveTokenAddress, setReceiveTokenAddress] = useState(token0Detail?.address);
  const [showReceiveTokenSelectModal, setShowReceiveTokenSelectModal] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const [usdAmount, setUsdAmount] = useState<string>('');
  const [showWarning, setShowWarning] = useState(false);
  const [withdrawModalProps, setWithdrawModalProps] = useState<SingleWithdrawModalProps>();
  const selectedToken1 = useMemo(() => {
    const token = getTokenByAddress(receiveTokenAddress, false);
    if (token.isNative) {
      return getWrappedNativeToken(chainId);
    }
    return token;
  }, [chainId, getTokenByAddress, getWrappedNativeToken, receiveTokenAddress]);
  const farmBalance = farmItem?.balance;
  const tokenAmount = useMemo(() => {
    if (isMax) {
      return farmBalance;
    } else if (usdAmount) {
      return getTokenAmountFromUsdInBigNumber(Number(usdAmount), token0Detail);
    } else {
      return BigNumber.from(0);
    }
  }, [isMax, usdAmount, farmBalance, token0Detail]);

  const withdrawValueInSelectedToken1 = useMemo(() => {
    if (usdAmount) {
      return getTokenAmountFromUsdInBigNumber(Number(usdAmount), selectedToken1);
    }
  }, [selectedToken1, usdAmount]);

  const disabled = !usdAmount || Number(usdAmount) <= 0 || showWarning;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsMax(false);
    const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setUsdAmount(removedComma);
  };

  const handleMax = () => {
    setIsMax(true);
    setUsdAmount(yourWorth.toString());
  };

  const handleReceiveTokenSelect = (token?: IWhitelistToken) => {
    setReceiveTokenAddress(token?.address);
  };

  const handleWithdraw = () => {
    const payload = {
      isOpen: true,
      inputToken: token0Detail,
      inputTokenAmount: tokenAmount,
      outputToken: selectedToken1,
      outputTokenEstimatedAmount: formatBigNumber(withdrawValueInSelectedToken1, selectedToken1?.decimals),
      farm: farmItem,
    };
    check(() => setWithdrawModalProps(payload));
  };

  useEffect(() => {
    usdAmount && Number(usdAmount) > yourWorth ? setShowWarning(true) : setShowWarning(false);
  }, [yourWorth, usdAmount]);

  const onWithdrawSuccessCallback = () => {
    setIsMax(false);
    setUsdAmount('');
  };

  const handleDismissWithdrawModal = () => {
    setWithdrawModalProps({...withdrawModalProps, isOpen: false});
  };

  return (
    <ContentlWrapper>
      <StyledCol gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Amount to withdraw</BodyVariant>
        <StyledInputWrapper>
          <StyledFullRow>
            <StyledRow flex alignCenter>
              <BodyVariant color={COLORS.PRIMARY}>$</BodyVariant>
              <StyledInput
                value={usdAmount}
                placeholder='0.0'
                inputMode='decimal'
                pattern='^[0-9]*[.,]?[0-9]*$'
                onChange={onChange}
              />
            </StyledRow>
            <StyledMaxButton onClick={handleMax}>
              <BodyParagraph color={COLORS.SECONDARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                MAX
              </BodyParagraph>
            </StyledMaxButton>
          </StyledFullRow>
        </StyledInputWrapper>
        {showWarning && usdAmount && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>Amount to withdraw exceeds your deposit.</BodyParagraph>
          </StyledWarningBox>
        )}
      </StyledCol>
      <StyledCol marginTop={24} gap={10}>
        {token0Detail && (
          <StyledTokenItem
            token={token0Detail}
            value={formatBigNumber(tokenAmount, token0Detail?.decimals).toFixed(token0Detail?.interfaceDecimals)}
          />
        )}
      </StyledCol>
      <ArrowWrapper>
        <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
      </ArrowWrapper>
      <StyledCol gap={4}>
        <BodyVariant color={COLORS.PRIMARY}>Receive</BodyVariant>
        <StyledRow margin={12}>
          <StyledCol gap={8}>
            <BorderWrapper>
              <TokenSelectItem token={selectedToken1} onSelect={() => setShowReceiveTokenSelectModal(true)} />
            </BorderWrapper>
            <BodyParagraph color={COLORS.GRAY_LIGHT}>
              = {selectedToken1.symbol}{' '}
              {withdrawValueInSelectedToken1 &&
                formatBigNumber(withdrawValueInSelectedToken1, selectedToken1?.decimals).toFixed(
                  selectedToken1?.interfaceDecimals,
                )}
            </BodyParagraph>
          </StyledCol>
        </StyledRow>
      </StyledCol>
      <StyledCol marginTop={30}>
        <Button disabled={disabled} title='Withdraw' onClick={handleWithdraw} />
      </StyledCol>
      <TokenSelectModal
        selectedToken={selectedToken1}
        showClearToken={true}
        hideNativeToken={true}
        onTokenSelect={handleReceiveTokenSelect}
        isOpen={showReceiveTokenSelectModal}
        onDismiss={() => setShowReceiveTokenSelectModal(false)}
      />
      <SingleWithdrawModal
        {...withdrawModalProps}
        onWithdrawSuccess={onWithdrawSuccessCallback}
        onDismiss={handleDismissWithdrawModal}
      />
    </ContentlWrapper>
  );
};

const ContentlWrapper = styled.div`
  flex: 1;
  padding: 31px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 12px;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 4px;
`;

const StyledInput = styled.input`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
`;

const StyledRow = styled.div<{flex?: boolean; margin?: number; alignCenter?: boolean}>`
  ${(props) =>
    props.flex &&
    `
    flex: 1;
  `}
  display: flex;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
  gap: ${(props) => props.margin ?? 0}px;
`;

const StyledFullRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-tween;
`;

const StyledMaxButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const ArrowWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BorderWrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 12px;
  padding: 8px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
