import React, {useContext, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {toast} from 'react-toastify';
import {CloseIcon} from 'src/components/Svgs';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useTokenValidation} from 'src/hooks/custom-token-import/useCustomToken';
import {useModalState} from 'src/hooks/custom-token-import/useCustomTokenImportModalState';
import {useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types/token';
import styled from 'styled-components';

import {Modal} from './Modal';
import {Button} from '../Buttons';
import {BodyVariant} from '../Typography';
import Warningbox from '../WarningBox';

type AddCustomTokenModalProps = {
  isOpen: boolean;
  tokenAddress?: string;
};

const AddCustomTokenModal: React.FC<AddCustomTokenModalProps> = ({isOpen, tokenAddress}) => {
  const {account} = useWeb3React();
  const customTokens = useAppSelector((state) => state.user.customTokens || []);
  const {tokens} = useContext(AppContext);
  const {contractAddress, setContractAddress, closeModal} = useModalState(tokenAddress);
  const {tokenInfo, status, saveCustomToken} = useTokenValidation(contractAddress);
  const {dispatch} = useModals();

  const [loading, setLoading] = useState(false);
  const [allowedCustomToken, setAllowedCustomToken] = useState(false);

  useEffect(() => {
    const checkExistingToken = (token: IWhitelistToken) => {
      const existingCustomToken = customTokens?.find((item) => {
        return item.chainId === token.chainId && item.address?.toUpperCase() === token.address?.toUpperCase();
      });
      const existingWhitelistToken = tokens?.find((item) => {
        return item.chainId === token.chainId && item.address?.toUpperCase() === token.address?.toUpperCase();
      });
      return existingWhitelistToken || existingCustomToken;
    };

    if (tokenInfo && !checkExistingToken(tokenInfo)) {
      setAllowedCustomToken(true);
    } else {
      setAllowedCustomToken(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInfo, status]);

  const onDismiss = () => {
    setContractAddress('');
    closeModal();
    setLoading(false);
  };

  const onConfirm = async () => {
    setLoading(true);
    if (allowedCustomToken) {
      const newCustomTokens = [...(customTokens ?? [])];
      newCustomTokens.push(tokenInfo);
      saveCustomToken(newCustomTokens);
      onDismiss();
    } else {
      toast('Existing contract address');
    }
  };

  const onConnectWallet = () => {
    const payload = {isOpen: true, next: 'updateAddCustomTokenModal'};
    dispatch({type: 'updateWalletConnectModal', payload});
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Add Custom Token</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          <WarningBoxStyle>
            <Warningbox
              description={
                'Anyone can create a token, including creating fake versions of existing tokens. Please double-check your token addresses.'
              }
              bgColor={COLORS.GRAY_BASE_40}
            />
          </WarningBoxStyle>
          <InputBox>
            <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.MEDIUM}>
              Token
            </BodyVariant>
            <Input
              placeholder='Contract Address'
              value={contractAddress}
              disabled={!account}
              onChange={(e) => setContractAddress(e.target.value)}
              data-testid='addCustomTokenInput'
            />
          </InputBox>
          {status === 'valid' && tokenInfo && (
            <InputBox>
              <FlexRow>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.MEDIUM}>
                  Token Name
                </BodyVariant>
                <ValueLabel>{tokenInfo?.name}</ValueLabel>
              </FlexRow>
              <FlexRow>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.MEDIUM}>
                  Token Symbol
                </BodyVariant>
                <ValueLabel>{tokenInfo?.symbol}</ValueLabel>
              </FlexRow>
              <FlexRow>
                <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.MEDIUM}>
                  Token Decimals
                </BodyVariant>
                <ValueLabel>{tokenInfo?.decimals}</ValueLabel>
              </FlexRow>
            </InputBox>
          )}
          {status === 'invalid' && (
            <InvalidContractBox>
              <Label>Oh no! Invalid contract address</Label>
              <CenterLabel>
                It seems that you have entered an invalid contract address. Please double check your token address and
                try again.
              </CenterLabel>
            </InvalidContractBox>
          )}
          {status === 'notConnected' && (
            <InvalidContractBox>
              <Label>Your wallet is not connected</Label>
              <CenterLabel>Please connect before adding a token.</CenterLabel>
            </InvalidContractBox>
          )}
          <ButtonWrapper>
            {account ? (
              <Button
                color={COLORS.PRIMARY}
                title={status === 'valid' && !allowedCustomToken ? 'Existing contract address' : 'Import'}
                disabled={status !== 'valid' || !allowedCustomToken}
                onClick={onConfirm}
                isLoading={loading}
              />
            ) : (
              <Button color={COLORS.PRIMARY} title='Connect Wallet' onClick={onConnectWallet} />
            )}
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const WarningBoxStyle = styled.div`
  padding-bottom: 24px;
`;

const InputBox = styled.div`
  padding-bottom: 30px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Label = styled.label`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #333;
  padding-bottom: 10px;
`;

const ValueLabel = styled.span`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 12px;
  color: #000;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const InvalidContractBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const CenterLabel = styled(Label)`
  text-align: center;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

// const SearchView = styled.div`
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 38px;
//   border: 1px solid ${COLORS.GRAY_BORDER};
//   border-radius: 6px;
//   padding: 0 8px;
// `;
const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

export default AddCustomTokenModal;
