import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {PremiumModes} from 'src/constants/premiums';
import {COLORS} from 'src/styles';
import {convertKMPrice} from 'src/utils/text-util';
import styled from 'styled-components';

type TierInfoProps = {
  targetTierIndex: number;
  remainingPrice: number;
  remainingPriceUSD: number;
};

export const TierInfo = ({targetTierIndex, remainingPrice, remainingPriceUSD}: TierInfoProps) => {
  return (
    <Wrapper>
      <StyledImg size={60} src={PremiumModes[targetTierIndex].icon} />
      <div>
        <BodyVariant color={COLORS.PRIMARY}>{`${convertKMPrice(remainingPrice)} LIQ till ${
          PremiumModes[targetTierIndex].title
        }`}</BodyVariant>
        <BodyParagraph color={COLORS.GRAY_LIGHT} style={{textAlign: 'center'}}>
          ${remainingPriceUSD.toFixed(2)}
        </BodyParagraph>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 14px;
`;

const StyledImg = styled.img<{size?: number}>`
  width: ${(props) => props.size ?? 32}px;
`;
