import {useCallback, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import LibUpdater from 'src/hooks/transactions/updater';
import {SerializableTransactionReceipt} from 'src/types';

import {transactionSlice} from './reducer';
import {useAppDispatch, useAppSelector} from '../hooks';

export default function Updater() {
  const {chainId} = useWeb3React();
  const transactions = useAppSelector((state) => state.transactions);

  const dispatch = useAppDispatch();
  const onCheck = useCallback(
    ({chainId, hash, blockNumber}: {chainId: number; hash: string; blockNumber: number}) =>
      dispatch(transactionSlice.actions.checkedTransaction({chainId, hash, blockNumber})),
    [dispatch],
  );
  const onReceipt = useCallback(
    ({chainId, hash, receipt}: {chainId: number; hash: string; receipt: SerializableTransactionReceipt}) => {
      dispatch(
        transactionSlice.actions.finalizeTransaction({
          chainId,
          hash,
          receipt: {
            blockHash: receipt.blockHash,
            blockNumber: receipt.blockNumber,
            contractAddress: receipt.contractAddress,
            from: receipt.from,
            status: receipt.status,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            transactionIndex: receipt.transactionIndex,
          },
        }),
      );
    },
    [dispatch],
  );

  const pendingTransactions = useMemo(() => (chainId ? transactions[chainId] ?? {} : {}), [chainId, transactions]);

  return <LibUpdater pendingTransactions={pendingTransactions} onCheck={onCheck} onReceipt={onReceipt} />;
}
