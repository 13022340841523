import React from 'react';

import {useNavigate} from 'react-router';
import WarningImg from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

export const Notfound = () => {
  const navigate = useNavigate();
  const onErrorReturn = () => {
    navigate('/home');
  };

  return (
    <ErrorBoundaryWrapper>
      <ErrorBoundaryContentContainer>
        <WarningIcon src={WarningImg} />
        <ErrorHeader>Page Not Found</ErrorHeader>
        <ErrorMsg>
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
          Please check the URL for typos or return to our homepage.
        </ErrorMsg>
        <ButtonWrapper>
          <Button color={COLORS.PRIMARY} title='Back to Home' onClick={onErrorReturn} />
        </ButtonWrapper>
      </ErrorBoundaryContentContainer>
    </ErrorBoundaryWrapper>
  );
};

const ErrorBoundaryWrapper = styled.main`
  font-family: Montserrat;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${COLORS.PRIMARY};
`;

const ErrorBoundaryContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WarningIcon = styled.img`
  width: 50px;
  height: 47.3px;
`;

const ErrorHeader = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 1.2rem;
  }
`;

const ErrorMsg = styled.p`
  font-size: 1.125rem;
  width: 505px;
  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 1rem;
  }

  @media screen and (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 90%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 342px;

  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 250px;
  }
`;
