import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract} from 'ethers';
import ERC20_ABI from 'src/abis/ERC20.json';
import {IWhitelistToken} from 'src/types';

export const useSendToken = () => {
  const {account, provider} = useWeb3React();

  const estimateSendToken = async (to: string, amount: BigNumber, token: IWhitelistToken) => {
    const signer = provider.getSigner(account);
    if (!token.isNative) {
      // general token send
      const contract = new Contract(token.address, ERC20_ABI, signer);
      // Send tokens
      const estimateGas = await contract.estimateGas.transfer(to, amount);
      return estimateGas;
    } else {
      const payload = {
        from: account,
        to,
        value: amount,
      };
      const estimateGas = await signer.estimateGas(payload);
      return estimateGas;
    }
  };

  const sendToken = async (to: string, amount: BigNumber, gasLimit: BigNumber, token: IWhitelistToken) => {
    const signer = provider.getSigner(account);
    if (!token.isNative) {
      // general token send
      const contract = new Contract(token.address, ERC20_ABI, signer);
      // How many tokens?
      const numberOfTokens = amount;
      // Send tokens
      const tx = await contract.transfer(to, numberOfTokens, {gasLimit});
      return tx;
    } else {
      const payload = {
        from: account,
        to,
        value: amount,
        nonce: provider.getTransactionCount(account, 'latest'),
        gasLimit,
      };
      const tx = await signer.sendTransaction(payload);
      return tx;
    }
  };

  return {estimateSendToken, sendToken};
};
