import {useNavigate} from 'react-router-dom';
import {paths} from 'src/constants';
import {useToken} from 'src/hooks';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IYieldFarm, POOL_TYPE} from 'src/types';
import {calculatePrice, formatBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph, BodyVariant} from '../Typography';

type SingleFarmItemForYieldProps = {
  data: IYieldFarm;
  selected?: boolean;
};

export const SingleFarmItemForYield = ({data, selected}: SingleFarmItemForYieldProps) => {
  const navigate = useNavigate();
  const {getTokenByAddress} = useToken();
  const symbol = data.rewardTokenChainSpecifics?.symbol;
  const singleToken = getTokenByAddress(data.rewardTokenChainSpecifics.address.hash);
  const tokenName = `${symbol}`;
  const tokenPriceUSDUnit = calculatePrice(singleToken?.priceUSD, singleToken?.priceDecimals);
  const tokenBalance = formatBigNumber(singleToken?.balance, singleToken?.decimals) * tokenPriceUSDUnit;
  const yearlyRevenue = (tokenBalance * data.apy) / 100;
  const handleNavigation = () => {
    navigate(`${paths.poolDetail}?type=${POOL_TYPE.SINGLE_FARM}&address=${data.contractAddress.hash}`);
  };

  return (
    <StyledTokenBox selected={selected}>
      <StyledLeftWrapper>
        <TokenIconWrapper>
          <TokenIcon width={24} height={24} token={singleToken} />
        </TokenIconWrapper>
        <StyledTokenNameWrapper>
          <TokenName color={COLORS.PRIMARY}>{tokenName}</TokenName>
          <StyledPlatformLabel color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {`${data?.apy?.toFixed(1)}% APY - $${yearlyRevenue.toFixed(2)} per year`}
          </StyledPlatformLabel>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <EarnButton onClick={handleNavigation}>
          <p>Earn</p>
        </EarnButton>
      </StyledRightWrapper>
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.div<{selected?: boolean}>`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  width: 100%;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 90%;
  }
`;

const TokenIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTokenNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPlatformLabel = styled(BodyParagraph)`
  text-align: left;
`;

const TokenName = styled(BodyVariant)`
  font-size: 12px;
`;

const EarnButton = styled.a`
  padding: 8px 22px;
  // width: 39px;
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY};
  border-radius: 8px;
  border: none;
  & p {
    margin: 0;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    background-color: rgb(17, 36, 140);
  }
`;
