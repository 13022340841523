import {gql} from '@apollo/client';

export const CONTACT = gql`
  query userPreferences($accountAddress: String!) {
    getUserPreference(accountAddress: $accountAddress) {
      hash
      accountName
      contacts {
        hash
        name
      }
      customNetworks {
        name
        chainId
        rpcUrl
        symbol
        blockExplorerUrl
      }
      gasPrice
    }
  }
`;
