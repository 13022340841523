import {useCallback} from 'react';

import {useModals} from 'src/contexts/modals';

import {useTandCAgreement} from './useTandCAgreement';

interface userSignaturePayload {
  isOpen: boolean;
  name: string;
  additionalConditions: string[] | undefined;
  openNextModal: () => void;
}

export const useUserSignature = () => {
  const modalContext = useModals();
  const {getTandCAgreement} = useTandCAgreement();

  const openUserSignature = useCallback((nextModal?: () => void) => {
    const payload: userSignaturePayload = {
      isOpen: true,
      name: 'Liquidus',
      additionalConditions: undefined,
      openNextModal: nextModal,
    };
    modalContext.dispatch({type: 'updateUserSignature', payload});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependency on modalContext would trigger infinte re-renders

  const closeUserSignature = useCallback(() => {
    getTandCAgreement();
    modalContext.dispatch({type: 'updateUserSignature', payload: {isOpen: false}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Dependency on modalContext would trigger infinte re-renders
  return {
    openUserSignature,
    closeUserSignature,
  };
};
