import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type TickIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const TickIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: TickIconType) => (
  <svg width={size} height={size} viewBox='0 0 30 30' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.3502 15.9962L10.5782 13.4599L9 15.1842L13.4218 19.2302L21 11.652L19.348 10L13.3502 15.9962Z'
      fill={color}
    />
  </svg>
);

export default TickIcon;
