export const NEW_LIQ_GLOBAL_NAME = 'NewLIQ';
export const CLASSIC_LIQ_GLOBAL_NAME = 'LIQ';
export const ABR_GLOBAL_NAME = 'Allbridge';
export const USDT_GLOBAL_NAME = 'USDT';
export const BNB_GLOBAL_NAME = 'BNB Smart Chain';

export enum wrappedPositionEnum {
  NONE = 'none',
  FIRST = 'first',
  LAST = 'last',
}

export const EXTRA_DECIMALS = 4;

export const TOPUP_THRESHOLD = 0.1;

export enum inputTokenOptions {
  ADD_LIQUIDITY,
  TOKEN_0,
  TOKEN_1,
  DEFAULT,
}
