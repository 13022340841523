import React, {useCallback, useContext, useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon, SettingsIcon} from 'src/components/Svgs';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useToken, useWithdraw} from 'src/hooks';
import {COLORS} from 'src/styles';
import {ILiquidityPool, IWhitelistToken, IYieldFarm} from 'src/types';
import {isTransactionSuccessful} from 'src/utils/utils';
import styled from 'styled-components';

import {ApproveLPTokens} from './ApproveLPTokens';
import {RemoveLiquidityPool} from './RemoveLiquidityPool';
import {SuccessTopup} from './SuccessTopup';
import {WithdrawLPFromFarm} from './WithdrawLPFromFarm';
import {FailedTransaction} from '../FailedTransaction';

export type WithdrawModalProps = {
  isOpen: boolean;
  onWithdrawSuccess?: () => void;
  onDismiss?: () => void;
  lpToken1?: IWhitelistToken;
  lpToken2?: IWhitelistToken;
  lpToken1Amount?: number;
  lpToken2Amount?: number;
  outputToken?: IWhitelistToken;
  outputTokenEstimatedAmount?: number;
  lpTokensAmount?: BigNumber;
  totalUsdAmount?: number;
  farm?: IYieldFarm;
  lp?: ILiquidityPool;
  keepTokensMode?: boolean;
};

export const WithdrawModal = ({
  isOpen = false,
  onWithdrawSuccess,
  onDismiss,
  lpToken1,
  lpToken2,
  lpToken1Amount,
  lpToken2Amount,
  outputToken,
  outputTokenEstimatedAmount,
  lpTokensAmount,
  totalUsdAmount,
  farm,
  lp,
  keepTokensMode,
}: WithdrawModalProps) => {
  const {refreshTokens, refreshFarmsAndLPs, farmLoading} = useContext(AppContext);
  const modalContext = useModals();
  const {getTokenByAddress} = useToken();
  const [step, setStep] = useState(0);
  const [retryStep, setRetryStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [zapOutFailed, setZapOutFailed] = useState(false);
  const [zapOutFailedCounter, setZapOutFailedCounter] = useState(0);
  const [zapReceivedError, setZapRecievedError] = useState<string>();
  const lpMode = !farm;
  const title = step !== 3 ? 'Withdraw' : 'Withdraw Successful';
  const rewardsToken = getTokenByAddress(farm?.rewardTokenChainSpecifics?.address?.hash);
  const {isLPTokenApproved, approveLpTokensCallback, zapOutCallback, withdrawLpTokensCallback} = useWithdraw(
    lpToken1,
    lpToken2,
    lpToken1Amount,
    lpToken2Amount,
    lp,
    farm,
    lpTokensAmount,
    keepTokensMode ? undefined : outputToken,
  );

  useEffect(() => {
    setRetryStep(0);
    if (lpMode) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [isOpen, lpMode]);

  useEffect(() => {
    if (loading && !farmLoading) setLoading(false);
    if (isLPTokenApproved && step === 1) {
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLPTokenApproved, step, farmLoading]);

  const handleOpenTopupSwapSettings = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateZapSettingsModal', payload});
  };

  const onTransactionFail = (failStep: number, retry?: number, e?: unknown) => {
    //@ts-expect-error: unkown type to incorporate all error types - reason is present
    if (e.reason === zapKnownErrorReasons.TRANSACTION_FAILED || e === undefined) {
      setStep(failStep);
      if (retry) setRetryStep(retry);
    }
  };

  const onWithdrawLPFromFarm = useCallback(async () => {
    setLoading(true);
    try {
      const withdrawResult = await withdrawLpTokensCallback();
      if (!withdrawResult) {
        setLoading(false);
        return;
      }
      const tx2 = await withdrawResult.wait();
      console.log(tx2);
      setStep(isTransactionSuccessful(tx2) ? 1 : 4);
    } catch (e) {
      console.log(e);
      onTransactionFail(4, 0, e);
    }
    setLoading(false);
  }, [withdrawLpTokensCallback]);

  const onApproveLPToken = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await approveLpTokensCallback();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [approveLpTokensCallback]);

  const onRemoveLiquidityPool = useCallback(async () => {
    setZapOutFailed(false);
    setLoading(true);
    try {
      const {zapOutResult} = await zapOutCallback();
      if (!zapOutResult) {
        setLoading(false);
        return;
      }
      const tx1 = await zapOutResult.wait();
      console.log(tx1);
      if (isTransactionSuccessful(tx1)) {
        refreshTokens(true);
        refreshFarmsAndLPs();
        onWithdrawSuccess && onWithdrawSuccess();
        setStep(3);
      } else onTransactionFail(4, 2);
    } catch (e) {
      console.log({e});
      onTransactionFail(4, 2, e);
      // @ts-expect-error: e obj includes reason
      if (e.reason !== zapKnownErrorReasons.USERREJECTION) {
        // @ts-expect-error: e obj includes reason
        setZapRecievedError(e.reason);
        setZapOutFailed(true);
        setZapOutFailedCounter(zapOutFailedCounter + 1);
        setLoading(false);
        return;
      }
    }
    setLoading(false);
  }, [onWithdrawSuccess, refreshFarmsAndLPs, refreshTokens, zapOutCallback, zapOutFailedCounter]);

  const onRefresh = () => {
    refreshTokens(true);
    refreshFarmsAndLPs();
    setZapOutFailed(false);
  };

  const onhandleDismiss = () => {
    onRefresh();
    onDismiss();
  };

  const onRetry = () => {
    onRefresh();
    setStep(retryStep);
    setLoading(true);
    setRetryStep(0);
  };
  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <StyledSettingsContainer>
            <StyledIconButton onClick={handleOpenTopupSwapSettings}>
              <SettingsIcon />
            </StyledIconButton>
            <IconButton onClick={onhandleDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledSettingsContainer>
        </Header>
        {step === 0 && (
          <WithdrawLPFromFarm
            lpToken1={lpToken1}
            lpToken2={lpToken2}
            totalUsdAmount={totalUsdAmount}
            platform={lpMode ? lp?.platform : farm?.platform}
            apy={farm?.apy}
            rewardsToken={rewardsToken}
            rewards={farm?.pendingReward}
            keepTokensMode={keepTokensMode}
            loading={loading}
            disabled={loading}
            onConfirm={onWithdrawLPFromFarm}
          />
        )}
        {step === 1 && (
          <ApproveLPTokens
            lpToken1={lpToken1}
            lpToken2={lpToken2}
            keepTokensMode={keepTokensMode}
            lpMode={lpMode}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveLPToken}
          />
        )}
        {step === 2 && (
          <RemoveLiquidityPool
            lpToken1={lpToken1}
            lpToken2={lpToken2}
            lpToken1Amount={lpToken1Amount}
            lpToken2Amount={lpToken2Amount}
            outputToken={outputToken}
            outputTokenAmount={outputTokenEstimatedAmount}
            totalUsdAmount={totalUsdAmount}
            keepTokensMode={keepTokensMode}
            lpMode={lpMode}
            loading={loading}
            disabled={loading}
            onConfirm={onRemoveLiquidityPool}
            zapOutError={zapOutFailed}
            zapOutErrorMessage={zapReceivedError}
          />
        )}
        {step === 3 && (
          <SuccessTopup
            lpToken1={lpToken1}
            lpToken2={lpToken2}
            lpToken1Amount={lpToken1Amount}
            lpToken2Amount={lpToken2Amount}
            outputToken={outputToken}
            outputTokenAmount={outputTokenEstimatedAmount}
            totalUsdAmount={totalUsdAmount}
            keepTokensMode={keepTokensMode}
            onDone={onDismiss}
          />
        )}{' '}
        {step === 4 && <FailedTransaction onExit={onRetry} />}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const StyledSettingsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledIconButton = styled.div`
  cursor: pointer;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }
`;
