import {useNavigate} from 'react-router-dom';
import {paths} from 'src/constants';
import {useToken} from 'src/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IYieldFarm, POOL_TYPE} from 'src/types';
import {convertKMPrice} from 'src/utils/text-util';
import {calculatePrice, formatBigNumber, getVestingInMonth} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph, BodyVariant} from '../Typography';

type TokenDepositItemProps = {
  type?: number;
  data: IYieldFarm;
  selected?: boolean;
  disabled?: boolean;
  size?: 'default' | 'small';
};

export const TokenDepositItem = ({type, data, selected, disabled, size = 'default'}: TokenDepositItemProps) => {
  const navigate = useNavigate();
  const {getTokenByAddress} = useToken();
  const symbol = data.rewardTokenChainSpecifics?.symbol;
  const addressHash = data.rewardTokenChainSpecifics.address.hash;
  const singleToken = getTokenByAddress(addressHash);
  const vestingInMonth = getVestingInMonth(data.vestingTimeInDays);
  const tokenName = `${symbol} - ${vestingInMonth} Month`;
  const tokenPriceUSDUnit = calculatePrice(singleToken?.priceUSD, singleToken?.priceDecimals);
  const tokenBalance = formatBigNumber(data?.balance, singleToken?.decimals) * tokenPriceUSDUnit;
  const tvlPrice = data?.tvlNumber;

  const handleNavigation = () => {
    navigate(`${paths.poolDetail}?type=${POOL_TYPE.SINGLE_FARM}&address=${data.contractAddress.hash}`);
  };

  return (
    <StyledTokenBox disabled={disabled} selected={selected} onClick={handleNavigation}>
      <StyledLeftWrapper>
        <TokenIconWrapper>
          <TokenIcon width={size === 'default' ? 40 : 24} height={size === 'default' ? 40 : 24} token={singleToken} />
        </TokenIconWrapper>
        <StyledTokenNameWrapper>
          <BodyVariant
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            {tokenName}
          </BodyVariant>
          <StyledPlatformLabel color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {data?.platform?.name}
          </StyledPlatformLabel>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      {type ? (
        <StyledRightWrapper>
          <StyledTokenLabel
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            {data?.apy?.toFixed(1)}% APY
          </StyledTokenLabel>
          <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            ${convertKMPrice(tvlPrice || 0)} TVL
          </StyledTokenPrice>
        </StyledRightWrapper>
      ) : (
        <StyledRightWrapper>
          <StyledTokenLabel
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            ${tokenBalance.toFixed(2)}
          </StyledTokenLabel>
          <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {data?.apy?.toFixed(1)}% APY
          </StyledTokenPrice>
        </StyledRightWrapper>
      )}
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.button<{selected?: boolean}>`
  padding: 12px 16px;
  height: 84px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const TokenIconWrapper = styled.div<{size?: 'default' | 'small'}>`
  width: ${(props) => (props.size == 'default' ? 60 : 30)}px;
  display: flex;
  justify-content: center;
  margin-right: ${(props) => (props.size == 'default' ? 24 : 12)}px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTokenNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPlatformLabel = styled(BodyParagraph)`
  text-align: left;
`;

const StyledTokenLabel = styled(BodyVariant)`
  text-align: right;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;
