import React, {useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {CLASSIC_LIQ_GLOBAL_NAME, NEW_LIQ_GLOBAL_NAME} from 'src/constants';
import {PremiumModes} from 'src/constants/premiums';
import {useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {getTokenUSDPrice} from 'src/utils/token-util';
import styled from 'styled-components';

import {StakeLIQConfirm} from './StakeLIQConfirm';
import {StakeSelectLIQ} from './StakeSelectLIQ';
import {TierInfo} from './TierInfo';

export type PremiumTierModalProps = {
  isOpen: boolean;
  targetTierIndex: number;
  remainingPrice: number;
  onDismiss?: (stakeLiqFarm?: IYieldFarm, inputToken?: IWhitelistToken, inputTokenAmount?: BigNumber) => void;
};

export const PremiumTierModal = ({
  isOpen = false,
  targetTierIndex = 0,
  remainingPrice,
  onDismiss,
}: PremiumTierModalProps) => {
  const {getTokenByGlobalName} = useToken();
  const [step, setStep] = useState(0);
  const [liqFarm, setLiqFarm] = useState<IYieldFarm>();
  const title = `Get ${PremiumModes[targetTierIndex].title} Tier`;

  let liqToken = getTokenByGlobalName(NEW_LIQ_GLOBAL_NAME);
  if (!liqToken) {
    // Fallback for chains where NewLIQ token is not available
    liqToken = getTokenByGlobalName(CLASSIC_LIQ_GLOBAL_NAME);
  }
  const remainingPriceUSD = getTokenUSDPrice(remainingPrice, liqToken?.priceUSD, liqToken?.priceDecimals);

  useEffect(() => {
    if (isOpen) {
      setStep(0);
    }
  }, [isOpen]);

  const handleConfirmLIQFarm = (liqFarm: IYieldFarm) => {
    setLiqFarm(liqFarm);
    setStep(1);
  };

  const handleConfirmStake = (liqFarm?: IYieldFarm, inputToken?: IWhitelistToken, inputTokenAmount?: BigNumber) => {
    onDismiss(liqFarm, inputToken, inputTokenAmount);
  };

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={() => onDismiss()}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        <StyledBody>
          <TierInfo
            targetTierIndex={targetTierIndex}
            remainingPrice={remainingPrice}
            remainingPriceUSD={remainingPriceUSD}
          />
          {step === 0 && <StakeSelectLIQ onConfirm={handleConfirmLIQFarm} />}
          {step === 1 && (
            <StakeLIQConfirm inputPriceUSD={remainingPriceUSD} liqFarm={liqFarm} onConfirm={handleConfirmStake} />
          )}
        </StyledBody>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledBody = styled.div`
  overflow: auto;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
