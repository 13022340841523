import {useMemo} from 'react';

import {ConnectionType, useGetConnection} from 'src/connectors';
import {useAppSelector} from 'src/state/hooks';

const SELECTABLE_WALLETS = [ConnectionType.INJECTED, ConnectionType.WALLET_CONNECT2, ConnectionType.COINBASE_WALLET];

export const useOrderedConnections = () => {
  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);
  const getConnection = useGetConnection();
  return useMemo(() => {
    const orderedConnectionTypes: ConnectionType[] = [];

    if (selectedWallet) {
      orderedConnectionTypes.push(selectedWallet);
    }
    orderedConnectionTypes.push(...SELECTABLE_WALLETS.filter((wallet) => wallet !== selectedWallet));

    // Add network connection last as it should be the fallback.
    orderedConnectionTypes.push(ConnectionType.NETWORK);

    return orderedConnectionTypes.map((connectionType) => getConnection(connectionType));
  }, [getConnection, selectedWallet]);
};
