import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {YieldBox} from 'src/components/YieldBox';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type HarvestSuccessfulProps = {
  title?: string;
  rewardToken?: IWhitelistToken;
  outputToken?: IWhitelistToken;
  inputAmount?: number;
  outputAmount?: number;
  totalRewards?: number;
  rewardsInUSD?: number;
  swapMsg?: boolean;
  onConfirm: () => void;
};

export const HarvestSuccessful = ({
  title,
  rewardToken,
  totalRewards,
  rewardsInUSD,
  onConfirm,
  inputAmount,
  outputAmount,
  outputToken,
  swapMsg,
}: HarvestSuccessfulProps) => {
  return (
    <Wrapper>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
        <YieldBox value={rewardsInUSD} />
      </StatusWrapper>
      <StyledCol>
        <BodyVariant color={COLORS.PRIMARY}>{title}</BodyVariant>
        {!swapMsg ? (
          <TokenInfoBox>
            <ItemsWrapper>
              <TokenItem token={rewardToken} value={inputAmount.toFixed(rewardToken?.interfaceDecimals)} />
              <ArrowBox>
                <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
              </ArrowBox>
              <TokenItem token={outputToken} value={outputAmount.toFixed(outputToken?.interfaceDecimals)} />
              <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${rewardsInUSD.toFixed(2)}</StyledOutputUsdPrice>
            </ItemsWrapper>
          </TokenInfoBox>
        ) : (
          <StyledTokenItem token={rewardToken} value={totalRewards.toFixed(2)} />
        )}
      </StyledCol>
      <ButtonWrapper>
        <Button color={COLORS.PRIMARY} title='Done' onClick={onConfirm} />
      </ButtonWrapper>
      {swapMsg && (
        <StyledParagraph color={COLORS.PRIMARY}>
          Swap could not be performed after rewards were harvested
        </StyledParagraph>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0 14px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const StyledTokenItem = styled(TokenItem)`
  padding: 12px 16px;
  margin-top: 10px;
  border-radius: 20px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const TokenInfoBox = styled.div`
  padding: 0 16px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 10px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 20px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
`;
