import ERC20_ABI from '../abis/ERC20.json';
import V2_LIQ_Migration_ABI from '../abis/V2_LIQ_Migration_ABI.json';

export const CLASSIC_LIQ_CONTRACT = {
  abi: ERC20_ABI,
  tokenAbi: ERC20_ABI,
  singleTokenAddress: '0xc7981767f644C7F8e483DAbDc413e8a371b83079', //LIQ Token
};

export const V2_MIGRATE_LIQ_CONTRACT = {
  abi: V2_LIQ_Migration_ABI,
  tokenAbi: V2_LIQ_Migration_ABI,
  singleTokenAddress: '0x77cdA2E252D4B9a2adBBf20132e7D8671207B3Ed',
};

export const CLASSIC_LIQ_ETH_CONTRACT = {
  abi: ERC20_ABI,
  tokenAbi: ERC20_ABI,
  singleTokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
};

export const CLASSIC_LIQ_POLYGON_CONTRACT = {
  abi: ERC20_ABI,
  tokenAbi: ERC20_ABI,
  singleTokenAddress: '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
};

export const CLASSIC_LIQ_CRONOS_CONTRACT = {
  abi: ERC20_ABI,
  tokenAbi: ERC20_ABI,
  singleTokenAddress: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
};
