import React from 'react';

import {Button} from 'src/components/Buttons';
import {TickIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import styled from 'styled-components';

type ApproveToken1Props = {
  token: IWhitelistToken;
  loading?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  onConfirm?: () => void;
  onFail?: () => void;
};

export const ApproveToken1 = ({token, loading, disabled, errorMsg, onFail, onConfirm}: ApproveToken1Props) => {
  const tokenUrl = token?.iconUrl ?? getTokenIconByGlobalName(token?.globalName);

  return (
    <Body>
      <StepsContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Token 1</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Approve Token 2</BodyVariant>
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox>
            <BodyVariant color={COLORS.GRAY_LIGHT}>3</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Add Liquidity</BodyVariant>
        </StyledRow>
      </StepsContainer>
      <TokenInfoBox>
        <TokenContainer>
          <StyledImage src={tokenUrl} />
          <TokenSymbol>{token?.symbol}</TokenSymbol>
          <TokenName>{token?.name}</TokenName>
        </TokenContainer>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title={errorMsg ? 'Try Again' : 'Approve Token 2'}
          onClick={errorMsg ? onFail : onConfirm}
        />
        {errorMsg ? (
          <StyledParagraph color={COLORS.WARNING}>{errorMsg}</StyledParagraph>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StepsContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 125px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 16px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const TokenName = styled(BodyParagraph)`
  font-size: 16px;
  font-weight: 700;
  color: ${COLORS.PRIMARY};
`;

const TokenSymbol = styled(TokenName)`
  color: ${COLORS.GRAY_LIGHT};
`;

const StyledImage = styled.img`
  width: 35px;
  height: 35px;
`;
