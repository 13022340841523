import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {LiFi, Order, Orders, Route, RouteOptions, RoutesRequest} from '@lifi/sdk';
import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {debounce} from 'lodash';
import {useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {NetworkSelectModal} from 'src/components/Modals';
import {ArrowRightIcon} from 'src/components/Svgs';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {NETWORKS} from 'src/constants';
import {useNetwork} from 'src/hooks';
import {useOnClickOutside} from 'src/hooks/useOnClickOutside';
import {farmSlice} from 'src/state/farm/reducer';
import {useAppDispatch} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import {Field, IWhitelistToken, NetworkType} from 'src/types';
import {calculatePrice, formatBigNumber, parseBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';
import {useDebounce} from 'use-debounce';

import {BridgeCardContainer} from './components/BridgeCardContainer';
import BrigeModal from './components/BridgeModal';
import {BridgeTokenSelectModal} from './components/BridgeTokenSelect';
import NetworkSelector from './components/NetWorkSelector';
import {ReceiveTokenSelectModal} from './components/ReceiveTokenSelect';

import './index.css';
export type GetQuateProps = {
  fromChain: number;
  toChain: number;
  fromTokenAddress: string;
  toTokenAddress: string;
  fromAmount: number;
  fromAddress: string;
  routeMode: Order;
};

const lifi = new LiFi({
  integrator: 'Liquidus Finance Inc',
  // apiUrl: 'https://staging.li.quest/v1', //This is testing URL, it should be removed before deploy.
});

export const Bridge = () => {
  const {account, chainId} = useWeb3React();
  const [searchParms] = useSearchParams();
  const {switchNetwork} = useNetwork();

  const symbol = searchParms.get('symbol');
  const appDispatch = useAppDispatch();
  //Modal open handle state
  const [showInputTokenSelectModal, setShowInputTokenSelectModal] = useState(false);
  const [isShowReceiveTokenSelectModal, setIsShowReceiveTokenSelectModal] = useState(false);

  const [inputToken, setInputToken] = useState<IWhitelistToken | null>(null);
  const [outputToken, setOutputToken] = useState<IWhitelistToken | null>(null);
  // Token select and balance checking
  const [outputTokenBalance, setOutpuTokenBalance] = useState('');
  const [typedValue, setTypedValue] = useState('');
  const [tradeInputValue, setTradeInputValue] = useState<BigNumber>(BigNumber.from(0));
  const balance = formatBigNumber(inputToken?.balance, inputToken?.decimals);

  const [isEditing, setIsEditing] = useState(false);

  //Network set
  const [currentFromNetwork, setCurrentFromNetwork] = useState(NETWORKS[chainId ?? 1]);
  const [currentNetWorkType, setCurrentNetworkType] = useState<Field>(Field.INPUT);
  const [toNetwork, setToNetwork] = useState<NetworkType>();
  const [fromNetwork, setFromNetwork] = useState<NetworkType>();

  const [showNetworkSelectionModal, setShowNetworkSelectionModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [bridgeSteps, setBridgeSteps] = useState([]);
  const [isContinue, setIsContinue] = useState(false);
  const [isDisableBridgeCard, setDisableBridgeCard] = useState(false);
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropDownRef, isEditing ? () => setIsEditing(false) : undefined);

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (symbol) {
      appDispatch(farmSlice.actions.selectToken({field: Field.INPUT, tokenSymbol: symbol}));
    }
  }, [appDispatch, symbol]);

  const selectTokenModalHandler = (type: Field) => {
    if (type === Field.INPUT) {
      setShowInputTokenSelectModal(true);
    } else {
      setIsShowReceiveTokenSelectModal(true);
    }
  };

  const handleInputTokenSelect = (token?: IWhitelistToken) => {
    setInputToken(token);
  };

  const handleOutputTokenSelect = (token?: IWhitelistToken) => {
    setOutputToken(token);
  };

  const reportDebouncedChance = debounce((value) => setTradeInputValue(value), 500);

  const onSendInputAmountChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setTypedValue(removedComma);
    reportDebouncedChance(parseBigNumber(removedComma, inputToken?.decimals));
  };

  const handleApplyMaxBalance = () => {
    const _tokenBalance = formatBigNumber(inputToken.balance, inputToken.decimals);
    setTypedValue(_tokenBalance.toFixed(inputToken.interfaceDecimals));
    setTradeInputValue(inputToken.balance);
  };

  const showModal = () => {
    if (selectedBridge !== null) {
      setIsModalOpen(true);
    } else {
      toast('Please check the options ');
    }
  };

  const closeBridgeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setInputToken(null);
    setOutputToken(null);
    setOutpuTokenBalance('');
    setTypedValue('');
    setTradeInputValue(BigNumber.from(0));
    setCurrentFromNetwork(NETWORKS[chainId ?? 1]);
    setCurrentNetworkType(Field.INPUT);
    setBridgeSteps([]);
    setCurrent(0);
    setIsContinue(false);
    setTimeStamp(0);
  };

  useEffect(() => {
    if (current === 2) {
      setIsModalOpen(false);
      return;
    }
  }, [current]);

  const openNetworkSelectModal = (type: Field) => {
    setCurrentNetworkType(type);
    setShowNetworkSelectionModal(true);
  };

  const handleFromNetworkSelect = async (network: NetworkType) => {
    setCurrentFromNetwork(network);
    if (currentNetWorkType === Field.INPUT) {
      await switchNetwork(network.chainId);
      setFromNetwork(network);
    } else {
      setToNetwork(network);
    }
  };

  useEffect(() => {
    const _coinAmount = tradeInputValue;
    if (inputToken?.balance !== undefined && _coinAmount.gt(inputToken?.balance)) {
      setErrorMessage('Insufficient Balance');
    } else {
      setErrorMessage('');
    }
  }, [inputToken, tradeInputValue]);

  const usdInputPrice = useMemo(() => {
    const _tokenPrice = formatBigNumber(tradeInputValue, inputToken?.decimals);
    const usdUnitPrice = calculatePrice(inputToken?.priceUSD, inputToken?.priceDecimals);
    const usdPrice = usdUnitPrice * _tokenPrice;
    return usdPrice;
  }, [inputToken, tradeInputValue]);

  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [timeStamp, setTimeStamp] = useState(0);

  const getQuote = useCallback(async () => {
    setIsQuoteLoading(true);
    try {
      const routeOptions: RouteOptions = {
        slippage: 0.005,
        allowSwitchChain: true,
        order: Orders[0],
      };
      const fromTokenDecimal = inputToken?.decimals;
      const fromAmount = parseFloat(typedValue) * Math.pow(10, fromTokenDecimal);

      const routesRequest: RoutesRequest = {
        fromChainId: fromNetwork?.chainId,
        fromAmount: fromAmount.toString(), // 1USDT
        fromTokenAddress: inputToken.address.toLowerCase(),
        fromAddress: account.toLowerCase(),
        toAddress: account.toLowerCase(),
        toTokenAddress: outputToken.address.toLowerCase(),
        toChainId: toNetwork?.chainId,
        options: routeOptions,
      };

      const sdkResult = await lifi.getRoutes(routesRequest);

      const routes = sdkResult.routes;

      setBridgeSteps(routes);
      if (routes.length > 0) {
        setSelectedBridge(routes[0]);
      }
      if (routes.length === 0) {
        toast('There is no response');
      }
      setTimeStamp(60);
    } catch (error) {
      setSelectedBridge(null);
      console.log(error);
    } finally {
      setIsQuoteLoading(false);
    }
  }, [
    account,
    fromNetwork?.chainId,
    inputToken?.address,
    inputToken?.decimals,
    outputToken?.address,
    toNetwork?.chainId,
    typedValue,
  ]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (timeStamp > 0) {
      timer = setTimeout(() => setTimeStamp((current) => current - 1), 1000);
    } else {
      setTimeStamp(0);
    }
    if (timeStamp === 0 && bridgeSteps.length > 0) {
      getQuote();
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStamp, bridgeSteps]);

  const [value] = useDebounce(typedValue, 1000);

  useEffect(() => {
    if (inputToken && fromNetwork && toNetwork && account && outputToken && value) {
      getQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputToken, fromNetwork, outputToken, toNetwork, value, account]);

  const [selectedBridge, setSelectedBridge] = useState(null);

  const bridgeHandler = (data: Route) => {
    setSelectedBridge(data);
    setIsContinue(true);
    const {toAmount, toToken} = data;
    const toTokenBalance = parseFloat(toAmount) / Math.pow(10, toToken.decimals);
    setOutpuTokenBalance(toTokenBalance.toFixed(outputToken.interfaceDecimals));
  };

  const handleBridgeContinue = (value: boolean) => {
    if (value) {
      setDisableBridgeCard(false);
    } else {
      setDisableBridgeCard(true);
    }
    setIsContinue(value);
  };

  return (
    <Wrapper>
      <Container>
        <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
          Bridge
        </BodyVariant>
        <TokenPairWrapper>
          <TokenPairContainer>
            <NetworkSelector
              label='From Network'
              currentNetwork={fromNetwork}
              networkHandler={() => {
                openNetworkSelectModal(Field.INPUT);
              }}
            />
            <ArrowWrapper>
              <StyledArrowRightIcon size={14} color={COLORS.WHITE} />
              <StyledArrowDownIcon size={14} color={COLORS.WHITE} />
            </ArrowWrapper>
            <NetworkSelector
              label='To'
              currentNetwork={toNetwork}
              networkHandler={() => {
                openNetworkSelectModal(Field.OUTPUT);
              }}
            />
          </TokenPairContainer>
        </TokenPairWrapper>
        <TokenPairWrapper>
          <TokenPairContainer>
            <StyledPanel>
              <StyledFullRow>
                <BodyVariant color={COLORS.GRAY_LIGHT}>You Send</BodyVariant>
                <StyledBalanceWrapper>
                  <StyledBalanceLabel color={COLORS.GRAY_LIGHT}>
                    Balance: {balance.toFixed(inputToken?.interfaceDecimals)}
                  </StyledBalanceLabel>
                  {balance > 0 && <StyledMaxBalance onClick={handleApplyMaxBalance}>MAX</StyledMaxBalance>}
                </StyledBalanceWrapper>
              </StyledFullRow>
              <TokenSelectWrapper>
                <TokenSelectItem token={inputToken} onSelect={() => selectTokenModalHandler(Field.INPUT)} />
                <StyledInput
                  autoComplete='off'
                  align='right'
                  placeholder='0.00'
                  inputMode='decimal'
                  pattern='^[0-9]*[.,]?[0-9]*$'
                  value={typedValue}
                  onChange={onSendInputAmountChanged}
                />
              </TokenSelectWrapper>
              <TokenUsdBalance>{`= $${usdInputPrice?.toFixed(2)}`}</TokenUsdBalance>
              {account && errorMessage !== '' && (
                <StyledWarningBox>
                  <StyledWarningIcon src={WarningIcon} />
                  <BodyParagraph color={COLORS.WARNING}>{errorMessage}</BodyParagraph>
                </StyledWarningBox>
              )}
            </StyledPanel>
            <ArrowWrapper>
              <StyledArrowRightIcon size={14} color={COLORS.WHITE} />
              <StyledArrowDownIcon size={14} color={COLORS.WHITE} />
            </ArrowWrapper>
            <StyledPanel>
              <StyledFullRow>
                <BodyVariant color={COLORS.GRAY_LIGHT}>Receive</BodyVariant>
              </StyledFullRow>
              <TokenSelectWrapper>
                <TokenSelectItem token={outputToken} onSelect={() => selectTokenModalHandler(Field.OUTPUT)} />
                <StyledInput
                  autoComplete='off'
                  align='right'
                  placeholder='0.00'
                  inputMode='decimal'
                  pattern='^[0-9]*[.,]?[0-9]*$'
                  disabled={true}
                  value={outputTokenBalance}
                />
              </TokenSelectWrapper>
              <TokenUsdBalance>{`= $${usdInputPrice?.toFixed(2)}`}</TokenUsdBalance>
              {account && errorMessage !== '' && (
                <StyledWarningBox>
                  <StyledWarningIcon src={WarningIcon} />
                  <BodyParagraph color={COLORS.WARNING}>{errorMessage}</BodyParagraph>
                </StyledWarningBox>
              )}
            </StyledPanel>
          </TokenPairContainer>
        </TokenPairWrapper>
        {timeStamp > 0 && <TimeStampText>{`Quotes will update in ${timeStamp} seconds.`}</TimeStampText>}
        {bridgeSteps.length > 0 && (
          <BridgeCardContainer data={bridgeSteps} handleBridge={bridgeHandler} disableState={isDisableBridgeCard} />
        )}
        <ButtonWrapper>
          <Button
            color={COLORS.PRIMARY}
            disabled={!isContinue}
            isLoading={isQuoteLoading}
            title='Continue'
            onClick={showModal}
          />
        </ButtonWrapper>
      </Container>

      <BridgeTokenSelectModal
        selectedToken={inputToken}
        showClearToken
        onTokenSelect={handleInputTokenSelect}
        isOpen={showInputTokenSelectModal}
        onDismiss={() => setShowInputTokenSelectModal(false)}
      />
      <ReceiveTokenSelectModal
        selectedToken={outputToken}
        showClearToken
        userSelectedNetwork={toNetwork}
        onTokenSelect={handleOutputTokenSelect}
        isOpen={isShowReceiveTokenSelectModal}
        onDismiss={() => setIsShowReceiveTokenSelectModal(false)}
      />
      {selectedBridge !== null && (
        <BrigeModal
          isOpen={isModalOpen}
          transactionData={selectedBridge}
          cancelHandler={handleCancel}
          closeModal={closeBridgeModal}
          handleContinue={handleBridgeContinue}
        />
      )}
      <NetworkSelectModal
        selectedNetwork={currentFromNetwork}
        onNetworkSelect={handleFromNetworkSelect}
        isOpen={showNetworkSelectionModal}
        onDismiss={() => {
          setShowNetworkSelectionModal(false);
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 80px 0;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 0;
  }
`;

const Container = styled.div`
  width: 712px;
  border-radius: 24px;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    padding: 0 16px;
  }
`;

const TokenPairWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
`;

const TokenPairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    flex-direction: column;
  }
`;

const StyledPanel = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 100%;
  }
`;

const TokenSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 18px;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  font-weight: 600;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: auto;
  }
`;

const StyledInput = styled.input<{align?: string}>`
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.PRIMARY};
  background-color: transparent;
  text-align: ${(props) => props.align ?? 'left'};
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
`;

const StyledBalanceLabel = styled(BodyParagraph)`
  display: block;
`;

const StyledMaxBalance = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  color: ${COLORS.SECONDARY};
  background: transparent;
  margin-left: 5px;
`;

const StyledBalanceWrapper = styled.div`
  cursor: pointer;
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFullRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
  margin-bottom: 7px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const ArrowWrapper = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.GRAY_BASE_40};
  border-radius: 22px;
  margin-top: 52px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-top: 16px;
    margin-bottom: 16px;
    align-self: center;
  }
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  display: block;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    display: none;
  }
`;

const StyledArrowDownIcon = styled(ArrowRightIcon)`
  display: none;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    display: block;
    rotate: -270deg;
  }
`;

const TokenUsdBalance = styled(BodyParagraph)`
  font-size: 11px;
  color: ${COLORS.GRAY_LIGHT};
  margin-left: 20px;
  margin-top: 0px;
`;

const TimeStampText = styled(BodyVariant)`
  font-size: 12px;
  text-align: center;
  margin-top: 17px;
  font-style: italic;
`;
