import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ForwardIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ForwardIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: ForwardIconType) => (
  <svg width={size} height={size} viewBox='0 0 23 23' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.281 9.30571L12.9337 1.65725C12.68 1.44999 12.3286 1.40723 12.0321 1.54758C11.7357 1.68792 11.546 1.98722 11.546 2.31531V5.79893C-0.370279 7.28789 0.506992 20.9329 0.517223 21.0732C0.545107 21.4422 0.808148 21.7517 1.16876 21.8372C1.52938 21.9237 1.90301 21.7657 2.09355 21.4478C6.19248 14.6136 10.0877 14.042 11.546 14.0977V17.6111C11.546 17.9392 11.7357 18.2384 12.0321 18.3788C12.3286 18.5192 12.68 18.4764 12.9337 18.2691L22.281 10.6207C22.4781 10.4589 22.5933 10.2182 22.5933 9.96264C22.5933 9.70797 22.4781 9.46633 22.281 9.30553V9.30571Z'
      stroke={color}
      strokeLinejoin='round'
    />
  </svg>
);

export default ForwardIcon;
