import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ContactIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ContactIcon = ({size = 18, color = COLORS.PRIMARY, ...props}: ContactIconType) => (
  <svg width={size} height={size} viewBox='0 0 13 16' fill='none' {...props}>
    <g clipPath='url(#clip0_24465_840)'>
      <path
        d='M11.4091 14.9333H1.58397V1.06661H11.4021L11.4091 14.9333ZM11.9555 -5.82821e-05H1.04637C0.974712 -0.000282866 0.903691 0.0133243 0.837418 0.0399834C0.771145 0.0666424 0.710921 0.105833 0.660167 0.155301C0.609412 0.204769 0.569135 0.263544 0.541658 0.328259C0.514181 0.392974 0.500045 0.462356 0.500046 0.532426V15.4658C0.500046 15.6074 0.557604 15.7433 0.66006 15.8435C0.762516 15.9437 0.901479 15.9999 1.04637 15.9999H11.9555C12.0271 15.9999 12.0981 15.9861 12.1643 15.9592C12.2305 15.9324 12.2906 15.893 12.3412 15.8434C12.3918 15.7938 12.4318 15.7349 12.4591 15.6701C12.4864 15.6053 12.5003 15.5358 12.5 15.4658V0.532426C12.5003 0.462437 12.4863 0.39309 12.4591 0.328384C12.4318 0.263679 12.3917 0.20489 12.3411 0.1554C12.2904 0.10591 12.2303 0.0666944 12.1641 0.0400146C12.0979 0.0133348 12.027 -0.000283693 11.9555 -5.82821e-05Z'
        fill={color}
      />
      <path
        d='M3.77274 4.26668H7.41026C7.48449 4.27078 7.55878 4.26004 7.62861 4.23509C7.69844 4.21014 7.76233 4.17153 7.81637 4.1216C7.8704 4.07167 7.91345 4.01148 7.94287 3.94472C7.97228 3.87797 7.98746 3.80604 7.98746 3.73334C7.98746 3.66065 7.97228 3.58873 7.94287 3.52197C7.91345 3.45521 7.8704 3.39502 7.81637 3.34509C7.76233 3.29517 7.69844 3.25655 7.62861 3.2316C7.55878 3.20665 7.48449 3.1959 7.41026 3.20001H3.77274C3.63341 3.20772 3.50237 3.26727 3.40652 3.36644C3.31067 3.46562 3.25726 3.59689 3.25726 3.73334C3.25726 3.86979 3.31067 4.00107 3.40652 4.10025C3.50237 4.19942 3.63341 4.25897 3.77274 4.26668Z'
        fill={color}
      />
      <path
        d='M3.77274 6.93251H9.22728C9.30151 6.93662 9.37581 6.92587 9.44564 6.90092C9.51547 6.87598 9.57936 6.83736 9.63339 6.78743C9.68743 6.7375 9.73047 6.67731 9.75989 6.61055C9.78931 6.54379 9.80446 6.47187 9.80446 6.39918C9.80446 6.32649 9.78931 6.25456 9.75989 6.1878C9.73047 6.12104 9.68743 6.06085 9.63339 6.01092C9.57936 5.96099 9.51547 5.92237 9.44564 5.89743C9.37581 5.87248 9.30151 5.86174 9.22728 5.86584H3.77274C3.63341 5.87355 3.50237 5.9331 3.40652 6.03228C3.31067 6.13145 3.25726 6.26273 3.25726 6.39918C3.25726 6.53563 3.31067 6.66691 3.40652 6.76608C3.50237 6.86525 3.63341 6.9248 3.77274 6.93251Z'
        fill={color}
      />
      <path
        d='M3.77274 9.59999H9.22728C9.30151 9.6041 9.37581 9.59335 9.44564 9.5684C9.51547 9.54346 9.57936 9.50484 9.63339 9.45491C9.68743 9.40498 9.73047 9.3448 9.75989 9.27804C9.78931 9.21128 9.80446 9.13935 9.80446 9.06666C9.80446 8.99397 9.78931 8.92204 9.75989 8.85528C9.73047 8.78852 9.68743 8.72834 9.63339 8.67841C9.57936 8.62848 9.51547 8.58986 9.44564 8.56491C9.37581 8.53997 9.30151 8.52922 9.22728 8.53332H3.77274C3.63341 8.54103 3.50237 8.60058 3.40652 8.69976C3.31067 8.79893 3.25726 8.93021 3.25726 9.06666C3.25726 9.20311 3.31067 9.33439 3.40652 9.43356C3.50237 9.53273 3.63341 9.59228 3.77274 9.59999Z'
        fill={color}
      />
      <path
        d='M9.22728 11.7299H8.13637C7.99704 11.7376 7.866 11.7972 7.77015 11.8964C7.6743 11.9955 7.6209 12.1268 7.6209 12.2633C7.6209 12.3997 7.6743 12.531 7.77015 12.6302C7.866 12.7293 7.99704 12.7889 8.13637 12.7966H9.22728C9.30151 12.8007 9.3758 12.7899 9.44563 12.765C9.51546 12.74 9.57935 12.7014 9.63339 12.6515C9.68742 12.6016 9.73047 12.5414 9.75989 12.4746C9.7893 12.4079 9.80446 12.3359 9.80446 12.2633C9.80446 12.1906 9.7893 12.1186 9.75989 12.0519C9.73047 11.9851 9.68742 11.9249 9.63339 11.875C9.57935 11.8251 9.51546 11.7865 9.44563 11.7615C9.3758 11.7366 9.30151 11.7258 9.22728 11.7299Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_24465_840'>
        <rect width='12' height='16' fill='white' transform='translate(0.5)' />
      </clipPath>
    </defs>
  </svg>
);

export default ContactIcon;
