//SemdToken index.tsx
import React, {useContext, useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import {CloseIcon} from 'src/components/Svgs';
import {AppContext} from 'src/contexts/AppContext';
import {useSendToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {formatBigNumber, getTokenUSDPriceFromBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import {SendToken} from './SendToken';
import {SendTokenSuccessful} from './SendTokenSuccessful';
import {Modal} from '../Modal';

type SendTokenModalProps = {
  isOpen: boolean;
  onDismiss?: () => void;
  selectedToken: IWhitelistToken;
  amount: BigNumber;
  recipientAddress: string;
};

export const SendTokenModal = ({isOpen, onDismiss, selectedToken, amount, recipientAddress}: SendTokenModalProps) => {
  const [sentAmount, setSentAmount] = useState(0); // Initialize as a number
  const {refreshTokens} = useContext(AppContext);
  const {estimateSendToken, sendToken} = useSendToken();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  //const amountIn = formatBigNumber(amount, selectedToken?.decimals);
  const usdAmount = getTokenUSDPriceFromBigNumber(amount, selectedToken);
  const title = step === 0 ? 'Send Token' : 'Send Token Successful';

  useEffect(() => {
    if (isOpen) {
      setStep(0);
    }
  }, [isOpen]);

  const onConfirm = async () => {
    // confirm
    try {
      setLoading(true);
      const formattedAmount = formatBigNumber(amount, selectedToken?.decimals);

      const gasLimit = await estimateSendToken(recipientAddress, amount, selectedToken);
      if (gasLimit) {
        const tx = await sendToken(recipientAddress, amount, gasLimit, selectedToken);
        if (tx) {
          await tx.wait();
          refreshTokens(true);
          setStep(1);
          setSentAmount(formattedAmount);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>{title}</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          {step === 0 && (
            <SendToken
              selectedToken={selectedToken}
              recipientAddress={recipientAddress}
              amount={formatBigNumber(amount, selectedToken?.decimals)} // Pass the formatted amount
              usdAmount={usdAmount}
              loading={loading}
              onConfirm={onConfirm}
            />
          )}
          {step === 1 && (
            <SendTokenSuccessful
              selectedToken={selectedToken}
              recipientAddress={recipientAddress}
              amount={sentAmount} // Pass the stored sent amount
              usdAmount={usdAmount}
              onConfirm={onDismiss}
            />
          )}
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
  border-bottom: 1px solid ${COLORS.GRAY_BASE_40};
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
