import {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, ethers} from 'ethers';
import {useAppSelector} from 'src/state/hooks';
import {calculateGasMargin} from 'src/utils/swap/kyber-swap';

export const useGasFee = () => {
  const {chainId, provider} = useWeb3React();
  const {standardGasFeeMode} = useAppSelector((state) => state.user);

  const getFeeData = useCallback(async () => {
    const feeData = await provider?.getFeeData();
    return feeData;
  }, [provider]);

  const getGasFee = useCallback(async () => {
    let extraFee = {};
    if (chainId) {
      const feeData = await getFeeData();
      const bscMode = feeData?.maxFeePerGas === null;
      if (bscMode) {
        const marginGasPrice = ethers.utils.parseUnits(`${standardGasFeeMode?.value || 0}`, 'gwei');
        const gasPriceGwei = feeData.gasPrice?.add(marginGasPrice);
        extraFee = {gasPrice: gasPriceGwei};
      } else {
        if (feeData?.maxFeePerGas && feeData.maxPriorityFeePerGas) {
          extraFee = {
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          };
        }
      }
    }
    return extraFee;
  }, [chainId, getFeeData, standardGasFeeMode?.value]);

  const createGasSettings = (
    bscMode: boolean,
    address: string,
    gasPrice: number,
    maxPriorityFee: number,
    maxFee: number,
    gasLimit: number,
  ) => {
    let gasSettings;
    if (bscMode) {
      const gasPriceGwei = ethers.utils.parseUnits(`${gasPrice}`, 'gwei');
      gasSettings = {
        gasPrice: gasPriceGwei,
        nonce: provider.getTransactionCount(address, 'latest'),
        gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
      };
      return gasSettings;
    } else {
      const maxPriorityFeeGwei = ethers.utils.parseUnits(`${maxPriorityFee}`, 'gwei');
      const maxFeeGwei = ethers.utils.parseUnits(`${maxFee}`, 'gwei');
      gasSettings = {
        maxPriorityFeePerGas: maxPriorityFeeGwei,
        maxFeePerGas: maxFeeGwei,
        gasLimit: calculateGasMargin(BigNumber.from(gasLimit)),
        nonce: provider.getTransactionCount(address, 'latest'),
      };

      return gasSettings;
    }
  };

  return {getGasFee, createGasSettings};
};
