import React, {useCallback, useMemo, useState} from 'react';

import ProgressBar from '@ramonak/react-progress-bar';
import {useWeb3React} from '@web3-react/core';
import {Link, useLocation} from 'react-router-dom';
import {toast} from 'react-toastify';
import {AVATARS} from 'src/constants/avatar';
import {PremiumModes} from 'src/constants/premiums';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useModals} from 'src/contexts/modals';
import {usePortfolioBalance} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {PARAGRAPH_FONT_ENUM} from 'src/styles';
import {COLORS} from 'src/styles/colors';
import {convertKMPrice, toFixedRound} from 'src/utils/text-util';
import {simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

import {SIDEBAR_DATA, SIDEBAR_SECONDARY_DATA} from './data';
import {SettingsContent} from './SettingsContent';
import {BalanceText, ItemContainer, ItemWrapper, ItemsList, SidebarContainer} from './SidebarStyles';
import {
  ChevronDownIcon,
  CopyPlusIcon,
  FeeIcon,
  ReceiveIconType,
  SecurityRatingIcon,
  SendIconType,
  SettingsIcon,
} from '../Svgs';
import {BodyParagraph, BodyVariant} from '../Typography';

type SidebarContentProps = {
  onClose?: () => void;
  open?: boolean;
};

export const SidebarContent = ({onClose, open}: SidebarContentProps) => {
  const location = useLocation();
  const modalContext = useModals();
  const {account} = useWeb3React();
  const {totalBalance} = usePortfolioBalance();
  const {chainId} = useWeb3React();
  const {connectedWallets} = useAppSelector((state) => state.wallets);
  const currentWallet = useMemo(
    () => connectedWallets.find((item) => item.account === account),
    [account, connectedWallets],
  );
  const {feeMode, userHolding, feePercent, liqBoost, nextStepPrice, nextStepProgress} = useLiqHoldings();
  const [showSettings, setShowSettings] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const activeItemData =
    SIDEBAR_DATA.find((item) => item.path === location.pathname) ??
    SIDEBAR_SECONDARY_DATA.find((item) => item.path === location.pathname);
  const activeId = activeItemData?.id ?? 0;
  const avatarType = currentWallet?.avatarType ?? 0;
  const selectedIndex = currentWallet?.avatarIndex ?? 0;
  const Avatar = AVATARS[avatarType][selectedIndex];
  const reducedFeePercent = feePercent - 0.1;

  const onCopyClipboard = useCallback(() => {
    navigator.clipboard.writeText(account);
    toast.success('Wallet address copied to the clipboard', {autoClose: 2000, hideProgressBar: true});
  }, [account]);

  const onOpenRenameAccountModal = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateRenameAccountModal', payload});
  };

  const handleMenuItem = (
    subItem:
      | {
          id: number;
          name: string;
          path: string;
          icon: ({size, color, ...props}: SendIconType) => JSX.Element;
          modal?: undefined;
        }
      | {
          id: number;
          name: string;
          modal: string;
          icon: ({size, color, ...props}: ReceiveIconType) => JSX.Element;
          path?: undefined;
        },
  ) => {
    if (subItem.modal) {
      const payload = {isOpen: true};
      modalContext.dispatch({type: subItem.modal, payload});
    }
  };

  return (
    <SidebarContainer open={open}>
      {showSettings && <SettingsContent onClose={() => setShowSettings(false)} open={showSettings} />}
      {!account || !expanded ? (
        <WalletWrapper>
          <StyledFullRow>
            <StyledRow>
              <StyledImg size={32} src={Avatar} />
              <WalletContentWrapper>
                <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                  {currentWallet?.accountName ?? 'Your account'}
                </BodyParagraph>
                <BalanceText>${totalBalance.toFixed(2)}</BalanceText>
              </WalletContentWrapper>
            </StyledRow>
            <StyledButton onClick={() => setExpanded((prev) => !prev)}>
              <ChevronDownIcon />
            </StyledButton>
          </StyledFullRow>
        </WalletWrapper>
      ) : (
        <AccountDetailWrapper>
          <StyledFullRow>
            <div />
            <StyledCol>
              <AccountTitle>{currentWallet?.accountName ?? 'Your account'}</AccountTitle>
              <StyledButton onClick={onCopyClipboard}>
                <StyledRow gap={8}>
                  <BodyParagraph color={COLORS.GRAY_LIGHT}>{simplifyAddress(account)}</BodyParagraph>
                  <CopyPlusIcon />
                </StyledRow>
              </StyledButton>
            </StyledCol>
            <StyledButton onClick={() => setExpanded((prev) => !prev)}>
              <ChevronUpIcon />
            </StyledButton>
          </StyledFullRow>
          <StyledFullRow marginTop={16}>
            <Link to='/premium-detail'>
              <StyledButton>
                <BodyVariant color={COLORS.PRIMARY}>{convertKMPrice(userHolding)}</BodyVariant>
                <BodyParagraph color={COLORS.GRAY_LIGHT}>LIQ Holdings</BodyParagraph>
              </StyledButton>
            </Link>
            <StyledButton onClick={onOpenRenameAccountModal}>
              <AvatarWrapper>
                <StyledImg size={60} src={Avatar} />
              </AvatarWrapper>
            </StyledButton>
            <StyledButton>
              <BodyVariant color={COLORS.PRIMARY}>{liqBoost}%</BodyVariant>
              <BodyParagraph color={COLORS.GRAY_LIGHT}>LIQ Boost</BodyParagraph>
            </StyledButton>
          </StyledFullRow>
          <StyledFullRow marginTop={16}>
            <StyledButton>
              <Link to='/premium-detail'>
                <StyledRow gap={8}>
                  <StyledImg size={24} src={PremiumModes[feeMode].icon} />
                  <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                    {PremiumModes[feeMode].title}
                  </BodyParagraph>
                </StyledRow>
              </Link>
            </StyledButton>
            <StyledButton>
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                {toFixedRound(nextStepProgress)}%
              </BodyParagraph>
              <BodyParagraph color={COLORS.GRAY_LIGHT}>
                {convertKMPrice(userHolding)} / {convertKMPrice(nextStepPrice)}
              </BodyParagraph>
            </StyledButton>
          </StyledFullRow>
          <StyledProgressWrapper>
            <ProgressBar
              height='4px'
              completed={nextStepProgress}
              bgColor={COLORS.SECONDARY}
              baseBgColor={COLORS.GRAY_BASE_40}
              isLabelVisible={false}
            />
          </StyledProgressWrapper>
          <StyledFullRow marginTop={16}>
            <StyledButton>
              <StyledRow gap={6}>
                <FeeIcon />
                <StyledCol>
                  <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                    {toFixedRound(reducedFeePercent, 2)}%
                  </BodyParagraph>
                  <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.XSMALL}>
                    {PremiumModes[feeMode].label}
                  </BodyParagraph>
                </StyledCol>
              </StyledRow>
            </StyledButton>
            <StyledButton>
              <StyledRow gap={6}>
                <SecurityRatingIcon />
                <StyledCol>
                  <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                    Yes
                  </BodyParagraph>
                  <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.XSMALL}>
                    Security Rating
                  </BodyParagraph>
                </StyledCol>
              </StyledRow>
            </StyledButton>
          </StyledFullRow>
        </AccountDetailWrapper>
      )}
      <StyledSeparator />
      <ItemsList>
        {SIDEBAR_DATA.filter((item) => !(chainId !== 56 && item.id === 6)).map((itemData, index) => (
          <ItemContainer
            key={index}
            onClick={() => {
              onClose && onClose();
            }}
          >
            <Link to={itemData.path}>
              <ItemWrapper>
                <itemData.icon color={itemData.id === activeId ? COLORS.SECONDARY : COLORS.PRIMARY} />
                <BodyParagraph
                  size={PARAGRAPH_FONT_ENUM.LARGE}
                  color={itemData.id === activeId ? COLORS.SECONDARY : COLORS.PRIMARY}
                >
                  {itemData.name}
                </BodyParagraph>
              </ItemWrapper>
            </Link>
          </ItemContainer>
        ))}
      </ItemsList>
      <StyledSeparator />
      <ItemsList>
        {SIDEBAR_SECONDARY_DATA.map((itemData, index) => (
          <ItemContainer
            key={index}
            onClick={() => {
              handleMenuItem(itemData);
              onClose && onClose();
            }}
          >
            <Link to={itemData.path ? itemData.path : `${location.pathname}${location.search}`}>
              <ItemWrapper>
                <itemData.icon color={itemData.id === activeId ? COLORS.SECONDARY : COLORS.PRIMARY} />
                <BodyParagraph
                  size={PARAGRAPH_FONT_ENUM.LARGE}
                  color={itemData.id === activeId ? COLORS.SECONDARY : COLORS.PRIMARY}
                >
                  {itemData.name}
                </BodyParagraph>
              </ItemWrapper>
            </Link>
          </ItemContainer>
        ))}
      </ItemsList>
      <ItemsList alignBottom={true}>
        <ItemContainer onClick={() => setShowSettings(true)}>
          <ItemWrapper>
            <SettingsIcon color={COLORS.PRIMARY} />
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              Settings
            </BodyParagraph>
          </ItemWrapper>
        </ItemContainer>
      </ItemsList>
    </SidebarContainer>
  );
};

const WalletWrapper = styled.div`
  padding: 24px 31px;
  display: flex;
  align-items: center;
`;

const StyledImg = styled.img<{size?: number}>`
  width: ${(props) => props.size ?? 32}px;
`;

const AvatarWrapper = styled.div`
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid ${COLORS.GRAY_LIGHTER};
`;

const WalletContentWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
`;

const StyledSeparator = styled.div`
  min-height: 1px;
  background-color: ${COLORS.GRAY_BASE_40};
`;

const StyledFullRow = styled.div<{marginTop?: number}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

const AccountDetailWrapper = styled.div`
  padding: 25px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AccountTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: ${COLORS.PRIMARY};
`;

const StyledProgressWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const ChevronUpIcon = styled(ChevronDownIcon)`
  transform: rotate(180deg);
`;
