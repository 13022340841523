import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ArrowLeftIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ArrowLeftIcon = ({size = 20, color = COLORS.PRIMARY, ...props}: ArrowLeftIconType) => (
  <svg width={size} height={size} viewBox='0 0 19 12' fill='none' {...props}>
    <path d='M18 6.11116L2.44444 6.11116' stroke={color} strokeWidth='2' strokeLinecap='round' />
    <path d='M6.8904 11L1.99997 6.11111L6.8904 1.22222' stroke={color} strokeWidth='2' strokeLinecap='round' />
  </svg>
);

export default ArrowLeftIcon;
