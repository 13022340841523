import {useCallback, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {getChainInfo} from 'src/constants';
import {IWhitelistToken} from 'src/types';

export const useNativeToken = () => {
  const {chainId, account, provider} = useWeb3React();
  const chainInfo = getChainInfo(chainId);

  const nativeToken: IWhitelistToken | undefined = useMemo(() => {
    if (chainInfo) {
      return {
        globalName: chainInfo.name,
        name: chainInfo.name,
        symbol: chainInfo.symbol,
        interfaceDecimals: 4,
        decimals: 18,
        balance: BigNumber.from(0),
        chainId,
        address: chainInfo.symbol,
        isNative: true,
      };
    }
  }, [chainId, chainInfo]);

  const getNativeTokenBalance = useCallback(async () => {
    if (account && provider) {
      return await provider.getBalance(account);
    }
    return BigNumber.from(0);
  }, [account, provider]);

  return {
    nativeToken,
    getNativeTokenBalance,
  };
};
