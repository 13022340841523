import React, {ChangeEvent, useMemo, useState} from 'react';

import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types/token';
import {calculatePrice, formatBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenSelectModal} from './Modals';
import {TokenSelectItem} from './Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from './Typography';

type Size = 'default' | 'small';

interface SwapInfoItemProps {
  name: string;
  selectedToken?: IWhitelistToken;
  inputValue?: string;
  onChangeInputValue?: (text: string) => void;
  onTokenSelect?: (token: IWhitelistToken) => void;
  onMaxBalance?: () => void;
  size?: Size;
  usdMode?: boolean;
  disabled?: boolean;
  updateValueCountdown?: string;
  homeSwap?: boolean;
}

export const SwapInfoItem = ({
  name,
  selectedToken,
  inputValue,
  onChangeInputValue,
  onTokenSelect,
  onMaxBalance,
  size = 'default',
  usdMode,
  disabled = false,
  updateValueCountdown = '',
  homeSwap,
}: SwapInfoItemProps) => {
  const [showTokenSelectModal, setShowTokenSelectModal] = useState(false);
  const tokenBalance = useMemo(() => {
    const _tokenBalance = formatBigNumber(selectedToken?.balance, selectedToken?.decimals);
    if (usdMode) {
      const usdUnitPrice = calculatePrice(selectedToken?.priceUSD, selectedToken?.priceDecimals);
      const usdPrice = usdUnitPrice * _tokenBalance;
      return usdPrice.toFixed(selectedToken?.priceDecimals);
    } else {
      return _tokenBalance.toFixed(selectedToken?.interfaceDecimals);
    }
  }, [selectedToken, usdMode]);

  return (
    <Wrapper home={homeSwap}>
      <StyledRowBetween size={size}>
        <BodyVariant color={COLORS.PRIMARY}>{name}</BodyVariant>
        <StyledBalanceWrapper maxBalance={!!onMaxBalance} onClick={onMaxBalance}>
          <StyledBalanceLabel color={COLORS.PRIMARY}>
            Balance: {usdMode && '$'}
            {tokenBalance}
          </StyledBalanceLabel>
          <StyledMaxBalance color={COLORS.PRIMARY}>Max Balance</StyledMaxBalance>
        </StyledBalanceWrapper>
      </StyledRowBetween>
      <StyledRowBetween marginTop={size === 'default' ? 16 : 8} size={size} home={homeSwap}>
        <StyledRow>
          <StyledInput
            style={{fontSize: size === 'default' ? 18 : 15}}
            value={inputValue}
            placeholder='0.0'
            inputMode='decimal'
            pattern='^[0-9]*[.,]?[0-9]*$'
            disabled={disabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputValue && onChangeInputValue(e.target.value)}
            home={homeSwap}
          />
        </StyledRow>
        <TokenSelectItem token={selectedToken} size={size} onSelect={() => setShowTokenSelectModal(true)} />
      </StyledRowBetween>
      <BodyParagraph color={COLORS.GRAY_BASE_40}>{updateValueCountdown}</BodyParagraph>
      {onTokenSelect && (
        <TokenSelectModal
          selectedToken={selectedToken}
          onTokenSelect={onTokenSelect}
          isOpen={showTokenSelectModal}
          onDismiss={() => setShowTokenSelectModal(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{size?: Size; home?: boolean}>`
  padding: ${(props) => (props.size === 'default' ? '20px' : '0px 10px')};
  border-radius: ${(props) => (props.home ? 0 : 12)}px;
  background-color: ${(props) => (props.home ? 'unset' : COLORS.DISABLED)};
`;

const StyledRowBetween = styled.div<{marginTop?: number; size?: Size; home?: boolean}>`
  display: flex;
  flex-direction: ${(props) => (props.home ? 'row-reverse' : 'row')};
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${(props) => (props.size === 'default' ? 8 : 4)}px;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-left: ${(props) => (props.home ? 10 : 0)}px;
  margin-right: ${(props) => (props.home ? 10 : 0)}px;
  border-radius: ${(props) => (props.home ? 12 : 0)}px;
  border: ${(props) => props.home && `1px solid ${COLORS.GRAY_LIGHTER}`};
  padding: 12px;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const StyledInput = styled.input<{home?: boolean}>`
  width: 100%;
  color: ${COLORS.PRIMARY};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  text-align: ${(props) => (props.home ? 'right' : 'unset')};
`;

const StyledBalanceLabel = styled(BodyParagraph)`
  display: block;
`;

const StyledMaxBalance = styled(BodyParagraph)`
  display: none;
`;

const StyledBalanceWrapper = styled.div<{maxBalance?: boolean}>`
  cursor: pointer;

  ${(props) =>
    props.maxBalance &&
    `
    &:hover ${StyledBalanceLabel} {
      display: none;
    }

    &:hover ${StyledMaxBalance} {
      display: block;
    }
  `}
`;
