import {useCallback, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {NEW_LIQ_GLOBAL_NAME, USDT_GLOBAL_NAME} from 'src/constants';
import {IYieldFarm, argsObj} from 'src/types';
import {getFarmContract} from 'src/utils/farm-util';

import {useToken} from './useToken';

type HarvestCall = {
  farm: IYieldFarm;
  functionName?: string;
  args?: argsObj;
  gasEstimation?: BigNumber;
};

type HarvestTransaction = {
  farm: IYieldFarm;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transaction: any; // transaction response is dynamic
};

function useHarvestFunctionCalls(farms?: IYieldFarm[]) {
  const {account} = useWeb3React();
  return useMemo(() => {
    const calls: HarvestCall[] = [];
    if (farms) {
      for (const farm of farms) {
        if (!farm || !account) {
          continue;
        }
        const farmHarvestSignature = farm.harvestFn?.signature;

        const farmArguments: argsObj = {};
        if (farm.harvestFn) {
          for (const farmArgument of farm.harvestFn.arguments) {
            if (farmArgument.name === '_pid' || farmArgument.name === 'pid') {
              farmArguments[farmArgument.name] = farm.poolId;
            } else if (farmArgument.name === '_amount' || farmArgument.name === 'amount') {
              farmArguments[farmArgument.name] = 0;
            } else if (farmArgument.name === 'to' || farmArgument.name === '_referrer') {
              farmArguments[farmArgument.name] = account;
            }
          }
        }
        calls.push({
          functionName: farmHarvestSignature,
          args: farmArguments,
          farm: farm,
        });
      }
    }
    return calls;
  }, [farms, account]);
}

export function useHarvest(farms?: IYieldFarm[]) {
  const {account, provider} = useWeb3React();
  const {getTokenByGlobalName} = useToken();
  const harvestFunctionCalls = useHarvestFunctionCalls(farms);

  const estimateGas = useCallback(async () => {
    const gasFeePromises = [];
    for (const harvestFunctionCall of harvestFunctionCalls) {
      const contract = await getFarmContract(harvestFunctionCall.farm, account, provider);

      const functionName = harvestFunctionCall.functionName;
      const functionArgs = harvestFunctionCall.args;

      if (functionName && contract) {
        gasFeePromises.push(
          contract.estimateGas[functionName](...(functionArgs ? Object.values(functionArgs) : []))
            .then((estimation) => {
              harvestFunctionCall.gasEstimation = estimation;
            })
            .catch((e) => console.error('Gas estimate failed', e, harvestFunctionCall.farm)),
        );
      }
    }
    await Promise.all(gasFeePromises);

    return harvestFunctionCalls;
  }, [account, harvestFunctionCalls, provider]);

  const harvest = useCallback(async () => {
    const harvestTransactions: HarvestTransaction[] = [];
    if (account) {
      let nonce = await provider.getTransactionCount(account);
      for (const harvestFunctionCall of harvestFunctionCalls) {
        const contract = await getFarmContract(harvestFunctionCall.farm, account, provider);
        const functionName = harvestFunctionCall.functionName;
        const functionArgs = harvestFunctionCall.args;
        if (!contract || !functionName) {
          continue;
        }
        const gasEstimation = await contract.estimateGas[functionName](
          ...(functionArgs ? Object.values(functionArgs) : []),
          {
            from: account,
          },
        );
        const tx = await contract[functionName](...(functionArgs ? Object.values(functionArgs) : []), {
          gasLimit: gasEstimation,
          from: account,
          nonce: nonce++,
        });

        harvestTransactions.push({
          transaction: tx,
          farm: harvestFunctionCall.farm,
        });
      }
    }
    return harvestTransactions;
  }, [account, provider, harvestFunctionCalls]);

  const harvestOutputTokens = useCallback(() => {
    const liqToken = getTokenByGlobalName(NEW_LIQ_GLOBAL_NAME);
    const usdtToken = getTokenByGlobalName(USDT_GLOBAL_NAME);

    const harvestTokens = {
      NEW_LIQ_GLOBAL_NAME: liqToken,
      USDT_GLOBAL_NAME: usdtToken,
    };

    return harvestTokens;
  }, [getTokenByGlobalName]);

  return {harvest, estimateGas, harvestOutputTokens};
}
