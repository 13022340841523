import React from 'react';

import {useWeb3React} from '@web3-react/core';
import {useAppDispatch} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

import {DisconnectIcon} from '../Svgs';
import {BodyParagraph} from '../Typography';

type SettingPopupProps = {
  onClose?: () => void;
};

export const SettingPopup = ({onClose}: SettingPopupProps) => {
  const appDispatch = useAppDispatch();
  const {connector} = useWeb3React();
  const disconnect = () => {
    if (connector && connector.deactivate) {
      connector.deactivate();
    }
    connector.resetState();
    appDispatch(userSlice.actions.updateSelectedWallet({wallet: undefined}));
    onClose && onClose();
  };

  return (
    <>
      <StyledOverlay onClick={onClose} />
      <StyledContainer onClick={() => disconnect()}>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.WHITE}>
          Disconnect
        </BodyParagraph>
        <DisconnectIcon size={16} color={COLORS.WHITE} />
      </StyledContainer>
    </>
  );
};

const StyledOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(17, 36, 85, 0);
`;

const StyledContainer = styled.div`
  position: absolute;
  right: 0;
  top: 44px;
  background-color: #131517;
  width: 100px;
  padding: 12px 16px;
  z-index: 10;
  border-radius: 12px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
