import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type SearchIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const SearchIcon = ({size = 24, color = COLORS.BLACK_TEXT, ...props}: SearchIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M17.8125 17.0391L14.6719 13.8984C15.3516 13.0781 15.7266 12.0234 15.7266 10.875C15.7266 8.20312 13.5234 6 10.8516 6C8.15625 6 6 8.20312 6 10.875C6 13.5703 8.17969 15.75 10.8516 15.75C11.9766 15.75 13.0312 15.375 13.875 14.6953L17.0156 17.8359C17.1328 17.9531 17.2734 18 17.4375 18C17.5781 18 17.7188 17.9531 17.8125 17.8359C18.0469 17.625 18.0469 17.2734 17.8125 17.0391ZM7.125 10.875C7.125 8.8125 8.78906 7.125 10.875 7.125C12.9375 7.125 14.625 8.8125 14.625 10.875C14.625 12.9609 12.9375 14.625 10.875 14.625C8.78906 14.625 7.125 12.9609 7.125 10.875Z'
      fill={color}
    />
  </svg>
);

export default SearchIcon;
