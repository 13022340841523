import React from 'react';

import HighlightedNewsLogo from 'src/assets/images/tokens/BTC.png';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {SecondaryButton} from '../Buttons';
import {BodyVariant} from '../Typography';

export const HighlightedNews = ({...props}) => {
  return (
    <Wrapper {...props}>
      <BodyVariant color={COLORS.BLACK} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
        $BTC ETF approved.{'\n'}All you need to know.
      </BodyVariant>
      <StyledImg src={HighlightedNewsLogo} />
      <div style={{display: 'flex', justifyContent: 'left'}}>
        <a
          href='https://www.sec.gov/news/statement/gensler-statement-spot-bitcoin-011023'
          target='_blank'
          rel='noopener noreferrer'
          style={{textDecoration: 'none'}}
        >
          <SecondaryButton title='Explore' />
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 174px;
  height: 222px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #ffeac7;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  right: 5px;
  bottom: 38px;
  width: 57px;
`;
