import React from 'react';

import {Token} from '@lifi/sdk';
import ARROW_DOWN from 'src/assets/images/transaction/arrow-down.svg';
import {ModalToken, ModalTokenProps} from 'src/components/ModalToken';
import {NETWORKS} from 'src/constants';
import styled from 'styled-components';

type BridgeDetailsProps = {
  fromToken: Token;
  fromAmount: number | string;
  fromChainId: number;
  toToken: Token;
  toAmount: number | string;
  toChainId: number;
};

const BridgeDetails = (props: BridgeDetailsProps) => {
  const {fromToken, fromAmount, fromChainId, toToken, toAmount, toChainId} = props;
  const fromTokenTransactionData: ModalTokenProps = {
    symbol: fromToken.symbol,
    symbolIcon: fromToken?.logoURI,
    balance: (fromAmount as number) / Math.pow(10, fromToken.decimals),
    usdBalance: fromToken.priceUSD,
    tokenNetwork: NETWORKS[fromChainId].displayName,
    tokenNetworkIcon: NETWORKS[fromChainId].icon,
  };
  const toTokenTransactionData: ModalTokenProps = {
    symbol: toToken.symbol,
    symbolIcon: toToken?.logoURI,
    balance: (toAmount as number) / Math.pow(10, toToken.decimals),
    usdBalance: toToken.priceUSD,
    tokenNetwork: NETWORKS[toChainId].displayName,
    tokenNetworkIcon: NETWORKS[toChainId].icon,
  };
  return (
    <Wrapper>
      <CustomHr />
      <ModalToken {...fromTokenTransactionData} />
      <ArrowContainer>
        <ArrowDownImg src={ARROW_DOWN} />
      </ArrowContainer>
      <ModalToken {...toTokenTransactionData} />
    </Wrapper>
  );
};

export default BridgeDetails;

const Wrapper = styled.div`
  margin: 10px 0;
`;
const ArrowContainer = styled.div`
  margin-bottom: 10px;
`;
const ArrowDownImg = styled.img`
  width: 30px;
  height: 30px;
`;

const CustomHr = styled.hr`
  margin: 20px 0px;
  position: relative;
  left: -24px;
  width: 110%;
  height: 2px;
  background-color: #9aa6cf;
  border: none;
  opacity: 25%;
`;
