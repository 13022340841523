import React from 'react';

import {Button} from 'src/components/Buttons';
import {TickIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type ApproveLPTokensProps = {
  lpToken1?: IWhitelistToken;
  lpToken2?: IWhitelistToken;
  keepTokensMode?: boolean;
  lpMode?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
};

export const ApproveLPTokens = ({
  lpToken1,
  lpToken2,
  keepTokensMode,
  lpMode,
  loading,
  disabled,
  onConfirm,
}: ApproveLPTokensProps) => {
  return (
    <Body>
      <StpesContainer>
        {lpMode ? (
          <>
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.PRIMARY}>Approve LP Tokens</BodyVariant>
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox>
                <BodyVariant color={COLORS.GRAY_LIGHT}>2</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>
                {keepTokensMode ? 'Remove Liquidity' : 'Remove Liquidity & Swap'}
              </BodyVariant>
            </StyledRow>
          </>
        ) : (
          <>
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>Withdraw LP from Farm</BodyVariant>
              <TickIcon color={COLORS.SECONDARY} />
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.PRIMARY}>Approve LP Tokens</BodyVariant>
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox>
                <BodyVariant color={COLORS.GRAY_LIGHT}>3</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>
                {keepTokensMode ? 'Remove Liquidity' : 'Remove Liquidity & Swap'}
              </BodyVariant>
            </StyledRow>
          </>
        )}
      </StpesContainer>
      <TokenInfoBox>
        <StyledRow>
          <TokenBox>
            <TokenIcon width={24} height={24} token={lpToken1} />
            {lpToken2 && <SecondTokenIcon width={24} height={24} token={lpToken2} />}
          </TokenBox>
          <BodyVariant color={COLORS.PRIMARY}>
            {lpToken1?.symbol}-{lpToken2?.symbol}
          </BodyVariant>
        </StyledRow>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title='Approve LP Tokens'
          onClick={onConfirm}
        />
        <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StpesContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;
