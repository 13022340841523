import {useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import {LiquidityCalculationResult} from 'src/types/farm';
import {calculateLiquidityAmount} from 'src/utils/farm-util';

export function usePoolReserves(
  poolAddress?: string,
  amountA?: ethers.BigNumber,
  amountB?: ethers.BigNumber,
  calculateB?: boolean,
): LiquidityCalculationResult {
  const {provider} = useWeb3React();
  const [result, setResult] = useState<LiquidityCalculationResult>({
    otherToken: undefined,
    otherTokenAmount: undefined,
  });

  useEffect(() => {
    const fetchLiquidityAmount = async () => {
      const liquidityCalculationResult = await calculateLiquidityAmount(
        provider,
        poolAddress,
        amountA,
        amountB,
        calculateB,
      );
      const otherToken = liquidityCalculationResult.otherToken;
      const otherTokenAmount = liquidityCalculationResult.otherTokenAmount;
      return {otherToken, otherTokenAmount};
    };

    if (provider && poolAddress && (amountA || amountB)) {
      const fetched = fetchLiquidityAmount();
      fetched.then((res: LiquidityCalculationResult) => {
        setResult(res);
      });
    }
  }, [poolAddress, amountA, amountB, calculateB, provider]);
  return result;
}
