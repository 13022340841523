import React from 'react';

import {NetworkSelectItem} from 'src/components/NetworkSelectItem';
import {BodyVariant} from 'src/components/Typography';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {NetworkType} from 'src/types';
import styled from 'styled-components';

export type NetworkSelectorProps = {
  label: string;
  currentNetwork: NetworkType;
  networkHandler: () => void;
};

const NetworkSelector = ({label, currentNetwork, networkHandler}: NetworkSelectorProps) => {
  return (
    <StyledPanel>
      <StyledFullRow>
        <BodyVariant color={COLORS.GRAY_LIGHT}>{label}</BodyVariant>
      </StyledFullRow>
      <TokenSelectWrapper>
        <NetworkSelectItem network={currentNetwork} onSelect={networkHandler} />
      </TokenSelectWrapper>
    </StyledPanel>
  );
};

export default NetworkSelector;

const StyledPanel = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 100%;
  }
`;

const StyledFullRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const TokenSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 18px;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  font-weight: 600;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: auto;
  }
`;
