import React, {useEffect, useMemo, useState} from 'react';

import {useQuery} from '@apollo/client';
import {useWeb3React} from '@web3-react/core';
import {ChevronRightIcon, CloseIcon, TickIcon} from 'src/components/Svgs';
import {PLATFORM_SORTING_LIST, TOLERANCE_ITEMS} from 'src/constants';
import {useModals} from 'src/contexts/modals';
import {PLATFORMS} from 'src/graphql/query';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import {
  IItemType,
  IPlatform,
  IPlatformData,
  PlatformSettingsMode,
  PlatformSettingsSection,
  PlatformSorting,
} from 'src/types';
import {getPlatformIcon} from 'src/utils/icon-util';
import styled from 'styled-components';

import {Modal} from './Modal';
import {Button} from '../Buttons';
import {ItemPicker} from '../ItemPicker';
import {BodyParagraph, BodyVariant} from '../Typography';

type PlatformProps = {
  item: IPlatform;
  selected: boolean;
  onPress: (item: IPlatform) => void;
};

const PlatformItem = ({item, selected, onPress}: PlatformProps) => {
  const iconUrl = item?.iconUrl || getPlatformIcon(item.name);
  return (
    <PlatformBox topBorder={true} onClick={() => onPress(item)}>
      <StyledRow gap={8}>
        <StyledImage src={iconUrl} />
        <BodyVariant size={BODY_FONT_ENUM.SMALL}>{item.name}</BodyVariant>
      </StyledRow>
      {selected && <TickIcon />}
    </PlatformBox>
  );
};

type PlatformSettingsModalProps = {
  isOpen: boolean;
  type: PlatformSettingsMode;
  section?: PlatformSettingsSection;
};

export const PlatformSettingsModal = ({isOpen, type, section}: PlatformSettingsModalProps) => {
  const {chainId} = useWeb3React();
  const modalContext = useModals();
  const {platformSettings, zapSettings} = useAppSelector((state) => state.user);
  const appDispatch = useAppDispatch();

  const {data: platformData} = useQuery<IPlatformData>(PLATFORMS, {
    variables: {skip: 0, limit: 100},
    fetchPolicy: 'cache-and-network',
  });

  const filteredPlatformList = useMemo(() => {
    const _filteredList = platformData?.platforms.filter((item) => {
      let existing = false;
      if (!item.isFilter) {
        return false;
      }
      item.chainSpecifics?.forEach((chainSpecific) => {
        if (chainSpecific.chainId === chainId) {
          existing = true;
        }
      });
      return existing;
    });

    return [
      {
        name: 'All',
        slug: 'all',
      },
      ...(_filteredList ? _filteredList : []),
    ];
  }, [chainId, platformData?.platforms]);

  const [showPlatformList, setShowPlatformList] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(platformSettings?.platform || filteredPlatformList[0]);
  const [selectedSortId, setSelectedSortId] = useState(0);
  const [selectedTolerance, setSelectedTolerance] = useState(TOLERANCE_ITEMS[0]);
  const [customValue, setCustomValue] = useState('');
  const warning = useMemo(() => {
    if (selectedTolerance.name === 'Custom' && Number(customValue) > 5.0) {
      return true;
    }
    return false;
  }, [selectedTolerance, customValue]);
  const iconUrl = selectedPlatform?.iconUrl || getPlatformIcon(selectedPlatform.name);

  useEffect(() => {
    if (isOpen) {
      platformSettings[section] && setSelectedPlatform(platformSettings[section]);
      setSelectedSortId(platformSettings ? platformSettings[type] || 0 : 0);
      setSelectedTolerance(zapSettings?.slippage ? zapSettings.slippage : TOLERANCE_ITEMS[0]);
      if (zapSettings?.slippage?.name === 'Custom') {
        setCustomValue(zapSettings?.slippage.value === -1 ? '' : zapSettings?.slippage.value.toString());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPlatformList, isOpen, platformSettings, zapSettings, type]);

  const onSelectePlatform = (item: IPlatform) => {
    const platform = {...item, chainId};
    setSelectedPlatform(platform);
    setShowPlatformList(false);
  };

  const onSelectSortingMode = (item: {id: PlatformSorting; name: string}) => {
    setSelectedSortId(item.id || 0);
  };

  const onPickedToleranceItem = (item: IItemType) => {
    setSelectedTolerance(item);
  };

  const onChangeCustomValue = (value: string) => {
    const removedComma = value.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setCustomValue(removedComma);
  };

  const saveSettings = () => {
    // save platform settings
    const _platformSettings = {
      ...platformSettings,
      [section]: selectedPlatform,
      [type]: selectedSortId,
    };
    const platformSettingsPayload = {platformSettings: _platformSettings};
    appDispatch(userSlice.actions.updatePlatformSettings(platformSettingsPayload));
    // save tolerance
    const _zapSettings = {
      ...zapSettings,
      slippage:
        selectedTolerance.name !== 'Custom' ? selectedTolerance : {...selectedTolerance, value: Number(customValue)},
    };
    const zapSettingsPayload = {zapSettings: _zapSettings};
    appDispatch(userSlice.actions.updateZapSettings(zapSettingsPayload));
    onDismiss();
  };

  const onDismiss = () => {
    setShowPlatformList(false);
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updatePlatformSettingsModal', payload});
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Filters</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          <SectionWrapper>
            <StyledSectionTitle>Platforms</StyledSectionTitle>
            <PlatformWrapper>
              <PlatformBox onClick={() => setShowPlatformList((prev) => !prev)}>
                <StyledRow gap={8}>
                  <StyledImage src={iconUrl} />
                  <BodyVariant size={BODY_FONT_ENUM.SMALL}>{selectedPlatform.name || 'Choose platform'}</BodyVariant>
                </StyledRow>
                <ChevronRightIcon />
              </PlatformBox>
              {showPlatformList && (
                <PlatformListBox>
                  {filteredPlatformList.map((item) => (
                    <PlatformItem
                      key={item.name}
                      item={item}
                      selected={selectedPlatform.name === item.name}
                      onPress={onSelectePlatform}
                    />
                  ))}
                </PlatformListBox>
              )}
            </PlatformWrapper>
          </SectionWrapper>
          <SectionWrapper marginTop={16}>
            <StyledSectionTitle>Sorting</StyledSectionTitle>
            <PlatformWrapper>
              {PLATFORM_SORTING_LIST[type]?.map((item) => (
                <PlatformBox key={item.id} topBorder={true} onClick={() => onSelectSortingMode(item)}>
                  <BodyVariant size={BODY_FONT_ENUM.SMALL}>{item.name}</BodyVariant>
                  {selectedSortId === item.id && <TickIcon />}
                </PlatformBox>
              ))}
            </PlatformWrapper>
          </SectionWrapper>
          <SectionWrapper marginTop={16}>
            <StyledSectionTitle>
              Slippage tolerance
              <br />
              (Top-up & Withdraw)
            </StyledSectionTitle>
            <ItemPicker
              items={TOLERANCE_ITEMS}
              selectedItem={selectedTolerance}
              onPicked={onPickedToleranceItem}
              customValue={customValue}
              onChangeCustomValue={onChangeCustomValue}
              warning={warning}
            />
          </SectionWrapper>
          {warning && (
            <StyledParagraph color={COLORS.WARNING}>
              Please note that your current slippage is beyond the recommended maximum of 5%
            </StyledParagraph>
          )}
          <ButtonWrapper>
            <Button color={COLORS.PRIMARY} title='Save' onClick={saveSettings} />
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const StyledSectionTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const PlatformWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 23px;
  padding: 0 16px;
`;

const PlatformBox = styled.div<{topBorder?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 47px;
  ${(props) =>
    props.topBorder &&
    `
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: ${COLORS.GRAY_BORDER};
  `}
  cursor: pointer;
`;

const PlatformListBox = styled.div`
  margin-top: 0;
`;

const StyledImage = styled.img`
  width: 22px;
  height: 22px;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;
