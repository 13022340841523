import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
  Price,
  Token,
  TokenAmount,
  TradeType,
} from '@kyberswap/ks-sdk-core';
import JSBI from 'jsbi';
import {ETHER_ADDRESS, KYBERSWAP_SOURCE} from 'src/constants/swap';
import {FeeConfig} from 'src/hooks/kyber-swap';
import invariant from 'tiny-invariant';

import fetchWaiting from '../api';

export type Swap = {
  pool: string;
  tokenIn: string;
  tokenOut: string;
  swapAmount: string;
  amountOut: string;
  limitReturnAmount: string;
  maxPrice: string;
  exchange: string;
  poolLength: number;
  poolType: string;
  extra:
    | {
        poolLength: number;
        tokenInIndex: number;
        tokenOutIndex: number;
      }
    | undefined;
  collectAmount: string | undefined;
  recipient: string | undefined;
  reserveUsd: number; // Solana only
};

type Tokens = {
  [address: string]: (Token & {price: number}) | undefined;
};
/**
 */
export class Aggregator {
  /**
   * The type of the trade, either exact in or exact out.
   */
  public readonly tradeType: TradeType;
  /**
   * The input amount for the trade assuming no slippage.
   */
  public readonly inputAmount: CurrencyAmount<Currency>;
  /**
   * The output amount for the trade assuming no slippage.
   */
  public readonly outputAmount: CurrencyAmount<Currency>;
  /**
   */
  public readonly swaps: Swap[][];
  /**
   */
  public readonly tokens: Tokens;
  /**
   * The price expressed in terms of output amount/input amount.
   */
  public readonly executionPrice: Price<Currency, Currency>;

  public readonly amountInUsd: number;
  public readonly amountOutUsd: number;
  public readonly receivedUsd: number;
  public readonly gasUsd: number | undefined;
  // -1 mean can not get price of token => can not calculate price impact
  public readonly priceImpact: number;
  public readonly encodedSwapData: string;
  public readonly routerAddress: string;

  private constructor(
    inputAmount: CurrencyAmount<Currency>,
    outputAmount: CurrencyAmount<Currency>,
    amountInUsd: number,
    amountOutUsd: number,
    receivedUsd: number,
    swaps: Swap[][],
    tokens: Tokens,
    tradeType: TradeType,
    gasUsd: number,
    priceImpact: number,
    encodedSwapData: string,
    routerAddress: string,
  ) {
    this.tradeType = tradeType;
    this.inputAmount = inputAmount;
    this.outputAmount = outputAmount;
    this.amountInUsd = amountInUsd;
    this.amountOutUsd = amountOutUsd;
    this.receivedUsd = receivedUsd;
    this.executionPrice = new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.inputAmount.quotient,
      this.outputAmount.quotient,
    );
    try {
      this.swaps = swaps;
    } catch (e) {
      this.swaps = [[]];
    }
    try {
      this.tokens = tokens;
    } catch (e) {
      this.tokens = {};
    }
    this.gasUsd = gasUsd;
    this.priceImpact = priceImpact;
    this.encodedSwapData = encodedSwapData;
    this.routerAddress = routerAddress;
  }

  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public minimumAmountOut(slippageTolerance: Percent, feePercent: number): CurrencyAmount<Currency> {
    invariant(!slippageTolerance.lessThan(JSBI.BigInt(0)), 'SLIPPAGE_TOLERANCE');
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount.subtract(this.outputAmount.multiply(feePercent * 100).divide(10000));
    } else {
      const slippageAdjustedAmountOut = new Fraction(JSBI.BigInt(1))
        .add(slippageTolerance)
        .invert()
        .multiply(this.outputAmount.quotient).quotient;
      const currnecyAmount = TokenAmount.fromRawAmount(this.outputAmount.currency, slippageAdjustedAmountOut);
      return currnecyAmount.subtract(currnecyAmount.multiply(feePercent * 100).divide(10000));
    }
  }

  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public maximumAmountIn(slippageTolerance: Percent): CurrencyAmount<Currency> {
    invariant(!slippageTolerance.lessThan(JSBI.BigInt(0)), 'SLIPPAGE_TOLERANCE');
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount;
    } else {
      const slippageAdjustedAmountIn = new Fraction(JSBI.BigInt(1))
        .add(slippageTolerance)
        .multiply(this.inputAmount.quotient).quotient;
      return TokenAmount.fromRawAmount(this.inputAmount.currency, slippageAdjustedAmountIn);
    }
  }

  /**
   * @param baseURL
   * @param currencyAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param saveGas
   * @param dexes
   * @param slippageTolerance
   * @param deadline
   * @param to
   * @param feeConfig
   * @param signal
   * @param minimumLoadingTime
   */
  public static async bestTradeExactIn(
    baseURL: string,
    currencyAmountIn: CurrencyAmount<Currency>,
    currencyOut: Currency,
    saveGas = false,
    dexes = '',
    slippageTolerance: number,
    deadline: number | undefined,
    to: string,
    feeConfig: FeeConfig | undefined,
    minimumLoadingTime: number,
  ): Promise<Aggregator | null> {
    const amountIn = currencyAmountIn;
    const tokenOut = currencyOut.wrapped;
    const tokenInAddress = currencyAmountIn.currency.isNative ? ETHER_ADDRESS : amountIn.currency.wrapped.address;
    const tokenOutAddress = currencyOut.isNative ? ETHER_ADDRESS : tokenOut.address;

    if (tokenInAddress && tokenOutAddress) {
      const search = new URLSearchParams({
        // Trade config
        tokenIn: tokenInAddress,
        tokenOut: tokenOutAddress,
        amountIn: currencyAmountIn.quotient?.toString(),
        saveGas: saveGas ? '1' : '0',
        gasInclude: saveGas ? '1' : '0',
        ...(dexes ? {dexes} : {}),
        slippageTolerance: slippageTolerance?.toString() ?? '',
        deadline: deadline?.toString() ?? '',
        to,

        // Fee config
        chargeFeeBy: feeConfig?.chargeFeeBy ?? '',
        feeReceiver: feeConfig?.feeReceiver ?? '',
        isInBps: feeConfig?.isInBps !== undefined ? (feeConfig.isInBps ? '1' : '0') : '',
        feeAmount: feeConfig?.feeAmount ?? '',

        // Client data
        clientData: KYBERSWAP_SOURCE,
      });
      try {
        const response = await fetchWaiting(
          `${baseURL}?${search}`,
          {
            headers: {
              'Accept-Version': 'Latest',
            },
          },
          minimumLoadingTime,
        );
        if (!response.ok) return null;
        if (Math.round(response.status / 100) !== 2) {
          throw new Error(`Aggregator status fail: ${response.status} for: ${baseURL}?${search}`);
        }
        const result = await response.json();
        if (
          !result ||
          !result.inputAmount ||
          !result.outputAmount ||
          typeof result.swaps?.[0]?.[0].pool !== 'string' ||
          !result.tokens ||
          result.inputAmount === '0' ||
          result.outputAmount === '0'
        ) {
          return null;
        }

        const toCurrencyAmount = function (value: string, currency: Currency): CurrencyAmount<Currency> {
          try {
            return TokenAmount.fromRawAmount(currency, JSBI.BigInt(value));
          } catch (e) {
            return TokenAmount.fromRawAmount(currency, 0);
          }
        };

        const outputAmount = toCurrencyAmount(result?.outputAmount, currencyOut);

        const priceImpact = !result.amountOutUsd
          ? -1
          : ((-result.amountOutUsd + result.amountInUsd) * 100) / result.amountInUsd;

        return new Aggregator(
          currencyAmountIn,
          outputAmount,
          result.amountInUsd,
          result.amountOutUsd,
          result.receivedUsd,
          result.swaps || [],
          result.tokens || {},
          TradeType.EXACT_INPUT,
          result.gasUsd,
          priceImpact,
          result.encodedSwapData,
          result.routerAddress,
        );
      } catch (e) {
        // ignore aborted request error
        console.log(e);
        return null;
      }
    }

    return null;
  }
}
