import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {BODY_FONT_ENUM, COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

const BAR_HEIGHT = 400;
export const SecurityRatingBar = ({value}: {value: number}) => {
  return (
    <Wrapper>
      <StyledRow>
        <SideContainer>
          <BubbleWrapper bottom={(value * BAR_HEIGHT) / 100 - 45}>
            <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE}>
              {value.toFixed(0)}
            </BodyVariant>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL}>100 points</BodyParagraph>
          </BubbleWrapper>
        </SideContainer>
        <BarWrapper>
          <PercentLabel color={COLORS.WHITE} bottom={51}>
            50
          </PercentLabel>
          <PercentLabel color={COLORS.WHITE} bottom={61}>
            60
          </PercentLabel>
          <PercentLabel color={COLORS.WHITE} bottom={71}>
            70
          </PercentLabel>
          <PercentLabel color={COLORS.WHITE} bottom={86}>
            85
          </PercentLabel>
          <PinkBar height={value < 50 ? (BAR_HEIGHT * 0.5 * value) / 50 : undefined} />
          {value > 50 && <HighRiskBar height={value < 60 ? (BAR_HEIGHT * 0.1 * (value - 50)) / 10 : undefined} />}
          {value > 60 && <IncreaseRiskBar height={value < 70 ? (BAR_HEIGHT * 0.1 * (value - 60)) / 10 : undefined} />}
          {value > 70 && (
            <ReasonableRiskBar height={value < 85 ? (BAR_HEIGHT * 0.15 * (value - 70)) / 15 : undefined} />
          )}
          {value > 85 && <LowRiskBar height={value < 100 ? (BAR_HEIGHT * 0.15 * (value - 85)) / 15 : undefined} />}
        </BarWrapper>
        <SideContainer>
          <DesrciptionLabel color={COLORS.GRAY_LIGHT} bottom={120}>
            - Too risky
          </DesrciptionLabel>
          <DesrciptionLabel color={COLORS.GRAY_LIGHT} bottom={210}>
            - High risky
          </DesrciptionLabel>
          <DesrciptionLabel color={COLORS.GRAY_LIGHT} bottom={250}>
            - Increased risky
          </DesrciptionLabel>
          <DesrciptionLabel color={COLORS.GRAY_LIGHT} bottom={300}>
            - Reasonable risky
          </DesrciptionLabel>
          <DesrciptionLabel color={COLORS.GRAY_LIGHT} bottom={345}>
            - Low risky
          </DesrciptionLabel>
        </SideContainer>
      </StyledRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
`;

const SideContainer = styled.div`
  position: relative;
  flex: 1;
`;

const BubbleWrapper = styled.div<{bottom?: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: ${COLORS.SECONDARY};
  position: absolute;
  right: 24px;
  bottom: ${(props) => props.bottom ?? 0}px;
`;

const BarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 6px;
  border-radius: 30px;
  width: 72px;
  height: ${BAR_HEIGHT}px;
  background-color: ${COLORS.GRAY_BASE_40};
  overflow: hidden;
`;

const PinkBar = styled.div<{height?: number}>`
  width: 100%;
  height: ${(props) => props.height ?? BAR_HEIGHT * 0.5}px;
  background-color: #9140e9;
  border-bottom-radius: 30px;
`;

const HighRiskBar = styled.div<{height?: number}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: ${(props) => props.height ?? BAR_HEIGHT * 0.1}px;
  background-color: #7b5be5;
`;

const IncreaseRiskBar = styled.div<{height?: number}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: ${(props) => props.height ?? BAR_HEIGHT * 0.1}px;
  background-color: #697ae1;
`;

const ReasonableRiskBar = styled.div<{height?: number}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: ${(props) => props.height ?? BAR_HEIGHT * 0.15}px;
  background-color: #60afd8;
`;

const LowRiskBar = styled.div<{height?: number}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: ${(props) => props.height ?? BAR_HEIGHT * 0.15}px;
  background-color: #17e7d6;
`;

const PercentLabel = styled(BodyParagraph)<{bottom?: number}>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${(props) => props.bottom ?? 0}%;
  text-align: center;
`;

const DesrciptionLabel = styled(BodyParagraph)<{bottom?: number}>`
  position: absolute;
  left: 10px;
  bottom: ${(props) => props.bottom ?? 0}px;
`;
