export const RedirectLinkIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.915 13.7433V14.859C17.9168 15.4266 17.7592 15.9833 17.4602 16.4658C17.1879 16.9107 16.8054 17.2779 16.3498 17.5317C15.8941 17.7856 15.3806 17.9176 14.859 17.915H9.14111C8.67045 17.915 8.20625 17.8054 7.78528 17.5949C7.3643 17.3844 6.99812 17.0788 6.71572 16.7023C6.32214 16.1775 6.10938 15.5392 6.10938 14.8832V9.14111C6.10938 8.33705 6.42879 7.56591 6.99735 6.99735C7.56591 6.42879 8.33705 6.10938 9.14111 6.10938H10.2871'
      stroke='#17E7D6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.9718 13.9434L16.3991 7.51611'
      stroke='#17E7D6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.0763 6.90356H15.8716C16.1498 6.90356 16.4166 7.01408 16.6133 7.2108C16.81 7.40752 16.9205 7.67434 16.9205 7.95255V10.7539'
      stroke='#17E7D6'
      strokeWidth='1.2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default RedirectLinkIcon;
