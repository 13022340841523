import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useDispatch} from 'react-redux';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {zapKnownErrorReasons} from 'src/constants/zaps';
import {AppContext} from 'src/contexts/AppContext';
import {ApprovalState, useApproveCallbackFromTrade, useSwapCallback} from 'src/hooks/kyber-swap';
import {swapSlice} from 'src/state/swap/reducer';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {Aggregator} from 'src/utils/swap/aggregator';
import {isTransactionSuccessful} from 'src/utils/utils';
import styled from 'styled-components';

import {ConfirmSwap} from './ConfirmSwap';
import {ProcessingSwap} from './ProcessingSwap';
import {SuccessSwap} from './SuccessSwap';
import {FailedTransaction} from '../FailedTransaction';

type SwapModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  inputToken?: IWhitelistToken;
  outputToken?: IWhitelistToken;
  trade: Aggregator;
  feePercent?: number;
  slippageAmount?: number;
  slippageAdjustedAmount?: string;
};

export const SwapModal = ({
  isOpen,
  onDismiss,
  inputToken,
  outputToken,
  trade,
  feePercent = 0,
  slippageAmount,
  slippageAdjustedAmount,
}: SwapModalProps) => {
  const {refreshTokens} = useContext(AppContext);
  const [step, setStep] = useState(0);
  const [dismiss, setDismiss] = useState(false);
  const [outputAmount, setOutAmount] = useState<string>();

  const [swapError, setSwapError] = useState(false);
  const [swapErrorMsg, setSwapErrorMsg] = useState('');
  const [swapErrorSuggestion, setSwapErrorSuggestion] = useState('');
  const [swapErrorCounter, setSwapErrorCounter] = useState(0);
  const [hasRejectedSwap, sethasRejectedSwap] = useState(false);

  const title = step === 0 ? 'Confirm Swap' : step === 1 ? 'Waiting for confirmation' : '';
  const inputAmount = trade?.inputAmount.toSignificant(18);
  const {callback} = useSwapCallback(trade);
  const [approval] = useApproveCallbackFromTrade(trade, slippageAmount, feePercent);

  const {toggleAggregatorUpdates} = swapSlice.actions;

  const dispatch = useDispatch();

  const handleToggleAggregatorUpdates = useCallback(() => {
    dispatch(toggleAggregatorUpdates());
  }, [dispatch, toggleAggregatorUpdates]);

  useEffect(() => {
    if (isOpen) {
      setStep(0);
    } else {
      sethasRejectedSwap(true);
    }
  }, [hasRejectedSwap, isOpen]);

  useEffect(() => {
    setDismiss(false);
    const initialOutPutAmount = trade?.outputAmount
      .subtract(trade?.outputAmount?.multiply(feePercent * 100).divide(10000))
      ?.toSignificant(6);

    setOutAmount(initialOutPutAmount);
  }, [dismiss, feePercent, trade?.outputAmount]);

  const onConfirm = useCallback(async () => {
    if (callback) {
      handleToggleAggregatorUpdates();
      setStep(1);
      try {
        const result = await callback();
        if (result && result.hash) {
          // A tx hash is returned, indicating the swap tx has been successfully executed
          const tx = await result.wait();
          console.log(tx);
          setStep(isTransactionSuccessful(tx) ? 2 : 3);
        } else {
          // Handle case where there is no transaction result or no hash
          setSwapError(true);
          setSwapErrorMsg('Transaction failed or no transaction hash returned');
          setStep(0);
        }
      } catch (e) {
        console.log({e});
        setSwapError(true);
        sethasRejectedSwap(true);
        // @ts-expect-error: e obj includes reason
        if (!e.reason.includes(zapKnownErrorReasons.USERREJECTION)) {
          setSwapErrorCounter(swapErrorCounter + 1);
          // @ts-expect-error: e obj includes reason
          setSwapErrorMsg(`${e.reason.slice(20)}...`);
          // @ts-expect-error: e obj includes reason
          if (e.reason.includes(zapKnownErrorReasons.SWAPERROR) && swapErrorCounter < 2) {
            setSwapErrorSuggestion('Please adjust your slippage in order to proceed (0.5% - 1% is recommended)');
          } else {
            setSwapErrorSuggestion('Please refresh and try again or try a different token.');
          }
          // @ts-expect-error: e obj includes reason
        } else if (e.reason === zapKnownErrorReasons.TRANSACTION_FAILED) {
          setStep(3);
          return;
        }
        setStep(0);
      }
    }
  }, [handleToggleAggregatorUpdates, swapErrorCounter, callback]);

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton
            onClick={() => {
              handleToggleAggregatorUpdates();
              setDismiss(true);
              onDismiss();
            }}
          >
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <ConfirmSwap
            inputToken={inputToken}
            outputToken={outputToken}
            inputAmount={inputAmount}
            outputAmount={outputAmount}
            slippageAdjustedAmount={slippageAdjustedAmount}
            loading={approval === ApprovalState.PENDING}
            disabled={approval !== ApprovalState.APPROVED}
            onConfirm={onConfirm}
            isError={swapError}
            errorMsg={swapErrorMsg}
            errorSuggestion={swapErrorSuggestion}
            errorClick={() => {
              handleToggleAggregatorUpdates();
              onDismiss();
              setSwapError(false);
            }}
            hasRejected={hasRejectedSwap}
          />
        )}
        {step === 1 && (
          <ProcessingSwap
            inputToken={inputToken}
            outputToken={outputToken}
            inputAmount={inputAmount}
            outputAmount={outputAmount}
            slippageAdjustedAmount={slippageAdjustedAmount}
          />
        )}
        {step === 2 && (
          <SuccessSwap
            inputToken={inputToken}
            outputToken={outputToken}
            inputAmount={inputAmount}
            outputAmount={outputAmount}
            onDone={() => {
              handleToggleAggregatorUpdates();
              setDismiss(true);
              onDismiss();
              refreshTokens(true);
            }}
          />
        )}
        {step === 3 && (
          <FailedTransaction
            onExit={() => {
              refreshTokens(true);
              setStep(0);
            }}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 24px 8px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
