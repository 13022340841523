import {useNavigate} from 'react-router-dom';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph} from 'src/components/Typography';
import {paths} from 'src/constants';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {FarmType, POOL_TYPE} from 'src/types';
import {useYearlyRevenue} from 'src/utils/token-util';
import styled from 'styled-components';

export const EarnWithIdleTokenItem = ({...props}) => {
  const {data, isDiscoverFarm, isHighPayingFarm} = props;
  const name = `${data?.liquidityPool?.token0Symbol} - ${data?.liquidityPool?.token1Symbol}`;
  const {getYearlyRevenue} = useYearlyRevenue();
  const navigate = useNavigate();
  const earnButton = () => {
    const address =
      data.type === FarmType.FARM_MASTERCHEF ? data.liquidityPool.address.hash : data.contractAddress.hash;
    navigate(
      `${paths.poolDetail}?type=${POOL_TYPE.FARMING_POOL}&address=${address}&farm-type=${data.type}&discover-farm=${isDiscoverFarm}`,
    );
  };
  /*
  Now in the first section “Earn with your idle tokens” it recommends the WBNB-BUSD pool.

  It means the user can simply take the tokens he already has in his wallet and provide liquidity to start earning.
  He needs 50% of each token.
  As he has more BUSD than BNB, BNB is the limiting token.

  -> The user can take $69 worth of BNB and $69 worth of BUSD to provide liquidity.
  = $138
  Applying the APY 16.5% to the amount returns annual earnings of $22.77
  */

  const {yearlyRevenue, token0, token1} = getYearlyRevenue(data);

  return (
    <FarmItem>
      <LeftWrapper>
        <TokenIconWrapper>
          <TokenIcon width={24} height={24} token={token0} />
          <SecondTokenIcon width={24} height={24} token={token1} />
        </TokenIconWrapper>
        <TokenDescription>
          <CoinPairNames>{name}</CoinPairNames>
          <RevenueText>
            {!isHighPayingFarm
              ? `${data?.apy?.toFixed(1)}% APY -  $${yearlyRevenue.toFixed(2)} per year`
              : `${data?.apy?.toFixed(1)}% APY`}
          </RevenueText>
        </TokenDescription>
      </LeftWrapper>
      <EarnButton onClick={earnButton}>
        <p>Earn</p>
      </EarnButton>
    </FarmItem>
  );
};

const FarmItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 17px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px 0px #1124550f;
  border-radius: 12px;
  align-items: center;
  width: 100%;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    width: 90%;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
`;

const TokenIconWrapper = styled.div`
  display: flex;
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;
`;

const TokenDescription = styled.div`
  margin-left: 6px;
`;

const CoinPairNames = styled(BodyParagraph)`
  font-weight: 700;
  margin-bottom: 5px;
  color: ${COLORS.PRIMARY};
`;

const RevenueText = styled(BodyParagraph)`
  font-weight: 400;
  color: ${COLORS.GRAY_LIGHT};
`;

const EarnButton = styled.a`
  padding: 8px 22px;
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  & p {
    margin: 0;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    background-color: rgb(17, 36, 140);
  }
`;
