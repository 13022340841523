import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type CloseIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const CloseIcon = ({size = 24, color = COLORS.PRIMARY, ...props}: CloseIconType) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    stroke={color}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  >
    <line x1='18' y1='6' x2='6' y2='18'></line>
    <line x1='6' y1='6' x2='18' y2='18'></line>
  </svg>
);

export default CloseIcon;
