import moment from 'moment';

export const convertFromISODate = (dateString: string, format: string) => {
  const date = moment(dateString).format(format);
  return date;
};

export const convertFrom1970sDate = (milliseconds: number, format: string) => {
  const date = moment(new Date(milliseconds)).format(format);
  return date;
};

export const getDeadline = (minutes: number): number => {
  const currentTime = Math.floor(Date.now() / 1000);
  const offset = minutes * 60;
  return currentTime + offset;
};
