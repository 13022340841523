import {BigNumber} from 'ethers';

import {IFunction} from './harvest';
import {IPlatform} from './platform';
import {ILiquidityPool} from './pool';
import {IChainSpecific, IPrice, ITokenAddress} from './token';

export enum FarmType {
  FARM_MASTERCHEF,
  FARM_LP,
  FARM_SINGLE,
  FARM_GAUGE, //solidly-type farms
  FARM_GRIZLYFI,
}

export type IFarmData = {
  farms: IFarm[];
};

export type IFarm = {
  type?: FarmType;
  contractAddress?: ITokenAddress;
  platform?: IPlatform;
  rewardPerBlock?: number;
  rewardTokenChainSpecifics?: IChainSpecific;
  annualRewards?: IPrice;
  baseAPR?: number;
  stakedTokenChainSpecifics?: IChainSpecific;
  stakedTokenSupply?: string;
  liquidityPool?: ILiquidityPool;
  tvl?: IPrice;
  apy?: number;
  tvlNumber?: number;
  vestingTimeInDays?: number;
  balance?: BigNumber;
  pendingReward?: number;
  harvestFn?: IFunction;
  depositFn?: IFunction;
  withdrawFn?: IFunction;
  hotness?: number;
};

export type LiquidityCalculationResult = {
  otherToken?: 'A' | 'B';
  otherTokenAmount?: BigNumber;
};
