import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {FarmItem, TokenDepositItem} from 'src/components/Holdings';
import {TokenSelectModal} from 'src/components/Modals';
import {Spinner} from 'src/components/Spinner';
import {FilterIcon} from 'src/components/Svgs';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyVariant} from 'src/components/Typography';
import {minTVL} from 'src/constants/farms';
import {AppContext} from 'src/contexts/AppContext';
import {useModals} from 'src/contexts/modals';
import {useToken} from 'src/hooks';
import {farmSlice} from 'src/state/farm/reducer';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import {
  FarmType,
  Field,
  IWhitelistToken,
  IYieldFarm,
  PlatformSettingsMode,
  PlatformSettingsSection,
  PlatformSorting,
} from 'src/types';
import {sortBy} from 'src/utils/token-util';
import styled from 'styled-components';

export type FilterIndicatorProps = {
  isActive?: boolean;
};

export const Farm = () => {
  const {farmLoading, farmingPools, farms, gauges, grizzlyFarms} = useContext(AppContext);
  const {chainId} = useWeb3React();
  const modalContext = useModals();
  const appDispatch = useAppDispatch();
  const farmState = useAppSelector((state) => state.farm);
  const {platformSettings} = useAppSelector((state) => state.user);
  const [showInputToken0SelectModal, setShowInputToken0SelectModal] = useState(false);
  const [showInputToken1SelectModal, setShowInputToken1SelectModal] = useState(false);
  const [prevChainId, setPrevChainId] = useState(chainId);
  const [areFiltersActive, setAreFiltersActive] = useState(false);

  const {getTokenByGlobalName} = useToken();
  const inputToken0 = getTokenByGlobalName(farmState.INPUT.tokenGlobalName, true);
  const inputToken1 = getTokenByGlobalName(farmState.OUTPUT.tokenGlobalName, true);

  const filteredFarmingPools = useMemo(() => {
    let token0Symbol = inputToken0?.symbol;
    let token1Symbol = inputToken1?.symbol;
    // check native token
    if (inputToken0?.isNative) {
      token0Symbol = `W${token0Symbol}`;
    }
    if (inputToken1?.isNative) {
      token1Symbol = `W${token1Symbol}`;
    }
    if (farmingPools && farms && gauges && grizzlyFarms) {
      const _lpFarms = farms?.filter((item) => item.contractAddress?.chainId === chainId);
      const v2FarmingPools = farmingPools.filter((item) => {
        if (
          item?.masterChefAddress?.chainId === chainId &&
          item.version !== 1 &&
          item?.platform?.name !== 'MM Finance'
        ) {
          const {...newItem} = item;
          return newItem;
        }
        return null;
      });
      const mergedFarms: IYieldFarm[] = [...v2FarmingPools, ..._lpFarms, ...gauges, ...grizzlyFarms];

      const platformSlug = platformSettings?.farmPlatform?.slug ?? platformSettings?.platform?.slug;
      let _farmingPools = mergedFarms?.filter((item) => {
        return (
          (item.apy || 0) > 0.1 &&
          item?.tvlNumber > minTVL &&
          (platformSlug === 'all' || item.platform.slug === platformSlug)
        );
      });

      if (inputToken0) {
        _farmingPools = _farmingPools?.filter((item) => {
          if (item.type === FarmType.FARM_SINGLE) {
            // TODO: Maybe do filtering by address (but take care that native tokens filtering still show farms)
            return item.rewardTokenChainSpecifics?.symbol === token0Symbol;
          }
          return item.liquidityPool?.token0Symbol === token0Symbol || item.liquidityPool?.token1Symbol === token0Symbol;
        });
      }
      if (inputToken1) {
        _farmingPools = _farmingPools?.filter((item) => {
          if (item.type === FarmType.FARM_SINGLE) {
            return item.rewardTokenChainSpecifics?.symbol === token1Symbol;
          }
          return item.liquidityPool?.token0Symbol === token1Symbol || item.liquidityPool?.token1Symbol === token1Symbol;
        });
      }

      switch (platformSettings?.farmSorting) {
        case PlatformSorting.SORT_BY_ALPHABET: {
          _farmingPools = sortBy(
            _farmingPools || [],
            (item) => item.vestingTimeInDays || 0,
            PlatformSorting.SORT_BY_ALPHABET_DESC,
          );
          _farmingPools = sortBy(
            _farmingPools || [],
            (item) => item.liquidityPool?.token1Symbol ?? '',
            PlatformSorting.SORT_BY_ALPHABET_DESC,
          );
          _farmingPools = sortBy(
            _farmingPools || [],
            (item) => item.liquidityPool?.token0Symbol ?? '',
            PlatformSorting.SORT_BY_ALPHABET,
          );
          break;
        }
        case PlatformSorting.SORT_BY_APY: {
          _farmingPools = sortBy(_farmingPools || [], (item) => item.apy || 0, PlatformSorting.SORT_BY_APY);
          break;
        }
        case PlatformSorting.SORT_BY_TVL: {
          _farmingPools = sortBy(_farmingPools || [], (item) => item.tvlNumber || 0, PlatformSorting.SORT_BY_TVL);
          break;
        }
        case PlatformSorting.SORT_BY_SECURITY_RATING: {
          _farmingPools = sortBy(
            _farmingPools || [],
            (item) => item.liquidityPool?.platform.securityRating?.securityRating || 0,
            PlatformSorting.SORT_BY_SECURITY_RATING,
          );
          break;
        }
      }
      return _farmingPools;
    }
  }, [
    inputToken0,
    inputToken1,
    farmingPools,
    farms,
    gauges,
    grizzlyFarms,
    platformSettings?.farmPlatform?.slug,
    platformSettings?.platform?.slug,
    platformSettings?.farmSorting,
    chainId,
  ]);

  useEffect(() => {
    if (prevChainId !== chainId) {
      appDispatch(farmSlice.actions.resetToken());

      setPrevChainId(chainId);
    }
    platformSettings?.farmPlatform?.slug !== 'all' ? setAreFiltersActive(true) : setAreFiltersActive(false);
  }, [appDispatch, chainId, platformSettings?.farmPlatform?.slug, prevChainId]);

  const handleOpenFilters = () => {
    const payload = {
      isOpen: true,
      type: PlatformSettingsMode.PLATFORM_SETTINGS_FARM,
      section: PlatformSettingsSection.PLATFORM_SETTINGS_FARM,
    };
    modalContext.dispatch({type: 'updatePlatformSettingsModal', payload});
  };

  const handleToken0Select = useCallback(
    (token?: IWhitelistToken) => {
      appDispatch(farmSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token?.globalName}));
    },
    [appDispatch],
  );

  const handleToken1Select = useCallback(
    (token?: IWhitelistToken) => {
      appDispatch(farmSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: token?.globalName}));
    },
    [appDispatch],
  );

  return (
    <Wrapper>
      <Container>
        <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
          Select Token or Pair
        </BodyVariant>
        <TokenPairWrapper>
          <TokenSelectWrapper>
            <TokenSelectItem token={inputToken0} onSelect={() => setShowInputToken0SelectModal(true)} />
          </TokenSelectWrapper>
          <TokenSelectWrapper>
            <TokenSelectItem token={inputToken1} onSelect={() => setShowInputToken1SelectModal(true)} />
          </TokenSelectWrapper>
        </TokenPairWrapper>
        <StyledFarms>
          <StyledFullRow>
            <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
              Farms
            </BodyVariant>
            <StyledIconButton onClick={handleOpenFilters}>
              <FilterIcon color={areFiltersActive ? COLORS.SECONDARY : COLORS.PRIMARY} />
              <FilterIndicator isActive={areFiltersActive} />
            </StyledIconButton>
          </StyledFullRow>
          {farmLoading ? (
            <StyledFullWrapper>
              <Spinner size={32} color={COLORS.SECONDARY} />
            </StyledFullWrapper>
          ) : filteredFarmingPools?.length ? (
            filteredFarmingPools?.map((item, index) => {
              if (item.type !== FarmType.FARM_SINGLE) {
                return <FarmItem key={index} type={1} data={item} />;
              }
              return <TokenDepositItem key={index} type={1} data={item} />;
            })
          ) : null}
        </StyledFarms>
      </Container>
      {handleToken0Select && (
        <TokenSelectModal
          selectedToken={inputToken0}
          showClearToken={true}
          onTokenSelect={handleToken0Select}
          isOpen={showInputToken0SelectModal}
          onDismiss={() => setShowInputToken0SelectModal(false)}
        />
      )}
      {handleToken1Select && (
        <TokenSelectModal
          selectedToken={inputToken1}
          showClearToken={true}
          onTokenSelect={handleToken1Select}
          isOpen={showInputToken1SelectModal}
          onDismiss={() => setShowInputToken1SelectModal(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 80px 0;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 0;
  }
`;

const Container = styled.div`
  width: 712px;
  border-radius: 24px;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    padding: 0 16px;
  }
`;

const TokenPairWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
`;

const TokenSelectWrapper = styled.div`
  width: 200px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BASE_40};

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: auto;
  }
`;

const StyledFarms = styled.div`
  margin-top: 44px;
  display: flex;
  flex-direction: column;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconButton = styled.div`
  margin-right: 24px;
  display: flex;
  cursor: pointer;
  transition: all 0.5s ease 0s;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const FilterIndicator = styled.div<FilterIndicatorProps>`
  width: 9px;
  height: 9px;
  flex-shrink: 0;
  border-radius: 10px;
  background: ${(props) => (props.isActive ? '#654CEC' : 'transparent')};
`;

const StyledFullWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    min-height: 300px;
  }
`;
