import React from 'react';

import {BigNumber} from 'ethers';
import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {LiquidityInputItem} from 'src/components/Liquidity/LiquidityInputItem';
import {LiquidityReceiveItem} from 'src/components/Liquidity/LiquidityReceiveItem';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type AddLiquiditySuccessfulProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  liquidity: number;
  share: number;
  shareDecimals: number;
  token0Amount: BigNumber;
  token1Amount: BigNumber;
  totalInputValue: number;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
};

export const AddLiquiditySuccessful = ({
  token0,
  token1,
  liquidity,
  share,
  shareDecimals,
  token0Amount,
  token1Amount,
  totalInputValue,
  loading,
  disabled,
  onConfirm,
}: AddLiquiditySuccessfulProps) => {
  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          You received
        </BodyParagraph>
        <LiquidityReceiveItem
          token0={token0}
          token1={token1}
          lpReceived={liquidity}
          poolShare={share}
          poolShareDecimals={shareDecimals}
        />
        <StyledInputHeader color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          Input
        </StyledInputHeader>
        <LiquidityInputItem token0={token0} token1={token1} token0Amount={token0Amount} token1Amount={token1Amount} />
        <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          = ${totalInputValue.toFixed(2)}
        </BodyParagraph>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton color={COLORS.PRIMARY} isLoading={loading} disabled={disabled} title='Done' onClick={onConfirm} />
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const TokenInfoBox = styled.div`
  padding: 20px 25px 10px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledInputHeader = styled(BodyParagraph)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
