import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ChevronRightIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ChevronRightIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: ChevronRightIconType) => (
  <svg width={size} height={size} viewBox='0 0 30 30' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 15L18.466 14.376L13.858 9L12.4 10.248L16.474 15L12.4 19.752L13.858 21L18.466 15.624L19 15Z'
      fill={color}
    />
  </svg>
);

export default ChevronRightIcon;
