import {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import {Contract, ethers} from 'ethers';
import FarmContractForSingleToken from 'src/abis/FarmContractForSingleToken.json';
import FarmContractVesting from 'src/abis/FarmContractVesting.json';
import {IFarm} from 'src/types';

export const useUserFarm = () => {
  const {account, provider} = useWeb3React();

  const getLiqInfo = useCallback(
    async (farm: IFarm) => {
      let farmAbi = FarmContractForSingleToken;
      if (farm.liquidityPool) {
        farmAbi = FarmContractVesting;
      }
      if (account && farm.contractAddress) {
        try {
          const signer = provider.getSigner(account);
          const contract = new Contract(farm.contractAddress?.hash, farmAbi, signer);
          const pendingRewardContractCall = contract.pendingReward(account);
          const userInfoContractCall = contract.userInfo(account);
          const result = await Promise.all([pendingRewardContractCall, userInfoContractCall]);
          const pendingReward = result[0];
          const userInfo = result[1];
          const pendingRewardInfo = ethers.utils.formatUnits(pendingReward, 18);
          return [userInfo, Number(pendingRewardInfo)];
        } catch (e) {
          console.log(e);
        }
      }
      return null;
    },
    [account, provider],
  );

  return {getLiqInfo};
};
