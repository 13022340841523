import {gql} from '@apollo/client';

export const GAUGES = gql`
  query whitelistedGauges($platformSlug: String, $chainId: Float) {
    whitelistedGauges(platformSlug: $platformSlug, chainId: $chainId) {
      address {
        hash
        chainId
      }
      apy
      baseAPR
      rewardTokenChainSpecifics {
        name
        symbol
        decimals
      }
      platform {
        name
        slug
        securityRating {
          projectStart
          projectAgeInDays
          governanceTokenMarketCap
          tvl
          rugdocRiskLevel
          certikTrustScore
          cerRating
          updatedAt
          securityRating
          projectAgeRating
          tvlRating
          marketCapRating
        }
        iconUrl
        governanceTokenChainSpecifics {
          name
          symbol
          decimals
        }
      }
      liquidityPool {
        platform {
          name
          slug
          iconUrl
          securityRating {
            projectStart
            projectAgeInDays
            governanceTokenMarketCap
            tvl
            rugdocRiskLevel
            certikTrustScore
            cerRating
            updatedAt
            securityRating
            projectAgeRating
            tvlRating
            marketCapRating
          }
          governanceTokenChainSpecifics {
            name
            symbol
            decimals
          }
          routerAddresses {
            hash
            chainId
          }
        }
        reserve0
        reserve1
        token0Symbol
        token1Symbol
        token0Hash
        token1Hash
        totalSupply
        decimals
        lpTokenPriceUSD {
          value
          decimals
        }
        address {
          hash
          chainId
        }
        lpValueUSD {
          decimals
          value
        }
        poolApy
        pastVolumes
      }
      hotness
      voterContract {
        config {
          rewardsFn
          harvestFn {
            signature
            arguments {
              name
              type
            }
          }
          withdrawFn {
            signature
            arguments {
              name
              type
            }
          }
          balanceFn
          gaugeAbi
          depositFn {
            signature
            arguments {
              name
              type
            }
          }
        }
      }
    }
  }
`;
