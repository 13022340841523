import React from 'react';

import {Button} from 'src/components/Buttons';
import {ArrowDownIcon, TickIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {useToken} from 'src/hooks/useToken';
import {COLORS} from 'src/styles';
import {IYieldFarm} from 'src/types';
import styled from 'styled-components';

type DepositLPProps = {
  highestAPYPool: IYieldFarm;
  name: string;
  yearlyRevenue: number;
  iconUrl: string;
  lowestPoolInfo: string;
  lowestPooltvl: string;
  hightestPooltvl: string;
  highIconUrl: string;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
};

export const DepositLP = ({
  highestAPYPool,
  name,
  yearlyRevenue,
  iconUrl,
  lowestPoolInfo,
  lowestPooltvl,
  hightestPooltvl,
  highIconUrl,
  loading,
  disabled,
  onConfirm,
}: DepositLPProps) => {
  const {getTokenByAddress} = useToken();
  const token0HighestApyPool = getTokenByAddress(highestAPYPool?.liquidityPool?.token0Hash);
  const token1HighestApyPool = getTokenByAddress(highestAPYPool?.liquidityPool?.token1Hash);
  return (
    <Body>
      <StpesContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.GRAY_LIGHT}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Withdraw LP from Farm</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.GRAY_LIGHT}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve LP Tokens</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.GRAY_LIGHT}>3</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Migrate LP Tokens</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.GRAY_LIGHT}>4</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve new LP Tokens</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>5</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Deposit LP Tokens to Farm</BodyVariant>
        </StyledRow>
        <StyledVerticalLine />
      </StpesContainer>
      <TokenInfoBox>
        <LeftWrapper>
          <FarmInformation>
            <FarmName>
              <TokenIconWrapper>
                <OverLappedTokenIcon width={24} height={24} token={token0HighestApyPool} />
                <SecondTokenIcon width={24} height={24} token={token1HighestApyPool} />
              </TokenIconWrapper>
              <CoinPairNames>{name}</CoinPairNames>
            </FarmName>
            <FarmRevenue>
              <RevenueText>
                <strong>{`+$${yearlyRevenue.toFixed(2)}`}</strong>
                {' per year'}
              </RevenueText>
            </FarmRevenue>
          </FarmInformation>
          <SwapContainer>
            <FarmPairsContainer>
              <FarmPairs>
                <TokenDescription>
                  <div>
                    <StyledImage src={iconUrl} />
                  </div>
                  <PoolInfo>
                    <CoinPairNames>{lowestPoolInfo}</CoinPairNames>
                    {lowestPooltvl && <TvlText>{lowestPooltvl}</TvlText>}
                  </PoolInfo>
                </TokenDescription>
              </FarmPairs>
              <ArrowDownIcon size={24} color={COLORS.GRAY_LIGHT} />
              <FarmPairs>
                <TokenDescription>
                  <div>
                    <StyledImage src={highIconUrl} />
                  </div>
                  <PoolInfo>
                    <CoinPairNames>
                      {`${highestAPYPool?.platform?.name} - `}
                      <ApyText>{`${highestAPYPool?.apy?.toFixed(1)}% APY`}</ApyText>
                    </CoinPairNames>
                    <TvlText>{hightestPooltvl}</TvlText>
                  </PoolInfo>
                </TokenDescription>
              </FarmPairs>
            </FarmPairsContainer>
          </SwapContainer>
        </LeftWrapper>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title='Deposit LP'
          onClick={onConfirm}
        />
        <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StpesContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 20px 25px 10px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const FarmInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TokenIconWrapper = styled.div`
  display: flex;
  position: relative;
`;

const FarmName = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const FarmRevenue = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const OverLappedTokenIcon = styled(TokenIcon)`
  z-index: 2;
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -10px;
  z-index: 1;
`;

const SwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 6px;
  justify-content: space-between;
`;

const FarmPairsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;
`;

const FarmPairs = styled.div`
  flex: 1;
`;

const TokenDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CoinPairNames = styled(BodyParagraph)`
  margin-left: 10px;
  font-weight: 700;
  color: ${COLORS.PRIMARY};
`;

const RevenueText = styled(BodyParagraph)`
  font-weight: 400;
  color: ${COLORS.SECONDARY};
`;
const TvlText = styled(BodyParagraph)`
  font-weight: 400;
  color: ${COLORS.GRAY_LIGHT};
  margin-left: 10px;
`;
const ApyText = styled.span`
  font-weight: 700;
  color: ${COLORS.SECONDARY};
`;

const StyledImage = styled.img`
  width: 26px;
  height: 26px;
`;

const PoolInfo = styled.div`
  flex: 1;
  padding-left: 5px;
`;
