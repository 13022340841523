import {DEVICE_ENUM} from 'src/styles';
import {COLORS} from 'src/styles/colors';
import styled from 'styled-components';

// Children Component
export const Children = styled.div`
  flex: 1;
  height: 100%;
`;

export const ItemsList = styled.div<{alignBottom?: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 39px;

  ${(props) =>
    props.alignBottom &&
    `
    position: sticky;
    height: 100%;
    justify-content: flex-end;
  `}
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;
`;

export const ItemWrapper = styled.div<{alignSpaceBetween?: boolean}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.alignSpaceBetween ? 'space-between' : 'flex-start')};
  gap: 8px;
`;

export const BalanceText = styled.p`
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  color: ${COLORS.PRIMARY};
`;

export const DescriptionText = styled.p`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  margin: 0;
  color: ${COLORS.PRIMARY};
  opacity: 0.2;
`;

// Sidebar Container
export const SidebarContainer = styled.div<{open: boolean}>`
  position: fixed;
  // top: 81px;
  display: flex;
  flex-direction: column;
  width: 254px;
  // width: 100%;
  // bottom: 0px;
  // height: 100vh;
  height: calc(100vh - 81px);
  background: #ffffff;
  overflow-y: auto;
  // z-index: 999;

  @media (max-width: ${DEVICE_ENUM.md}) {
    // top: 60px;
    // transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    // height: calc(100vh - 60px);
    height: 100%;
  }
`;
