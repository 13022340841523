import React from 'react';

import {Button} from 'src/components/Buttons';
import {ArrowLeftIcon, RemoveIcon} from 'src/components/Svgs';
import {BodyVariant} from 'src/components/Typography';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {COLORS} from 'src/styles';
import {IAddressBook} from 'src/types';
import styled from 'styled-components';

type ContactBookDetailProps = {
  contact?: IAddressBook;
  onBack: () => void;
  onNavigate: () => void;
};

export const ContactBookDetail = ({contact, onBack, onNavigate}: ContactBookDetailProps) => {
  const {addressBook} = useAppSelector((state) => state.user);
  const appDispatch = useAppDispatch();

  const onRemove = () => {
    const newAddressBook = addressBook.filter((item) => item.address !== contact.address);
    const payload = {addressBook: newAddressBook};
    appDispatch(userSlice.actions.updateContactBook(payload));
    onBack();
  };

  return (
    <>
      <SectionWrapper>
        <StyledRow>
          <IconButton onClick={onBack}>
            <ArrowLeftIcon />
          </IconButton>
          <BodyVariant color={COLORS.PRIMARY}>{contact?.name}</BodyVariant>
          <IconButton onClick={onRemove}>
            <RemoveIcon size={20} />
          </IconButton>
        </StyledRow>
      </SectionWrapper>
      <SectionWrapper marginTop={24}>
        <BodyVariant color={COLORS.PRIMARY}>Contact Name</BodyVariant>
        <InputView>
          <Input placeholder='Name' value={contact.name} disabled />
        </InputView>
      </SectionWrapper>
      <SectionWrapper marginTop={16}>
        <BodyVariant color={COLORS.PRIMARY}>Wallet Address</BodyVariant>
        <InputView>
          <Input placeholder='0x...' value={contact.address} disabled />
        </InputView>
      </SectionWrapper>
      <ButtonWrapper>
        <Button color={COLORS.PRIMARY} title='Send Token' onClick={onNavigate} />
      </ButtonWrapper>
    </>
  );
};

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const InputView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 6px;
  padding: 0 8px;
  margin-top: 4px;
`;

const Input = styled.input`
  flex: 1;
  margin-left: 4px;
  color: ${COLORS.PRIMARY};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
