import {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {IYieldFarm} from 'src/types';
import {getFarmContract} from 'src/utils/farm-util';

type WithdrawCall = {
  farm: IYieldFarm;
  functionName?: string;
  args?: string[];
  gasEstimation?: BigNumber;
};

function getWithdrawCall(amount: BigNumber, farm?: IYieldFarm, account?: string): WithdrawCall {
  if (!farm || !account) {
    throw new Error('Mising parameters for functions');
  }
  const farmWithdrawSignature = farm.withdrawFn?.signature;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const farmArguments: any = {};

  if (farm.withdrawFn) {
    for (const farmArgument of farm.withdrawFn.arguments) {
      if (farmArgument.name === '_pid' || farmArgument.name === 'pid') {
        farmArguments[farmArgument.name] = farm.poolId;
      } else if (farmArgument.name === '_amount' || farmArgument.name === 'amount') {
        farmArguments[farmArgument.name] = amount;
      } else if (farmArgument.name === 'to' || farmArgument.name === '_referrer') {
        farmArguments[farmArgument.name] = account;
      }
    }
  }
  return {
    functionName: farmWithdrawSignature,
    args: farmArguments,
    farm: farm,
  };
}

export function useWithdrawFarmCallback(farm?: IYieldFarm) {
  const {account, provider} = useWeb3React();
  const estimateGasForWithdrawFarm = useCallback(
    async (amount: BigNumber) => {
      const withdrawFunctionCall: WithdrawCall = getWithdrawCall(amount, farm, account);
      const contract = await getFarmContract(withdrawFunctionCall?.farm, account, provider);

      const functionName = withdrawFunctionCall.functionName;
      const functionArgs = withdrawFunctionCall.args;

      if (functionName && contract) {
        const estimatedGasFee = await contract.estimateGas[functionName](
          ...(functionArgs ? Object.values(functionArgs) : []),
        );
        return estimatedGasFee;
      }
      return null;
    },
    [farm, account, provider],
  );

  const withdrawFarm = useCallback(
    async (gasLimit: BigNumber, amount: BigNumber) => {
      const withdrawFunctionCall = getWithdrawCall(amount, farm, account);

      if (account) {
        const nonce = await provider.getTransactionCount(account);
        const contract = await getFarmContract(withdrawFunctionCall.farm, account, provider);
        const gasSettings = {};

        const functionName = withdrawFunctionCall.functionName;
        const functionArgs = withdrawFunctionCall.args;
        if (!contract || !functionName) {
          return;
        }
        try {
          const tx = await contract[functionName](...(functionArgs ? Object.values(functionArgs) : []), {
            gasLimit: gasLimit,
            from: account,
            ...gasSettings,
            nonce: nonce,
          });
          return tx;
        } catch (e) {
          console.error('Withdraw failed', e, withdrawFunctionCall.farm);
        }
      }
    },
    [farm, account, provider],
  );

  return {
    withdrawFarmCallback: withdrawFarm,
    estimateGasForWithdrawFarm,
  };
}
