import {IFarm, IWhitelistToken} from 'src/types';
import {convertKMPrice} from 'src/utils/text-util';
import {getVestingInMonth} from 'src/utils/token-util';

import RewardTokenItem from './RewardTokenItem';

type StakeLiqFarmProps = {
  item: IFarm;
  liqToken: IWhitelistToken;
  selected?: boolean;
  type?: string;
  onPress?: (selectedFarm: IFarm) => void;
};

export const StakLiqItem = ({liqToken, item, selected, type, onPress}: StakeLiqFarmProps) => {
  const singleTokenSymbol = item.rewardTokenChainSpecifics?.symbol;
  const vestingInMonth = getVestingInMonth(item.vestingTimeInDays);
  const singleTokenName = `${singleTokenSymbol} - ${vestingInMonth} Month`;
  const apyLabel = `${item.apy?.toFixed(2)}%`;

  const handleTokenItem = () => {
    onPress && onPress(item);
  };

  return (
    <RewardTokenItem
      token={liqToken}
      name={singleTokenName}
      label={item.platform?.name}
      rightTopLabel={apyLabel}
      rightBottomLabel={`${convertKMPrice(item.tvlNumber)} TVL`}
      selected={selected}
      type={type}
      onSelect={handleTokenItem}
    />
  );
};
