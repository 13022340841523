import React from 'react';

import {Button} from 'src/components/Buttons';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type ApproveLpTokensProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  loading?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  onConfirm?: () => void;
  onFail?: () => void;
};

export const ApproveLpTokens = ({
  token0,
  token1,
  loading,
  disabled,
  errorMsg,
  onFail,
  onConfirm,
}: ApproveLpTokensProps) => {
  return (
    <Body>
      <StepsContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Approve LP Tokens</BodyVariant>
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox>
            <BodyVariant color={COLORS.GRAY_LIGHT}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Remove Liquidity</BodyVariant>
        </StyledRow>
      </StepsContainer>
      <TokenInfoBox>
        <TokenContainer>
          <TokenBox>
            <TokenIcon width={24} height={24} token={token0} />
            <SecondTokenIcon width={24} height={24} token={token1} />
          </TokenBox>
          <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.MEDIUM_MOBILE} mobile={BODY_FONT_ENUM.SMALL_MOBILE}>
            {token0?.symbol}-{token1?.symbol} LP
          </BodyVariant>
        </TokenContainer>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title={errorMsg ? 'Try Again' : 'Approve LP Tokens'}
          onClick={errorMsg ? onFail : onConfirm}
        />
        {errorMsg ? (
          <StyledParagraph color={COLORS.WARNING}>{errorMsg}</StyledParagraph>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StepsContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 125px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 16px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;
