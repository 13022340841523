import {useWeb3React} from '@web3-react/core';
import {toast} from 'react-toastify';
import {networkConnection, walletConnectConnection} from 'src/connectors';
import {getChainInfo} from 'src/constants';

export const useNetwork = () => {
  const {connector} = useWeb3React();

  const switchNetwork = async (targetChainId: number) => {
    try {
      if (connector === walletConnectConnection.connector || connector === networkConnection.connector) {
        await connector.activate(targetChainId);
      } else {
        const _info = getChainInfo(targetChainId);
        const addChainParameter = {
          chainId: targetChainId,
          chainName: _info?.displayName,
          rpcUrls: _info?.rpcUrl,
          nativeCurrency: {name: _info?.symbol, symbol: _info?.symbol, decimals: _info?.decimals},
          blockExplorerUrls: [_info?.blockExplorerUrls],
        };
        await connector.activate(addChainParameter);
      }
    } catch (e) {
      console.log(e);
      //@ts-expect-error: e object contains a message
      if (e.message !== 'User rejected the request.')
        toast.error('Failed to switch network. Please switch the network in your wallet’s settings.');
    }
  };
  const resetConnector = async () => await connector.resetState();

  return {switchNetwork, resetConnector};
};
