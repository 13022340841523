import {useMemo, useState} from 'react';

import {BigNumber} from 'ethers';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {EstimateBox} from 'src/components/EstimateBox';
import {ArrowDownIcon} from 'src/components/Svgs';
import {StakLiqItem} from 'src/components/Tokens/StakeLiqItem';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {CLASSIC_LIQ_GLOBAL_NAME, NEW_LIQ_GLOBAL_NAME} from 'src/constants';
import {useToken} from 'src/hooks';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {
  calculatePrice,
  formatBigNumber,
  getTokenAmountFromUsdInBigNumber,
  getVestingInMonth,
} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenSelectModal} from '../TokenSelect';

type StakeLIQConfirmProps = {
  inputPriceUSD: number;
  liqFarm: IYieldFarm;
  onConfirm: (liqFarm?: IYieldFarm, inputToken?: IWhitelistToken, inputTokenAmount?: BigNumber) => void;
};

export const StakeLIQConfirm = ({inputPriceUSD, liqFarm, onConfirm}: StakeLIQConfirmProps) => {
  const {getTokenByGlobalName} = useToken();
  const [showTokenSelectModal, setShowTokenSelectModal] = useState(false);
  const [fundingTokenGlobalName, setFundingTokenGlobalName] = useState('');
  const fundingToken = getTokenByGlobalName(fundingTokenGlobalName, false);
  const estimatedYield = (inputPriceUSD * (liqFarm?.baseAPR || 0)) / 100;
  const fundingTokenBalance = formatBigNumber(fundingToken.balance, fundingToken.decimals);
  const fundingTokenUnitUsdPrice = calculatePrice(fundingToken.priceUSD, fundingToken.priceDecimals);
  const marginPercent = 2.5; // Margin for slippage and fees
  const adaptedInputAmountUSD = (inputPriceUSD * (100 + marginPercent)) / 100;
  const fundingTokenRequiredAmount = adaptedInputAmountUSD / fundingTokenUnitUsdPrice;
  const disabled = fundingTokenRequiredAmount > fundingTokenBalance;
  let liqToken = getTokenByGlobalName(NEW_LIQ_GLOBAL_NAME);
  if (!liqToken) {
    // Fallback for chains where NewLIQ token is not available
    liqToken = getTokenByGlobalName(CLASSIC_LIQ_GLOBAL_NAME);
  }

  const convertedUSDToTokenAmount = useMemo(() => {
    return getTokenAmountFromUsdInBigNumber(adaptedInputAmountUSD, fundingToken);
  }, [adaptedInputAmountUSD, fundingToken]);

  const handleFundingTokenSelect = (token?: IWhitelistToken) => {
    setFundingTokenGlobalName(token.globalName);
  };

  const handleConfirm = () => {
    onConfirm(liqFarm, fundingToken, convertedUSDToTokenAmount);
  };

  return (
    <Wrapper>
      <TokenSelectBox>
        <TokenSelectItem token={fundingToken} onSelect={() => setShowTokenSelectModal(true)} />
        <StyledBodyVariant>=</StyledBodyVariant>
        <BodyVariant color={COLORS.PRIMARY}>
          {fundingTokenRequiredAmount.toFixed(2)} {fundingToken.symbol}
        </BodyVariant>
      </TokenSelectBox>
      <BodyParagraph color={COLORS.GRAY_LIGHT}>
        Balance: {fundingTokenBalance?.toFixed(fundingToken.interfaceDecimals)}
      </BodyParagraph>
      {disabled && (
        <StyledWarningBox>
          <StyledWarningIcon src={WarningIcon} />
          <BodyParagraph color={COLORS.WARNING}>Amount to swap exceeds your wallet balance.</BodyParagraph>
        </StyledWarningBox>
      )}
      {!disabled && (
        <StyledCol marginTop={10} itemsCenter={true}>
          <BodyParagraph size={PARAGRAPH_FONT_ENUM.MEDIUM} color={COLORS.GRAY_LIGHT}>
            As output amounts are estimates only, it will swap slightly more LIQ than needed. You do not lose any funds.
          </BodyParagraph>
        </StyledCol>
      )}
      <ArrowBox>
        <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
      </ArrowBox>
      <StyledCol>
        <StakLiqItem liqToken={liqToken} item={liqFarm} type='1' />
        <StyledEstimateBox value={estimatedYield} />
        <StyledCol marginTop={10} itemsCenter>
          <BodyParagraph color={COLORS.GRAY_LIGHT}>Your LIQ tokens will have the a vesting period of:</BodyParagraph>
          <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.GRAY_LIGHT}>
            {getVestingInMonth(liqFarm.vestingTimeInDays)} months lock
          </BodyParagraph>
        </StyledCol>
      </StyledCol>
      <ButtonWrapper>
        <Button disabled={disabled} color={COLORS.PRIMARY} title='Swap & Stake LIQ' onClick={handleConfirm} />
      </ButtonWrapper>
      <TokenSelectModal
        selectedToken={fundingToken}
        showClearToken={true}
        hideNativeToken={true}
        onTokenSelect={handleFundingTokenSelect}
        isOpen={showTokenSelectModal}
        onDismiss={() => setShowTokenSelectModal(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number; itemsCenter?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;

  ${(props) =>
    props.itemsCenter &&
    `
    align-items: center;
  `}
`;

const StyledEstimateBox = styled(EstimateBox)`
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

const ArrowBox = styled.div`
  margin-top: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
`;

const TokenSelectBox = styled.div`
  border: 1px solid ${COLORS.GRAY_LIGHT};
  border-radius: 8px;
  padding: 8px 12px 8px 10px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBodyVariant = styled(BodyVariant)`
  left: 50%;
  transform: translateX(-50%);
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
