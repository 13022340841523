import {CoinbaseWallet} from '@web3-react/coinbase-wallet';
import {Web3ReactHooks, initializeConnector} from '@web3-react/core';
import {MetaMask} from '@web3-react/metamask';
import {Network} from '@web3-react/network';
import {Connector} from '@web3-react/types';
import {WalletConnect as WalletConnectV2} from '@web3-react/walletconnect-v2';
import {NETWORKS, RPC_URLS} from 'src/constants';

import {TrustWallet} from './TrustWalletClass';

export enum ConnectionType {
  INJECTED = 'INJECTED',
  TRUSTWALLET = 'TRUSTWALLET',
  COINBASE_WALLET = 'COINBASE_WALLET',
  WALLET_CONNECT2 = 'WALLET_CONNECT2',
  NETWORK = 'NETWORK',
}

export interface Connection {
  name: string;
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
  overrideActivate?: () => void;
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

const [web3Network, web3NetworkHooks] = initializeConnector<Network>(
  (actions) => new Network({actions, urlMap: RPC_URLS, defaultChainId: 56}),
);
const [web3Injected, web3InjectedHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({actions, onError}));
const [web3TrustWallet, web3InjectedTrustWalletHooks] = initializeConnector<TrustWallet>(
  (actions) => new TrustWallet({actions, onError}),
);
const [mainnet, ...optionalChains] = Object.keys(NETWORKS).map(Number);
const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnectV2>(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        chains: [mainnet],
        optionalChains,
        rpcMap: RPC_URLS,
        showQrModal: true,
      },
    }),
);
const [web3CoinbaseWallet, web3CoinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: RPC_URLS[1]?.[0] ?? '',
        appName: 'Liquidus',
      },
    }),
);

export const networkConnection: Connection = {
  name: 'Network',
  connector: web3Network,
  hooks: web3NetworkHooks,
  type: ConnectionType.NETWORK,
};

export const injectedConnection: Connection = {
  name: 'MetaMask',
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: ConnectionType.INJECTED,
  overrideActivate: () => window.open('https://metamask.io/', 'inst_metamask'),
};

export const walletConnectConnection: Connection = {
  name: 'WalletConnect',
  connector: web3WalletConnect,
  hooks: web3WalletConnectHooks,
  type: ConnectionType.WALLET_CONNECT2,
};

export const coinbaseWalletConnection: Connection = {
  name: 'Coinbase Wallet',
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: ConnectionType.COINBASE_WALLET,
};

export const trustWalletConnection: Connection = {
  name: 'TrustWallet',
  connector: web3TrustWallet,
  hooks: web3InjectedTrustWalletHooks,
  type: ConnectionType.TRUSTWALLET,
  overrideActivate: () => window.open('https://trustwallet.com/browser-extension/', '_blank'),
};

export const getConnections = () => {
  return [
    injectedConnection,
    walletConnectConnection,
    coinbaseWalletConnection,
    networkConnection,
    trustWalletConnection,
  ];
};

export const useGetConnection = () => {
  return (c: Connector | ConnectionType) => {
    if (c instanceof Connector) {
      const connection = getConnections().find((connection) => connection.connector === c);
      if (!connection) {
        throw Error('unsupported connector');
      }
      return connection;
    } else {
      switch (c) {
        case ConnectionType.INJECTED:
          return injectedConnection;
        case ConnectionType.TRUSTWALLET:
          return trustWalletConnection;
        case ConnectionType.COINBASE_WALLET:
          return coinbaseWalletConnection;
        case ConnectionType.WALLET_CONNECT2:
          return walletConnectConnection;
        case ConnectionType.NETWORK:
          return networkConnection;
      }
    }
  };
};
