// TableView.tsx

import {COLORS} from 'src/styles';
import styled from 'styled-components';

export interface IColumn {
  name: string;
  align?: string;
}

export interface RenderItemInfo<ItemT> {
  item?: ItemT;
  index?: number;
}

interface TableViewProps<ItemT> {
  columns: IColumn[];
  data: ItemT[];
  selectable?: boolean;
  renderItem: ({item, index}: RenderItemInfo<ItemT>) => React.ReactElement;
}

export const TableView = <ItemT,>({columns, data, selectable = false, renderItem}: TableViewProps<ItemT>) => {
  return (
    <StyledTable>
      <StyledHead>
        <tr>
          {selectable && <th className='w-10' />}
          {columns.map((column) => (
            <StyledColumn key={column.name} textAlign={column.align} className={column.name.split(' ')[0]}>
              {column.name}
            </StyledColumn>
          ))}
        </tr>
      </StyledHead>
      <tbody>{data?.map((item, index) => renderItem({item, index}))}</tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;
  table-layout: auto;
  z-index: 1; // Default stacking order for table
  position: relative; // Position must be set for z-index to take effect
`;

const StyledHead = styled.thead`
  border-bottom: 1px solid ${COLORS.GRAY_BORDER};
  background-color: #fcfcfc;
`;

const StyledColumn = styled.th<{textAlign?: string}>`
  text-align: ${(props) => props.textAlign ?? 'left'};
  font-size: 13px;
  font-weight: 400;
  color: #9ba1a6;
  padding: 8px 12px;
`;
