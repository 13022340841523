export const MIN_GAS_LIMIT = 21000;
export const MIN_GAS_PRICE = 5;

export const GasPriceItems = [
  {name: 'Low', value: 0},
  {name: 'Medium', value: 1},
  {name: 'High', value: 2},
];

export const SppedUpOptions = [
  {name: '+25%', value: 0},
  {name: '+50%', value: 1},
  {name: '+75%', value: 2},
];

export const DEFAULT_GAS_LIMIT_MARGIN = 20000;
