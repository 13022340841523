import {useCallback, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import LiquidusAutoLPFarmOutABI from 'src/abis/LiquidusAutoLPFarmOutABI.json';
import {getChainInfo} from 'src/constants';
import {ZapOutCall} from 'src/types/topup';

export enum ZapOutCallbackState {
  INVALID,
  LOADING,
  VALID,
}

export function useZapOutCallback(): {
  state: ZapOutCallbackState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  estimateGas: null | ((zapOutCall: ZapOutCall) => any); // wait is not present on unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: null | ((zapOutCall: ZapOutCall) => Promise<any>); // wait is not present on unknown
  error: string | null;
} {
  const {chainId, account, provider} = useWeb3React();
  const getZapOutEstimatedGas = useCallback(
    async (zapOutCall: ZapOutCall) => {
      const chainInfo = getChainInfo(chainId);
      if (!chainInfo) {
        return null;
      }
      const walletSigner = provider.getSigner(account).connectUnchecked();
      const liquidusAutoLPFarmOutAddress = chainInfo.liquidusAutoLPFarmOutAddress;
      const contract = new ethers.Contract(liquidusAutoLPFarmOutAddress, LiquidusAutoLPFarmOutABI, walletSigner);
      const gasLimit = await contract.estimateGas.zapOut(zapOutCall);
      return gasLimit;
    },
    [account, chainId, provider],
  );

  const zapOut = useCallback(
    async (zapOutCall: ZapOutCall) => {
      const walletSigner = provider.getSigner(account).connectUnchecked();
      const chainInfo = getChainInfo(chainId);
      if (!chainInfo) {
        return null;
      }
      const liquidusAutoLPFarmOutAddress = chainInfo.liquidusAutoLPFarmOutAddress;
      const contract = new ethers.Contract(liquidusAutoLPFarmOutAddress, LiquidusAutoLPFarmOutABI, walletSigner);
      const gasSettings = {};
      const result = await contract.zapOut(
        [
          zapOutCall._toTokens,
          zapOutCall.swaps,
          zapOutCall.addLiquidityCall,
          zapOutCall._liquidity,
          zapOutCall._lpAddress,
        ],
        gasSettings,
      );
      return result;
    },
    [account, chainId, provider],
  );
  return useMemo(() => {
    if (!chainId || !account) {
      return {
        state: ZapOutCallbackState.INVALID,
        estimateGas: null,
        callback: null,
        error: 'Missing dependencies',
      };
    }

    const estimateGas = async (zapOutCall: ZapOutCall) => {
      const gasLimit = await getZapOutEstimatedGas(zapOutCall);
      return gasLimit;
    };
    const onZapOut = async (zapOutCall: ZapOutCall): Promise<string> => {
      const hash = await zapOut(zapOutCall);
      if (hash === undefined) {
        throw new Error('Transaction returned undefined.');
      }
      return hash;
    };

    return {
      state: ZapOutCallbackState.VALID,
      estimateGas: estimateGas,
      callback: onZapOut,
      error: null,
    };
  }, [account, chainId, getZapOutEstimatedGas, zapOut]);
}
