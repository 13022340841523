import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type SwapIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const SwapIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: SwapIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.6467 11.6976C14.4588 11.5134 14.4551 11.2075 14.6431 11.0196L16.3822 9.25465H8.09176C7.82648 9.25465 7.61275 9.04094 7.61275 8.77564C7.61275 8.51033 7.82646 8.29662 8.09176 8.29662H16.3787L14.6395 6.53165C14.4552 6.34373 14.4552 6.04158 14.6432 5.85366C14.8311 5.66943 15.1332 5.66943 15.3212 5.85734L17.8637 8.43665C17.9042 8.48086 17.941 8.52876 17.9632 8.58772C17.9889 8.64667 18 8.70931 18 8.77195C18 8.89723 17.9521 9.01515 17.8637 9.10725L15.3212 11.6866C15.1369 11.8782 14.8348 11.8818 14.6469 11.6976L14.6467 11.6976ZM9.35314 18.1486C9.54106 17.9644 9.54474 17.6585 9.35682 17.4706L7.62134 15.7056H15.9082C16.1735 15.7056 16.3873 15.4919 16.3873 15.2266C16.3873 14.9613 16.1735 14.7476 15.9082 14.7476H7.62134L9.36052 12.9826C9.54476 12.7947 9.54476 12.4926 9.35684 12.3047C9.16892 12.1204 8.86677 12.1204 8.67885 12.3083L6.13633 14.8876C6.0958 14.9319 6.05895 14.9798 6.03685 15.0387C6.01105 15.0977 6 15.1603 6 15.2229C6 15.3482 6.0479 15.4661 6.13633 15.5582L8.67885 18.1375C8.86308 18.3291 9.16522 18.3328 9.35314 18.1486L9.35314 18.1486Z'
      fill={color}
    />
  </svg>
);

export default SwapIcon;
