import {Provider} from '@ethersproject/providers';
import {BigNumber, Contract, ethers} from 'ethers';
import LIQHolding from 'src/abis/LIQHolding.json';

export const getLiqHoldings = async (account: string, farmContract: string, decimal: number, provider: Provider) => {
  try {
    const contract = new Contract(farmContract, LIQHolding, provider);
    const userHoldingsCall = contract.userHoldings(account);
    const feeStandardCall = contract.feeBronze();
    const feeSilverCall = contract.feeSilver();
    const feeGoldCall = contract.feeGold();
    const feeTitanCall = contract.feeTitan();
    const percentStandardCall = contract.percentBronze();
    const percentSilverCall = contract.percentSilver();
    const percentGoldCall = contract.percentGold();
    const percentTitanCall = contract.percentTitan();
    const silverCall = contract.silver();
    const goldCall = contract.gold();
    const titanCall = contract.titan();
    const result = await Promise.all([
      userHoldingsCall,
      feeStandardCall,
      feeSilverCall,
      feeGoldCall,
      feeTitanCall,
      percentStandardCall,
      percentSilverCall,
      percentGoldCall,
      percentTitanCall,
      silverCall,
      goldCall,
      titanCall,
    ]);
    const userHolding = ethers.utils.formatUnits(result[0], decimal);
    const feeStandard = BigNumber.from(result[1]).toNumber();
    const feeSilver = BigNumber.from(result[2]).toNumber();
    const feeGold = BigNumber.from(result[3]).toNumber();
    const feeTitan = BigNumber.from(result[4]).toNumber();
    const silver = BigNumber.from(result[9]).toNumber();
    const gold = BigNumber.from(result[10]).toNumber();
    const titan = BigNumber.from(result[11]).toNumber();
    return [
      Number(userHolding),
      feeStandard,
      feeSilver,
      feeGold,
      feeTitan,
      result[5],
      result[6],
      result[7],
      result[8],
      silver,
      gold,
      titan,
    ];
  } catch (e) {
    console.log(e);
  }
  return null;
};
