import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type FarmIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const FarmIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: FarmIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M15.625 8.17188V14.2656C15.625 14.6211 15.3457 14.875 15.0156 14.875C14.6602 14.875 14.4062 14.6211 14.4062 14.2656V9.64453L8.54102 15.5098C8.41406 15.6367 8.26172 15.6875 8.10938 15.6875C7.93164 15.6875 7.7793 15.6367 7.67773 15.5098C7.42383 15.2812 7.42383 14.9004 7.67773 14.6719L13.543 8.78125H8.92188C8.56641 8.78125 8.3125 8.52734 8.3125 8.17188C8.3125 7.8418 8.56641 7.5625 8.92188 7.5625H15.0156C15.3457 7.5625 15.625 7.8418 15.625 8.17188Z'
      fill={color}
    />
  </svg>
);

export default FarmIcon;
