import {PropsWithChildren} from 'react';

import {ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache} from '@apollo/client';

// Initialize Apollo Client
const client = new ApolloClient({
  link: ApolloLink.from([new HttpLink({uri: process.env.REACT_APP_SERVER_URL})]),
  cache: new InMemoryCache(),
});

export const userServicesClient = new ApolloClient({
  link: ApolloLink.from([new HttpLink({uri: process.env.REACT_APP_USER_SERVICES_URL})]),
  cache: new InMemoryCache(),
});

export const GraphQLProvider = ({children}: PropsWithChildren) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
