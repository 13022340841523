import React, {useState} from 'react';

import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types/token';
import styled from 'styled-components';

import {TokenSelectModal} from './Modals';
import {TokenSelectItem} from './Tokens/TokenSelectItem';

type Size = 'default' | 'small';

interface SwapInfoItemProps {
  selectedToken?: IWhitelistToken;
  onTokenSelect?: (token: IWhitelistToken) => void;
}

export const ImportPoolTokenItem = ({selectedToken, onTokenSelect}: SwapInfoItemProps) => {
  const [showTokenSelectModal, setShowTokenSelectModal] = useState(false);

  return (
    <Wrapper>
      <StyledRowBetween>
        <TokenSelectItem
          token={selectedToken}
          size={'small'}
          onSelect={() => setShowTokenSelectModal(true)}
          isImportPool={true}
        />
      </StyledRowBetween>
      <TokenSelectModal
        selectedToken={selectedToken}
        onTokenSelect={onTokenSelect}
        isOpen={showTokenSelectModal}
        onDismiss={() => setShowTokenSelectModal(false)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div<{size?: Size}>`
  padding: 5px 10px;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BORDER};
`;

const StyledRowBetween = styled.div<{marginTop?: number; size?: Size}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
`;
