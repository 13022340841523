import React from 'react';

import {getTokenIcon} from 'src/utils/icon-util';
import styled from 'styled-components';
export type ModalTokenProps = {
  symbolIcon: string;
  symbol: string;
  balance: string | number;
  usdBalance: string;
  tokenNetwork: string;
  tokenNetworkIcon: string;
};

export const ModalToken = (props: ModalTokenProps) => {
  const {symbol, symbolIcon, balance, usdBalance, tokenNetwork, tokenNetworkIcon} = props;
  const tokenLogo = getTokenIcon(symbol) || symbolIcon;
  const tokenBalance =
    typeof balance === 'number' ? balance * parseFloat(usdBalance) : parseFloat(balance) * parseFloat(usdBalance);
  return (
    <Wrapper>
      <TokenWrapper>
        <TokenImg src={tokenLogo} />
        <InputTokenSymbol>{symbol}</InputTokenSymbol>
        <Price>{typeof balance === 'number' ? balance.toFixed(4) : parseFloat(balance).toFixed(4)}</Price>
      </TokenWrapper>
      <TokenWrapper>
        <TokenPrice>{`$${tokenBalance.toFixed(2)} - on ${tokenNetwork}`}</TokenPrice>
        <FromTokenImg src={tokenNetworkIcon} />
      </TokenWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const TokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const TokenImg = styled.img`
  width: 30px;
  height: 30px;
`;
const FromTokenImg = styled.img`
  width: 14px;
  height: 14px;
  position: relative;
  margin-left: 5px;
`;

const InputTokenSymbol = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #9aa6cf;
  margin-left: 10px;
`;

const Price = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
  margin-left: 10px;
`;

const TokenPrice = styled.div`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #9aa6cf;
`;
