import {PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {LIQ_HOLDING_CONTRACTS} from 'src/constants/holdings';
import {getLiqHoldings} from 'src/utils/farm-holding';

interface LiqHoldingsContextValue {
  holdingInfo?: number[];
  feeMode?: number;
  userHolding?: number;
  feePercent?: number;
  liqBoost?: number;
  nextStepPrice: number;
  nextStepProgress: number;
  refreshLiqHoldings: () => void;
}

export const LiqHoldingsContext = createContext<LiqHoldingsContextValue>({
  holdingInfo: [],
  feeMode: 0,
  userHolding: 0,
  feePercent: 0,
  liqBoost: undefined,
  nextStepPrice: undefined,
  nextStepProgress: 0,
  refreshLiqHoldings: () => null,
});

// DisplayName is used by React context Devtool to debug the state
LiqHoldingsContext.displayName = 'LigHoldingsContext';

export const LigHoldingsContextProvider = (props: PropsWithChildren) => {
  const {account, chainId, provider} = useWeb3React();

  const [holdingInfo, setHoldingInfo] = useState([]);
  const [userHolding, setUserholding] = useState(0);

  const refreshHoldings = useCallback(async () => {
    if (account) {
      try {
        const holdingContract = LIQ_HOLDING_CONTRACTS.find((item) => item.netId === chainId);
        if (holdingContract) {
          const result = await getLiqHoldings(account, holdingContract.address, 18, provider);
          if (result) {
            setHoldingInfo(result);
            setUserholding(result[0]);
          } else {
            setHoldingInfo([]);
            setUserholding(0);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [account, chainId, provider]);

  useEffect(() => {
    refreshHoldings();
  }, [refreshHoldings]);

  const feeMode = useMemo(() => {
    if (holdingInfo && holdingInfo.length > 0) {
      const _silver = holdingInfo[9];
      const _gold = holdingInfo[10];
      const _titan = holdingInfo[11];
      if (userHolding < _silver) {
        return 0;
      } else if (userHolding < _gold) {
        return 1;
      } else if (userHolding < _titan) {
        return 2;
      } else {
        return 3;
      }
    }
    return 0;
  }, [userHolding, holdingInfo]);

  const feePercent = useMemo(() => {
    if (holdingInfo && holdingInfo.length > 0) {
      const _percentage = holdingInfo[1 + feeMode];
      return Number((_percentage / 100).toFixed(2));
    }
    return 0;
  }, [feeMode, holdingInfo]);
  const liqBoost = useMemo(() => {
    if (holdingInfo && holdingInfo.length > 0) {
      return holdingInfo[5 + feeMode];
    }
    return 0;
  }, [feeMode, holdingInfo]);

  const nextStepPrice = useMemo(() => {
    if (holdingInfo && holdingInfo.length > 0) {
      if (feeMode < 3) {
        return holdingInfo[9 + feeMode];
      }
      return holdingInfo[11];
    }
    return 0;
  }, [feeMode, holdingInfo]);

  const nextStepProgress = useMemo(() => {
    if (nextStepPrice > 0) {
      return (userHolding * 100) / nextStepPrice;
    }
    return 0;
  }, [userHolding, nextStepPrice]);

  const values = useMemo(
    () => ({
      holdingInfo,
      feeMode,
      userHolding,
      feePercent,
      liqBoost,
      nextStepPrice,
      nextStepProgress,
      refreshLiqHoldings: refreshHoldings,
    }),
    [holdingInfo, feeMode, userHolding, feePercent, liqBoost, nextStepPrice, nextStepProgress, refreshHoldings],
  );

  return <LiqHoldingsContext.Provider value={values}>{props.children}</LiqHoldingsContext.Provider>;
};

export const useLiqHoldings = () => useContext(LiqHoldingsContext);
