import * as React from 'react';

import {COLORS} from 'src/styles/colors';
import {IconType} from 'src/types';

export const CopyPlusIcon = ({size = 22, color = COLORS.GRAY_LIGHT, ...props}: IconType) => (
  <svg width={size} height={size} viewBox='0 0 22 22' fill='none' {...props}>
    <g clipPath='url(#clip0_1755_13062)'>
      <path
        d='M13.6552 15.2323C13.6552 15.2835 13.6349 15.3326 13.5987 15.3688C13.5625 15.4049 13.5134 15.4253 13.4623 15.4253H6.76768C6.71651 15.4253 6.66743 15.4049 6.63125 15.3688C6.59507 15.3326 6.57475 15.2835 6.57475 15.2323V8.53776C6.57475 8.48659 6.59507 8.43751 6.63125 8.40133C6.66743 8.36515 6.71651 8.34483 6.76768 8.34483H13.457C13.5081 8.34483 13.5572 8.36515 13.5934 8.40133C13.6296 8.43751 13.6499 8.48659 13.6499 8.53776L13.6552 15.2323ZM13.4623 7.63678H6.76768C6.52872 7.63678 6.29956 7.73171 6.1306 7.90068C5.96163 8.06964 5.8667 8.29881 5.8667 8.53776V15.2323C5.86717 15.471 5.96231 15.6997 6.13122 15.8683C6.30014 16.0369 6.52903 16.1316 6.76768 16.1316H13.457C13.6956 16.1316 13.9245 16.0369 14.0934 15.8683C14.2624 15.6997 14.3575 15.471 14.3579 15.2323V8.53776C14.3579 8.29881 14.263 8.06964 14.094 7.90068C13.9251 7.73171 13.6959 7.63678 13.457 7.63678H13.4623Z'
        fill={color}
      />
      <path
        d='M15.2273 5.86667H8.53273C8.43884 5.86667 8.34878 5.90395 8.28239 5.97034C8.21599 6.03674 8.17871 6.1268 8.17871 6.22069C8.17871 6.31458 8.21599 6.40463 8.28239 6.47102C8.34878 6.53741 8.43884 6.57471 8.53273 6.57471H15.2273C15.2785 6.57471 15.3275 6.59504 15.3637 6.63122C15.3999 6.6674 15.4202 6.71648 15.4202 6.76765V13.4622C15.4202 13.5561 15.4575 13.6462 15.5239 13.7126C15.5903 13.779 15.6804 13.8162 15.7743 13.8162C15.8682 13.8162 15.9582 13.779 16.0246 13.7126C16.091 13.6462 16.1283 13.5561 16.1283 13.4622V6.76765C16.1283 6.52869 16.0334 6.29953 15.8644 6.13057C15.6954 5.9616 15.4663 5.86667 15.2273 5.86667Z'
        fill={color}
      />
      <path
        d='M12.0622 11.531H10.4691V9.93795C10.4691 9.84405 10.4318 9.75399 10.3654 9.6876C10.299 9.62121 10.209 9.58392 10.1151 9.58392C10.0212 9.58392 9.93118 9.62121 9.86479 9.6876C9.7984 9.75399 9.76109 9.84405 9.76109 9.93795V11.531H8.16799C8.0741 11.531 7.98405 11.5683 7.91766 11.6347C7.85127 11.7011 7.81396 11.7912 7.81396 11.8851C7.81396 11.979 7.85127 12.069 7.91766 12.1354C7.98405 12.2018 8.0741 12.2391 8.16799 12.2391H9.76109V13.8322C9.76109 13.9261 9.7984 14.0161 9.86479 14.0825C9.93118 14.1489 10.0212 14.1862 10.1151 14.1862C10.209 14.1862 10.299 14.1489 10.3654 14.0825C10.4318 14.0161 10.4691 13.9261 10.4691 13.8322V12.2391H12.0622C12.1561 12.2391 12.2462 12.2018 12.3126 12.1354C12.379 12.069 12.4163 11.979 12.4163 11.8851C12.4163 11.7912 12.379 11.7011 12.3126 11.6347C12.2462 11.5683 12.1561 11.531 12.0622 11.531Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_1755_13062'>
        <rect width='10.2667' height='10.2667' fill='white' transform='translate(5.8667 5.86667)' />
      </clipPath>
    </defs>
  </svg>
);

export default CopyPlusIcon;
