import {PropsWithChildren, useMemo} from 'react';

import {Web3ReactHooks, Web3ReactProvider} from '@web3-react/core';
import {Connector} from '@web3-react/types';
import {useOrderedConnections} from 'src/hooks';
import useEagerlyConnect from 'src/hooks/useEagerlyConnect';

export const Web3Provider = ({children}: PropsWithChildren) => {
  useEagerlyConnect();
  const connections = useOrderedConnections();
  const connectors: [Connector, Web3ReactHooks][] = connections.map(({hooks, connector}) => [connector, hooks]);
  const key = useMemo(() => connections.map((connection) => connection.name).join('-'), [connections]);
  return (
    <Web3ReactProvider connectors={connectors} key={key}>
      {children}
    </Web3ReactProvider>
  );
};
