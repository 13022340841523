import {gql} from '@apollo/client';

export const GET_WHITELIST_TOKENS = gql`
  query tokens($skip: Float, $limit: Float) {
    tokens(pagination: {skip: $skip, limit: $limit}) {
      globalName
      interfaceDecimals
      priceDecimals
      isWhitelisted
      coinGeckoId
      marketCap
      iconUrl
      chainSpecifics {
        name
        symbol
        decimals
        address {
          hash
          chainId
        }
        priceUSD {
          decimals
          value
        }
      }
    }
  }
`;
