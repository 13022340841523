import {gql} from '@apollo/client';

export const LAST_TRANSACTION = gql`
  query GetWalletTokenTransfers($address: String!, $chainId: Float!, $limit: Float, $cursor: String) {
    getWalletTokenTransfers(address: $address, chainId: $chainId, limit: $limit, cursor: $cursor) {
      page
      page_size
      cursor
      result {
        token_name
        token_symbol
        token_logo
        token_decimals
        from_address
        from_address_label
        to_address
        to_address_label
        address
        block_hash
        block_number
        block_timestamp
        transaction_hash
        transaction_index
        log_index
        value
        possible_spam
        value_decimal
      }
    }
  }
`;
