import React from 'react';

import {CloseIcon} from 'src/components/Svgs';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

interface TokenCloseItemProps {
  onCloseButton?: () => void | Promise<void>;
}

export const TokenCloseItem = ({onCloseButton}: TokenCloseItemProps) => {
  return (
    <IconButton onClick={onCloseButton}>
      <CloseIcon color={COLORS.PRIMARY} />
    </IconButton>
  );
};

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
