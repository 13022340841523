import {gql} from '@apollo/client';

export const CREATE_T_AND_C_AGREEMENT = gql`
  mutation CreateTandCAgreement($data: CreateTandCAgreementInput!) {
    createTandCAgreement(data: $data) {
      accountHash
      signDate
      signature
      originalMessage
    }
  }
`;
