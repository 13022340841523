import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type SuccessTopupProps = {
  lpToken1?: IWhitelistToken;
  lpToken2?: IWhitelistToken;
  lpToken1Amount?: number;
  lpToken2Amount?: number;
  outputToken?: IWhitelistToken;
  outputTokenAmount?: number;
  keepTokensMode?: boolean;
  totalUsdAmount?: number;
  onDone?: () => void;
};

export const SuccessTopup = ({
  lpToken1,
  lpToken2,
  lpToken1Amount,
  lpToken2Amount,
  outputToken,
  outputTokenAmount,
  keepTokensMode,
  totalUsdAmount,
  onDone,
}: SuccessTopupProps) => {
  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        {!keepTokensMode ? (
          <>
            <TokenItem token={lpToken1} value={lpToken1Amount?.toFixed(lpToken1?.interfaceDecimals)} />
            <StyledTokenItem token={lpToken2} value={lpToken2Amount?.toFixed(lpToken2?.interfaceDecimals)} />
          </>
        ) : (
          <StyledRow marginTop={12}>
            <TokenBox>
              <TokenIcon width={24} height={24} token={lpToken1} />
              <SecondTokenIcon width={24} height={24} token={lpToken2} />
            </TokenBox>
            <BodyVariant color={COLORS.PRIMARY}>
              {lpToken1?.symbol}-{lpToken2?.symbol}
            </BodyVariant>
          </StyledRow>
        )}
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        {!keepTokensMode ? (
          <StyledTokenItem token={outputToken} value={outputTokenAmount?.toFixed(outputToken?.interfaceDecimals)} />
        ) : (
          <>
            <StyledTokenItem token={lpToken1} value={lpToken1Amount?.toFixed(lpToken1?.interfaceDecimals)} />
            <StyledTokenItem token={lpToken2} value={lpToken2Amount?.toFixed(lpToken2?.interfaceDecimals)} />
          </>
        )}
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Done' onClick={onDone} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const StyledRow = styled.div<{marginTop?: number}>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: 10px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
