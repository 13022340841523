import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type DisconnectIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const DisconnectIcon = ({size = 24, color = COLORS.PRIMARY, ...props}: DisconnectIconType) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    {...props}
  >
    <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
    <polyline points='16 17 21 12 16 7'></polyline>
    <line x1='21' y1='12' x2='9' y2='12'></line>
  </svg>
);

export default DisconnectIcon;
