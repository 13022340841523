import * as React from 'react';

import {COLORS} from 'src/styles/colors';
import {IconType} from 'src/types';

export const FeeIcon = ({size = 22, color = COLORS.SECONDARY, ...props}: IconType) => (
  <svg width={size} height={size} viewBox='0 0 22 19' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.6875 16.1741C5.41964 17.8527 7.72408 18.7778 10.1723 18.7778C12.666 18.7778 14.9991 17.825 16.7401 16.0939C16.9294 15.9067 16.9249 15.6037 16.7321 15.4165C16.5383 15.2326 16.2258 15.237 16.0321 15.4242C14.4767 16.9673 12.3982 17.8165 10.1722 17.8165C5.65787 17.8208 1.98723 14.2635 1.98723 9.88886C1.98723 5.51424 5.65787 1.95706 10.1722 1.95706C14.6865 1.95706 18.3571 5.51446 18.3571 9.88886L18.3571 9.98896L17.5419 9.19932C17.4473 9.10698 17.3205 9.05951 17.1928 9.05951C17.0652 9.05951 16.9419 9.10698 16.8428 9.19932C16.6491 9.38659 16.6491 9.68952 16.8428 9.87678L18.4973 11.4837C18.6902 11.6718 19.0036 11.6718 19.1964 11.4837L20.8554 9.87678C21.0482 9.6895 21.0482 9.38658 20.8554 9.19932C20.6616 9.01119 20.3491 9.01119 20.1554 9.19932L19.3402 9.98896L19.3402 9.88886C19.3402 7.51652 18.3857 5.28293 16.6535 3.60365C14.9214 1.92509 12.617 0.999995 10.1678 0.999995C7.71957 0.999995 5.4151 1.92514 3.68298 3.60364C1.95084 5.28306 1 7.51652 1 9.88886C1 12.2612 1.95447 14.4948 3.68755 16.1741L3.6875 16.1741Z'
      fill={color}
      stroke={color}
      strokeWidth='0.2'
    />
    <path
      d='M8.34159 10.3599C7.88529 10.3599 7.51196 10.2059 7.22159 9.89771C6.93714 9.58363 6.79492 9.16585 6.79492 8.64437C6.79492 8.12289 6.93714 7.70808 7.22159 7.39993C7.51196 7.08585 7.88529 6.92882 8.34159 6.92882C8.79788 6.92882 9.16825 7.08585 9.4527 7.39993C9.73714 7.70808 9.87937 8.12289 9.87937 8.64437C9.87937 9.16585 9.73714 9.58363 9.4527 9.89771C9.16825 10.2059 8.79788 10.3599 8.34159 10.3599ZM12.0483 6.99993H13.106L8.85714 13.2221H7.79937L12.0483 6.99993ZM8.34159 9.63104C8.54307 9.63104 8.70011 9.54808 8.8127 9.38215C8.93122 9.21622 8.99048 8.9703 8.99048 8.64437C8.99048 8.31845 8.93122 8.07252 8.8127 7.90659C8.70011 7.74067 8.54307 7.65771 8.34159 7.65771C8.14603 7.65771 7.989 7.74363 7.87048 7.91548C7.75196 8.08141 7.6927 8.32437 7.6927 8.64437C7.6927 8.96437 7.75196 9.2103 7.87048 9.38215C7.989 9.54808 8.14603 9.63104 8.34159 9.63104ZM12.5638 13.2933C12.2675 13.2933 12.0008 13.2251 11.7638 13.0888C11.5327 12.9466 11.352 12.7451 11.2216 12.4844C11.0912 12.2236 11.026 11.9214 11.026 11.5777C11.026 11.234 11.0912 10.9318 11.2216 10.671C11.352 10.4103 11.5327 10.2118 11.7638 10.0755C12.0008 9.93326 12.2675 9.86215 12.5638 9.86215C13.0201 9.86215 13.3905 10.0192 13.6749 10.3333C13.9653 10.6414 14.1105 11.0562 14.1105 11.5777C14.1105 12.0992 13.9653 12.517 13.6749 12.831C13.3905 13.1392 13.0201 13.2933 12.5638 13.2933ZM12.5638 12.5644C12.7653 12.5644 12.9223 12.4814 13.0349 12.3155C13.1534 12.1436 13.2127 11.8977 13.2127 11.5777C13.2127 11.2577 13.1534 11.0147 13.0349 10.8488C12.9223 10.677 12.7653 10.591 12.5638 10.591C12.3683 10.591 12.2112 10.674 12.0927 10.8399C11.9742 11.0059 11.9149 11.2518 11.9149 11.5777C11.9149 11.9036 11.9742 12.1496 12.0927 12.3155C12.2112 12.4814 12.3683 12.5644 12.5638 12.5644Z'
      fill={color}
    />
  </svg>
);

export default FeeIcon;
