import {BigNumber} from 'ethers';

export type ITokenAddress = {
  hash: string;
  chainId: number;
};

export type IPrice = {
  value: number;
  decimals: number;
};

export type IChainSpecific = {
  name: string;
  symbol: string;
  decimals: number;
  address: ITokenAddress;
  priceUSD?: IPrice;
};

export type IWhitelistTokenItem = {
  interfaceDecimals: number;
  priceDecimals?: number;
  lastPriceUpdate?: number;
  globalName: string;
  coinGeckoId?: string;
  marketCap?: number;
  chainSpecifics: IChainSpecific[];
  iconUrl?: string;
};

export type ITokenSearchData = {
  tokenSearch: IWhitelistTokenItem[];
};

export type IWhitelisttokenData = {
  tokens: IWhitelistTokenItem[];
};

export type IWhitelistToken = {
  globalName: string;
  name: string;
  symbol: string;
  decimals: number;
  priceUSD?: IPrice;
  interfaceDecimals: number;
  priceDecimals?: number;
  balance: BigNumber;
  coinGeckoId?: string;
  marketCap?: number;
  chainSpecifics?: IChainSpecific[];
  address?: string;
  chainId?: number;
  customToken?: boolean;
  testID?: string;
  iconUrl?: string;
  isNative?: boolean;
};

// don't change existing enum values because old transactions at history screen will be missed becasued they are stored in local storage
export enum TransactionType {
  Send = 'Send',
  Swap = 'Swap',
  HarvestSingleFarm = 'HarvestSingleFarm',
  HarvestFarm = 'HarvestFarm',
  ZapIn = 'ZapIn',
  DepositWithZapIn = 'DepositAndZapIn',
  ZapOut = 'ZapOut',
  DepositSingleFarm = 'Deposit',
  WithdrawSingleFarm = 'Withdraw',
  DepositLpTokens = 'DepositLpTokens',
}

export enum TransactionStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  Canceled = 'Canceled',
}

export const WRAPPED_TOKEN_LIST: {[key: string]: string} = {
  BNB: 'WBNB',
  ETH: 'WETH',
  CRO: 'WCRO',
  MATIC: 'WMATIC',
};

export const TOKEN_BOUNDARY_BALANCE = 25;
