import {useMemo} from 'react';

import {useUserSignature} from 'src/hooks/user-signature';
import {useTandCAgreement} from 'src/hooks/user-signature/useTandCAgreement';

export const useAgreementCheck = () => {
  const {agreementExists} = useTandCAgreement();
  const {openUserSignature} = useUserSignature();

  const proceed = useMemo(() => agreementExists === true, [agreementExists]);

  const check = (prevfn?: () => void) => {
    if (!proceed) {
      openUserSignature(prevfn);
    } else prevfn();
  };

  return {check, proceed};
};
