import {gql} from '@apollo/client';

export const TOKEN_SEARCH = gql`
  query tokenSearch($term: String!) {
    tokenSearch(term: $term) {
      globalName
      chainSpecifics {
        name
        symbol
        decimals
        address {
          hash
          chainId
        }
        priceUSD {
          value
          decimals
        }
      }
      interfaceDecimals
      priceDecimals
    }
  }
`;
