import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type FilterIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const FilterIcon = ({size = 24, color = COLORS.PRIMARY, ...props}: FilterIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 18' fill='none' {...props}>
    <path
      d='M14.5312 0C15.6094 0 16.5 0.890625 16.5 1.96875C16.5 2.4375 16.3125 2.90625 15.9375 3.28125L11.5781 7.96875V16.5C11.5781 17.3438 10.9219 18 10.0781 18C9.75 18 9.46875 17.9531 9.23438 17.7656L5.67188 15.4219C5.15625 15.0938 4.875 14.4844 4.875 13.875V7.96875L0.515625 3.28125C0.140625 2.90625 0 2.4375 0 1.96875C0 0.890625 0.84375 0 1.92188 0H14.5312ZM7.07812 7.5V13.6875L9.32812 15.1406V7.5C9.32812 7.21875 9.46875 6.98438 9.65625 6.75L13.8281 2.25H2.625L6.79688 6.75C6.98438 6.98438 7.07812 7.21875 7.07812 7.5ZM22.875 15.375C23.4844 15.375 24 15.8906 24 16.5C24 17.1562 23.4844 17.625 22.875 17.625H16.125C15.4688 17.625 15 17.1562 15 16.5C15 15.8906 15.4688 15.375 16.125 15.375H22.875ZM15 9C15 8.39062 15.4688 7.875 16.125 7.875H22.875C23.4844 7.875 24 8.39062 24 9C24 9.65625 23.4844 10.125 22.875 10.125H16.125C15.4688 10.125 15 9.65625 15 9ZM22.875 0.375C23.4844 0.375 24 0.890625 24 1.5C24 2.15625 23.4844 2.625 22.875 2.625H19.125C18.4688 2.625 18 2.15625 18 1.5C18 0.890625 18.4688 0.375 19.125 0.375H22.875Z'
      fill={color}
    />
  </svg>
);

export default FilterIcon;
