import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type BackwardIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const BackwardIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: BackwardIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 22' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.42694 13.4055L10.7743 21.0539C11.028 21.2612 11.3793 21.304 11.6758 21.1636C11.9723 21.0233 12.1619 20.724 12.1619 20.3959V16.9123C24.0783 15.4233 23.201 1.77832 23.1908 1.63794C23.1629 1.26895 22.8998 0.95944 22.5392 0.873949C22.1786 0.787512 21.805 0.945515 21.6144 1.26338C17.5155 8.09762 13.6202 8.66914 12.1619 8.61347V5.1001C12.1619 4.77201 11.9723 4.47273 11.6758 4.33237C11.3793 4.19203 11.028 4.23478 10.7743 4.44205L1.42694 12.0905C1.2299 12.2522 1.11465 12.493 1.11465 12.7485C1.11465 13.0032 1.2299 13.2449 1.42694 13.4057V13.4055Z'
      stroke={color}
      strokeLinejoin='round'
    />
  </svg>
);

export default BackwardIcon;
