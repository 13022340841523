import React from 'react';

import {Route} from '@lifi/sdk';
import TickCircleGreen from 'src/assets/images/tiers/tick-circle-green.svg';
import {NETWORKS} from 'src/constants';
import {COLORS} from 'src/styles';
import {getTokenIcon} from 'src/utils/icon-util';
import styled from 'styled-components';

import {BodyVariant} from '../Typography';

type Size = 'default' | 'small';

interface BridgeCardPropType {
  isClicked: boolean;
  data: Route;
  onClick: (data: Route) => void;
}

export const BridgeCard = ({isClicked, onClick, data}: BridgeCardPropType) => {
  const {toAmount, toToken, tags, toAmountUSD, steps, gasCostUSD} = data;
  const {logoURI: toTokenLogo, chainId: toChainId, symbol: toTokenSymbol, decimals: toTokenDecimal} = toToken;
  const toNetWorkName = NETWORKS[toChainId].name;
  const {name: bridgeType, logoURI: bridgeImg} = steps[0].toolDetails;
  const {executionDuration} = steps[0].estimate;
  const toTokenBalance = parseInt(toAmount) / Math.pow(10, toTokenDecimal);
  const tokenLogo = getTokenIcon(toToken?.symbol) || toTokenLogo;
  return (
    <BridgeCardWrapper
      activeBorder={isClicked}
      onClick={() => {
        onClick(data);
      }}
    >
      {isClicked && <RibbonImage src={TickCircleGreen} />}
      <CardHeader>
        <TokenInfo>
          <StyledImage src={tokenLogo} />
          <BodyVariant color={COLORS.GRAY_LIGHT}>{toToken?.symbol}</BodyVariant>
          <PriceWrapper>{toTokenBalance.toFixed(4)}</PriceWrapper>
        </TokenInfo>
        {tags.length > 0 && (
          <BridgeOptionWrapper>
            <BadgeWrapper>{tags[0]}</BadgeWrapper>
          </BridgeOptionWrapper>
        )}
      </CardHeader>
      <TokenPriceWrapper>
        <TokenPriceText>{`$${toAmountUSD} - ${toTokenSymbol} on ${toNetWorkName}`}</TokenPriceText>
      </TokenPriceWrapper>
      <TokenInfo>
        <StyledImage src={bridgeImg} />
        <BridgeCardText>Bridge</BridgeCardText>
        <BridgeType>{bridgeType}</BridgeType>
      </TokenInfo>
      <TextWrapper>Estimated Fees: ${gasCostUSD}</TextWrapper>
      <TextWrapper>Duration: {Math.ceil(executionDuration / 60)}m</TextWrapper>
    </BridgeCardWrapper>
  );
};

const BridgeCardWrapper = styled.div<{activeBorder: boolean}>`
  min-width: 381px;
  height: 150px;
  margin-top: 24px;
  margin-right: 20px;
  position: relative;
  padding: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
  border: 2px solid ${(props) => (props.activeBorder ? ' rgb(23, 231, 214)' : 'transparent;')};
  display: flex;
  flex-direction: column;
  &:hover {
    border: 2px rgb(23, 231, 214) solid;
  }
`;

const StyledImage = styled.img<{size?: Size}>`
  width: ${(props) => (props.size === 'default' ? 30 : 20)}px;
  height: ${(props) => (props.size === 'default' ? 30 : 20)}px;
  margin-right: 8px;
  border-radius: 10px;
`;

const RibbonImage = styled.img`
  width: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
`;

const PriceWrapper = styled(BodyVariant)`
  //styleName: Body Bold;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
  margin-left: 5px;
`;

const BridgeCardText = styled(BodyVariant)`
  //styleName: Small Body Bold;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #9aa6cf;
`;

const TextWrapper = styled.div`
  //styleName: Small;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #9aa6cf;
  margin-bottom: 5px;
`;

const BadgeWrapper = styled.p`
  //styleName: Small Body;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
  margin: 0;
  background: #17e7d6b2;
  padding: 3px 14px;
  border-radius: 14px;
`;

const TokenInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BridgeOptionWrapper = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
`;
const TokenPriceWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const TokenPriceText = styled(BodyVariant)`
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #9aa6cf;
  margin-left: 38px;
`;

const BridgeType = styled(BodyVariant)`
  //styleName: Small Body Bold;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
  margin-left: 10px;
  font-weight: 600;
`;
