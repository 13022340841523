import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ReceiveIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ReceiveIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: ReceiveIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M18.3984 12.8691L16.8496 6.57227C16.748 6.19141 16.418 5.9375 16.0625 5.9375H7.9375C7.55664 5.9375 7.22656 6.19141 7.125 6.54688L5.57617 12.8691C5.52539 13.123 5.5 13.4023 5.5 13.6562V15.6875C5.5 16.6016 6.21094 17.3125 7.125 17.3125H16.875C17.7637 17.3125 18.5 16.6016 18.5 15.6875V13.6562C18.5 13.4023 18.4492 13.123 18.3984 12.8691ZM8.24219 7.15625H15.7324L17.0273 12.4375H14.8438C14.5898 12.4375 14.3867 12.5898 14.2852 12.793L13.6504 14.0625H10.3242L9.68945 12.793C9.58789 12.5898 9.38477 12.4375 9.15625 12.4375H6.94727L8.24219 7.15625ZM17.2812 15.6875C17.2812 15.916 17.0781 16.0938 16.875 16.0938H7.125C6.89648 16.0938 6.71875 15.916 6.71875 15.6875V13.6562H8.77539L9.41016 14.9512C9.51172 15.1543 9.71484 15.2812 9.96875 15.2812H14.0312C14.2598 15.2812 14.4629 15.1543 14.5645 14.9512L15.1992 13.6562H17.2812V15.6875Z'
      fill={color}
    />
  </svg>
);

export default ReceiveIcon;
