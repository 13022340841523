import {gql} from '@apollo/client';

export const GRIZLYFI_FARM = gql`
  query grizlyfiFarms {
    grizlyfiFarms {
      apy
      tvl {
        decimals
        value
      }
      baseAPR
      hotness
      contractAddress {
        hash
        chainId
      }
      token0Hash
      token1Hash
      token0Symbol
      token1Symbol
      platform {
        name
        slug
        iconUrl
      }
      hotness
    }
  }
`;
