import {useCallback, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import LiquidusAutoLPFarmInABI from 'src/abis/LiquidusAutoLPFarmInABI.json';
import {getChainInfo} from 'src/constants';
import {ZapInCall} from 'src/types/topup';
// import {useGasFee} from './useGasFee';

export interface FeeConfig {
  chargeFeeBy: 'currency_in' | 'currency_out';
  feeReceiver: string;
  isInBps: boolean;
  feeAmount: string;
}

export enum ZapInCallbackState {
  INVALID,
  LOADING,
  VALID,
}

export default function isZero(hexNumberString: string) {
  return /^0x0*$/.test(hexNumberString);
}

export function useZapInCallback(): {
  state: ZapInCallbackState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  estimateGas: null | ((zapInCall: ZapInCall) => any); // wait is not present on unknown
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: null | ((zapInCall: ZapInCall) => Promise<any>); // wait is not present on unknown
  error: string | null;
} {
  const {chainId, account, provider} = useWeb3React();
  // const {createGasSettings} = useGasFee();
  const getZapInEstimatedGas = useCallback(
    async (zapInCall: ZapInCall) => {
      const chainInfo = getChainInfo(chainId);
      if (!chainInfo) {
        return null;
      }
      const liquidusAutoLPFarmInAddress = chainInfo.liquidusAutoLPFarmInAddress;
      const walletSigner = provider.getSigner(account);
      const contract = new ethers.Contract(liquidusAutoLPFarmInAddress, LiquidusAutoLPFarmInABI, walletSigner);
      const gasLimit = zapInCall.value
        ? await contract.estimateGas.zapIn(
            [
              zapInCall._inTokens,
              zapInCall._inAmounts,
              zapInCall._toTokens,
              zapInCall.swaps,
              zapInCall.addLiquidityCall,
              zapInCall._lpAddress,
            ],
            {value: zapInCall.value},
          )
        : await contract.estimateGas.zapIn(zapInCall);
      return gasLimit;
    },
    [account, chainId, provider],
  );

  const zapIn = useCallback(
    async (zapInCall: ZapInCall) => {
      const walletSigner = provider.getSigner(account);
      const chainInfo = getChainInfo(chainId);
      if (!chainInfo) {
        return null;
      }
      const liquidusAutoLPFarmInAddress = chainInfo.liquidusAutoLPFarmInAddress;
      const contract = new ethers.Contract(liquidusAutoLPFarmInAddress, LiquidusAutoLPFarmInABI, walletSigner);
      // const gasSettings = createGasSettings(bscMode, account, gasPrice, maxPriorityFee, maxFee, gasLimit);
      const gasSettings = {};

      const value = zapInCall.value;
      let payload;
      if (value) {
        payload = {...gasSettings, value};
      } else {
        payload = gasSettings;
      }
      const result = await contract.zapIn(
        [
          zapInCall._inTokens,
          zapInCall._inAmounts,
          zapInCall._toTokens,
          zapInCall.swaps,
          zapInCall.addLiquidityCall,
          zapInCall._lpAddress,
        ],
        payload,
      );
      return result;

      // if (e.code === 'INSUFFICIENT_FUNDS') {
      //   Toast.show({
      //     type: 'error',
      //     text1: 'Native token balance is too low to cover gas fees for zap in.',
      //   });
      // }
    },
    [account, chainId, provider],
  );
  return useMemo(() => {
    if (!chainId || !account) {
      return {
        state: ZapInCallbackState.INVALID,
        estimateGas: null,
        callback: null,
        error: 'Missing dependencies',
      };
    }

    const estimateGas = async (zapInCall: ZapInCall) => {
      const gasLimit = await getZapInEstimatedGas(zapInCall);
      return gasLimit;
    };
    const onZapIn = async (zapInCall: ZapInCall): Promise<string> => {
      const hash = await zapIn(zapInCall);
      if (hash === undefined) {
        throw new Error('Transaction returned undefined.');
      }
      return hash;
    };

    return {
      state: ZapInCallbackState.VALID,
      estimateGas: estimateGas,
      callback: onZapIn,
      error: null,
    };
  }, [account, chainId, getZapInEstimatedGas, zapIn]);
}
