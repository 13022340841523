import {createSlice} from '@reduxjs/toolkit';
import {Wallet} from 'src/types';

export interface WalletState {
  connectedWallets: Wallet[];
}

const initialState: WalletState = {
  connectedWallets: [],
};

export const walletSlice = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    addConnectedWallet(state, {payload}) {
      const existsAlready = state.connectedWallets.find((wallet) => wallet.account === payload?.account);
      if (!existsAlready) {
        state.connectedWallets = state.connectedWallets.concat(payload);
      }
    },
    updateWallet(state, {payload}) {
      const existingWallet = state.connectedWallets.find((wallet) => wallet.account === payload?.account);
      if (existingWallet) {
        existingWallet.accountName = payload?.accountName;
        existingWallet.avatarIndex = payload?.avatarIndex;
        existingWallet.avatarType = payload?.avatarType;
      } else {
        state.connectedWallets = state.connectedWallets.concat(payload);
      }
    },
    removeConnectedWallet(state, {payload}) {
      state.connectedWallets = state.connectedWallets.filter((wallet) => wallet.account !== payload?.account);
    },
  },
});
