import React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';
import {DEVICE_ENUM, HEADING_FONT_ENUM, HeadingFontScale, HeadingFontScaleType} from 'src/styles/size';
import styled from 'styled-components';

export interface HeadingType extends React.HTMLAttributes<HTMLHeadingElement> {
  size?: HEADING_FONT_ENUM;
  lg?: HEADING_FONT_ENUM;
  mobile?: HEADING_FONT_ENUM;
  color?: ColorsTypes;
  children: string | React.ReactNode;
}

export const Heading = ({
  size = HEADING_FONT_ENUM.H1,
  lg = HEADING_FONT_ENUM.H2,
  mobile = HEADING_FONT_ENUM.H3,
  children,
  color = COLORS.BLACK,
  ...props
}: HeadingType) => {
  return (
    <StyledHeading
      color={color}
      size={HeadingFontScale[size]}
      lg={HeadingFontScale[lg]}
      mobile={HeadingFontScale[mobile]}
      {...props}
    >
      {children}
    </StyledHeading>
  );
};

export default Heading;

const StyledHeading = styled.h1<{
  size: HeadingFontScaleType;
  lg: HeadingFontScaleType;
  mobile: HeadingFontScaleType;
  color: ColorsTypes;
}>`
  color: ${(props) => props.color};
  font-family: ${(props) => props.size.fontFamily};
  font-size: ${(props) => props.size.fontSize}px;
  line-height: ${(props) => props.size.lineHeight}px;
  font-weight: ${(props) => props.size.fontWeight};
  margin: 0;

  @media (max-width: ${DEVICE_ENUM.lg}) {
    font-family: ${(props) => props.lg.fontFamily};
    font-size: ${(props) => props.lg.fontSize}px;
    line-height: ${(props) => props.lg.lineHeight}px;
    font-weight: ${(props) => props.lg.fontWeight};
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    font-family: ${(props) => props.mobile.fontFamily};
    font-size: ${(props) => props.mobile.fontSize}px;
    line-height: ${(props) => props.mobile.lineHeight}px;
    font-weight: ${(props) => props.mobile.fontWeight};
  }
`;
