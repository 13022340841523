import React from 'react';

import YieldInfoIcon from 'src/assets/images/icons/yieldInfo-icon.svg';
import {Button} from 'src/components/Buttons';
import {useModals} from 'src/contexts/modals';
import {COLORS} from 'src/styles';
import {ILiquidityPool, IYieldFarm} from 'src/types';
import styled from 'styled-components';

import {Modal} from './Modal';
import {BodyVariant} from '../Typography';

type YieldInformationModalProps = {
  isOpen: boolean;
  farm?: IYieldFarm;
  lp?: ILiquidityPool;
  isPool?: boolean;
};

export const YieldInfoModal = ({isOpen, farm, lp, isPool}: YieldInformationModalProps) => {
  const modalContext = useModals();
  const onDismiss = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateYieldInfoModal', payload});
  };

  const farmApy =
    farm?.apy == null && lp?.poolApy == null ? 'N/A' : `${isPool ? lp.poolApy.toFixed(1) : farm.apy.toFixed(1)}% APY`;

  const lpFees =
    farm?.liquidityPool?.poolRewards == null && lp?.poolRewards == null
      ? 'N/A'
      : `${isPool ? lp?.poolRewards.toFixed(1) : farm?.liquidityPool?.poolRewards.toFixed(1)}% APR`;

  const farmingIncentive =
    farm?.baseAPR === undefined || farm?.baseAPR === null ? 'N/A' : `${farm?.baseAPR.toFixed(1)}% APR`;

  const combinedAPR =
    lpFees !== 'N/A' || farmingIncentive !== 'N/A'
      ? `${(
          Number(
            farm?.liquidityPool?.poolRewards
              ? farm?.liquidityPool?.poolRewards.toFixed(1)
              : isPool
              ? lp?.poolRewards.toFixed(1)
              : 0,
          ) + Number(farm?.baseAPR ? farm?.baseAPR : 0)
        ).toFixed(1)}% APR`
      : 'N/A';

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <BodyVariant>
            {`Yield Info${
              (
                isPool
                  ? lp?.token0Symbol && lp?.token1Symbol // check if there is info at the lp and farm
                  : farm?.liquidityPool?.token0Symbol && farm?.liquidityPool?.token1Symbol
              )
                ? ` - ${isPool ? lp?.token0Symbol : farm?.liquidityPool?.token0Symbol} - ${
                    isPool ? lp?.token1Symbol : farm?.liquidityPool?.token1Symbol // display token 1 and 2 if there was info
                  }`
                : ` - ${farm?.stakedTokenChainSpecifics?.symbol}` // else display the singleFarm info (if not pool then its singleFarm)
            }`}
          </BodyVariant>
          <StyledImage src={YieldInfoIcon} />
          <ApyText>{farmApy}</ApyText>
        </Header>
        <Body>
          <BodyTextContainer>
            <SubTitle>LP Fees</SubTitle>
            <SubTitle>{lpFees}</SubTitle>
          </BodyTextContainer>
          <Description>
            Liquidity Provider (LP) rewards are earned directly from trading fees. On the V2 model, these rewards get
            compounded automatically to increase the amount of your tokens inside the pool.
          </Description>
          <BodyTextContainer>
            <SubTitle>Farming Incentives</SubTitle>
            <SubTitle>{farmingIncentive}</SubTitle>
          </BodyTextContainer>
          <Description>
            Decentralised exchanges often incentivise certain pools for limited amounts of times or for longer periods.
            These rewards are paid out in the native token of the protocol such as CAKE for example. You can harvest
            these reward tokens.
          </Description>
          <BodyTextContainer>
            <SubTitle>Combined APR</SubTitle>
            <SubTitle>{combinedAPR}</SubTitle>
          </BodyTextContainer>
          <Description>Assuming daily compounding of the reward tokens returns the APY shown on top.</Description>
          <ButtonWrapper>
            <Button color={COLORS.PRIMARY} title='Done' onClick={onDismiss} />
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApyText = styled.p`
  margin: 0;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  color: ${COLORS.SECONDARY};
`;

const StyledImage = styled.img`
  width: 80px;
  height: 80px;
  margin: 12px 0px;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const BodyTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubTitle = styled.p`
  margin: 0;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.BLACK};
`;

const Description = styled.p`
  font-size: 12px;
  margin: 0;
  margin-bottom: 10px;
  color: ${COLORS.GRAY_LIGHT};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
`;
