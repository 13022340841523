import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import {calculatePrice} from 'src/utils/token-util';
import styled from 'styled-components';

type SuccessSwapProps = {
  inputToken?: IWhitelistToken;
  outputToken?: IWhitelistToken;
  inputAmount?: string;
  outputAmount?: string;
  onDone?: () => void;
};

export const SuccessSwap = ({inputToken, outputToken, inputAmount, outputAmount, onDone}: SuccessSwapProps) => {
  const outputUSDPriceUnit = calculatePrice(outputToken?.priceUSD, outputToken?.priceDecimals);
  const outputUSDPrice = Number(outputAmount) * outputUSDPriceUnit;
  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
        <BodyVariant color={COLORS.PRIMARY}>Swap successful</BodyVariant>
      </StatusWrapper>
      <TokenInfoBox>
        <TokenItem token={inputToken} value={inputAmount} />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledTokenItem token={outputToken} value={outputAmount} />
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(outputUSDPrice, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Done' onClick={onDone} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 10px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
