import {useContext, useMemo} from 'react';

import {useWeb3React} from '@web3-react/core';
import {AppContext} from 'src/contexts/AppContext';
import {IYieldFarm} from 'src/types';
import {calculatePrice, formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';

export const usePortfolioBalance = () => {
  const {chainId} = useWeb3React();
  const {tokens, farmingPools, liquidityPools, farms} = useContext(AppContext);

  const totalWhitelistBalance = useMemo(() => {
    let balance = 0;
    tokens?.forEach((whitelistToken) => {
      if (formatBigNumber(whitelistToken.balance, whitelistToken.decimals) > 0.0000001) {
        const usdPriceUnit = calculatePrice(whitelistToken.priceUSD, whitelistToken.priceDecimals);
        const usdPrice = usdPriceUnit * formatBigNumber(whitelistToken.balance, whitelistToken.decimals);
        balance += usdPrice;
      }
    });
    return balance;
  }, [tokens]);
  const totalLiquidityBalance = useMemo(() => {
    let balance = 0;
    liquidityPools?.forEach((liquidityPool) => {
      if (liquidityPool?.balance && liquidityPool?.balance.gt(0)) {
        const lpBalance = formatBigNumber(liquidityPool.balance, liquidityPool.decimals);
        const usdPrice = getTokenUSDPrice(lpBalance, liquidityPool.lpTokenPriceUSD);
        balance += usdPrice;
      }
    });
    return balance;
  }, [liquidityPools]);
  const totalFarmingBalance = useMemo(() => {
    let balance = 0;
    if (farmingPools && farms) {
      const _lpFarms = farms?.filter(
        (item) =>
          item.contractAddress?.chainId === chainId &&
          item.liquidityPool &&
          formatBigNumber(item.balance, item.liquidityPool.decimals) > 0.000001,
      );
      const mergedFarms: IYieldFarm[] = [...farmingPools, ..._lpFarms];
      mergedFarms?.forEach((farmingPool) => {
        if (farmingPool?.balance && farmingPool?.balance.gt(0)) {
          const lpTokenPrice = calculatePrice(farmingPool?.liquidityPool?.lpTokenPriceUSD, 3);
          const farmPoolBalance = formatBigNumber(farmingPool?.balance, farmingPool?.liquidityPool.decimals);
          const usdPrice = (farmPoolBalance || 0) * lpTokenPrice;
          balance += usdPrice;
        }
      });
      return balance;
    }
    return 0;
  }, [chainId, farmingPools, farms]);
  const totalRewards = useMemo(() => {
    let balance = 0;
    if (farmingPools && farms) {
      const _lpFarms = farms?.filter(
        (item) => item.contractAddress?.chainId === chainId && (item.pendingReward || 0) > 0.000001,
      );
      const mergedFarms: IYieldFarm[] = [...farmingPools, ..._lpFarms];
      mergedFarms?.forEach((farmingPool) => {
        if (farmingPool?.pendingReward && farmingPool?.pendingReward > 0) {
          const rewardTokenPriceUSD = calculatePrice(farmingPool?.rewardTokenChainSpecifics?.priceUSD, 3);
          const usdPrice = farmingPool?.pendingReward * rewardTokenPriceUSD;
          balance += usdPrice;
        }
      });
      return balance;
    }
    return 0;
  }, [chainId, farmingPools, farms]);
  const totalSingleTokenFarmBalanceAndRewards = useMemo(() => {
    let stakeBalance = 0;
    let rewardsBalance = 0;
    const _liqSingleFarms = farms?.filter(
      (item) =>
        item.contractAddress?.chainId === chainId &&
        !item.liquidityPool &&
        formatBigNumber(item.balance, 18) > 0.000001,
    );
    _liqSingleFarms?.forEach((singleFarm) => {
      const tokenDepositItem = tokens?.find(
        (item) => item.address === singleFarm.stakedTokenChainSpecifics?.address.hash,
      );
      const unitUsdPrice = calculatePrice(tokenDepositItem?.priceUSD, tokenDepositItem?.priceDecimals);
      const stakedBalanceUSD =
        formatBigNumber(singleFarm?.balance, singleFarm.stakedTokenChainSpecifics?.decimals) * unitUsdPrice;

      const rewardsUSD = singleFarm?.pendingReward ? singleFarm.pendingReward * unitUsdPrice : 0;

      stakeBalance += stakedBalanceUSD;
      rewardsBalance += rewardsUSD;
    });
    return [stakeBalance, rewardsBalance];
  }, [farms, tokens, chainId]);
  const totalBalance =
    totalWhitelistBalance +
    totalLiquidityBalance +
    totalFarmingBalance +
    totalSingleTokenFarmBalanceAndRewards[0] +
    totalSingleTokenFarmBalanceAndRewards[1] +
    totalRewards;
  return {
    totalBalance,
    totalRewards,
    totalWhitelistBalance,
    totalLiquidityBalance,
    totalFarmingBalance,
    totalSingleTokenBalance: totalSingleTokenFarmBalanceAndRewards[0],
    totalSingleTokenRewards: totalSingleTokenFarmBalanceAndRewards[1],
  };
};
