import React, {useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {CloseIcon} from 'src/components/Svgs';
import {AVATARS} from 'src/constants/avatar';
import {useModals} from 'src/contexts/modals';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {walletSlice} from 'src/state/wallets/reducer';
import {COLORS} from 'src/styles';
import styled from 'styled-components';

import {Modal} from './Modal';
import {Button} from '../Buttons';
import {BodyVariant} from '../Typography';

const COLOR_ARRAYS = ['#17E7D6', '#3FCEF1', '#9C2BEC', '#112455', '#F76379'];

type ColorSelectorProps = {
  color: string;
  index: number;
  selectedIndex: number;
  setSelectedIndex: () => void;
};

const ColorSelector = ({color, index, selectedIndex, setSelectedIndex}: ColorSelectorProps) => {
  return (
    <StyledButton borderColor={index === selectedIndex ? color : 'transparent'} onClick={setSelectedIndex}>
      <ColorBox color={color} />
    </StyledButton>
  );
};

type RenameAccountModalProps = {
  isOpen: boolean;
};

export const RenameAccountModal = ({isOpen}: RenameAccountModalProps) => {
  const {account} = useWeb3React();
  const modalContext = useModals();
  const {connectedWallets} = useAppSelector((state) => state.wallets);
  const appDispatch = useAppDispatch();
  const currentWallet = useMemo(
    () => connectedWallets.find((item) => item.account === account),
    [account, connectedWallets],
  );

  const avatarType = useMemo(() => currentWallet?.avatarType || 0, [currentWallet]);
  const [selectedIndex, setSelectedIndex] = useState(currentWallet?.avatarIndex || 0);
  const Avatar = AVATARS[avatarType][selectedIndex];
  const [accountName, setAccountName] = useState(currentWallet?.accountName || `Your account`);

  const saveSettings = () => {
    appDispatch(walletSlice.actions.updateWallet({account, accountName, avatarType, avatarIndex: selectedIndex}));
    onDismiss();
  };

  const onDismiss = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateRenameAccountModal', payload});
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledFullRow>
            <StyledTitle>Rename Account</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledFullRow>
        </Header>
        <Body>
          <SectionWrapper marginTop={30}>
            <StyledRow gap={16}>
              <AvatarWrapper>
                <StyledImg size={60} src={Avatar} />
              </AvatarWrapper>
              <StyledRow>
                {COLOR_ARRAYS.map((color, index) => (
                  <ColorSelector
                    key={index}
                    index={index}
                    color={color}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={() => setSelectedIndex(index)}
                  />
                ))}
              </StyledRow>
            </StyledRow>
          </SectionWrapper>
          <SectionWrapper marginTop={30}>
            <BodyVariant color={COLORS.PRIMARY}>Name</BodyVariant>
            <StyledInputWrapper>
              <StyledInput
                placeholder='Your account'
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
              />
            </StyledInputWrapper>
          </SectionWrapper>
          <ButtonWrapper>
            <Button color={COLORS.PRIMARY} title='Save' onClick={saveSettings} />
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledFullRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const StyledImg = styled.img<{size?: number}>`
  width: ${(props) => props.size ?? 32}px;
`;

const AvatarWrapper = styled.div`
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid ${COLORS.GRAY_LIGHTER};
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const StyledButton = styled.button<{borderColor?: string}>`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 44px;
  outline: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

const ColorBox = styled.div<{color?: string}>`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: ${(props) => props.color ?? 'transparent'};
`;

const StyledInputWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 8px;
`;

const StyledInput = styled.input`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
`;
