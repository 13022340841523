import React from 'react';

import CrossBoxIcon from 'src/assets/images/icons/cross-box.svg';
import {useTokenValidation} from 'src/hooks/custom-token-import/useCustomToken';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types/token';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import {calculatePrice, formatBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import Tooltip from '../ToolTip/toolTip';
import {BodyParagraph} from '../Typography';

export const ClearTokenItem = ({onPress}: {onPress: () => void}) => {
  return (
    <StyledTokenBox onClick={onPress}>
      <StyledLeftWrapper>
        <StyledImage src={CrossBoxIcon} />
        <StyledTokenNameLabel color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          Clear Selection
        </StyledTokenNameLabel>
      </StyledLeftWrapper>
    </StyledTokenBox>
  );
};

type TokenItemProps = {
  token: IWhitelistToken;
  selected?: boolean;
  onSelect?: (token: IWhitelistToken) => void;
};

export const TokenItem = ({token, selected, onSelect}: TokenItemProps) => {
  const balance = formatBigNumber(token.balance, token.decimals)?.toFixed(token.interfaceDecimals);
  const {removeCustomToken} = useTokenValidation(token?.address);
  const price = calculatePrice(token.priceUSD, token.priceDecimals);
  const value = (formatBigNumber(token.balance, token.decimals) * price).toFixed(2);
  const label = token.symbol;
  const tokenUrl = token.iconUrl || getTokenIconByGlobalName(token.globalName);

  return (
    <StyledTokenBox
      selected={selected}
      onClick={() => {
        if (onSelect) {
          onSelect(token);
        }
      }}
    >
      <StyledLeftWrapper>
        <StyledImage src={tokenUrl} />
        <StyledTokenNameWrapper>
          {token.isNative ? (
            <StyledTokenNameLabel color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
              {token.name}
            </StyledTokenNameLabel>
          ) : (
            <Tooltip
              tokenAddress={token?.address}
              isCustom={token?.customToken}
              removeCustomToken={() => removeCustomToken(token)}
            >
              <StyledTokenNameLabel color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                {token.name}
              </StyledTokenNameLabel>
            </Tooltip>
          )}
          <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            ${price}
          </BodyParagraph>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <StyledTokenLabel color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          {balance} {label}
        </StyledTokenLabel>
        <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          ${value}
        </StyledTokenPrice>
      </StyledRightWrapper>
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.div<{selected?: boolean}>`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 20px;
  // overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px 0px rgba(17, 36, 85, 0.06);

  ${(props) =>
    props.selected &&
    `
    border: 1px solid ${COLORS.SECONDARY};
  `}
`;

const StyledImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: 'flex-end';
`;

const StyledTokenNameWrapper = styled.div`
  flex: 1;
`;

const StyledTokenNameLabel = styled(BodyParagraph)`
  flex-wrap: wrap;
`;

const StyledTokenLabel = styled(BodyParagraph)`
  text-align: right;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;
