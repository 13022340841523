// TwitterTrending.tsx
import React from 'react';

import FireIcon from 'src/assets/images/banners/icon-fire.png';
import TwitterIcon from 'src/assets/images/banners/icon-x.png';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

import {SecondaryButton} from '../Buttons';
import {BodyParagraph, BodyVariant} from '../Typography';

export const Hotness = ({hotness = 0, size = 12, ...props}: {hotness: number; size?: number}) => (
  <FireWrapper {...props}>
    {Array.from({length: hotness}).map((_, index) => (
      <img key={index} style={{width: size}} src={FireIcon} />
    ))}
  </FireWrapper>
);

type TrendingItemProps = {
  token?: string;
  fire: number;
  twitter: number;
};

const TrendingItem = ({token, fire, twitter}: TrendingItemProps) => {
  return (
    <div style={{width: '90px'}}>
      <TrendingItemWrapper>
        <HotnessWrapper hotness={fire} />
        <TwitterWrapper>
          <img style={{width: '14px', height: '12px'}} src={TwitterIcon} />
          <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
            {twitter}
          </BodyParagraph>
        </TwitterWrapper>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
          ${token}
        </BodyParagraph>
      </TrendingItemWrapper>
    </div>
  );
};

const TrendingItemWrapper = styled.div`
  position: relative;
  display: flex;
  width: 55px;
  height: 26px;
  align-items: center;
  justify-content: center;
  background: rgba(186, 255, 249, 0.36);
`;

const FireWrapper = styled.div`
  display: flex;
  position: absolute;
`;

const HotnessWrapper = styled(Hotness)`
  top: -7px;
  left: 0;
`;

const TwitterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -7px;
  left: 88%;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9); // White with 90% opacity
  display: flex;
  align-items: center;
  justify-content: center;
  color: #17e7d6; // Cyan color for the text
  font-size: 24px; // Adjust the size as needed
  font-weight: 600;
  z-index: 2; // Adjust if needed to be below the border-radius container
  border-radius: 12px; // Match the border-radius of the Wrapper or the container
`;

export const TwitterTrending = ({...props}) => {
  return (
    <Wrapper {...props}>
      <StyledOverlay>Coming Soon</StyledOverlay> {/* Overlay added */}
      <StyledTitle color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
        X Trending
      </StyledTitle>
      <StyledRow gap={8}>
        <TrendingItem token='LIQ' fire={3} twitter={147} />
        <TrendingItem token='PEPE' fire={2} twitter={156} />
      </StyledRow>
      <StyledRow gap={8}>
        <TrendingItem token='BTC' fire={0} twitter={354} />
        <TrendingItem token='BTT' fire={2} twitter={135} />
      </StyledRow>
      <StyledRow gap={8}>
        <TrendingItem token='BTE' fire={1} twitter={104} />
        <TrendingItem token='FTM' fire={2} twitter={243} />
      </StyledRow>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <SecondaryButton title='More stats' />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 222px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.sm}) {
    flex: auto;
  }
`;

const StyledTitle = styled(BodyVariant)`
  text-align: center;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-around;
  gap: ${(props) => props.gap ?? 0}px;
`;
