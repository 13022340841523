import React, {useEffect, useMemo, useState} from 'react';

import {CloseIcon} from 'src/components/Svgs';
import {TOLERANCE_ITEMS} from 'src/constants';
import {useModals} from 'src/contexts/modals';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {COLORS} from 'src/styles';
import {IItemType} from 'src/types';
import styled from 'styled-components';

import {Modal} from './Modal';
import {Button} from '../Buttons';
import {ItemPicker} from '../ItemPicker';

type SwapSettingsModalProps = {
  isOpen: boolean;
};

export const SwapSettingsModal = ({isOpen}: SwapSettingsModalProps) => {
  const modalContext = useModals();
  const {swapSettings} = useAppSelector((state) => state.user);
  const appDispatch = useAppDispatch();

  const [selectedTolerance, setSelectedTolerance] = useState(TOLERANCE_ITEMS[0]);
  const [customValue, setCustomValue] = useState('');
  const warning = useMemo(() => {
    if (selectedTolerance.name === 'Custom' && Number(customValue) > 20.0) {
      return true;
    }
    return false;
  }, [selectedTolerance, customValue]);

  useEffect(() => {
    if (isOpen) {
      setSelectedTolerance(swapSettings.slippage ? swapSettings.slippage : TOLERANCE_ITEMS[0]);
      if (swapSettings.slippage?.name === 'Custom') {
        setCustomValue(swapSettings?.slippage.value === -1 ? '' : swapSettings?.slippage.value.toString());
      }
    }
  }, [isOpen, swapSettings]);

  const onPickedToleranceItem = (item: IItemType) => {
    setSelectedTolerance(item);
  };

  const onChangeCustomValue = (value: string) => {
    const removedComma = value.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setCustomValue(removedComma);
  };

  const saveSettings = () => {
    // save tolerance
    const _swapSettings = {
      ...swapSettings,
      slippage:
        selectedTolerance.name !== 'Custom' ? selectedTolerance : {...selectedTolerance, value: Number(customValue)},
    };
    const swapSettingsPayload = {swapSettings: _swapSettings};
    appDispatch(userSlice.actions.updateSwapSettings(swapSettingsPayload));
    onDismiss();
  };

  const onDismiss = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateSwapSettingsModal', payload});
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Swap Settings</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          <SectionWrapper>
            <StyledSectionTitle>Slippage tolerance</StyledSectionTitle>
            <ItemPicker
              items={TOLERANCE_ITEMS}
              selectedItem={selectedTolerance}
              onPicked={onPickedToleranceItem}
              customValue={customValue}
              onChangeCustomValue={onChangeCustomValue}
              warning={warning}
            />
          </SectionWrapper>
          <ButtonWrapper>
            <Button color={COLORS.PRIMARY} title='Save' onClick={saveSettings} />
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const StyledSectionTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
