import {PropsWithChildren, useEffect, useRef, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {useLocation} from 'react-router';
import {Button} from 'src/components/Buttons';
import {SUPPORTED_NETWORKS, getChainInfo, paths} from 'src/constants';
import {useSidebar} from 'src/contexts/SidebarContext';
import {useNetwork} from 'src/hooks';
import {useOnClickOutside} from 'src/hooks/useOnClickOutside';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {NetworkType} from 'src/types';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import {checkCurPage} from 'src/utils/utils';
import styled, {createGlobalStyle} from 'styled-components';

import {Header} from '../Header';
import {Sidebar} from '../Sidebar';
import {Spinner} from '../Spinner';

export const NavLayout = ({children}: PropsWithChildren) => {
  const location = useLocation();
  const {chainId, isActive} = useWeb3React();
  const {switchNetwork} = useNetwork();
  const [isLoading, setIsLoading] = useState(true);

  const supportedNetworks = Object.values(SUPPORTED_NETWORKS);
  const tokenIconUrl = getTokenIconByGlobalName('WBNB');

  const {isOpen, toggleSidebar} = useSidebar();
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, isOpen ? toggleSidebar : undefined);

  const isMigrationPage = checkCurPage(location.pathname, ['/migrate']);
  const isLiquidityPage = checkCurPage(location.pathname, ['/liquidity', '/add-liquidity']);

  const switchTitle = isLiquidityPage ? 'Switch Network to BNB Smart Chain' : 'Switch Network';

  const networkDynamicData = (networkArr: NetworkType[], key: 'displayName' | 'name', isLiquidity: boolean) => {
    const arr = isLiquidity ? networkArr.filter((el) => el.chainId === 56) : networkArr;
    if (arr.length <= 1) return arr.map((item) => item[key]).join('');
    return arr
      .map((item, idx) => {
        if (idx === arr.length - 1) return item[key];
        return `${item[key]} or `;
      })
      .join('');
  };

  useEffect(() => {
    if (isActive) {
      setIsLoading(false);
    }
  }, [isActive]);

  const onSwitchNetwork = async (targetChainId: number) => {
    // const targetChainId = chainId; // TODO: Add further netwoks upon support
    await switchNetwork(targetChainId);
  };

  if (isLoading) {
    return (
      <StyledFullWrapper>
        <Spinner size={32} color={COLORS.SECONDARY} />
      </StyledFullWrapper>
    );
  }

  if ((!getChainInfo(chainId) && !isMigrationPage) || (chainId !== 56 && isLiquidityPage)) {
    return (
      <>
        <GlobalStyle />
        <Wrapper>
          <Header />
          <NetworkMessageContainer>
            {isLiquidityPage && (
              <PoolInfoWithBorder>
                <StyledTokenImage width={70} height={70} src={tokenIconUrl} onClick={() => onSwitchNetwork(56)} />
              </PoolInfoWithBorder>
            )}
            <NetworkMessage>{switchTitle}</NetworkMessage>
            <SecondaryMessage>
              Please switch to {networkDynamicData(supportedNetworks, 'displayName', isLiquidityPage)} to use Liquidus.
              Check your wallet settings and select ‘{networkDynamicData(supportedNetworks, 'name', isLiquidityPage)}‘
              under Networks.
              <br />
              {!isLiquidityPage && 'More networks will be supported soon.'}
            </SecondaryMessage>
            {isLiquidityPage && (
              <ButtonWrapper>
                <Button color={COLORS.PRIMARY} title='Switch Network' onClick={() => onSwitchNetwork(56)} />
              </ButtonWrapper>
            )}
            {!isLiquidityPage && (
              <PoolInfoWithBorder>
                <>
                  {supportedNetworks.map((item, idx) => (
                    <StyledTokenImage
                      key={idx}
                      width={70}
                      height={70}
                      src={item.icon}
                      onClick={() => onSwitchNetwork(item.chainId)}
                      clickable={true}
                    />
                  ))}
                </>
              </PoolInfoWithBorder>
            )}
          </NetworkMessageContainer>
        </Wrapper>
      </>
    );
  }

  if (location?.pathname !== paths.yieldScanner && location?.pathname !== paths.yieldResult) {
    return (
      <Wrapper>
        <div ref={node}>
          <Header />
          <Sidebar />
        </div>
        <Main>{children}</Main>
      </Wrapper>
    );
  } else {
    return <>{children}</>;
  }
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

const StyledFullWrapper = styled.div`
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    min-height: 300px;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
`;

const StyledTokenImage = styled.img<{clickable?: boolean}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-bottom: 24px; // Adjust spacing as needed
  cursor: ${(props) => (props.clickable ? 'pointer' : 'unset')};
`;

const Main = styled.div`
  padding: 81px 0 0 254px;
  background-color: #f9f9f9;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 61px 0 0;
  }
`;

const NetworkMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 100px; // Add padding to push content down
  background-color: #f9f9f9;
  text-align: center;

  @media (max-width: ${DEVICE_ENUM.md}) {
    height: calc(100vh - 60px);
    padding-top: 150px; // Adjust for smaller screens if needed
  }
`;

const NetworkMessage = styled.h1`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  color: ${COLORS.PRIMARY};
  margin: 0;
`;

const SecondaryMessage = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 140%;
  color: ${COLORS.PRIMARY};
  margin-top: 24px; // Spacing between the two messages
  text-align: center;
  width: 600px; // To ensure the text doesn't stretch too wide

  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 90%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 10px;
  // width: 342px;

  @media screen and (max-width: ${DEVICE_ENUM.TABLET}) {
    flex-direction: column;
    width: 250px;
  }
`;

const PoolInfoWithBorder = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  gap: 4px;
  margin: 0 0 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    justify-content: center;
    padding: 0 6px;
  }
`;
