import * as React from 'react';

export const HackenLogoIcon = () => (
  <svg width='58' height='13' viewBox='0 0 58 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'></svg>
    <g transform='translate(0)'>
      <path d='M0 12.6928H0.767986V0H0V12.6928Z' fill='#2BE2B3' />
      <path d='M12.6875 12.6928H13.4555V0H12.6875V12.6928Z' fill='#2BE2B3' />
      <path d='M11.9196 12.6914H11.1307V6.74846H0.381836V5.95947H11.9196V12.6914Z' fill='#2BE2B3' />
      <path d='M10.3791 12.6932H9.61111V8.30723H0.572266V7.50024H10.3791V12.6932Z' fill='#2BE2B3' />
      <path d='M8.84012 12.6928H8.05414V10.0019H0.572266V9.23389H8.84012V12.6928Z' fill='#2BE2B3' />
      <path d='M4.80566 12.6924H5.57365V9.6145H4.80566V12.6924Z' fill='#2BE2B3' />
      <path d='M3.07422 12.6923H3.84221V9.8064H3.07422V12.6923Z' fill='#2BE2B3' />
      <path d='M1.53906 12.6927H2.30705V9.61475H1.53906V12.6927Z' fill='#2BE2B3' />
      <path d='M1.53906 0V6.73189H13.0769V5.9429H2.32805V0H1.53906Z' fill='#2BE2B3' />
      <path d='M3.26758 0V5.19291H13.0744V4.38592H4.03856V0H3.26758Z' fill='#2BE2B3' />
      <path d='M12.8831 3.46194H4.61523V0H5.40422V2.69095H12.8861V3.46194H12.8831Z' fill='#2BE2B3' />
      <path d='M7.88184 3.07795H8.64983V0H7.88184V3.07795Z' fill='#2BE2B3' />
      <path d='M9.61133 2.88595H10.3793V0H9.61133V2.88595Z' fill='#2BE2B3' />
      <path d='M11.1494 3.07795H11.9174V0H11.1494V3.07795Z' fill='#2BE2B3' />
    </g>

    <svg width='44' height='5' viewBox='0 0 44 5' fill='none' xmlns='http://www.w3.org/2000/svg'></svg>
    <g transform='translate(18, 4)'>
      <path
        d='M3.15525 0.045166V1.56567H1.29198V0.045166H0V4.55392H1.35351V2.98069H3.21677V4.55392H4.57028V0.045166H3.15525Z'
        fill='#2BE2B3'
      />
      <path
        d='M3.15525 0.045166V1.56567H1.29198V0.045166H0V4.55392H1.35351V2.98069H3.21677V4.55392H4.57028V0.045166H3.15525Z'
        fill='#2BE2B3'
      />
      <path
        d='M14.1953 0.043945H13.5186V4.5527H18.5898V3.26073H14.9248V1.29199H18.5898V0H14.1953V0.043945Z'
        fill='#2BE2B3'
      />
      <path
        d='M38.0713 0.0444336V1.96044L36.208 0.0444336H34.916V4.55319H36.2695V2.52294L38.1328 4.55319H39.4863V0.0444336H38.0713Z'
        fill='#2BE2B3'
      />
      <path
        d='M24.455 0.0429688V1.56347H22.2578V0.0429688H20.8516V4.55173H22.3193V2.97849H24.5166V4.55173H25.9843V2.91698H24.6308L25.9843 1.56347V0.0429688H24.455Z'
        fill='#2BE2B3'
      />
      <path
        d='M11.0351 0.045166H6.92187H6.75488V4.55392H8.04686V3.59592H9.91012V4.55392H11.2021V0.045166H11.0351ZM9.96287 2.24241H8.09959V1.33715H9.96287V2.24241Z'
        fill='#2BE2B3'
      />
      <path
        d='M28.5537 0.0458896H28.1582V4.55465H28.4922H32.6054V3.26267H29.3974V2.92868H32.6054V1.63671H29.3974V1.30272H32.6054V0.0107422H28.5449V0.0458896H28.5537Z'
        fill='#2BE2B3'
      />
    </g>
  </svg>
);

export default HackenLogoIcon;
