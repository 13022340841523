// SendToken.tsx
import {useMemo} from 'react';

import {Button} from 'src/components/Buttons';
import {ContactItem} from 'src/components/ContactItem';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {useAppSelector} from 'src/state/hooks';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type SendTokenProps = {
  selectedToken: IWhitelistToken;
  amount: number;
  usdAmount: number;
  recipientAddress: string;
  loading: boolean;
  onConfirm: () => void;
};

export const SendToken = ({selectedToken, amount, usdAmount, recipientAddress, loading, onConfirm}: SendTokenProps) => {
  const {addressBook} = useAppSelector((state) => state.user);
  const existingContact = useMemo(
    () => addressBook.find((item) => item.address === recipientAddress),
    [addressBook, recipientAddress],
  );
  return (
    <Wrapper>
      <ItemsWrapper>
        <TokenItem token={selectedToken} value={amount.toFixed(selectedToken?.interfaceDecimals)} />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        {existingContact ? (
          <ContactItem contact={existingContact} />
        ) : (
          <WalletAddress size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.GRAY_LIGHT}>
            {recipientAddress}
          </WalletAddress>
        )}
        <BodyParagraph color={COLORS.GRAY_LIGHT}>=${usdAmount?.toFixed(2)}</BodyParagraph>
      </ItemsWrapper>
      <ButtonWrapper>
        <Button color={COLORS.PRIMARY} title='Send Token' isLoading={loading} onClick={onConfirm} />
        <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet.</StyledParagraph>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 10px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const WalletAddress = styled(BodyParagraph)`
  margin-bottom: 6px;
`;
