import {useEffect} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {
  ApprovalState,
  getCurrency,
  useApproveCallback,
  useApproveCallbackFromTrade,
  useSwapCallback,
  useSwapInfo,
} from 'src/hooks/kyber-swap';
import {useAppSelector} from 'src/state/hooks';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';

import {useDepositCallback} from './useDeposit';

export const useSingleFarmTopUp = (
  fromToken?: IWhitelistToken,
  toToken?: IWhitelistToken,
  fromTokenAmount?: BigNumber,
  farmItem?: IYieldFarm,
) => {
  const {account} = useWeb3React();
  const {swapSettings} = useAppSelector((state) => state.user);
  const slippageAmount = (swapSettings?.slippage?.value || 0.1) * 100;

  const {v2Trade, onUpdateCallback} = useSwapInfo(
    false,
    fromTokenAmount || BigNumber.from(0),
    fromToken,
    toToken,
    slippageAmount,
  );

  const [swapTokenApproveState, swapTokenApproveCallback] = useApproveCallbackFromTrade(v2Trade, slippageAmount);

  const {estimateGas, callback} = useSwapCallback(v2Trade);

  const {estimateGasForDeposit, deposit} = useDepositCallback(farmItem);

  const fromTokenCurrency = getCurrency(fromToken);
  const fromTokenCurrencyAmount = tryParseAmount(fromTokenAmount, fromTokenCurrency, false);

  const [depositTokenApproveState, depositTokenApproveCallback] = useApproveCallback(
    fromToken?.address === toToken?.address ? fromTokenCurrencyAmount : v2Trade?.outputAmount,
    account,
    farmItem?.contractAddress?.hash,
  );

  useEffect(() => {
    if (!v2Trade) {
      onUpdateCallback(true, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v2Trade]);

  return {
    swapCallback: callback,
    estimateGasForSwap: estimateGas,
    swapTokenApproveState,
    isSwapTokenApproved: swapTokenApproveState === ApprovalState.APPROVED,
    swapTokenApproveCallback,
    depositCallback: deposit,
    estimateGasForDeposit,
    depositTokenApproveCallback,
    depositTokenApproveState,
    isDepositTokenApproved: depositTokenApproveState === ApprovalState.APPROVED,
    trade: v2Trade,
  };
};
