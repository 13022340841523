import {useWeb3React} from '@web3-react/core';
import {useNavigate} from 'react-router';
import {paths} from 'src/constants';
import {useNetwork, useToken} from 'src/hooks';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {FarmType, IYieldFarm, POOL_TYPE} from 'src/types';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph} from '../Typography';

interface HotFarmItemProps {
  data: IYieldFarm;
}

export const HotFarmItem = ({data}: HotFarmItemProps) => {
  const {chainId} = useWeb3React();
  const {switchNetwork} = useNetwork();
  const {getGlobalTokenItemByAddress} = useToken();
  const navigate = useNavigate();
  const name = `${data.liquidityPool.token0Symbol} - ${data.liquidityPool.token1Symbol}`;
  const platform = data.platform.name;
  const apy = data.apy;
  const farmChainId =
    data.type === FarmType.FARM_GRIZLYFI ? data.contractAddress?.chainId : data.liquidityPool?.address.chainId;
  const token0 = getGlobalTokenItemByAddress(data.liquidityPool?.token0Hash, farmChainId);
  const token1 = getGlobalTokenItemByAddress(data.liquidityPool?.token1Hash, farmChainId);

  const handleNavigation = async () => {
    const targetChainId =
      data.type === FarmType.FARM_MASTERCHEF ? data.liquidityPool.address.chainId : data.contractAddress.chainId;
    if (chainId !== targetChainId) {
      await switchNetwork(targetChainId);
    }
    const address =
      data.type === FarmType.FARM_MASTERCHEF ? data.liquidityPool.address.hash : data.contractAddress.hash;
    navigate(`${paths.poolDetail}?type=${POOL_TYPE.FARMING_POOL}&address=${address}&farm-type=${data.type}`);
  };

  return (
    <Wrapper onClick={handleNavigation}>
      <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
        {platform}
      </BodyParagraph>
      <TokenIconWrapper>
        <TokenIcon width={40} height={40} token={token0} />
        <SecondTokenIcon width={40} height={40} token={token1} />
      </TokenIconWrapper>
      <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
        {name}
      </BodyParagraph>
      <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
        {apy.toFixed(1)}% APY
      </BodyParagraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 137px;
  width: 186px;
  height: 168px;
  box-sizing: border-box;
  padding: 10px 10px 6px;
  background: white;
  box-shadow: 4px 4px 16px rgba(17, 36, 85, 0.06);
  border-radius: 12px;
`;

const TokenIconWrapper = styled.div`
  display: flex;
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -9px;
  margin-top: 9px;
`;
