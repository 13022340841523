import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type WalletIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const WalletIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: WalletIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M17.0781 5.9375C17.4082 5.9375 17.6875 6.2168 17.6875 6.54688C17.6875 6.90234 17.4082 7.15625 17.0781 7.15625H7.73438C7.15039 7.15625 6.71875 7.61328 6.71875 8.17188V15.0781C6.71875 15.6621 7.15039 16.0938 7.73438 16.0938H16.2656C16.8242 16.0938 17.2812 15.6621 17.2812 15.0781V10.6094C17.2812 10.0508 16.8242 9.59375 16.2656 9.59375H8.54688C8.19141 9.59375 7.9375 9.33984 7.9375 8.98438C7.9375 8.6543 8.19141 8.375 8.54688 8.375H16.2656C17.4844 8.375 18.5 9.39062 18.5 10.6094V15.0781C18.5 16.3223 17.4844 17.3125 16.2656 17.3125H7.73438C6.49023 17.3125 5.5 16.3223 5.5 15.0781V8.17188C5.5 6.95312 6.49023 5.9375 7.73438 5.9375H17.0781ZM14.4375 12.8438C14.4375 12.4121 14.793 12.0312 15.25 12.0312C15.6816 12.0312 16.0625 12.4121 16.0625 12.8438C16.0625 13.3008 15.6816 13.6562 15.25 13.6562C14.793 13.6562 14.4375 13.3008 14.4375 12.8438Z'
      fill={color}
    />
  </svg>
);

export default WalletIcon;
