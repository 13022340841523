import {useCallback, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {useLocation, useNavigate} from 'react-router-dom';
import LIQLogo from 'src/assets/images/liq-logo.svg';
import {ChainConfig, LIQUIDITY_NETWORKS, NETWORKS, SUPPORTED_NETWORKS, paths} from 'src/constants';
import {useModals} from 'src/contexts/modals';
import {useSidebar} from 'src/contexts/SidebarContext';
import {useNetwork} from 'src/hooks';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {NetworkType} from 'src/types';
import {checkCurPage, simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

import {SettingPopup} from './SettingPopup';
import {BUTTON_SIZE_ENUM, Button} from '../Buttons';
import {DropdownMenu} from '../Dropdown';
import {MenuItem} from '../MenuItem';
import {HamburgerMenuIcon} from '../Svgs';

export const Header = () => {
  const location = useLocation();
  const {dispatch} = useModals();
  const {chainId, account} = useWeb3React();
  const {switchNetwork, resetConnector} = useNetwork();
  const [openState, setOpenState] = useState(false);
  const [initialChain, setInitialChain] = useState(chainId);
  const [showSettings, setShowSettings] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkType | undefined>(NETWORKS[0]);
  const shouldHideNetwork = location.pathname === paths.poolDetail;
  const navigate = useNavigate();

  const isMigrationPage = checkCurPage(location.pathname, ['/migrate']);
  const isLiquidityPage = checkCurPage(location.pathname, ['/liquidity', '/add-liquidity']);

  let allowedNetworks: ChainConfig;

  switch (true) {
    case isMigrationPage:
      allowedNetworks = NETWORKS;
      break;
    case isLiquidityPage:
      allowedNetworks = LIQUIDITY_NETWORKS;
      break;
    default:
      allowedNetworks = SUPPORTED_NETWORKS;
      break;
  }

  const onChangeNetwork = useCallback(
    async (targetChainId?: number) => {
      setInitialChain(targetChainId);
      await switchNetwork(targetChainId);
      setOpenState(false);
    },
    [switchNetwork],
  );

  useEffect(() => {
    const network = NETWORKS[chainId ?? 56];
    if (network) {
      setSelectedNetwork(network);
    }

    if (initialChain !== chainId) {
      shouldHideNetwork && navigate(-1);
      resetConnector().then(() => {
        onChangeNetwork(chainId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  const onConnectWallet = () => {
    const payload = {isOpen: true};
    dispatch({type: 'updateWalletConnectModal', payload});
  };

  const navigateToHome = () => {
    navigate('/home');
  };

  const {toggleSidebar} = useSidebar();

  return (
    <Wrapper>
      <StyledRow>
        <IconButton onClick={toggleSidebar}>
          <HamburgerMenuIcon />
        </IconButton>
        <StyledImage
          src={LIQLogo}
          onClick={() => {
            navigateToHome();
          }}
        />
      </StyledRow>
      <StyledRow>
        {!shouldHideNetwork ? (
          <DropdownMenu
            title={selectedNetwork?.symbol}
            icon={selectedNetwork?.icon}
            openState={openState}
            toggleDropdown={() => setOpenState((prev) => !prev)}
          >
            {Object.keys(allowedNetworks).map((chainId) => (
              <MenuItem
                key={chainId}
                title={allowedNetworks[Number(chainId)]?.name}
                icon={allowedNetworks[Number(chainId)]?.icon}
                onClick={() => onChangeNetwork(Number(chainId))}
              />
            ))}
          </DropdownMenu>
        ) : null}
        {account ? (
          <StyledConnectButtonWrapper>
            <StyledButton
              size={BUTTON_SIZE_ENUM.SMALL}
              title={simplifyAddress(account)}
              onClick={() => setShowSettings(true)}
            />
            {showSettings && <SettingPopup onClose={() => setShowSettings(false)} />}
          </StyledConnectButtonWrapper>
        ) : (
          <StyledButton size={BUTTON_SIZE_ENUM.SMALL} title='Connect Wallet' onClick={onConnectWallet} />
        )}
      </StyledRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: ${COLORS.WHITE};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BASE_40};
  padding: 0 83px 0 20px;
  box-sizing: border-box;
  z-index: 10;

  @media (max-width: ${DEVICE_ENUM.md}) {
    height: 60px;
    padding: 0 16px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const IconButton = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: ${DEVICE_ENUM.md}) {
    display: block;
    height: 30px;
  }
`;

const StyledImage = styled.img`
  width: 116px;
  height: 40px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    display: none;
  }

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const StyledConnectButtonWrapper = styled.div`
  position: relative;
`;

const StyledButton = styled(Button)`
  margin-left: 12px;
`;
