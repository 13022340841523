import {useCallback, useContext, useMemo} from 'react';

import {NEW_LIQ_GLOBAL_NAME} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useAppSelector} from 'src/state/hooks';
import {IWhitelistToken, TokenShowMode, TokenSorting} from 'src/types';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';

export const useToken = () => {
  const {tokenSortSettings} = useAppSelector((state) => state.user);
  const {tokens, allTokens} = useContext(AppContext);
  const getTokenBySymbol = useCallback(
    (symbol?: string, ignoreNative?: boolean) => {
      if (symbol) {
        return tokens?.find((item) => item.symbol === symbol);
      }
      if (!ignoreNative) {
        const abc = tokens?.find((item) => item.isNative);
        return abc;
      }
    },
    [tokens],
  );

  const getTokenByGlobalName = useCallback(
    (globalName?: string, ignoreNative?: boolean) => {
      if (globalName) {
        return tokens?.find((item) => item.globalName === globalName);
      }
      if (!ignoreNative) {
        const abc = tokens?.find((item) => item.isNative);
        return abc;
      }
    },
    [tokens],
  );

  const getTokenByAddress = useCallback(
    (address?: string, ignoreNative?: boolean) => {
      if (address) {
        return tokens?.find((item) => item.address.toLowerCase() === address.toLowerCase());
      }
      if (!ignoreNative) {
        const abc = tokens?.find((item) => item.isNative);
        return abc;
      }
    },
    [tokens],
  );

  const getGlobalTokenItemByAddress = useCallback(
    (address?: string, chainId?: number) => {
      if (address && chainId) {
        return allTokens?.find((token) =>
          token.chainSpecifics.find(
            (cs) => cs.address.hash.toLowerCase() === address.toLowerCase() && cs.address.chainId === chainId,
          ),
        );
      }
    },
    [allTokens],
  );

  const getGlobalTokenItemByGlobalName = useCallback(
    (globalName?: string) => {
      return allTokens?.find((token) => token.globalName === globalName);
    },
    [allTokens],
  );

  const sortedTokens = useMemo(() => {
    if (!tokens) return [];

    const nativeToken = tokens.find((item) => item.isNative);
    const newLiq = tokens.find((item) => item.globalName === NEW_LIQ_GLOBAL_NAME);
    let otherTokens = tokens.filter((item) => !item.isNative);

    if (
      tokenSortSettings?.sortMode === TokenSorting.SORT_BY_MARKETCAP ||
      tokenSortSettings?.showMode === TokenShowMode.TOP_20_TOKENS
    ) {
      otherTokens = otherTokens.sort((a, b) => (b.marketCap || 0) - (a.marketCap || 0));
    } else {
      otherTokens = otherTokens.sort((a, b) => a.name.localeCompare(b.name));
    }

    let tokensWithBalances = otherTokens.filter(
      (item) =>
        getTokenUSDPrice(formatBigNumber(item.balance, item.decimals), item.priceUSD, item.priceDecimals) >= 0.01,
    );

    tokensWithBalances = tokensWithBalances.sort((a, b) => {
      const aFormatted = getTokenUSDPrice(formatBigNumber(a.balance, a.decimals), a.priceUSD);
      const bFormatted = getTokenUSDPrice(formatBigNumber(b.balance, b.decimals), b.priceUSD);
      return bFormatted - aFormatted;
    });

    let tokensWithoutBalances = otherTokens.filter(
      (item) =>
        getTokenUSDPrice(formatBigNumber(item.balance, item.decimals), item.priceUSD, item.priceDecimals) < 0.01,
    );

    const customTokensWithBalance = tokensWithBalances.filter((item) => item?.customToken);
    const customTokensWithoutBalance = tokensWithoutBalances.filter((item) => item?.customToken);
    const customTokens = [...customTokensWithBalance, ...customTokensWithoutBalance];

    tokensWithBalances = tokensWithBalances.filter((token) => !token?.customToken);
    tokensWithoutBalances = tokensWithoutBalances.filter((token) => !token?.customToken);

    const tokensHaveNoBalance = tokensWithBalances.length === 0;

    let sortedTokens: IWhitelistToken[] = [];
    if (nativeToken) {
      sortedTokens.push(nativeToken);
    }

    if (tokensHaveNoBalance) {
      // Place newLiq right after nativeToken if no tokens have balances
      if (newLiq) sortedTokens.push(newLiq);
      sortedTokens = [...sortedTokens, ...tokensWithBalances, ...customTokens, ...tokensWithoutBalances];
    } else {
      // Sort newLiq along with other tokens according to their balances
      sortedTokens = [...sortedTokens, ...tokensWithBalances, ...customTokens, ...tokensWithoutBalances];
    }

    return sortedTokens;
  }, [tokenSortSettings, tokens]);

  const getWrappedNativeToken = useCallback(
    (chainId: number) => {
      let token;
      if (chainId === 1) {
        token = tokens?.find((item) => item.symbol === 'WETH');
      } else if (chainId === 25) {
        token = tokens?.find((item) => item.symbol === 'WCRO');
      } else if (chainId === 56) {
        token = tokens?.find((item) => item.symbol === 'WBNB');
      } else if (chainId === 137) {
        token = tokens?.find((item) => item.symbol === 'WCMATIC');
      }
      return token;
    },
    [tokens],
  );
  return {
    sortedTokens,
    getTokenBySymbol,
    getTokenByGlobalName,
    getTokenByAddress,
    getGlobalTokenItemByAddress,
    getGlobalTokenItemByGlobalName,
    getWrappedNativeToken,
  };
};
