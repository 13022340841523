import {useContext, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import axios from 'axios';
import {BigNumber} from 'ethers';
import {getChainInfo} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {IWhitelistToken} from 'src/types';

export type SupportChainProps = {
  id: number;
  key: string;
  name: string;
  chainType: string;
  coin: string;
  mainnet: boolean;
  logoURI: string;
  tokenlistUrl: string;
  multicallAddress: string;
  metamask: {
    // this information is required to add the chain to your MetaMask wallet
    chainId: string;
    blockExplorerUrls: string[];
    chainName: string[];
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: string[];
  };
  nativeToken: SupportTokenProps;
};

export type SupportTokenProps = {
  address: string;
  chainId: number;
  symbol: string;
  decimals: number;
  name: string;
  priceUSD: string;
  logoURI: string;
  coinKey: string;
};

export const useLifiSwap = () => {
  const [supportedChains, setSupportedChains] = useState<SupportChainProps[]>([]);
  const [supportedTokens, setSupportedTokens] = useState([]);
  const [tokenListWithChainId, setTokenListWithChainId] = useState([]);
  const {allTokens} = useContext(AppContext);
  const {chainId} = useWeb3React();

  const tokenList = async (selectedChainId: number) => {
    const filteredTokens: IWhitelistToken[] = [];
    if (allTokens) {
      for (const token of allTokens) {
        const chainSpecific = token.chainSpecifics.find((chain) => chain.address.chainId === selectedChainId);

        if (chainSpecific) {
          const newToken = {
            globalName: token.globalName,
            name: chainSpecific.name,
            symbol: chainSpecific.symbol,
            decimals: chainSpecific.decimals,
            priceUSD: chainSpecific.priceUSD,
            interfaceDecimals: token.interfaceDecimals,
            priceDecimals: token.priceDecimals,
            marketCap: token.marketCap,
            address: chainSpecific.address.hash,
            chainSpecifics: token.chainSpecifics,
            balance: BigNumber.from(0),
            chainId: chainSpecific.address.chainId,
            iconUrl: token.iconUrl,
          };

          filteredTokens.push(newToken);
        }
      }

      const chainInfo = getChainInfo(selectedChainId);
      const nativeToken = {
        globalName: chainInfo.name,
        name: chainInfo.name,
        symbol: chainInfo.symbol,
        interfaceDecimals: 4,
        decimals: 18,
        balance: BigNumber.from(0),
        chainId,
        address: chainInfo.symbol,
        isNative: true,
      };

      filteredTokens.push(nativeToken);

      setTokenListWithChainId(filteredTokens);
    }
  };

  const getChains = async () => {
    const result = await axios.get('https://li.quest/v1/chains');
    const {chains} = result.data;
    setSupportedChains(chains);
  };

  const getTokens = async () => {
    const result = await axios.get('https://li.quest/v1/tokens');
    const {tokens} = result.data;
    setSupportedTokens(tokens);
  };

  useEffect(() => {
    getChains();
    getTokens();
  }, []);

  return {
    supportedChains,
    supportedTokens,
    tokenListWithChainId,
    tokenList,
  };
};
