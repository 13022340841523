//ExploreNewTokens.tsx
import React from 'react';

import {Link} from 'react-router-dom'; // Import the Link component from React Router
import WorldWebLogo from 'src/assets/images/banners/world-web.png';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {SecondaryButton} from '../Buttons';
import {BodyVariant} from '../Typography';

export const ExploreNewTokens = ({...props}) => {
  return (
    <Wrapper {...props}>
      <StyledRow>
        <StyledTitle color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
          Explore new Tokens
        </StyledTitle>
      </StyledRow>
      <StyledImg src={WorldWebLogo} />
      <div style={{display: 'flex', justifyContent: 'center'}}>
        {/* Wrap the SecondaryButton in a Link component */}
        <Link to='/market-overview' style={{textDecoration: 'none'}}>
          <SecondaryButton title='Explore' />
        </Link>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 174px;
  height: 222px;
  padding: 16px;
  box-sizing: border-box;
  background-color: #fcddec;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  left: -28px;
  bottom: 48px;
  width: 131px;
`;

const StyledTitle = styled(BodyVariant)`
  width: 100px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.gap ?? 0}px;
`;
