export type IItemType = {
  name: string;
  value: number;
};

export type ITokenSortSetting = {
  sortMode: TokenSorting;
  showMode: TokenShowMode;
};

export enum TokenSorting {
  SORT_BY_MARKETCAP,
  SORT_BY_ALPHABET,
}

export enum TokenShowMode {
  TOP_20_TOKENS,
  HIDE_ZERO_BALANCE,
  ALWAYS_SHOW_ALL,
}
