import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type HomeIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const HomeIcon = ({size = 24, color = COLORS.SECONDARY, ...props}: HomeIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M18.8965 10.9648C19.1504 11.1934 19.1758 11.5742 18.9727 11.8281C18.7441 12.082 18.3633 12.1074 18.1094 11.9043L17.5 11.3711V16.0938C17.5 17.2363 16.5859 18.125 15.4688 18.125H8.15625C7.01367 18.125 6.125 17.2363 6.125 16.0938V11.3711L5.49023 11.9043C5.23633 12.1074 4.85547 12.082 4.62695 11.8281C4.42383 11.5742 4.44922 11.1934 4.70312 10.9648L11.4062 5.27734C11.6348 5.09961 11.9648 5.09961 12.1934 5.27734L18.8965 10.9648ZM8.15625 16.9062H9.375V13.0469C9.375 12.4883 9.80664 12.0312 10.3906 12.0312H13.2344C13.793 12.0312 14.25 12.4883 14.25 13.0469V16.9062H15.4688C15.9004 16.9062 16.2812 16.5508 16.2812 16.0938V10.3301L11.8125 6.54688L7.34375 10.3301V16.0938C7.34375 16.5508 7.69922 16.9062 8.15625 16.9062ZM10.5938 16.9062H13.0312V13.25H10.5938V16.9062Z'
      fill={color}
    />
  </svg>
);

export default HomeIcon;
