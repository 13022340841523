import React from 'react';

import {Link} from 'react-router-dom';
import {CheckedBackgroundIcon, HackenLogoIcon} from 'src/components/Svgs';
import {auditList} from 'src/constants/auditors';
import styled from 'styled-components';

interface CustomComponentProps {
  specifiedAuditorName: string;
}

const AuditBanner: React.FC<CustomComponentProps> = ({specifiedAuditorName}) => {
  const selectedContent = auditList.find((content) => content.auditorName === specifiedAuditorName);

  if (!selectedContent) {
    return null;
  }

  return (
    <CustomLink to={selectedContent.link} target='_blank'>
      <Container>
        <ContentWrapper>
          <CheckedIcon />
          <Text>{selectedContent.text}</Text>
        </ContentWrapper>
        <Icon />
      </Container>
    </CustomLink>
  );
};

const CustomLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  color: #17e7d6;
  border-radius: 20px;
  border: 1px solid #17e7d6;
  padding-right: 5px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckedIcon = styled(CheckedBackgroundIcon)`
  margin-right: 5px;
`;

const Icon = styled(HackenLogoIcon)`
  // margin-right: 50px;
`;

const Text = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 12.6px;
  margin-right: 5px;
`;

export default AuditBanner;
