import {useMemo, useState} from 'react';

import {ArrowLeftIcon, ChevronDownIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IPlatform} from 'src/types';
import {convertFromISODate} from 'src/utils/date-util';
import {getPlatformIcon} from 'src/utils/icon-util';
import {localizeNumber, toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type DetailedCriteriaScoreProps = {
  platform: IPlatform;
  onBack?: () => void;
};

export const DetailedCriteriaScore = ({platform, onBack}: DetailedCriteriaScoreProps) => {
  const iconUrl = platform?.iconUrl || getPlatformIcon(platform.name);
  const [expandProjectAge, setExpandProjectAge] = useState(false);
  const [expandTVL, setExpandTVL] = useState(false);
  const [expandTokenMarketCap, setExpandTokenMarketCap] = useState(false);
  const launchDate = convertFromISODate(platform?.securityRating?.projectStart ?? '', 'yyyy-MM-DD');
  const projectAgeValue = platform?.securityRating?.projectAgeRating;
  const tvlRating = platform?.securityRating?.tvlRating;
  const tokenMarketCapValue = platform?.securityRating?.marketCapRating;
  const marketCapSymbol = platform?.governanceTokenChainSpecifics?.symbol;
  const smartContractsAudits = useMemo(() => {
    const rugdocValue = platform?.securityRating?.rugdocRiskLevel ?? 0;
    const cerTikvalue = platform?.securityRating?.certikTrustScore ?? 0;
    const cerLive = platform?.securityRating?.cerRating ?? 0;
    let count = 0;
    if (rugdocValue > 0) {
      count += 1;
    }
    if (cerTikvalue > 0) {
      count += 1;
    }
    if (cerLive > 0) {
      count += 1;
    }
    return (rugdocValue + cerTikvalue + cerLive) / count;
  }, [platform.securityRating]);
  return (
    <Wrapper>
      <Header>
        <IconButton onClick={onBack}>
          <ArrowLeftIcon />
        </IconButton>
        <BodyVariant color={COLORS.PRIMARY}>Detailed Criteria</BodyVariant>
        <div />
      </Header>
      <StyledRow gap={8}>
        <StyledImage src={iconUrl} />
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL}>{platform.name}</BodyParagraph>
      </StyledRow>
      <StyledFullRow marginTop={16}>
        <BodyVariant color={COLORS.PRIMARY}>Project Age</BodyVariant>
        <StyledRow>
          <BodyVariant color={COLORS.PRIMARY}>{projectAgeValue?.toFixed(0) ?? 'N /A'} </BodyVariant>
          <BodyParagraph color={COLORS.GRAY_LIGHT}>/ 15%</BodyParagraph>
        </StyledRow>
      </StyledFullRow>
      <StyledFullRow>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          Launch Date: {launchDate}
        </BodyParagraph>
        <ExpandButton onClick={() => setExpandProjectAge((prev) => !prev)}>
          <BodyParagraph color={COLORS.PRIMARY}>Details</BodyParagraph>
          {!expandProjectAge ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </ExpandButton>
      </StyledFullRow>
      {expandProjectAge && (
        <StyledExpandView>
          <StyledFullRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              Shorter than 30 days
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0 points
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              From 30 to 100 days
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0.5 points per day
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              From 100 to 180 days
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0.25 points per day
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              From 180 to 300 days
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0.1 points per day
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              From 300 to 500 days
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0.05 points per day
            </BodyParagraph>
          </StyledFullRow>
        </StyledExpandView>
      )}
      <StyledFullRow marginTop={10}>
        <BodyVariant color={COLORS.PRIMARY}>Total Value Locked</BodyVariant>
        <StyledRow>
          <BodyVariant color={COLORS.PRIMARY}>{tvlRating?.toFixed(0) ?? 'N / A'} </BodyVariant>
          <BodyParagraph color={COLORS.GRAY_LIGHT}>/ 25%</BodyParagraph>
        </StyledRow>
      </StyledFullRow>
      <StyledFullRow>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          TVL: ${localizeNumber(toFixed(platform.securityRating?.tvl || 0, 0))}
        </BodyParagraph>
        <ExpandButton onClick={() => setExpandTVL((prev) => !prev)}>
          <BodyParagraph color={COLORS.PRIMARY}>Details</BodyParagraph>
          {!expandTVL ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </ExpandButton>
      </StyledFullRow>
      {expandTVL && (
        <StyledExpandView>
          <StyledFullRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              Less than $1 million
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0 points
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $1m till $25 million
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              2 points per $1m TVL
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $25m till $50 millio
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              1 point per $1m TVL
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $50m till $100 million
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              1 point per $5m TVL
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $100m till $250 million
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              1 point per $10 TVL
            </BodyParagraph>
          </StyledFullRow>
        </StyledExpandView>
      )}
      <StyledFullRow marginTop={10}>
        <BodyVariant color={COLORS.PRIMARY}>Token Market Cap</BodyVariant>
        <StyledRow>
          <BodyVariant color={COLORS.PRIMARY}>{tokenMarketCapValue?.toFixed(0) ?? 'N / A'} </BodyVariant>
          <BodyParagraph color={COLORS.GRAY_LIGHT}>/ 20%</BodyParagraph>
        </StyledRow>
      </StyledFullRow>
      <StyledFullRow>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          {marketCapSymbol}: ${localizeNumber(toFixed(platform.securityRating?.governanceTokenMarketCap || 0, 0))}
        </BodyParagraph>
        <ExpandButton onClick={() => setExpandTokenMarketCap((prev) => !prev)}>
          <BodyParagraph color={COLORS.PRIMARY}>Details</BodyParagraph>
          {!expandTokenMarketCap ? <ChevronDownIcon /> : <ChevronUpIcon />}
        </ExpandButton>
      </StyledFullRow>
      {expandTokenMarketCap && (
        <StyledExpandView>
          <StyledFullRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              Less than $1 million
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              0 points
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $1m - $10m
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              5 points per $1m
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $10m - $20m
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              3 points per $1m
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $20m - $100
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              1 point per $10m
            </BodyParagraph>
          </StyledFullRow>
          <StyledFullRow marginTop={4}>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              $100m - $500m
            </BodyParagraph>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
              1 point per $40m
            </BodyParagraph>
          </StyledFullRow>
        </StyledExpandView>
      )}
      <StyledFullRow marginTop={10}>
        <BodyVariant color={COLORS.PRIMARY}>Smart Contracts & Audits</BodyVariant>
        <StyledRow>
          <BodyVariant color={COLORS.PRIMARY}>{smartContractsAudits?.toFixed(0)}</BodyVariant>
          <BodyParagraph color={COLORS.GRAY_LIGHT}>/ 40%</BodyParagraph>
        </StyledRow>
      </StyledFullRow>
      <StyledFullRow>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.GRAY_LIGHT}>
          Rating is based on the following third-party security assessment agencies’ average score.
        </BodyParagraph>
      </StyledFullRow>
      <StyledFullRow marginTop={8}>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          RugDoc:
        </BodyParagraph>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          {platform?.securityRating?.rugdocRiskLevel ?? 'N / A'}
        </BodyParagraph>
      </StyledFullRow>
      <StyledFullRow marginTop={8}>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          Certik:
        </BodyParagraph>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          {platform?.securityRating?.certikTrustScore ?? 'N / A'}
        </BodyParagraph>
      </StyledFullRow>
      <StyledFullRow marginTop={8}>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          Cer.Live:
        </BodyParagraph>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          {platform?.securityRating?.cerRating ?? 'N / A'}
        </BodyParagraph>
      </StyledFullRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const StyledFullRow = styled.div<{marginTop?: number}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledImage = styled.img`
  width: 22px;
  height: 22px;
`;

const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledExpandView = styled.div`
  background-color: ${COLORS.DISABLED};
  padding: 10px 24px;
  margin: 0 -24px;
`;

const ChevronUpIcon = styled(ChevronDownIcon)`
  transform: rotate(180deg);
`;
