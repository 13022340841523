//   WhitelistEvent.tsx

import React from 'react';

import WhitelistEventImage from 'src/assets/images/banners/GENERAL.svg';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {BUTTON_SIZE_ENUM, Button} from '../Buttons';
import {BodyVariant} from '../Typography';

export const WhitelistEvent = ({...props}) => {
  return (
    <Wrapper {...props}>
      <StyledRowBetween>
        <BodyVariant
          style={{width: '159px'}}
          color={COLORS.WHITE}
          size={BODY_FONT_ENUM.LARGE}
          mobile={BODY_FONT_ENUM.LARGE_MOBILE}
        >
          $TOKEN Whitelist Events
        </BodyVariant>
        <StyledLogo src={WhitelistEventImage} />
      </StyledRowBetween>
      <StyledRowBetween>
        <StyledButton size={BUTTON_SIZE_ENUM.XSMALL_BOLD} title='Coming Soon' onClick={() => null} />
        <StyledAPYLabel>+ XX% APY</StyledAPYLabel>
      </StyledRowBetween>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  height: 200px;
  padding: 20px 14px;
  box-sizing: border-box;
  background: #112455;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }
`;

const StyledRowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: 60px;
`;

const StyledButton = styled(Button)`
  border-radius: 20px;
  font-weight: 700 !important;
`;

const StyledAPYLabel = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  color: ${COLORS.SECONDARY};

  @media (max-width: ${DEVICE_ENUM.md}) {
    font-size: 22px;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 16px;
  }
`;
