import React, {useCallback, useContext, useEffect, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {useNavigate} from 'react-router';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {paths} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useDepositCallback, useToken} from 'src/hooks';
import {ApprovalState, getLpToken, useApproveCallback} from 'src/hooks/kyber-swap';
import {COLORS} from 'src/styles';
import {ILiquidityPool, IYieldFarm, POOL_TYPE} from 'src/types';
import {tryParseAmount} from 'src/utils/swap/kyber-swap';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import styled from 'styled-components';

import {ApproveLPTokens} from './ApproveLPTokens';
import {DepositLPToFarm} from './DepositLPToFarm';
import {SuccessTopup} from './SuccessTopup';

export type DepositModalProps = {
  isOpen: boolean;
  onDepositSuccess?: () => void;
  onDismiss?: () => void;
  farm?: IYieldFarm;
  lp?: ILiquidityPool;
};

export const DepositModal = ({isOpen = false, onDepositSuccess, onDismiss, farm, lp}: DepositModalProps) => {
  const {refreshTokens, refreshFarmsAndLPs} = useContext(AppContext);
  const {getTokenByAddress} = useToken();
  const {account, chainId} = useWeb3React();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const title = step !== 2 ? 'Deposit to Farm' : 'Deposit to Farm Successful';
  const outputToken1 = getTokenByAddress(farm?.liquidityPool?.token0Hash);
  const outputToken2 = getTokenByAddress(farm?.liquidityPool?.token1Hash);

  const lpTokensAmount = lp?.balance;
  const lpTokensAmountNumber = formatBigNumber(lpTokensAmount, lp?.decimals);
  const lpTokensUsdValue = getTokenUSDPrice(lpTokensAmountNumber, lp?.lpTokenPriceUSD);
  const lpTokenCurrency = getLpToken(lp?.address?.hash, chainId);
  const lpTokenCurrencyAmount = tryParseAmount(lpTokensAmount, lpTokenCurrency, false);

  const [lpTokenApprovalState, lpTokenApproveCallback] = useApproveCallback(
    lpTokenCurrencyAmount,
    account,
    farm?.contractAddress?.hash ?? farm?.masterChefAddress?.hash ?? farm?.address?.hash,
  );

  const {estimateGasForDeposit, deposit} = useDepositCallback(farm);

  useEffect(() => {
    if (lpTokenApprovalState === ApprovalState.APPROVED) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [isOpen, lpTokenApprovalState]);

  const onApproveLPToken = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await lpTokenApproveCallback();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [lpTokenApproveCallback]);

  const onConfirm = useCallback(async () => {
    if (!deposit) {
      return;
    }
    setLoading(true);
    try {
      const gasEstimation = await estimateGasForDeposit(lpTokensAmount);
      const depositResult = await deposit(gasEstimation, lpTokensAmount);
      if (!depositResult) {
        setLoading(false);
        return;
      }
      const tx2 = await depositResult.wait();
      console.log(tx2);
      onDepositSuccess && onDepositSuccess();
      setStep(2);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [deposit, estimateGasForDeposit, lpTokensAmount, onDepositSuccess]);

  const onSuccess = useCallback(async () => {
    refreshTokens(true);
    refreshFarmsAndLPs();
    onDismiss();
    navigate(
      `${paths.poolDetail}?type=${POOL_TYPE.FARMING_POOL}&address=${
        farm.contractAddress ? farm.contractAddress?.hash : farm?.liquidityPool.address.hash
      }&farm-type=${farm.type}`,
    );
  }, [
    farm?.contractAddress,
    farm?.liquidityPool?.address,
    farm?.type,
    onDismiss,
    refreshFarmsAndLPs,
    refreshTokens,
    navigate,
  ]);

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={() => onDismiss()}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <ApproveLPTokens
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            loading={loading}
            disabled={loading}
            onConfirm={onApproveLPToken}
          />
        )}
        {step === 1 && (
          <DepositLPToFarm
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            totalUsdAmount={lpTokensUsdValue}
            platform={farm?.platform}
            apy={farm?.apy}
            loading={loading}
            disabled={loading}
            onConfirm={onConfirm}
          />
        )}
        {step === 2 && (
          <SuccessTopup
            outputToken1={outputToken1}
            outputToken2={outputToken2}
            totalUsdAmount={lpTokensUsdValue}
            platform={farm?.platform}
            apy={farm?.apy}
            onDone={onSuccess}
          />
        )}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Set maximum height to 80% of the viewport height
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
