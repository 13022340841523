import React, {useCallback, useContext, useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import {Modal} from 'src/components/Modals';
import {CloseIcon} from 'src/components/Svgs';
import {AppContext} from 'src/contexts/AppContext';
import {useSingleFarmWithdraw, useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import {isTransactionSuccessful} from 'src/utils/utils';
import styled from 'styled-components';

import {ApproveLPTokens} from './ApproveTokens';
import {SuccessWithdraw} from './SuccessWithdraw';
import {SwapTokens} from './SwapTokens';
import {WithdrawFromFarm} from './WithdrawFromFarm';
import {FailedTransaction} from '../FailedTransaction';

export type SingleWithdrawModalProps = {
  isOpen: boolean;
  onWithdrawSuccess?: () => void;
  onDismiss?: () => void;
  inputToken?: IWhitelistToken;
  inputTokenAmount?: BigNumber;
  outputToken?: IWhitelistToken;
  outputTokenEstimatedAmount?: number;
  farm?: IYieldFarm;
};

export const SingleWithdrawModal = ({
  isOpen = false,
  onWithdrawSuccess,
  onDismiss,
  inputToken,
  inputTokenAmount,
  outputToken,
  outputTokenEstimatedAmount,
  farm,
}: SingleWithdrawModalProps) => {
  const {refreshTokens, refreshFarmsAndLPs, farmLoading} = useContext(AppContext);
  const {getTokenByAddress} = useToken();
  const [step, setStep] = useState(0);
  const [retryStep, setRetryStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const isSameToken = inputToken?.symbol === outputToken?.symbol;
  const title = step !== 3 ? 'Withdraw' : 'Withdraw Successful';
  const rewardsToken = getTokenByAddress(farm?.rewardTokenChainSpecifics?.address?.hash);
  const {isSwapTokenApproved, swapTokenApproveCallback, swapCallback, withdrawTokensCallback} = useSingleFarmWithdraw(
    inputToken,
    outputToken,
    inputTokenAmount,
    farm,
  );
  const inputTokenAmountValue = formatBigNumber(inputTokenAmount, inputToken?.decimals);
  const usdOutputAmount = getTokenUSDPrice(
    outputTokenEstimatedAmount,
    outputToken?.priceUSD,
    outputToken?.priceDecimals,
  );
  const usdRewardsAmount = getTokenUSDPrice(farm?.pendingReward, rewardsToken?.priceUSD, rewardsToken?.priceDecimals);
  const usdTotalOutputAmount = usdOutputAmount + usdRewardsAmount;

  useEffect(() => {
    setStep(0);
  }, [isOpen]);

  useEffect(() => {
    if (loading && !farmLoading) setLoading(false);
    if (isSwapTokenApproved && step === 1) {
      setStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwapTokenApproved, step, farmLoading]);

  const onWithdrawFromFarm = useCallback(async () => {
    setLoading(true);
    try {
      const withdrawResult = await withdrawTokensCallback();
      if (!withdrawResult) {
        setLoading(false);
        return;
      }
      const tx2 = await withdrawResult.wait();
      console.log(tx2);
      if (isTransactionSuccessful(tx2)) {
        refreshTokens(true);
        refreshFarmsAndLPs();
        onWithdrawSuccess && onWithdrawSuccess();
        if (isSameToken) {
          setStep(3);
        } else {
          setStep(1);
        }
      } else setStep(4);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [isSameToken, onWithdrawSuccess, refreshFarmsAndLPs, refreshTokens, withdrawTokensCallback]);

  const onApproveToken = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await swapTokenApproveCallback();
      await tx.wait();
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [swapTokenApproveCallback]);

  const onSwapToken = useCallback(async () => {
    setLoading(true);
    try {
      const tx = await swapCallback();
      await tx.wait();
      if (isTransactionSuccessful(tx)) {
        refreshTokens(true);
        refreshFarmsAndLPs();
        onWithdrawSuccess && onWithdrawSuccess();
        setStep(3);
      } else {
        setStep(4);
        setRetryStep(2);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [onWithdrawSuccess, refreshFarmsAndLPs, refreshTokens, swapCallback]);

  const onRetry = () => {
    refreshTokens(true);
    refreshFarmsAndLPs();
    setStep(retryStep);
    setLoading(true);
    setRetryStep(0);
  };

  return (
    <Modal isOpen={isOpen}>
      <Wrapper>
        <Header>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={() => onDismiss()}>
            <CloseIcon color={COLORS.PRIMARY} />
          </IconButton>
        </Header>
        {step === 0 && (
          <WithdrawFromFarm
            inputToken={inputToken}
            inputTokenAmount={inputTokenAmountValue}
            platform={farm?.platform}
            rewardsToken={rewardsToken}
            rewards={farm?.pendingReward}
            totalUsdAmount={usdTotalOutputAmount}
            isSameToken={isSameToken}
            loading={loading}
            disabled={loading}
            onConfirm={onWithdrawFromFarm}
          />
        )}
        {step === 1 && (
          <ApproveLPTokens inputToken={inputToken} loading={loading} disabled={loading} onConfirm={onApproveToken} />
        )}
        {step === 2 && (
          <SwapTokens
            inputToken={inputToken}
            outputToken={outputToken}
            inputTokenAmount={inputTokenAmountValue}
            outputTokenAmount={outputTokenEstimatedAmount}
            totalUsdAmount={usdTotalOutputAmount}
            loading={loading}
            disabled={loading}
            onConfirm={onSwapToken}
          />
        )}
        {step === 3 && (
          <SuccessWithdraw
            inputToken={inputToken}
            outputToken={outputToken}
            inputTokenAmount={inputTokenAmountValue}
            outputTokenAmount={outputTokenEstimatedAmount}
            totalUsdAmount={usdTotalOutputAmount}
            platform={farm?.platform}
            rewardsToken={rewardsToken}
            rewards={farm?.pendingReward}
            isSameToken={isSameToken}
            onDone={onDismiss}
          />
        )}
        {step === 4 && <FailedTransaction onExit={onRetry} />}
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 16px 16px 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
