import * as React from 'react';

import {COLORS} from 'src/styles/colors';
import {IconType} from 'src/types';

export const CopyIcon = ({size = 25, color = COLORS.SECONDARY, ...props}: IconType) => (
  <svg width={size} height={size} viewBox='0 0 25 25' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.73359 16.2184C8.50181 15.9866 8.37872 15.6728 8.37871 15.3456L8.3648 6.27451C8.3648 5.94735 8.48789 5.6335 8.71968 5.40172C8.95146 5.16994 9.26531 5.04684 9.59247 5.04684L18.6775 5.04684C19.3318 5.04684 19.8913 5.60633 19.8913 6.26065L19.8913 15.3457C19.8913 15.6728 19.7682 15.9867 19.5364 16.2185C19.3046 16.4503 18.9908 16.5733 18.6636 16.5734L9.59307 16.5867C9.26538 16.5873 8.96538 16.4503 8.73359 16.2185V16.2184ZM9.97463 6.65651L9.98854 14.9635L18.3094 14.9635L18.2955 6.65651H9.97463Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.41534 19.5367C5.18356 19.3049 5.06047 18.991 5.06046 18.6639L5.06046 9.57886C5.06046 9.14192 5.42866 8.77372 5.86505 8.77427C6.30199 8.77427 6.67018 9.14246 6.66964 9.57886L6.67018 18.2817L15.3725 18.2817C15.8094 18.2817 16.1776 18.6499 16.177 19.0863C16.177 19.5232 15.8088 19.8914 15.3725 19.8908L6.27474 19.9046C5.94758 19.9046 5.64704 19.7682 5.41526 19.5364L5.41534 19.5367Z'
      fill={color}
    />
  </svg>
);

export default CopyIcon;
