import React, {useEffect, useState} from 'react';

import {toast} from 'react-toastify';
import {CLASSIC_LIQ_GLOBAL_NAME} from 'src/constants';
import {useToken} from 'src/hooks';
import {calculatePrice} from 'src/utils/token-util';

import ArrowRightIcon from '../../assets/images/arrow-down-verticle.svg';
import classic_liq from '../../assets/images/tokens/LIQ_Secondary.png';
import new_liq from '../../assets/images/tokens/LIQIcon.svg';
import successfull_pic from '../../assets/images/transaction/transaction-success.png';

export default function TokenMigrationModel({
  setOpenPopup,
  coins,
  approveCoins,
  migrateTokens,
  netId,
  approvalRequired,
}) {
  const [approved, setApproved] = useState(!approvalRequired);
  const [headerText, setHeaderText] = useState('Confirm Migrate LIQ');
  const [isLoading, setLoading] = useState(false);
  const [classicLiqPrice, setClassicLiqPrice] = useState(0.8);
  const [isSuccessfull, setSuccessfull] = useState(false);
  const {getTokenByGlobalName} = useToken();

  const oldLiq = getTokenByGlobalName(CLASSIC_LIQ_GLOBAL_NAME);
  const oldLiqPrice = calculatePrice(oldLiq?.priceUSD, oldLiq?.decimals);

  async function migrate() {
    setLoading(true);
    let isMigrated = await migrateTokens();
    if (isMigrated) {
      setSuccessfull(true);
    } else {
      toast.error('Transaction failed', {
        autoClose: 5000,
        theme: 'colored',
        position: 'top-center',
      });
      setOpenPopup(false);
    }
    setLoading(false);
  }
  useEffect(() => {
    if (isLoading) {
      setHeaderText('Waiting for confirmation');
    } else if (isSuccessfull) {
      setHeaderText('Migration Successfull');
    } else {
      setHeaderText('Confirm Migrate LIQ');
    }
  }, [isLoading, isSuccessfull]);

  useEffect(() => {
    setClassicLiqPrice(oldLiqPrice);
  }, [oldLiqPrice]);

  async function takeApproval() {
    setLoading(true);
    let isApproved = await approveCoins();

    if (isApproved) {
      setApproved(true);
    } else {
      toast.error('Approval failed', {
        autoClose: 5000,
        theme: 'colored',
        position: 'top-center',
      });
      setOpenPopup(false);
    }
    setLoading(false);
  }
  return (
    <div className='token-migration-model-backdrop'>
      {!approved && netId === 56 ? (
        <div className='token-migration-model'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          ></div>
          {isLoading && <div className='token-migration-loader'></div>}
          <h1
            style={{
              fontWeight: 400,
              fontFamily: "'Playfair Display', serif",
              color: '#112455',
            }}
          >
            Approve Token
          </h1>
          <div style={{padding: '50px 0'}}>
            <div className='coin-name-chip'>
              <img src={classic_liq} alt='' />
              <p style={{color: '#9AA6CF', fontSize: '16px', fontWeight: 600}}>Classic LIQ</p>
            </div>
          </div>
          <button onClick={takeApproval} disabled={isLoading} className='primary-button-token-migration'>
            Approve
          </button>
        </div>
      ) : (
        <div className='token-migration-model'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          ></div>
          {isLoading && <div className='token-migration-loader'></div>}
          {isSuccessfull && <img style={{paddingBottom: 20, height: '100px'}} src={successfull_pic} alt='Success' />}
          <h1
            style={{
              fontWeight: 400,
              fontFamily: "'Playfair Display', serif",
              textAlign: 'center',
              color: '#112455',
            }}
          >
            {headerText}
          </h1>
          <div
            style={{
              padding: '50px 0',
              display: 'flex',
              gap: 20,
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <div className='coin-name-chip'>
              <img src={classic_liq} alt='' />
              <p style={{color: '#9AA6CF', fontSize: '16px', fontWeight: 600}}>Classic LIQ</p>
              <span>
                <b style={{color: '#112455'}}>{coins}</b>
              </span>
            </div>
            <span className='arrow-icon'>
              <img src={ArrowRightIcon} alt='' style={{marginLeft: '8px'}} />
            </span>
            <div className='coin-name-chip'>
              <img src={new_liq} alt='' />
              <p style={{color: '#9AA6CF', fontSize: '16px', fontWeight: 600}}>New LIQ</p>
              <span>
                <b style={{color: '#112455'}}>{coins / 5.0}</b>
              </span>
            </div>
            <p className='migrate_level_dollar'>= ${(coins * classicLiqPrice).toFixed(2)}</p>

            {isSuccessfull && (
              <div className='cross_chain'>
                <h3>{netId !== 56 ? 'Cross-Chain Migration' : 'New LIQ arrive next month'}</h3>
                <p>Your New LIQ are going to arrive on your account on BSC automatically at the next shuttle date.</p>
              </div>
            )}
          </div>

          <button
            className='primary-button-token-migration'
            onClick={() => {
              if (approved && !isSuccessfull) {
                migrate();
              }
              if (approved && isSuccessfull && !isLoading) {
                setOpenPopup(false);
              }
            }}
            disabled={isLoading && !isSuccessfull}
            style={{
              backgroundColor: isLoading && !isSuccessfull && '#d4d4d4',
              color: '#112455',
            }}
          >
            {isSuccessfull ? 'Done' : isLoading ? '...' : 'Confirm Migrate LIQ'}
          </button>
        </div>
      )}
    </div>
  );
}
