import React from 'react';

import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IPlatform, IWhitelistToken} from 'src/types';
import {getPlatformIcon} from 'src/utils/icon-util';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type WithdrawFromFarmProps = {
  inputToken?: IWhitelistToken;
  inputTokenAmount?: number;
  totalUsdAmount?: number;
  platform?: IPlatform;
  apy?: number;
  rewardsToken?: IWhitelistToken;
  rewards?: number;
  isSameToken?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
};

export const WithdrawFromFarm = ({
  inputToken,
  inputTokenAmount,
  totalUsdAmount,
  apy,
  rewardsToken,
  rewards,
  platform,
  isSameToken,
  loading,
  disabled,
  onConfirm,
}: WithdrawFromFarmProps) => {
  const iconUrl = platform?.iconUrl || getPlatformIcon(platform?.name);

  return (
    <Body>
      <StpesContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Withdraw from Farm</BodyVariant>
        </StyledRow>
        {!isSameToken && (
          <>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox>
                <BodyVariant color={COLORS.GRAY_LIGHT}>2</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Tokens</BodyVariant>
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox>
                <BodyVariant color={COLORS.GRAY_LIGHT}>3</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>Swap Tokens</BodyVariant>
            </StyledRow>
          </>
        )}
      </StpesContainer>
      <TokenInfoBox>
        <StyledFullRow>
          <StyledRow>
            <StyledImage src={iconUrl} />
            <BodyVariant color={COLORS.PRIMARY}>{platform?.name}</BodyVariant>
          </StyledRow>
          <BodyVariant color={COLORS.PRIMARY}>{`${apy?.toFixed(1) ?? 0}% APY`}</BodyVariant>
        </StyledFullRow>
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledRow>
          <TokenItem token={inputToken} value={inputTokenAmount?.toFixed(inputToken?.interfaceDecimals)} />
        </StyledRow>
        <ArrowBox>
          <PlusLabel>+</PlusLabel>
        </ArrowBox>
        <StyledFullRow>
          <TokenItem token={rewardsToken} value={rewards?.toFixed(2)} />
          <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.LARGE}>
            HARVESTED REWARDS
          </BodyParagraph>
        </StyledFullRow>
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title='Withdraw from Farm'
          onClick={onConfirm}
        />
        <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StpesContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginLeft?: number}>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.marginLeft ?? 0}px;
  gap: 10px;
`;

const StyledFullRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 22px 25px 10px;
`;

const ArrowBox = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PlusLabel = styled.p`
  font-size: 26px;
  font-weight: 400;
  color: ${COLORS.GRAY_LIGHT};
  margin: 0;
`;

const StyledImage = styled.img`
  width: 36px;
  height: 30px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;
