import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import styled from 'styled-components';

import {ChevronDownIcon} from '../Svgs';
import {BodyVariant} from '../Typography';

type Size = 'default' | 'small';

interface TokenSelectItemProps {
  token?: IWhitelistToken;
  disabled?: boolean;
  size?: Size;
  onSelect?: () => void;
  isImportPool?: boolean;
}

export const TokenSelectItem = ({token, disabled, size = 'default', onSelect, isImportPool}: TokenSelectItemProps) => {
  const tokenUrl = token?.iconUrl ?? getTokenIconByGlobalName(token?.globalName);
  return (
    <Wrapper disabled={disabled} onClick={onSelect} isImportPool={isImportPool}>
      {token ? (
        <>
          <StyledImage src={tokenUrl} size={size} />
          <BodyVariant style={{fontSize: size === 'default' ? 16 : 12}} color={COLORS.PRIMARY}>
            {token?.symbol}
          </BodyVariant>
        </>
      ) : (
        <StyledBodyVariant style={{fontSize: size === 'default' ? 16 : 14}} color={COLORS.PRIMARY}>
          {isImportPool ? 'Select a Token' : 'Select'}
        </StyledBodyVariant>
      )}
      <ChevronWrapper>{!disabled && <ChevronDownIcon />}</ChevronWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.button<{isImportPool: boolean}>`
  display: flex;
  ${(props) => props.isImportPool && 'width: 100%;'}
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
`;

const StyledImage = styled.img<{size?: Size}>`
  width: ${(props) => (props.size === 'default' ? 30 : 20)}px;
  height: ${(props) => (props.size === 'default' ? 30 : 20)}px;
  margin-right: 8px;
`;

const StyledBodyVariant = styled(BodyVariant)`
  white-space: nowrap;
`;

const ChevronWrapper = styled.div`
  margin-left: auto;
`;
