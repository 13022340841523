import React from 'react';

import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon, TickIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, DEVICE_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type RemoveLiquidityPoolProps = {
  lpToken1?: IWhitelistToken;
  lpToken2?: IWhitelistToken;
  lpToken1Amount?: number;
  lpToken2Amount?: number;
  outputToken?: IWhitelistToken;
  outputTokenAmount?: number;
  keepTokensMode?: boolean;
  totalUsdAmount?: number;
  lpMode?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
  zapOutError?: boolean;
  zapOutErrorMessage?: string;
};

export const RemoveLiquidityPool = ({
  lpToken1,
  lpToken2,
  lpToken1Amount,
  lpToken2Amount,
  outputToken,
  outputTokenAmount,
  keepTokensMode,
  totalUsdAmount,
  lpMode,
  loading,
  disabled,
  onConfirm,
  zapOutError,
  zapOutErrorMessage,
}: RemoveLiquidityPoolProps) => {
  return (
    <Body>
      <StpesContainer>
        {lpMode ? (
          <>
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>Approve LP Tokens</BodyVariant>
              <TickIcon color={COLORS.SECONDARY} />
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.PRIMARY}>
                {keepTokensMode ? 'Remove Liquidity' : 'Remove Liquidity & Swap'}
              </BodyVariant>
            </StyledRow>
          </>
        ) : (
          <>
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>Withdraw LP from Farm</BodyVariant>
              <TickIcon color={COLORS.SECONDARY} />
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.GRAY_LIGHT}>Approve LP Tokens</BodyVariant>
              <TickIcon color={COLORS.SECONDARY} />
            </StyledRow>
            <StyledVerticalLine />
            <StyledRow>
              <RoundedBox active>
                <BodyVariant color={COLORS.PRIMARY}>3</BodyVariant>
              </RoundedBox>
              <BodyVariant color={COLORS.PRIMARY}>
                {keepTokensMode ? 'Remove Liquidity' : 'Remove Liquidity & Swap'}
              </BodyVariant>
            </StyledRow>
          </>
        )}
      </StpesContainer>
      <TokenInfoBox>
        {!keepTokensMode ? (
          <>
            <TokenItem token={lpToken1} value={lpToken1Amount?.toFixed(lpToken1?.interfaceDecimals)} />
            <StyledTokenItem token={lpToken2} value={lpToken2Amount?.toFixed(lpToken2?.interfaceDecimals)} />
          </>
        ) : (
          <StyledRow marginTop={12}>
            <TokenBox>
              <TokenIcon width={24} height={24} token={lpToken1} />
              <SecondTokenIcon width={24} height={24} token={lpToken2} />
            </TokenBox>
            <BodyVariant color={COLORS.PRIMARY}>
              {lpToken1?.symbol}-{lpToken2?.symbol}
            </BodyVariant>
          </StyledRow>
        )}
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        {!keepTokensMode ? (
          <StyledTokenItem token={outputToken} value={outputTokenAmount?.toFixed(outputToken?.interfaceDecimals)} />
        ) : (
          <>
            <StyledTokenItem token={lpToken1} value={lpToken1Amount?.toFixed(lpToken1?.interfaceDecimals)} />
            <StyledTokenItem token={lpToken2} value={lpToken2Amount?.toFixed(lpToken2?.interfaceDecimals)} />
          </>
        )}
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title={keepTokensMode ? 'Remove Liquidity' : 'Remove Liquidity & Swap'}
          onClick={onConfirm}
        />
        {zapOutError ? (
          <>
            <StyledParagraph color={COLORS.WARNING}>{zapOutErrorMessage}</StyledParagraph>
          </>
        ) : (
          <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
        )}
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StpesContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div<{marginTop?: number}>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: 10px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 26px 25px 16px;
`;

const TokenBox = styled.div`
  display: flex;
  margin-right: 10px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-right: 0;
  }
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -12px;
  margin-top: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-left: -8px;
    margin-top: 0;
  }
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
