import {ChainId} from '@kyberswap/ks-sdk-core';

export interface SerializableTransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  blockHash: string;
  transactionHash: string;
  blockNumber: number;
  status?: number;
}

// ex: approve knc, stake 2 knc
export type TransactionExtraInfo1Token = {
  tokenAddress: string;
  tokenSymbol: string;
  tokenAmount?: string;
  contract?: string; // recipient, contract, spender, ...
};

// ex: swap 2knc to 2usdt
export type TransactionExtraInfo2Token = {
  tokenAddressIn: string;
  tokenAddressOut: string;
  tokenSymbolIn: string;
  tokenSymbolOut: string;
  tokenAmountIn: string;
  tokenAmountOut: string;

  contract?: string; // recipient, contract, spender, ...
  chainIdIn?: ChainId;
  chainIdOut?: ChainId;
  nftId?: string;
};

export type TransactionExtraInfoHarvestFarm = {
  tokenAddressIn?: string;
  tokenAddressOut?: string;
  tokenSymbolIn?: string;
  tokenSymbolOut?: string;
  rewards: {tokenAddress: string; tokenSymbol: string; tokenAmount: string}[];
  contract?: string; // recipient, contract, spender, ...
};

export type TransactionExtraInfoStakeFarm = {
  pairs: {
    tokenAddressIn: string;
    tokenAddressOut: string;
    tokenSymbolIn: string;
    tokenSymbolOut: string;
    tokenAmountIn: string;
    tokenAmountOut: string;
    poolAddress: string;
    nftId: string;
  }[];
  contract?: string; // recipient, contract, spender, ...
};

export type TransactionExtraBaseInfo = {
  summary?: string;
  contract?: string; // recipient, contract, spender, ...
};

// structure data, let's create a new type if your transaction does not match 1 of 3 template
export type TransactionExtraInfo = (
  | TransactionExtraInfo1Token
  | TransactionExtraInfo2Token
  | TransactionExtraBaseInfo
  | TransactionExtraInfoHarvestFarm
  | TransactionExtraInfoStakeFarm
) & {
  actuallySuccess?: boolean;
  needCheckSubgraph?: boolean;
  arbitrary?: unknown; // To store anything arbitrary, so it has unknown type
};

export interface TransactionDetails {
  hash: string;
  type: TRANSACTION_TYPE;
  receipt?: SerializableTransactionReceipt;
  lastCheckedBlockNumber?: number;
  addedTime: number;
  confirmedTime?: number;
  from: string;
  to?: string;
  data?: string;
  nonce?: number;
  sentAtBlock?: number;
  extraInfo?: TransactionExtraInfo;
  group: TRANSACTION_GROUP;
  chainId: ChainId;
}

export interface GroupedTxsByHash {
  [firstTxHash: string]: TransactionDetails[] | undefined;
}

export type TransactionHistory = {
  hash: string;
  desiredChainId?: ChainId; // ChainID after switching.
  type: TRANSACTION_TYPE;
  firstTxHash?: string;
  extraInfo?: TransactionExtraInfo;
};

export type TransactionPayload = TransactionHistory & {
  from: string;
  to?: string;
  nonce?: number;
  data?: string;
  sentAtBlock?: number;
  chainId: ChainId;
};

/**
 * when you put a new type, let's do:
 * 1. classify it by putting it into GROUP_TRANSACTION_BY_TYPE
 * 2. add a case in SUMMARY in TransactionPopup.tsx to render notification detail by type
 * 3. add a case in RENDER_DESCRIPTION_MAP in TransactionItem.tsx to render transaction detail by type
 * if you forgot. typescript error will occur.
 */
export enum TRANSACTION_TYPE {
  WRAP_TOKEN = 'Wrap Token',
  UNWRAP_TOKEN = 'Unwrap Token',
  APPROVE = 'Approve',
  BRIDGE = 'Bridge Token',
  SWAP = 'Swap',

  STAKE = 'Stake Into Farm',
  UNSTAKE = 'Unstake From Farm',

  HARVEST = 'Harvest',
  CANCEL_LIMIT_ORDER = 'Cancel Limit Order',
  TRANSFER_TOKEN = 'Send',
}

export const GROUP_TRANSACTION_BY_TYPE = {
  SWAP: [TRANSACTION_TYPE.SWAP, TRANSACTION_TYPE.WRAP_TOKEN, TRANSACTION_TYPE.UNWRAP_TOKEN],
  LIQUIDITY: [TRANSACTION_TYPE.STAKE, TRANSACTION_TYPE.UNSTAKE, TRANSACTION_TYPE.HARVEST],
  OTHER: [
    // to make sure you don't forgot
    TRANSACTION_TYPE.APPROVE,
    TRANSACTION_TYPE.BRIDGE,
    TRANSACTION_TYPE.CANCEL_LIMIT_ORDER,
    TRANSACTION_TYPE.TRANSFER_TOKEN,
  ],
};

export enum TRANSACTION_GROUP {
  SWAP = 'swap',
  LIQUIDITY = 'liquidity',
  OTHER = 'other',
}

const totalType = Object.values(TRANSACTION_TYPE).length;
const totalClassify = Object.values(GROUP_TRANSACTION_BY_TYPE).reduce((total, element) => total + element.length, 0);
if (totalType !== totalClassify) {
  throw new Error('Please set up group of the new transaction. Put your new type into GROUP_TRANSACTION_BY_TYPE');
}

export type WalletTokenTransfersResponseType = {
  page: number;
  page_size: number;
  cursor: string;
  result: Transaction[];
};

export type DetailedTokenTransferResponseType = {
  getWalletTokenTransfers: WalletTokenTransfersResponseType;
};

export type Transaction = {
  token_name: string;
  token_symbol: string;
  token_logo: string;
  token_decimals: string;
  from_address: string;
  from_address_label: string;
  to_address: string;
  to_address_label: string;
  address: string;
  block_hash: string;
  block_number: string;
  block_timestamp: string;
  transaction_hash: string;
  transaction_index: number;
  log_index: number;
  value: string;
  possible_spam: boolean;
  value_decimal: string;
};
