import LiquidusBoostIcon from 'src/assets/images/tiers/liq-boost.svg';
import NextLevel from 'src/assets/images/tiers/next-level.svg';
import OptionPlusIcon from 'src/assets/images/tiers/option-plus.svg';
import SecuurityRatingIcon from 'src/assets/images/tiers/security-rating.svg';
import SilverFeeIcon from 'src/assets/images/tiers/silver-fee.svg';
import TickCircle from 'src/assets/images/tiers/tick-circle-green.svg';
import TokenStakedIcon from 'src/assets/images/tiers/token-staked.svg';
import {Button} from 'src/components/Buttons';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {PremiumModes} from 'src/constants/premiums';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {toFixedRound} from 'src/utils/text-util';
import styled from 'styled-components';

type PremiumTierProps = {
  data?: {userHolding: number; holdingInfo: number[]; feeMode: number};
  index: number;
  handleStakeLiq: () => void;
};

export const PremiumTierBox = ({data, index, handleStakeLiq}: PremiumTierProps) => {
  const feeMode = data?.feeMode;
  const holdingInfo = data?.holdingInfo;
  const feePercent = holdingInfo && holdingInfo[1 + index] / 100;
  const liqBoost = holdingInfo && holdingInfo[5 + index];
  const reducedFeePercent = feePercent - 0.1;

  return (
    <Wrapper backgroundColor={PremiumModes[index].background}>
      <StyledFullRow gap={32}>
        <StyledCol flex gap={10}>
          <StyledRow>
            <StyledRow gap={12} itemsCenter>
              <StyledImg size={56} src={PremiumModes[index].icon} />
              <StyledCol>
                <StyledBodyVariant color={COLORS.PRIMARY}>{PremiumModes[index].title} Tier</StyledBodyVariant>
                <StyledRow itemsCenter gap={6}>
                  {feeMode >= index ? <StyledImg size={8} src={TickCircle} /> : <StyledImg size={8} src={NextLevel} />}
                  <BodyParagraph color={COLORS.PRIMARY}>
                    {feeMode > index ? 'Achieved' : feeMode === index ? 'Current' : 'Next Level'}
                  </BodyParagraph>
                </StyledRow>
              </StyledCol>
            </StyledRow>
          </StyledRow>
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={TokenStakedIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Tokens</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              {PremiumModes[index].tokenStaked}
            </BodyParagraph>
          </StyledFullRow>
          <StyledSeparator />
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={LiquidusBoostIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Liquidus Boost</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              {liqBoost}% APR
            </BodyParagraph>
          </StyledFullRow>
          <StyledSeparator />
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={SilverFeeIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>{PremiumModes[index].label}</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              {toFixedRound(reducedFeePercent, 2)}%
            </BodyParagraph>
          </StyledFullRow>
          <StyledSeparator />
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={SecuurityRatingIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Security Rating</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              Yes
            </BodyParagraph>
          </StyledFullRow>
        </StyledCol>
        <StyledCol flex gap={10}>
          <StyledButtonWrapper>
            {feeMode < 3 && (
              <Button
                borderColor={COLORS.PRIMARY}
                color={COLORS.PRIMARY}
                backgroundColor='transparent'
                title='Swap LIQ now'
                onClick={handleStakeLiq}
              />
            )}
          </StyledButtonWrapper>
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={OptionPlusIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Standard Fee</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              0.1%
            </BodyParagraph>
          </StyledFullRow>
          <StyledSeparator />
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={OptionPlusIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Yield intelligence</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              Coming soon
            </BodyParagraph>
          </StyledFullRow>
          <StyledSeparator />
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={OptionPlusIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Insurance fund</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              Coming soon
            </BodyParagraph>
          </StyledFullRow>
          <StyledSeparator />
          <StyledFullRow>
            <StyledRow gap={10} itemsCenter>
              <StyledImg size={10} src={OptionPlusIcon} />
              <BodyParagraph color={COLORS.PRIMARY}>Managed fund</BodyParagraph>
            </StyledRow>
            <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
              Coming soon
            </BodyParagraph>
          </StyledFullRow>
        </StyledCol>
      </StyledFullRow>
    </Wrapper>
  );
};

const Wrapper = styled.div<{backgroundColor?: string}>`
  padding: 30px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
`;

const StyledFullRow = styled.div<{marginTop?: number; gap?: number; itemsCenter?: boolean}>`
  width: 100%;
  display: flex;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
  justify-content: space-between;
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number; itemsCenter?: boolean}>`
  display: flex;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledCol = styled.div<{gap?: number; flex?: boolean; itemsCenter?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  gap: ${(props) => props.gap ?? 0}px;

  ${(props) =>
    props.flex &&
    `
    flex: 1;
  `}
`;

const StyledImg = styled.img<{size?: number}>`
  width: ${(props) => props.size ?? 32}px !important;
`;

const StyledBodyVariant = styled(BodyVariant)`
  font-size: 18px;
  font-weight: 500;
`;

const StyledSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.GRAY_BASE_40};
`;

const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 62.84px;
  display: flex;
  flex-direction: column;
`;
