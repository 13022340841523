import React from 'react';

import {TokenItem} from 'src/components/Holdings';
import {Spinner} from 'src/components/Spinner';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import {calculatePrice} from 'src/utils/token-util';
import styled from 'styled-components';

type ProcessingSwapProps = {
  inputToken?: IWhitelistToken;
  outputToken?: IWhitelistToken;
  inputAmount?: string;
  outputAmount?: string;
  slippageAdjustedAmount?: string;
};

export const ProcessingSwap = ({
  inputToken,
  outputToken,
  inputAmount,
  outputAmount,
  //gasFeePrice,
  slippageAdjustedAmount,
}: ProcessingSwapProps) => {
  const outputUSDPriceUnit = calculatePrice(outputToken?.priceUSD, outputToken?.priceDecimals);
  const outputUSDPrice = Number(outputAmount) * outputUSDPriceUnit;
  return (
    <Body>
      <StatusWrapper>
        <Spinner size={32} color={COLORS.SECONDARY} />
        <BodyVariant color={COLORS.PRIMARY}>Swapping tokens</BodyVariant>
      </StatusWrapper>
      <TokenInfoBox>
        <TokenItem token={inputToken} value={inputAmount} />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledTokenItem token={outputToken} value={outputAmount} />
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(outputUSDPrice, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      {/* <StyledRowBetween>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>Estimated gas fee</BodyParagraph>
        <BodyParagraph color={COLORS.SECONDARY}>${toFixed(gasFeePrice, 2)}</BodyParagraph>
      </StyledRowBetween> */}
      <BodyParagraph color={COLORS.GRAY_LIGHT}>
        Output is estimated. You will receive at least {slippageAdjustedAmount} {outputToken.symbol}.
      </BodyParagraph>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  gap: 10px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
