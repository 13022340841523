import React, {useEffect, useState} from 'react';

import {useAppSelector} from 'src/state/hooks';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IAddressBook} from 'src/types';
import {avatarName} from 'src/utils/text-util';
import {simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

import {BodyParagraph} from './Typography';

interface ContactItemProps {
  contact: IAddressBook;
  border?: boolean;
  onPress?: (contact: IAddressBook) => void;
}

export const ContactItem = ({contact, border, onPress}: ContactItemProps) => {
  const {addressBook} = useAppSelector((state) => state.user);
  const [userName, setUserName] = useState('AA');

  useEffect(() => {
    if (addressBook) {
      const selectedUser = addressBook.find((user) => user.address.toLowerCase() === contact.address.toLowerCase());
      setUserName(selectedUser?.name);
    }
  }, [addressBook, contact]);

  return (
    <Wrapper border={border} onClick={() => onPress && onPress(contact)}>
      <AvatarWrapper color={contact?.color}>
        <BodyParagraph size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.WHITE}>
          {avatarName(userName)}
        </BodyParagraph>
      </AvatarWrapper>
      <StyledCol>
        <BodyParagraph color={COLORS.PRIMARY}>{contact.name}</BodyParagraph>
        <BodyParagraph color={COLORS.GRAY_LIGHT}>{simplifyAddress(contact.address || '')}</BodyParagraph>
      </StyledCol>
    </Wrapper>
  );
};

const Wrapper = styled.div<{border?: boolean}>`
  display: flex;
  border-bottom: ${(props) => (props.border ? `1px solid ${COLORS.GRAY_BORDER}` : 'none')};
  padding: 10px 0;
  cursor: pointer;
`;

const AvatarWrapper = styled.div<{color?: string}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => props.color || '#ff00ff'};
  margin-right: 10px;
`;

const StyledCol = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
