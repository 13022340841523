import {createSlice} from '@reduxjs/toolkit';
import {Field} from 'src/types';

export interface FarmState {
  [Field.INPUT]: {tokenGlobalName: string | undefined};
  [Field.OUTPUT]: {tokenGlobalName: string | undefined};
}

const initialState: FarmState = {
  [Field.INPUT]: {tokenGlobalName: undefined},
  [Field.OUTPUT]: {tokenGlobalName: undefined},
};

export const farmSlice = createSlice({
  name: 'farm',
  initialState,
  reducers: {
    selectToken(state, {payload: {field, tokenGlobalName}}) {
      state[field as keyof FarmState] = {tokenGlobalName};
    },
    resetToken() {
      return initialState;
    },
  },
});
