export const migrationAddress = '0xb0403339Bd3e2f761e270D19e495E5cE65E2e283';
export const nullAddress = '0x0000000000000000000000000000000000000000';

export enum failHandleEnum {
  WITHDRAW,
  APPROVESPEND,
  MIGRATE,
  APPROVEDEPOSIT,
  DEPOSIT,
  APPROVELPTOKENS,
  APPROVE0,
  APPROVE1,
  ADDLIQUIDITY,
  REMOVELIQUIDITY,
}
