//Button.tsx

import React from 'react';

import {COLORS, ColorsTypes, DEVICE_ENUM} from 'src/styles';
import {IconType} from 'src/types';
import styled from 'styled-components';

import {BUTTON_SIZE_ENUM, ButtonSize, ButtonSizeType} from './buttonSize';
import {Spinner} from '../Spinner';
import ButtonFont from '../Typography/ButtonFont';

type ButtonType = {
  title: string;
  size?: BUTTON_SIZE_ENUM;
  mobileSize?: BUTTON_SIZE_ENUM;
  type?: 'submit' | 'reset' | 'button';
  color?: ColorsTypes;
  LeftIcon?: React.ComponentType<IconType>;
  RightIcon?: React.ComponentType<IconType>;
  disabled?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  isLoading?: boolean;
  homeFarm?: boolean;
  onClick?: () => void;
};

export const Button = ({
  isLoading,
  title,
  type = 'button',
  size = BUTTON_SIZE_ENUM.DEFAULT,
  mobileSize = BUTTON_SIZE_ENUM.NORMAL,
  color = COLORS.BLACK_TEXT,
  LeftIcon,
  RightIcon,
  disabled,
  borderColor,
  backgroundColor,
  homeFarm,
  onClick,
  ...props
}: ButtonType) => {
  return (
    <ButtonWrapper
      type={type}
      size={ButtonSize[size]}
      mobileSize={ButtonSize[mobileSize]}
      disabled={disabled}
      borderColor={borderColor}
      backgroundColor={disabled ? COLORS.DISABLED : backgroundColor}
      homeFarm={homeFarm}
      onClick={onClick}
      {...props}
    >
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color={color} />
        </SpinnerWrapper>
      )}
      {LeftIcon && <LeftIcon />}
      <ButtonText
        isLoading={isLoading}
        size={ButtonSize[size].labelSize}
        color={color}
        mobileSize={homeFarm && ButtonSize[size].labelSize}
      >
        {title}
      </ButtonText>
      {RightIcon && <RightIcon />}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button<{
  disabled?: boolean;
  size: ButtonSizeType;
  mobileSize: ButtonSizeType;
  borderColor?: string;
  backgroundColor?: string;
  homeFarm?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  height: ${(props) => props.size.height}px;
  padding: ${(props) => (props.homeFarm ? 0 : '0 24px')};
  width: ${(props) => (props.homeFarm ? `100px` : 'unset')};
  background-color: ${(props) => props.backgroundColor ?? COLORS.SECONDARY};
  border-radius: ${(props) => props.size.borderRadius}px;
  border-width: 1px;
  border-color: ${(props) => props.borderColor ?? 'transparent'};
  border-style: solid;
  cursor: pointer;
  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    }
  `}

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: ${(props) => (props.homeFarm ? 0 : '0 18px')};
    height: ${(props) => props.mobileSize.height}px;
  }
`;

const ButtonText = styled(ButtonFont)<{isLoading?: boolean}>`
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
