import React, {FC, ReactNode, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {getChainInfo} from 'src/constants';
import {simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

import RemoveIcon from '../../assets/images/tokens/ic-remove.png';
import {CopyIcon} from '../Svgs';
import RedirectLinkIcon from '../Svgs/RedirectLinkIcon';

type TooltipProps = {
  children?: ReactNode;
  tokenAddress?: string;
  isCustom?: boolean;
  removeCustomToken?: () => void;
};

const Tooltip: FC<TooltipProps> = ({children, tokenAddress, isCustom, removeCustomToken}) => {
  const {chainId} = useWeb3React();

  const [isVisible, setIsVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => {
    setIsVisible(false);
    setIsCopied(false); // Reset copy status when tooltip is hidden
  };

  // Function to copy the wallet address to the clipboard
  const copyToClipboard = () => {
    const walletAddress = tokenAddress;
    navigator.clipboard.writeText(walletAddress).then(
      () => {
        setIsCopied(true);
        // Display the message for 4 seconds before hiding it
        if (isCopied) {
          setTimeout(() => {
            setIsCopied(false);
          }, 4000);
        }
      },
      (err) => {
        console.error('Failed to copy wallet address:', err);
      },
    );
  };

  const handleNetworkExplorerClick = () => {
    const _info = getChainInfo(chainId);
    window.open(`${_info.blockExplorerUrls}address/${tokenAddress}`, '_blank');
  };

  const handleStopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.stopPropagation(); // This will prevent the event from bubbling up to the parent
  };

  return (
    <TooltipContainer onMouseOver={showTooltip} onMouseOut={hideTooltip}>
      {children}
      {isVisible && (
        <TooltipBubble>
          <TooltipText>{simplifyAddress(tokenAddress)}</TooltipText>
          <IconContainer
            onClick={(e) => {
              copyToClipboard();
              handleStopPropagation(e); // this is crucial as it prevents triggering Parent onClick events
            }}
          >
            {isCopied && <CopyMessage isVisible={isCopied}>Copied to clipboard!</CopyMessage>}
            <CopyIconStyled />
          </IconContainer>
          <IconContainer
            onClick={(e) => {
              handleNetworkExplorerClick();
              handleStopPropagation(e); // this is crucial as it prevents triggering Parent onClick events
            }}
          >
            <BscScanIconStyled />
          </IconContainer>
          {isCustom && (
            <IconContainer onClick={removeCustomToken}>
              <Image src={RemoveIcon} />
            </IconContainer>
          )}
        </TooltipBubble>
      )}
    </TooltipContainer>
  );
};

export default Tooltip;

// Tooltip container which positions the tooltip around the triggering element
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipBubble = styled.div`
  position: absolute;
  left: -70px;
  bottom: 100%;
  margin-bottom: 8px;
  background-color: #112455;
  padding: 8px 24px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  gap: 10px; // Space between text and icons
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  z-index: 1000; // Ensure it's above most other content
  cursor: auto;

  // this is for the arrow pointer on the bubble
  &::before {
    content: '';
    position: absolute;
    left: 85px;
    top: 100%; /* Arrow at the bottom of the tooltip */
    border-width: 10px;
    border-style: solid;
    border-color: #112455 transparent transparent transparent;
  }
`;

const TooltipText = styled.p`
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  margin: 0;
  flex-grow: 1; // Ensures it takes up the space between the icons
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const CopyIconStyled = styled(CopyIcon)``;

const BscScanIconStyled = styled(RedirectLinkIcon)``;

const Image = styled.img`
  width: 15px;
  height: 15px;
`;

const CopyMessage = styled.div<{isVisible: boolean}>`
  position: absolute;
  top: 90%; // Position the message below the copy icon
  left: 80%;
  transform: translateX(-50%);
  background: #17e7d6;
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  z-index: 1001; // Ensure it's above other content, adjust as needed
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &::before {
    content: '';
    position: absolute;
    left: 10%;
    bottom: 100%; /* Arrow at the top of the tooltip */
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #17e7d6 transparent;
    translate: rotate(45deg);
  }
`;
