import {useCallback, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {toast} from 'react-toastify';
import {BalanceItem} from 'src/components/BalanceItem';
import {SelectTokenModal} from 'src/components/Modals/SelectToken';
import {BackwardIcon, CopyPlusIcon, ForwardIcon, TimerIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {TokenItem} from 'src/components/WhitelistTokens/TokenItem';
import {AVATARS} from 'src/constants/avatar';
import {useModals} from 'src/contexts/modals';
import {usePortfolioBalance, useToken} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

type IconButtonProps = {
  Icon: React.ReactNode;
  title: string;
  onClick?: () => void;
};

const IconButton = ({Icon, title, onClick}: IconButtonProps) => {
  return (
    <StyledCol gap={8} itemsCenter={true}>
      <StyledButton onClick={onClick}>
        <StyledOval>{Icon}</StyledOval>
      </StyledButton>
      <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
        {title}
      </BodyParagraph>
    </StyledCol>
  );
};

export const Wallet = () => {
  const {account} = useWeb3React();
  const modalContext = useModals();
  const {
    totalBalance,
    totalWhitelistBalance,
    totalLiquidityBalance,
    totalFarmingBalance,
    totalSingleTokenBalance,
    // totalSingleTokenRewards,
    totalRewards,
  } = usePortfolioBalance();
  const {sortedTokens} = useToken();
  const {connectedWallets} = useAppSelector((state) => state.wallets);
  const currentWallet = useMemo(
    () => connectedWallets.find((item) => item.account === account),
    [account, connectedWallets],
  );
  const avatarType = currentWallet?.avatarType ?? 0;
  const selectedIndex = currentWallet?.avatarIndex ?? 0;
  const Avatar = AVATARS[avatarType][selectedIndex];
  const [showSelectTokenModal, setShowSelectTokenModal] = useState(false);

  const onCopyClipboard = useCallback(() => {
    navigator.clipboard.writeText(account);
    toast.success('Wallet address copied to the clipboard', {autoClose: 2000, hideProgressBar: true});
  }, [account]);

  const onOpenRenameAccountModal = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateRenameAccountModal', payload});
  };

  const handleShowTokenReceiveModal = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateTokenReceiveModal', payload});
  };

  return (
    <Wrapper>
      <Container>
        <BodyVariant size={BODY_FONT_ENUM.LARGE} color={COLORS.PRIMARY}>
          {currentWallet?.accountName ?? 'Your account'}
        </BodyVariant>
        <StyledFullRow marginTop={20}>
          <StyledRow itemsCenter={true} gap={24}>
            <AvatarWrapper onClick={onOpenRenameAccountModal}>
              <StyledImg size={60} src={Avatar} />
            </AvatarWrapper>
            <StyledCol>
              <StyledBalanceLabel>${totalBalance.toFixed(2)}</StyledBalanceLabel>
              {account && (
                <StyledButton onClick={onCopyClipboard}>
                  <StyledRow gap={8} itemsCenter={true}>
                    <BodyParagraph color={COLORS.GRAY_LIGHT}>{simplifyAddress(account)}</BodyParagraph>
                    <CopyPlusIcon />
                  </StyledRow>
                </StyledButton>
              )}
            </StyledCol>
          </StyledRow>
          <StyledRow gap={38}>
            <IconButton Icon={<ForwardIcon />} title='Send' onClick={() => setShowSelectTokenModal(true)} />
            <IconButton Icon={<BackwardIcon />} title='Receive' onClick={handleShowTokenReceiveModal} />
            <IconButton Icon={<TimerIcon color={COLORS.SECONDARY} />} title='History' />
          </StyledRow>
        </StyledFullRow>
        <StyledFullRow marginTop={30}>
          <BalanceItem label='Token Balances' value={totalWhitelistBalance} />
          <BalanceItem label='Liquidity Pools' value={totalLiquidityBalance} />
          <BalanceItem label='Yield Farming' value={totalFarmingBalance} />
          <BalanceItem label='Single Token Farms' value={totalSingleTokenBalance} />
          <BalanceItem label='Accrued Yield' value={totalRewards} active />
        </StyledFullRow>
        <StyledTokensWrapper>
          {sortedTokens?.map((token) => (
            <TokenItem key={token.globalName} token={token} />
          ))}
        </StyledTokensWrapper>
      </Container>
      <SelectTokenModal isOpen={showSelectTokenModal} onDismiss={() => setShowSelectTokenModal(false)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 96px 36px 36px;
  background-color: #f9f9f9;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 16px;
  }
`;

const Container = styled.div`
  max-width: 764px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number; itemsCenter?: boolean}>`
  display: flex;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledCol = styled.div<{gap?: number; itemsCenter?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.itemsCenter ? 'center' : 'flex-start')};
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledImg = styled.img<{size?: number}>`
  width: ${(props) => props.size ?? 32}px;
`;

const AvatarWrapper = styled.div`
  width: 74px;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid ${COLORS.GRAY_LIGHTER};
  cursor: pointer;
`;

const StyledBalanceLabel = styled.p`
  font-size: 42px;
  font-weight: 500;
  line-height: 51px;
  margin: 0;
  color: ${COLORS.PRIMARY};

  @media (max-width: ${DEVICE_ENUM.md}) {
    font-size: 22px;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    font-size: 16px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

const StyledFullRow = styled.div<{marginTop?: number}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const StyledOval = styled.div`
  width: 52px;
  height: 52px;
  background-color: ${COLORS.PRIMARY};
  border-radius: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTokensWrapper = styled.div`
  overflow: auto;
  padding: 28px 24px;
  margin: 0 -24px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 16px;
  }
`;
