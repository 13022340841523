import {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {IYieldFarm, argsObj} from 'src/types';
import {getFarmContract} from 'src/utils/farm-util';

type DepositCall = {
  farm: IYieldFarm;
  functionName?: string;
  args?: argsObj;
  gasEstimation?: BigNumber;
};

function getDepositCall(amount: BigNumber, farm?: IYieldFarm, account?: string): DepositCall {
  if (!farm || !account) {
    throw new Error('Mising parameters for functions');
  }
  const farmDepositSignature = farm.depositFn?.signature;

  const farmArguments: argsObj = {};
  if (farm.depositFn) {
    for (const farmArgument of farm.depositFn.arguments) {
      if (farmArgument.name === '_pid' || farmArgument.name === 'pid') {
        farmArguments[farmArgument.name] = farm.poolId;
      } else if (farmArgument.name === '_amount' || farmArgument.name === 'amount') {
        farmArguments[farmArgument.name] = amount;
      } else if (farmArgument.name === 'to' || farmArgument.name === '_referrer') {
        farmArguments[farmArgument.name] = account;
      }
    }
  }
  return {
    functionName: farmDepositSignature,
    args: farmArguments,
    farm: farm,
  };
}

export function useDepositCallback(farm?: IYieldFarm) {
  const {account, provider} = useWeb3React();
  const estimateGasForDeposit = useCallback(
    async (amount: BigNumber) => {
      const depositFunctionCall: DepositCall = getDepositCall(amount, farm, account);

      const contract = await getFarmContract(depositFunctionCall?.farm, account, provider);
      const functionName = depositFunctionCall.functionName;
      const functionArgs = depositFunctionCall.args;
      if (functionName && contract) {
        const estimatedGasFee = await contract.estimateGas[functionName](
          ...(functionArgs ? Object.values(functionArgs) : []),
        );
        return estimatedGasFee;
      }

      return null;
    },
    [farm, account, provider],
  );

  const deposit = useCallback(
    async (gasLimit: BigNumber, amount: BigNumber) => {
      const depositFunctionCall = getDepositCall(amount, farm, account);

      if (account) {
        const nonce = await provider.getTransactionCount(account);
        const contract = await getFarmContract(depositFunctionCall.farm, account, provider);

        const gasSettings = {};
        // if (chainId === 56) {
        //   const gasPriceGwei = ethers.utils.parseUnits(`${gasPrice}`, 'gwei');
        //   gasSettings = {gasPrice: gasPriceGwei};
        // } else {
        //   const maxPriorityFeeGwei = ethers.utils.parseUnits(`${maxPriorityFee}`, 'gwei');
        //   const maxFeeGwei = ethers.utils.parseUnits(`${maxFee}`, 'gwei');
        //   gasSettings = {
        //     maxPriorityFeePerGas: maxPriorityFeeGwei,
        //     maxFeePerGas: maxFeeGwei,
        //   };
        // }

        const functionName = depositFunctionCall.functionName;
        const functionArgs = depositFunctionCall.args;
        if (!contract || !functionName) {
          return;
        }
        try {
          const tx = await contract[functionName](...(functionArgs ? Object.values(functionArgs) : []), {
            gasLimit: gasLimit,
            from: account,
            ...gasSettings,
            nonce: nonce,
          });

          return tx;
        } catch (e) {
          console.error('Deposit failed', e, depositFunctionCall.farm);
          // if (e.code === 'INSUFFICIENT_FUNDS') {
          //   Toast.show({
          //     type: 'error',
          //     text1: 'Native token balance is too low to cover gas fees for depostiting to farm.',
          //   });
          // }
        }
      }
    },
    [farm, account, provider],
  );

  return {deposit, estimateGasForDeposit};
}
