import {BigNumber} from 'ethers';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import {formatBigNumber} from 'src/utils/token-util';
import styled from 'styled-components';

import {BodyParagraph} from '../Typography';

type LiquidityInputItemProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  token0Amount?: BigNumber;
  token1Amount?: BigNumber;
  token0BigNumberFormatted?: number;
  token1BigNumberFormatted?: number;
};

export const LiquidityInputItem = ({
  token0,
  token1,
  token0Amount,
  token1Amount,
  token0BigNumberFormatted,
  token1BigNumberFormatted,
}: LiquidityInputItemProps) => {
  const token0Url = token0?.iconUrl ?? getTokenIconByGlobalName(token0?.globalName);
  const token1Url = token1?.iconUrl ?? getTokenIconByGlobalName(token1?.globalName);
  const FormatToken0Amount = (token0BigNumberFormatted ?? formatBigNumber(token0Amount, token0.decimals)).toFixed(
    token0.interfaceDecimals,
  );
  const FormatToken1Amount = (token1BigNumberFormatted ?? formatBigNumber(token1Amount, token1.decimals)).toFixed(
    token1.interfaceDecimals,
  );
  return (
    <StyledTokenBox>
      <StyledLeftWrapper>
        <TokenContainer>
          <StyledImage src={token0Url} />
          <TokenSymbol>{token0?.symbol}</TokenSymbol>
          <TokenAmount>{FormatToken0Amount}</TokenAmount>
        </TokenContainer>
        <TokenContainer>
          <StyledImage src={token1Url} />
          <TokenSymbol>{token1?.symbol}</TokenSymbol>
          <TokenAmount>{FormatToken1Amount}</TokenAmount>
        </TokenContainer>
      </StyledLeftWrapper>
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

const TokenAmount = styled(BodyParagraph)`
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.PRIMARY};
`;

const TokenSymbol = styled(TokenAmount)`
  color: ${COLORS.GRAY_LIGHT};
`;

const StyledImage = styled.img`
  width: 35px;
  height: 35px;
`;
