import {useState} from 'react';

import {ArrowDownIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph} from 'src/components/Typography';
import {useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {getPlatformIcon} from 'src/utils/icon-util';
import {convertKMPrice} from 'src/utils/text-util';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {MigrationModal, MigrationModalProps} from '../Modals/MigrationModal';

export const DuplicateFarmItem = ({...props}) => {
  const {highestAPYFarm, userFarm, lp, isLp} = props;
  const {check} = useAgreementCheck();
  const [migrationModalProps, setMigrationModalProps] = useState<MigrationModalProps>();
  /*
    the user has $5 in this farm.

    Now the yield scanner suggests the user to move it to Knightswap to earn more APY which is correct.
    However, the +$15.41 is completely wrong.
    The user can earn 27.3%-3.1% = 24.2% more 
    Applied to $5 this is “+$1.21 per year

  */
  const {getTokenByAddress} = useToken();
  const name = `${highestAPYFarm?.liquidityPool?.token0Symbol} - ${highestAPYFarm?.liquidityPool?.token1Symbol}`;
  const earnButton = () => {
    const payload = {
      isOpen: true,
      yearlyRevenue,
      highestAPYPool: highestAPYFarm,
      lowestAPYPool: userFarm,
      name,
      iconUrl: isLp ? lpIconUrl : iconUrl,
      lowestPoolInfo,
      lowestPooltvl,
      hightestPooltvl,
      highIconUrl,
      isLp,
      lp,
      lpInfo,
    };
    check(() => setMigrationModalProps(payload));
  };

  const onHandlaDismiss = () => {
    setMigrationModalProps({...migrationModalProps, isOpen: false});
  };

  const diffAPY = highestAPYFarm?.apy - (userFarm?.apy ?? lp?.poolApy);

  const liquidityPool = lp ? lp : userFarm?.liquidityPool;

  const poolBalance = formatBigNumber(lp ? lp?.balance : userFarm?.balance, liquidityPool?.decimals);
  const yourWorth = getTokenUSDPrice(poolBalance, liquidityPool?.lpTokenPriceUSD);
  const yearlyRevenue = (yourWorth * diffAPY) / 100;

  const lowestPoolInfo = `${userFarm?.platform?.name} - ${userFarm?.apy?.toFixed(2)}% APY`;
  const lpInfo = `${lp?.platform?.name} Lp ${lp?.poolApy ? `${lp?.poolApy?.toFixed(1)}% APY` : ''}`;
  const lowestPooltvl = `$${convertKMPrice(userFarm?.tvlNumber || 0)} TVL`;
  const hightestPooltvl = `$${convertKMPrice(highestAPYFarm?.tvlNumber || 0)} TVL`;
  const iconUrl = !isLp ? userFarm?.platform?.iconUrl || getPlatformIcon(userFarm?.platform?.name) : undefined;
  const lpIconUrl = isLp ? lp?.platform?.iconUrl || getPlatformIcon(lp?.platform?.name) : undefined;
  const highIconUrl = highestAPYFarm.platform?.iconUrl || getPlatformIcon(highestAPYFarm.platform.name);
  const token0 = getTokenByAddress(highestAPYFarm?.liquidityPool?.token0Hash);
  const token1 = getTokenByAddress(highestAPYFarm?.liquidityPool?.token1Hash);

  return (
    <FarmItem>
      <LeftWrapper>
        <FarmInformation>
          <FarmName>
            <TokenIconWrapper>
              <OverLappedTokenIcon width={24} height={24} token={token0} />
              <SecondTokenIcon width={24} height={24} token={token1} />
            </TokenIconWrapper>
            <CoinPairNames>{name}</CoinPairNames>
          </FarmName>
          <FarmRevenue>
            <RevenueText>
              <strong>{`+$${yearlyRevenue.toFixed(2)}`}</strong>
              {' per year'}
            </RevenueText>
          </FarmRevenue>
        </FarmInformation>
        <SwapContainer>
          <FarmPairsContainer>
            <FarmPairs>
              <TokenDescription>
                <div>
                  <StyledImage src={isLp ? lpIconUrl : iconUrl} />
                </div>
                <PoolInfo>
                  <CoinPairNames>{isLp ? lpInfo : lowestPoolInfo}</CoinPairNames>
                  {!isLp && <TvlText>{lowestPooltvl}</TvlText>}
                </PoolInfo>
              </TokenDescription>
            </FarmPairs>
            <ArrowDownIcon size={24} color={COLORS.GRAY_LIGHT} />
            <FarmPairs>
              <TokenDescription>
                <div>
                  <StyledImage src={highIconUrl} />
                </div>
                <PoolInfo>
                  <CoinPairNames>
                    {`${highestAPYFarm?.platform?.name} - `}
                    <ApyText>{`${highestAPYFarm?.apy?.toFixed(1)}% APY`}</ApyText>
                  </CoinPairNames>
                  <TvlText>{hightestPooltvl}</TvlText>
                </PoolInfo>
              </TokenDescription>
            </FarmPairs>
          </FarmPairsContainer>
          <EarnButtonContainer>
            <EarnButton onClick={earnButton}>
              <p>Earn</p>
              <p>more</p>
            </EarnButton>
          </EarnButtonContainer>
        </SwapContainer>
      </LeftWrapper>
      <MigrationModal {...migrationModalProps} onDismiss={onHandlaDismiss} />
    </FarmItem>
  );
};

const FarmItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 17px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px 0px #1124550f;
  border-radius: 12px;
  align-items: center;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

const FarmInformation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TokenIconWrapper = styled.div`
  display: flex;
  position: relative;
`;

const FarmName = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const FarmRevenue = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const OverLappedTokenIcon = styled(TokenIcon)`
  z-index: 2;
`;

const SecondTokenIcon = styled(TokenIcon)`
  margin-left: -10px;
  z-index: 1;
`;

const SwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 6px;
  justify-content: space-between;
  align-items: center;
`;

const FarmPairsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  flex-wrap: wrap;
`;

const FarmPairs = styled.div`
  flex: 1;
`;

const EarnButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const TokenDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CoinPairNames = styled(BodyParagraph)`
  margin-left: 10px;
  font-weight: 700;
  color: ${COLORS.PRIMARY};
`;

const RevenueText = styled(BodyParagraph)`
  font-weight: 400;
  color: ${COLORS.SECONDARY};
`;
const TvlText = styled(BodyParagraph)`
  font-weight: 400;
  color: ${COLORS.GRAY_LIGHT};
  margin-left: 10px;
`;
const ApyText = styled.span`
  font-weight: 700;
  color: ${COLORS.SECONDARY};
`;

const EarnButton = styled.button`
  padding: 5px 14px;
  width: 83px;
  font-size: 16px;
  line-height: 19.5px;
  font-weight: 600;
  font-family: Montserrat;
  color: ${COLORS.WHITE};
  background-color: #112455;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  & p {
    margin: 0;
  }
  &:hover {
    opacity: 0.8;
  }
  &:active {
    background-color: rgb(17, 36, 140);
  }
`;

const StyledImage = styled.img`
  width: 26px;
  height: 26px;
`;

const PoolInfo = styled.div`
  flex: 1;
  padding-left: 5px;
`;
