import React, {useEffect, useState} from 'react';

import {COLORS} from 'src/styles';
import styled from 'styled-components';

import {BodyVariant} from './Typography';

const TabContent = styled.div`
  display: flex;
`;
type TabPanelProps = {
  label: string;
  disabled?: boolean;
  children: React.ReactNode;
};

export const TabPanel = ({children, ...props}: TabPanelProps) => (
  <TabContent role='tabpanel' {...props}>
    {children}
  </TabContent>
);

type TabsProps = {
  tabBreak?: number;
  isPlatformLP?: boolean;
  children: React.ReactElement | React.ReactElement[];
};

export const Tabs = ({tabBreak, children, isPlatformLP}: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (isPlatformLP) setSelectedTab(1);
  }, [isPlatformLP]);

  return (
    <TabsWrapper>
      <TabList breakPoint={tabBreak} role='tablist'>
        {React.Children.map(children, ({props: {label, disabled}}, index) => (
          <TabButton
            role='tab'
            selected={selectedTab === index}
            aria-selected={selectedTab === index ? 'true' : 'false'}
            disabled={disabled}
            onClick={() => setSelectedTab(index)}
          >
            <BodyVariant color={disabled ? COLORS.GRAY_LIGHTER : COLORS.PRIMARY}>{label}</BodyVariant>
          </TabButton>
        ))}
      </TabList>
      <Content>{React.Children.map(children, (comp, index) => (selectedTab === index ? comp : undefined))}</Content>
    </TabsWrapper>
  );
};

const TabsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TabButton = styled.button<{selected?: boolean}>`
  height: 50px;
  padding: 0 24px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: default;
  background: transparent;
  outline: none;
  transition: border-color 0.2s ease-in;
  border: none;
  border-radius: 12px 12px 0 0;
  background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
  z-index: 5;
  cursor: pointer;
`;

const TabList = styled.div<{breakPoint?: number}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${(props) => props.breakPoint}) {
    flex-direction: column;
    & > div,
    & > div > button {
      width: 100%;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  margin-top: -12px;
`;
