import React from 'react';

import {COLORS, ColorsTypes, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

import {BodyParagraph} from './Typography';

type MenuItemType = {
  title?: string;
  color?: ColorsTypes;
  icon?: string;
  onClick?: () => void;
};

export const MenuItem = ({title, color, icon, onClick, ...props}: MenuItemType) => {
  return (
    <ButtonWrapper onClick={onClick} {...props}>
      {icon && <StyledImg src={icon} />}
      <ButtonText size={PARAGRAPH_FONT_ENUM.SMALL} color={color}>
        {title}
      </ButtonText>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  height: 40px;
  padding: 0 16px;
  background-color: ${COLORS.GRAY_BASE_40};
  border-radius: 22.5px;
  border-width: 0;
  box-shadow: 0px 1px 6px rgba(150, 150, 150, 0.25);
  cursor: pointer;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    padding: 10px 24px;
  }
`;

const ButtonText = styled(BodyParagraph)`
  text-align: center;
  color: ${(props) => props.color ?? 'black'};
  margin: 0;
`;

const StyledImg = styled.img`
  width: 20px;
`;
