export function isValidEmail(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export const decimalCount = (numStr: string) => {
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
};

export const toFixed = (num: number, fixed?: number) => {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
};

export const toFixedRound = (num: number, fixed?: number) => {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.round(num * fixed) / fixed;
};

export const localizeNumber = (num: number) => {
  return num.toLocaleString('en');
};

export const convertKMPrice = (num: number) => {
  if (num < 1000) {
    return toFixed(num);
  } else if (num < 1000000) {
    const kiloNumber = toFixed(num / 1000, 1);
    return `${kiloNumber}k`;
  }
  const metaNumber = toFixed(num / 1000000, 1);
  return `${metaNumber}M`;
};

export const formatNumber = (num: number) => {
  if (num < 1) {
    return num.toFixed(4);
  }
  return num.toFixed(2);
};

export const avatarName = (name: string) => {
  if (name) {
    const splitedNames = name.split(' ');
    const firstLetter = splitedNames[0].substring(0, 1);
    if (splitedNames.length > 1) {
      const lastLetter = splitedNames[1].substring(0, 1);
      return firstLetter + lastLetter;
    } else {
      return firstLetter;
    }
  } else {
    return 'A';
  }
};
