import {createSlice} from '@reduxjs/toolkit';
import {GroupedTxsByHash, TransactionExtraInfo} from 'src/types';
import {findTx, getTransactionGroupByType} from 'src/utils/transaction-util';

const now = () => new Date().getTime();

export interface TransactionState {
  [chainId: number]: GroupedTxsByHash | undefined;
}

const initialState: TransactionState = {};

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransaction(
      state,
      {payload: {sentAtBlock, to, nonce, data, chainId, from, hash, type, firstTxHash, extraInfo}},
    ) {
      const chainTxs = state[chainId] ?? {};
      const txs = (firstTxHash && chainTxs[firstTxHash]) || [];
      if (txs.find((e) => e.hash === hash)) {
        // duplicate
        return;
      }
      txs.push({
        sentAtBlock,
        to,
        nonce,
        data,
        hash,
        type,
        from,
        addedTime: now(),
        chainId,
        extraInfo,
        group: getTransactionGroupByType(type),
      });
      chainTxs[txs[0].hash] = txs;
      state[chainId] = chainTxs;
    },
    clearAllTransactions(state, {payload: {chainId}}) {
      if (!state[chainId]) return;
      state[chainId] = {};
    },
    checkedTransaction(state, {payload: {chainId, hash, blockNumber}}) {
      const tx = findTx(state[chainId], hash);
      if (!tx) return;
      if (!tx.lastCheckedBlockNumber) tx.lastCheckedBlockNumber = blockNumber;
      else tx.lastCheckedBlockNumber = Math.max(blockNumber, tx.lastCheckedBlockNumber);
    },
    finalizeTransaction(state, {payload: {hash, chainId, receipt, needCheckSubgraph}}) {
      const tx = findTx(state[chainId], hash);
      if (!tx) return;
      tx.receipt = receipt;
      tx.confirmedTime = now();
      const newExtraInfo: TransactionExtraInfo = {...tx.extraInfo, needCheckSubgraph};
      tx.extraInfo = newExtraInfo;
    },
    modifyTransaction(state, {payload: {chainId, hash, extraInfo, needCheckSubgraph}}) {
      const tx = findTx(state[chainId], hash);
      if (!tx) return;
      const newExtraInfo: TransactionExtraInfo = {...tx.extraInfo, ...extraInfo};
      if (needCheckSubgraph !== undefined) newExtraInfo.needCheckSubgraph = needCheckSubgraph;
      tx.extraInfo = newExtraInfo;
    },
    replaceTx(state, {payload: {chainId, oldHash, newHash}}) {
      const chainTxs = state[chainId] ?? {};
      const txGroup =
        chainTxs[oldHash] || Object.values(chainTxs).find((txs) => txs?.some((tx) => tx?.hash === oldHash));
      if (!txGroup) return;
      const txIndex = txGroup.findIndex((tx) => tx?.hash === oldHash);
      if (txIndex < 0) return;
      txGroup[txIndex].hash = newHash;
      if (chainTxs[oldHash]) {
        chainTxs[newHash] = txGroup;
        delete chainTxs[oldHash];
      }
      state[chainId] = chainTxs;
    },
    removeTx(state, {payload: {chainId, hash}}) {
      const chainTxs = state[chainId] ?? {};
      if (chainTxs[hash]) {
        delete chainTxs[hash];
      } else {
        const txGroup = Object.values(chainTxs).find((txs) => txs?.some((tx) => tx?.hash === hash));
        if (!txGroup) return;
        if (txGroup.length === 1) {
          delete state[chainId]?.[hash];
        } else {
          const txIndex = txGroup.findIndex((tx) => tx?.hash === hash);
          if (txIndex < 0) return;
          txGroup.splice(txIndex, 1);
        }
      }
      state[chainId] = chainTxs;
    },
  },
});
