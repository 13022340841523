import {IItemType} from './settings';

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
  ARTICLEINITIAL_INPUT = 'ARTICLEINITIAL_INPUT',
}

export enum FieldValues {
  INPUT_VALUE = 'INPUT_VALUE',
}

export type SwapSettings = {
  showRoute?: boolean;
  skipConfirm?: boolean;
  slippage?: IItemType;
};
