import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ArrowRightIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ArrowRightIcon = ({size = 20, color = COLORS.PRIMARY, ...props}: ArrowRightIconType) => (
  <svg width={size} height={size} viewBox='0 0 19 12' fill='none' {...props}>
    <path d='M1.5 5.88884L17.0556 5.88884' stroke={color} strokeWidth='2' strokeLinecap='round' />
    <path d='M12.6096 1L17.5 5.88889L12.6096 10.7778' stroke={color} strokeWidth='2' strokeLinecap='round' />{' '}
  </svg>
);

export default ArrowRightIcon;
