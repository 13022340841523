import React, {useMemo, useState} from 'react';

import {useMutation} from '@apollo/client';
import {useWeb3React} from '@web3-react/core';
import {ethers} from 'ethers';
import {toast} from 'react-toastify';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {ArrowLeftIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {ADD_CONTACT} from 'src/graphql/query';
import {userServicesClient} from 'src/providers/GraphqlProvider';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {COLORS} from 'src/styles';
import {IAddressBook} from 'src/types';
import {randomColor} from 'src/utils/utils';
import styled from 'styled-components';

type AddContactBookProps = {
  newWalletAddress?: string;
  onBack: () => void;
};

export const AddContactBook = ({onBack, newWalletAddress}: AddContactBookProps) => {
  const {addressBook} = useAppSelector((state) => state.user);
  const appDispatch = useAppDispatch();
  const {account} = useWeb3React();
  const [addContact] = useMutation(ADD_CONTACT, {client: userServicesClient});
  const [contactName, setContactName] = useState('');
  const [walletAddress, setWalletAddress] = useState(newWalletAddress ?? '');
  const isValidAddress = useMemo(() => ethers.utils.isAddress(walletAddress), [walletAddress]);
  const shouldShowWarning = walletAddress.length > 0 && !isValidAddress;
  const disabled = !contactName || !walletAddress || !isValidAddress;

  const onAddNewContact = async () => {
    if (!account) {
      toast('Please connect your wallet');
      return;
    }
    const newAddressBook = [...(addressBook ?? [])];
    const contactIndex = newAddressBook.findIndex((item) => item.address === walletAddress);
    if (contactIndex >= 0 && contactName) {
      toast('Wallet address already used for another contact' + newAddressBook[contactIndex].name);
      return;
    } else {
      const newContact: IAddressBook = {
        name: contactName,
        address: walletAddress,
        color: randomColor(),
      };
      newAddressBook.push(newContact);
    }
    try {
      await addContact({
        variables: {
          accountAddress: account,
          contact: {
            name: contactName,
            hash: walletAddress,
          },
        },
      });
      const payload = {addressBook: newAddressBook};
      appDispatch(userSlice.actions.updateContactBook(payload));
      toast('The contact has been successfully added');
      onBack();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SectionWrapper marginTop={8}>
        <StyledRow>
          <IconButton onClick={onBack}>
            <ArrowLeftIcon />
          </IconButton>
          <BodyVariant color={COLORS.PRIMARY}>New Contact</BodyVariant>
          <div />
        </StyledRow>
      </SectionWrapper>
      <SectionWrapper marginTop={16}>
        <BodyVariant color={COLORS.PRIMARY}>Contact Name</BodyVariant>
        <InputView>
          <Input placeholder='Name' value={contactName} onChange={(e) => setContactName(e.target.value)} />
        </InputView>
      </SectionWrapper>
      <SectionWrapper marginTop={16}>
        <BodyVariant color={COLORS.PRIMARY}>Wallet Address</BodyVariant>
        <InputView>
          <Input placeholder='0x...' value={walletAddress} onChange={(e) => setWalletAddress(e.target.value)} />
        </InputView>
      </SectionWrapper>
      {shouldShowWarning && (
        <StyledWarningBox>
          <StyledWarningIcon src={WarningIcon} />
          <BodyParagraph color={COLORS.WARNING}>Invalid wallet address</BodyParagraph>
        </StyledWarningBox>
      )}
      <ButtonWrapper>
        <Button color={COLORS.PRIMARY} title='Save Contact' disabled={disabled} onClick={onAddNewContact} />
      </ButtonWrapper>
    </>
  );
};

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const InputView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 6px;
  padding: 0 8px;
  margin-top: 4px;
`;

const Input = styled.input`
  flex: 1;
  margin-left: 4px;
  color: ${COLORS.BLACK_TEXT};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
