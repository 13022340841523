import React, {useEffect, useMemo, useState} from 'react';

import {createSearchParams, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {CloseIcon} from 'src/components/Svgs';
import {paths} from 'src/constants';
import {useModals} from 'src/contexts/modals';
import {useAppSelector} from 'src/state/hooks';
import {COLORS} from 'src/styles';
import {IAddressBook} from 'src/types';
import styled from 'styled-components';

import {AddContactBook} from './AddContactBook';
import {ContactBook} from './ContactBook';
import {ContactBookDetail} from './ContactBookDetail';
import {Modal} from '../Modal';

type ContactBookModalProps = {
  isOpen: boolean;
  newWalletAddress?: string;
};

export const ContactBookModal = ({isOpen, newWalletAddress}: ContactBookModalProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParms, setSearchParams] = useSearchParams();
  const modalContext = useModals();
  const {addressBook} = useAppSelector((state) => state.user);
  const [value, setValue] = useState('');
  const [step, setStep] = useState(0);
  const [contact, setContact] = useState<IAddressBook>();
  const filteredAddressBook = useMemo(
    () => addressBook?.filter((item) => item.name.toLowerCase().includes(value)),
    [addressBook, value],
  );

  useEffect(() => {
    if (isOpen) {
      if (!newWalletAddress) {
        setStep(0);
      } else {
        setStep(1);
      }
    }
  }, [isOpen, newWalletAddress]);

  const onDismiss = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateContactBookModal', payload});
  };

  const onClickContact = (contact: IAddressBook) => {
    setContact(contact);
    setStep(2);
  };

  const onNavigateSendToken = () => {
    if (location.pathname === paths.send) {
      const newSearchParams = createSearchParams(searchParms);
      newSearchParams.set('address', contact.address);
      setSearchParams(newSearchParams);
    } else {
      navigate(`${paths.send}&address=${contact.address}`);
    }
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Contact Book</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          {step === 0 && (
            <ContactBook
              value={value}
              setValue={setValue}
              addressBook={filteredAddressBook}
              onAdd={() => setStep(1)}
              onClickContact={onClickContact}
            />
          )}
          {step === 1 && <AddContactBook onBack={() => onDismiss()} newWalletAddress={newWalletAddress} />}
          {step === 2 && (
            <ContactBookDetail contact={contact} onBack={() => setStep(0)} onNavigate={onNavigateSendToken} />
          )}
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow-y: auto; // Enable vertical scrolling
  max-height: 80vh; // Maximum height set to 80% of the viewport height
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
