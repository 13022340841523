import {
  GROUP_TRANSACTION_BY_TYPE,
  GroupedTxsByHash,
  TRANSACTION_GROUP,
  TRANSACTION_TYPE,
  TransactionDetails,
} from 'src/types';

export const findTx = (txs: GroupedTxsByHash | undefined, hash: string): TransactionDetails | undefined => {
  return txs
    ? txs?.[hash]?.[0] ||
        Object.values(txs)
          .flat()
          .find((tx) => tx?.hash === hash)
    : undefined;
};

export const getTransactionGroupByType = (type: TRANSACTION_TYPE) => {
  if (GROUP_TRANSACTION_BY_TYPE.SWAP.includes(type)) return TRANSACTION_GROUP.SWAP;
  if (GROUP_TRANSACTION_BY_TYPE.LIQUIDITY.includes(type)) return TRANSACTION_GROUP.LIQUIDITY;
  return TRANSACTION_GROUP.OTHER;
};

export const getTransactionStatus = (transaction: TransactionDetails) => {
  const pending = !transaction?.receipt;
  const success =
    !pending &&
    transaction &&
    (transaction.receipt?.status === 1 || typeof transaction.receipt?.status === 'undefined');
  return {
    pending,
    success,
    error: !pending && transaction?.receipt?.status !== 1,
  };
};
