import React, {useEffect, useState} from 'react';

import {CloseIcon, TickIcon} from 'src/components/Svgs';
import {useModals} from 'src/contexts/modals';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {userSlice} from 'src/state/user/reducer';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import {TokenShowMode, TokenSorting} from 'src/types';
import styled from 'styled-components';

import {Modal} from './Modal';
import {Button} from '../Buttons';
import {BodyVariant} from '../Typography';

const sortingItems = [
  {id: 0, name: 'Market Cap', value: TokenSorting.SORT_BY_MARKETCAP},
  {id: 1, name: 'A-Z', value: TokenSorting.SORT_BY_ALPHABET},
];

const tokenShowModeItems = [
  {
    id: 0,
    name: 'Always show top 20 tokens',
    value: TokenShowMode.TOP_20_TOKENS,
  },
  {
    id: 1,
    name: 'Hide zero balance tokens',
    value: TokenShowMode.HIDE_ZERO_BALANCE,
  },
  {id: 2, name: 'Always show all tokens', value: TokenShowMode.ALWAYS_SHOW_ALL},
];

type TokenSortingModalProps = {
  isOpen: boolean;
};

export const TokenSortingModal = ({isOpen}: TokenSortingModalProps) => {
  const modalContext = useModals();
  const {tokenSortSettings} = useAppSelector((state) => state.user);
  const appDispatch = useAppDispatch();

  const [sortMode, setSortMode] = useState<number>(0);
  const [tokenShowMode, setTokenShowMode] = useState<number>(0);

  useEffect(() => {
    if (tokenSortSettings) {
      setSortMode(tokenSortSettings.sortMode || 0);
      setTokenShowMode(tokenSortSettings.showMode || 0);
    }
  }, [tokenSortSettings]);

  const onUpdateSortMode = (value: number) => {
    const _tokenSortSettings = {
      sortMode: value,
      showMode: tokenShowMode,
    };
    const payload = {tokenSortSettings: _tokenSortSettings};
    appDispatch(userSlice.actions.updateTokenSortSettings(payload));
  };

  const onUpdateShowMode = (value: number) => {
    const _tokenSortSettings = {
      sortMode: sortMode,
      showMode: value,
    };
    const payload = {tokenSortSettings: _tokenSortSettings};
    appDispatch(userSlice.actions.updateTokenSortSettings(payload));
  };

  const saveSettings = () => {
    onDismiss();
  };

  const onDismiss = () => {
    const payload = {isOpen: false};
    modalContext.dispatch({type: 'updateTokenSortingModal', payload});
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Token Sorting</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          <SectionWrapper>
            <StyledSectionTitle>Token Sorting</StyledSectionTitle>
            <PlatformWrapper>
              {sortingItems.map((item, index) => (
                <StyledRow key={`token-sorting-${index}`}>
                  <PrefixItem isActive={sortMode === item.value} />
                  <NetworkItemWrapper>
                    <ItemButton showSeparator={index > 0} onClick={() => onUpdateSortMode(item.value)}>
                      <BodyVariant size={BODY_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
                        {item.name}
                      </BodyVariant>
                      {sortMode === item.value && <TickIcon />}
                    </ItemButton>
                  </NetworkItemWrapper>
                </StyledRow>
              ))}
            </PlatformWrapper>
          </SectionWrapper>
          <SectionWrapper marginTop={16}>
            <StyledSectionTitle>Tokens without balance</StyledSectionTitle>
            <PlatformWrapper>
              {tokenShowModeItems.map((item, index) => (
                <StyledRow key={`without-balance-${index}`}>
                  <PrefixItem isActive={tokenShowMode === item.value} />
                  <NetworkItemWrapper>
                    <ItemButton showSeparator={index > 0} onClick={() => onUpdateShowMode(item.value)}>
                      <BodyVariant size={BODY_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
                        {item.name}
                      </BodyVariant>
                      {tokenShowMode === item.value && <TickIcon />}
                    </ItemButton>
                  </NetworkItemWrapper>
                </StyledRow>
              ))}
            </PlatformWrapper>
          </SectionWrapper>
          <ButtonWrapper>
            <Button color={COLORS.PRIMARY} title='Save' onClick={saveSettings} />
          </ButtonWrapper>
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const StyledSectionTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const PlatformWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 12px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 23px;
  padding: 0;
`;

const PrefixItem = styled.div<{isActive?: boolean}>`
  width: 2px;
  padding: 16px 0;
  background-color: ${(props) => (props.isActive ? COLORS.SECONDARY : 'transparent')};
`;

const NetworkItemWrapper = styled.div`
  flex: 1;
  padding: 0 28px;
`;

const ItemButton = styled.button<{showSeparator?: boolean}>`
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0;
  ${(props) =>
    props.showSeparator &&
    `
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: ${COLORS.GRAY_BORDER};
  `}
`;

const Body = styled.div`
  padding: 0 24px 24px;
  flex: 1;
  overflow: auto;
`;

const SectionWrapper = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;
