import React, {useEffect, useState} from 'react';

import {GasCost, LiFi, LifiStep, Route} from '@lifi/sdk';
import {useWeb3React} from '@web3-react/core';
import {Button as AntdButton, Modal} from 'antd';
import {ethers} from 'ethers';
import moment from 'moment';
import {toast} from 'react-toastify';
import BRIDGE_SUCCESS from 'src/assets/images/transaction/bridge-success.svg';
import GAS from 'src/assets/images/transaction/gas-station.svg';
import TICK from 'src/assets/images/transaction/tick.svg';
import {Spinner} from 'src/components/Spinner';
import {BodyVariant} from 'src/components/Typography';
import {LifiContract, NETWORKS} from 'src/constants';
import {useToken} from 'src/hooks';
import {useTokenContract} from 'src/hooks/kyber-swap';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import styled from 'styled-components';

import BridgeDetails from './BrideDetails';

// import BridgeModalStepper from './BridgeModalStepper';

export type TransactionModalProps = {
  isOpen: boolean;
  transactionData: Route;
  cancelHandler: () => void;
  closeModal: () => void;
  handleContinue: (arg: boolean) => void;
};

const lifi = new LiFi({
  integrator: 'Liquidus Finance Inc',
});

const BSCprovider = new ethers.providers.JsonRpcProvider(NETWORKS[56].rpcUrl[0]);
// const ETHprovider = new ethers.providers.JsonRpcProvider(NETWORKS[1].rpcUrl[0]);
// const CRONOSprovider = new ethers.providers.JsonRpcProvider(NETWORKS[25].rpcUrl[0]);
// const PolygonProvider = new ethers.providers.JsonRpcProvider(NETWORKS[137].rpcUrl[0]);

const getNativeTokenBalance = async (tokenAddress: string, chainId: number) => {
  let balance = null;
  switch (chainId) {
    case 56:
      balance = await BSCprovider.getBalance(tokenAddress);
      break;
    // case 1:
    //   balance = await ETHprovider.getBalance(tokenAddress);
    //   break;
    // case 25:
    //   balance = await CRONOSprovider.getBalance(tokenAddress);
    //   break;
    // case 137:
    //   balance = await PolygonProvider.getBalance(tokenAddress);
    //   break;
  }
  if (balance) {
    return ethers.utils.formatEther(balance);
  } else {
    return null;
  }
};

const TransactionModal = ({
  isOpen,
  transactionData,
  cancelHandler,
  handleContinue,
  closeModal,
}: TransactionModalProps) => {
  const {sortedTokens} = useToken();
  const [insufficientFee, setInsufficientFee] = useState('');
  const {account, chainId, provider} = useWeb3React();
  const tokenContract = useTokenContract(LifiContract);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSignTransActionMark, setIsTransActionMark] = useState(false);
  const [isTransactionLoading, setIsTransactionLoading] = useState(false);
  const [isApproveToken, setIsApproveToken] = useState(false);
  const [totalExecution, setTotalExecution] = useState(0);
  const [remainTimeStamp, setRemainTimeStamp] = useState(0);
  const [isShowTimer, setIsShowTimer] = useState(false);

  useEffect(() => {
    if (transactionData) {
      setTotalExecution(0);
      transactionData.steps.forEach(async (step: LifiStep) => {
        const insufficientFees = await Promise.all(
          step.estimate.gasCosts.map(async (gasCostToken: GasCost) => {
            const matchedToken = sortedTokens.find((item) =>
              item.symbol.toLowerCase().includes(gasCostToken.token.symbol.toLowerCase()),
            );
            const gasFeeAmount = parseFloat(gasCostToken.amount) / Math.pow(10, gasCostToken.token.decimals);
            let amount = '0';
            if (chainId === gasCostToken.token.chainId) {
              const BigNumber = ethers.BigNumber.from(matchedToken.balance._hex);
              amount = ethers.utils.formatUnits(BigNumber, matchedToken.decimals);
            } else {
              const userCurrentBalance = await getNativeTokenBalance(account, gasCostToken.token.chainId);
              amount = userCurrentBalance;
            }

            if (parseFloat(amount) < gasFeeAmount) {
              const insufficientData = `${gasFeeAmount} ${gasCostToken.token.symbol} on ${
                NETWORKS[gasCostToken.token.chainId].name
              }`;
              setInsufficientFee(insufficientData);
              return true;
            }
            return false;
          }),
        );
        setTotalExecution((value) => value + step.estimate.executionDuration);

        if (insufficientFees.some((fee) => fee === true)) {
          setInsufficientFee('');
        }
      });
    } else {
      setInsufficientFee('');
    }
  }, [transactionData, account, chainId, sortedTokens]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (remainTimeStamp > 0) {
      timer = setTimeout(() => setRemainTimeStamp((current) => current - 1), 1000);
    } else {
      setRemainTimeStamp(0);
    }

    return () => clearTimeout(timer);
  }, [remainTimeStamp]);

  //step 0 - 1
  const approveTokens = async () => {
    setIsTransactionLoading(true);
    const signer = provider.getSigner(account);
    const checkApproval = await lifi.getTokenApproval(signer, transactionData?.fromToken, LifiContract);
    if (checkApproval === '0') {
      const request = {
        signer,
        token: transactionData?.fromToken,
        approvalAddress: LifiContract,
        amount: transactionData?.fromAmount,
      };
      try {
        await lifi.approveToken(request);
        setIsTransactionLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsTransactionLoading(false);
      setCurrentStep(1);
      setIsApproveToken(true);
    }
  };
  //step 1 - 2
  const signBridgeTransaction = async () => {
    setIsShowTimer(true);
    setRemainTimeStamp(totalExecution);
    const signer = provider.getSigner(account);
    try {
      setIsTransactionLoading(true);
      const switchChainHook = async (requiredChainId: number) => {
        const ethereum = window.ethereum;
        if (typeof ethereum === 'undefined') return;
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: requiredChainId}],
        });
        const newProvider = new ethers.providers.Web3Provider(window.ethereum);
        return newProvider.getSigner();
      };

      await lifi.executeRoute(signer, transactionData, {...switchChainHook});
      setIsTransActionMark(true);
      setCurrentStep(2);
    } catch (error) {
      console.log(error);
      const errorContent = JSON.stringify(error);
      const y = JSON.parse(errorContent);
      toast(y.htmlMessage);
    } finally {
      setIsTransactionLoading(false);
      setRemainTimeStamp(0);
      setIsShowTimer(false);
      handleContinue(true);
    }
  };

  const miniMizePopUp = () => {
    closeModal();
    handleContinue(false);
  };

  const doneAction = () => {
    setCurrentStep(0);
    setIsTransActionMark(false);
    setIsApproveToken(false);
    cancelHandler();
  };

  useEffect(() => {
    let isMounted = true;
    const checkApproval = async () => {
      try {
        const signer = provider.getSigner(account);
        const checkApproval = await lifi.getTokenApproval(signer, transactionData?.fromToken, LifiContract);
        if (checkApproval !== '0') {
          setIsApproveToken(true);
          setCurrentStep(1);
          isMounted = false;
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (currentStep === 0 && isMounted) {
      checkApproval();
    }
    return () => {
      isMounted = false;
    };
  }, [transactionData, account, provider, tokenContract, chainId, currentStep]);

  const buttonRender = () => {
    if (currentStep === 0) {
      return (
        <CustomAntdButton onClick={() => approveTokens()} loading={isTransactionLoading}>
          Approve Tokens
        </CustomAntdButton>
      );
    } else if (currentStep === 1) {
      const {fromChainId, toChainId} = transactionData;
      if (fromChainId !== toChainId) {
        if (isTransactionLoading) {
          return <CustomAntdButton onClick={() => miniMizePopUp()}>Minimize PopUp</CustomAntdButton>;
        } else {
          return (
            <CustomAntdButton
              disabled={insufficientFee !== ''}
              onClick={() => signBridgeTransaction()}
              loading={isTransactionLoading}
            >
              Sign Bridge Transacation
            </CustomAntdButton>
          );
        }
      } else {
        return (
          <CustomAntdButton
            disabled={insufficientFee !== ''}
            onClick={() => signBridgeTransaction()}
            loading={isTransactionLoading}
          >
            Sign Bridge Transacation
          </CustomAntdButton>
        );
      }
    } else {
      return (
        <CustomAntdButton onClick={() => doneAction()} loading={isTransactionLoading}>
          Done
        </CustomAntdButton>
      );
    }
  };
  return (
    <BridgeModal open={isOpen} onCancel={closeModal} footer={null} maskClosable={false}>
      <BridgeModalTitle>Bridge Transaction</BridgeModalTitle>
      <CustomHr />
      <Wapper>
        {currentStep === 2 ? (
          <BridgeSuccessImage src={BRIDGE_SUCCESS} />
        ) : (
          <>
            <StepWrapper>
              <Marked active={true}>
                <StepTitle active={true}>1</StepTitle>
              </Marked>
              <StepTitle active={currentStep >= 0}>Approve Tokens</StepTitle>
              {isApproveToken && <StepImage src={TICK} />}
            </StepWrapper>
            <VerticalLine />
            <StepWrapper>
              <Marked active={currentStep > 0}>
                <StepTitle active={currentStep > 0}>2</StepTitle>
              </Marked>
              <StepTitle active={currentStep > 0}>Sign Transaction</StepTitle>
              {isSignTransActionMark && <StepImage src={TICK} />}
            </StepWrapper>
            <VerticalLine />
            <StepWrapper>
              <Marked active={currentStep > 0}>
                <StepTitle active={currentStep > 0}>3</StepTitle>
              </Marked>
              <StepTitle active={currentStep > 1}>Bridging Tokens</StepTitle>
              {isShowTimer && (
                <>
                  <StepTitle active={currentStep > 0}>
                    {' '}
                    - {moment.utc(moment.duration(remainTimeStamp, 'seconds').asMilliseconds()).format('m:ss')}
                  </StepTitle>
                  {transactionData.fromChainId !== transactionData.toChainId && (
                    <SpinnerWrapper>
                      <Spinner color={COLORS.SECONDARY} />
                    </SpinnerWrapper>
                  )}
                </>
              )}
            </StepWrapper>
          </>
        )}
      </Wapper>
      {currentStep === 0 && (
        <>
          <CustomHr />
          <TokenWrapper>
            <TokenImg src={transactionData?.fromToken.logoURI} />
            <InputTokenSymbol>{transactionData?.fromToken.symbol}</InputTokenSymbol>
            <Price>{transactionData?.fromToken.name}</Price>
          </TokenWrapper>
        </>
      )}

      {currentStep > 0 && <BridgeDetails {...transactionData} />}
      {insufficientFee && (
        <InsufficientSection>
          <InsufficientHeader>
            <GasImage src={GAS} />
            <GasFeeTitle>Insufficient gas</GasFeeTitle>
          </InsufficientHeader>
          {`You don't have enough gas to complete the transaction. You need to add at least:
          ${insufficientFee}`}
        </InsufficientSection>
      )}
      <AntButtonWrapper>{buttonRender()}</AntButtonWrapper>
      {currentStep === 0 ? (
        <ConfirmText size={BODY_FONT_ENUM.SMALL}>Please confirm the transaction in your wallet</ConfirmText>
      ) : (
        <EmptySpace />
      )}
    </BridgeModal>
  );
};

export default TransactionModal;

const BridgeModal = styled(Modal)`
  width: 375px;
  height: 494px;
`;

const BridgeModalTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: 'Montserrat';
  line-height: 29.26px;
}
`;
const CustomHr = styled.hr`
  margin: 20px 0px;
  position: relative;
  left: -24px;
  width: 110%;
  height: 2px;
  background-color: #9aa6cf;
  border: none;
  opacity: 25%;
`;

const AntButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CustomAntdButton = styled(AntdButton)`
  width: 342px;
  height: 46px;
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 19.5px;
  font-family: 'Montserrat';
  background-color: #17e7d6;
`;

const ConfirmText = styled(BodyVariant)`
  text-align: center;
  margin-top: 10px;
  font-size: 11px;
`;

const EmptySpace = styled.div`
  height: 32px;
`;

const TokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding: 80px 40px;
  align-items: center;
  justify-content: center;
`;

const TokenImg = styled.img`
  width: 30px;
  height: 30px;
`;

const InputTokenSymbol = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #9aa6cf;
  margin-left: 10px;
`;

const Price = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
  margin-left: 10px;
`;
//-stepper

const Wapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const StepWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Marked = styled.div<{active: boolean}>`
  width: 23px;
  height: 23px;
  border-radius: 15px;
  background-color: ${(props) => (props.active ? '#17E7D6' : 'white')};
  border: 2px solid #17e7d6;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 10px;
  color: ${(props) => (props.active ? '#112455' : '#9AA6CF')};
`;

const StepTitle = styled.p<{active: boolean}>`
  margin: 0;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) => (props.active ? '#112455' : '#9AA6CF')};
`;

const StepImage = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const VerticalLine = styled.div`
  width: 1.5px;
  background-color: #17e7d6;
  height: 20px;
  margin-left: 12.5px;
`;

const BridgeSuccessImage = styled.img`
  position: relative;
  align-self: center;
  width: 136.66px;
  height: 120px;
`;

const InsufficientSection = styled.div`
  padding: 20px;
  background-color: #ffcc0029;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const InsufficientHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const GasImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

const GasFeeTitle = styled(BodyVariant)`
  font-size: 14px;
  color: rgb(255, 204, 0);
`;

const SpinnerWrapper = styled.div`
  margin-left: 7px;
`;
