import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IPlatform, IWhitelistToken} from 'src/types';
import {getPlatformIcon} from 'src/utils/icon-util';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type SuccessWithdrawProps = {
  inputToken?: IWhitelistToken;
  inputTokenAmount?: number;
  outputToken?: IWhitelistToken;
  outputTokenAmount?: number;
  totalUsdAmount?: number;
  platform?: IPlatform;
  rewardsToken?: IWhitelistToken;
  rewards?: number;
  isSameToken?: boolean;
  onDone?: () => void;
};

export const SuccessWithdraw = ({
  inputToken,
  inputTokenAmount,
  outputToken,
  outputTokenAmount,
  totalUsdAmount,
  rewardsToken,
  rewards,
  platform,
  isSameToken,
  onDone,
}: SuccessWithdrawProps) => {
  const iconUrl = platform?.iconUrl || getPlatformIcon(platform?.name);

  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        {!isSameToken ? (
          <>
            <TokenItem token={inputToken} value={inputTokenAmount?.toFixed(outputToken?.interfaceDecimals)} />
            <ArrowBox>
              <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
            </ArrowBox>
            <StyledTokenItem token={outputToken} value={outputTokenAmount?.toFixed(outputToken?.interfaceDecimals)} />
          </>
        ) : (
          <>
            <StyledFullRow>
              <StyledRow>
                <StyledImage src={iconUrl} />
                <BodyVariant color={COLORS.PRIMARY}>{platform?.name}</BodyVariant>
              </StyledRow>
            </StyledFullRow>
            <ArrowBox>
              <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
            </ArrowBox>
            <StyledRow marginTop={12}>
              <TokenItem token={inputToken} value={inputTokenAmount?.toFixed(inputToken?.interfaceDecimals)} />
            </StyledRow>
            <ArrowBox>
              <PlusLabel>+</PlusLabel>
            </ArrowBox>
            <StyledFullRow marginTop={12}>
              <TokenItem token={rewardsToken} value={rewards?.toFixed(2)} />
              <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.LARGE}>
                HARVESTED REWARDS
              </BodyParagraph>
            </StyledFullRow>
          </>
        )}
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Done' onClick={onDone} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const StyledRow = styled.div<{marginTop?: number}>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: 10px;
`;

const StyledFullRow = styled.div<{marginTop?: number}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const TokenInfoBox = styled.div`
  padding: 0 16px 16px;
`;

const ArrowBox = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const PlusLabel = styled.p`
  font-size: 26px;
  font-weight: 400;
  color: ${COLORS.GRAY_LIGHT};
  margin: 0;
`;

const StyledImage = styled.img`
  width: 36px;
  height: 30px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
