import React from 'react';

import {COLORS, ColorsTypes} from 'src/styles';
import {BUTTON_FONT_ENUM, ButtonFontScale, ButtonFontScaleType, DEVICE_ENUM} from 'src/styles/size';
import styled from 'styled-components';

export interface ButtonFontType extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: BUTTON_FONT_ENUM;
  mobileSize?: BUTTON_FONT_ENUM;
  color?: ColorsTypes;
  children: string | React.ReactNode;
}

export const ButtonFont = ({
  size = BUTTON_FONT_ENUM.MEDIUM,
  mobileSize = BUTTON_FONT_ENUM.NORMAL,
  color = COLORS.BLACK,
  children,
  ...props
}: ButtonFontType) => {
  return (
    <StyledButtonFont color={color} size={ButtonFontScale[size]} mobileSize={ButtonFontScale[mobileSize]} {...props}>
      {children}
    </StyledButtonFont>
  );
};

export default ButtonFont;

const StyledButtonFont = styled.p<{color: ColorsTypes; size: ButtonFontScaleType; mobileSize: ButtonFontScaleType}>`
  ${(props) => `
    color: ${props.color};
    font-size: ${props.size.fontSize}px;
    line-height: ${props.size.lineHeight}px;
    font-weight: ${props.size.fontWeight};
  `}
  margin: 0;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    ${(props) => `
      font-size: ${props.mobileSize.fontSize}px;
      line-height: ${props.mobileSize.lineHeight}px;
      font-weight: ${props.mobileSize.fontWeight};
    `}
  }
`;
