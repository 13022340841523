import React, {useState} from 'react';

import {Route} from '@lifi/sdk';
import {BridgeCard} from 'src/components/BridgeCard';
import styled from 'styled-components';

export type BridgeCardContainerType = {
  data: Route[];
  disableState: boolean;
  handleBridge: (data: Route) => void;
};

export const BridgeCardContainer = ({data, disableState, handleBridge}: BridgeCardContainerType) => {
  const [isFirstBridgeCardClicked, setIsFirstBridgeCardClicked] = useState<string>(null);
  const clickHandler = (data: Route) => {
    setIsFirstBridgeCardClicked(data.id);
    handleBridge(data);
  };
  return (
    <BridgeCardGroupWrapper isVisible={true}>
      {!disableState &&
        data.length > 0 &&
        data.map((bridge, index) => {
          return (
            <BridgeCard
              key={index}
              isClicked={isFirstBridgeCardClicked === bridge.id}
              onClick={clickHandler}
              data={bridge}
            />
          );
        })}
    </BridgeCardGroupWrapper>
  );
};

const BridgeCardGroupWrapper = styled.div<{isVisible: boolean}>`
  display: flex;
  overflow: scroll;
  padding-bottom: 20px;

  ${(props) =>
    !props.isVisible &&
    `
    display: none;
`}
`;
