import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {paths} from 'src/constants';
import {useToken} from 'src/hooks/useToken';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {FarmType, IYieldFarm, POOL_TYPE} from 'src/types';
import {convertKMPrice} from 'src/utils/text-util';
import {formatBigNumber, getTokenUSDPrice, getVestingInMonth} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph, BodyVariant} from '../Typography';

type FarmItemProps = {
  type?: number;
  data: IYieldFarm;
  selected?: boolean;
  disabled?: boolean;
  size?: 'default' | 'small';
  onPress?: () => void;
};

export const FarmItem = ({type, data, selected, disabled, size = 'default', onPress}: FarmItemProps) => {
  const navigate = useNavigate();
  const {getGlobalTokenItemByAddress} = useToken();
  const {check} = useAgreementCheck();
  const lpBalance = getTokenUSDPrice(
    formatBigNumber(data?.balance, data?.liquidityPool?.decimals || 18),
    data?.liquidityPool?.lpTokenPriceUSD,
  );

  const vestingInMonth = getVestingInMonth(data.vestingTimeInDays);

  const name = `${data?.liquidityPool?.token0Symbol} - ${data?.liquidityPool?.token1Symbol}${
    vestingInMonth ? `  ${vestingInMonth} Month` : ''
  }`;
  const tvlPrice = data?.tvlNumber;

  const chainId =
    data.type === FarmType.FARM_GRIZLYFI ? data.contractAddress?.chainId : data.liquidityPool?.address.chainId;

  const token0 = getGlobalTokenItemByAddress(data.liquidityPool?.token0Hash, chainId);
  const token1 = getGlobalTokenItemByAddress(data.liquidityPool?.token1Hash, chainId);

  const handleNavigation = () => {
    const address = // TODO: This doesn't work for Thena gauges => Implement global helper function that returns address per farm type (also use for chain id)
      data.type === FarmType.FARM_MASTERCHEF ? data.liquidityPool?.address.hash : data.contractAddress?.hash;
    if (address) {
      onPress
        ? check(onPress)
        : navigate(`${paths.poolDetail}?type=${POOL_TYPE.FARMING_POOL}&address=${address}&farm-type=${data.type}`);
    } else {
      toast('The Farm info is invalid');
    }
  };

  return (
    <StyledTokenBox disabled={disabled} selected={selected} onClick={handleNavigation}>
      <StyledLeftWrapper>
        <TokenIconWrapper size={size}>
          <TokenIcon width={size === 'default' ? 40 : 24} height={size === 'default' ? 40 : 24} token={token0} />
          <SecondTokenIcon width={size === 'default' ? 40 : 24} height={size === 'default' ? 40 : 24} token={token1} />
        </TokenIconWrapper>
        <StyledTokenNameWrapper>
          <BodyVariant
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            {name}
          </BodyVariant>
          <StyledPlatformLabel color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {data?.platform?.name}
          </StyledPlatformLabel>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      {type ? (
        <StyledRightWrapper>
          <StyledTokenLabel
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            {data?.apy?.toFixed(1)}% APY
          </StyledTokenLabel>
          <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            ${convertKMPrice(tvlPrice || 0)} TVL
          </StyledTokenPrice>
        </StyledRightWrapper>
      ) : (
        <StyledRightWrapper>
          <StyledTokenLabel
            color={COLORS.PRIMARY}
            size={size === 'default' ? BODY_FONT_ENUM.BUTTON : BODY_FONT_ENUM.SMALL_MOBILE}
          >
            ${lpBalance.toFixed(2)}
          </StyledTokenLabel>
          <StyledTokenPrice color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {data?.apy?.toFixed(1)}% APY
          </StyledTokenPrice>
        </StyledRightWrapper>
      )}
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.button<{selected?: boolean}>`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const TokenIconWrapper = styled.div<{size?: 'default' | 'small'}>`
  display: flex;
  margin-right: ${(props) => (props.size == 'default' ? 24 : 12)}px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const SecondTokenIcon = styled(TokenIcon)<{size?: 'default' | 'small'}>`
  margin-left: -${(props) => (props.size == 'default' ? 20 : 10)}px;
  margin-top: ${(props) => (props.size == 'default' ? 20 : 10)}px;
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTokenNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledPlatformLabel = styled(BodyParagraph)`
  text-align: left;
`;

const StyledTokenLabel = styled(BodyVariant)`
  text-align: right;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;
