import {useCallback, useMemo} from 'react';

import {useQuery} from '@apollo/client';
import {FARMS, GAUGES, GRIZLYFI_FARM, MASTER_CHEF_CONTRACTS_HOT} from 'src/graphql/query';
import {
  FarmType,
  IFarm,
  IFarmData,
  IFarmingPool,
  IGrizzlyFiFarm,
  IGrizzlyFiFarmData,
  IMasterChefContractData,
  IYieldFarm,
} from 'src/types';
import {IGauge, IGaugeData} from 'src/types/votercontract';
import {calculatePrice} from 'src/utils/token-util';

export const useHotFarms = () => {
  const {data: masterChefContractsData, refetch: refetchHotFarms} = useQuery<IMasterChefContractData>(
    MASTER_CHEF_CONTRACTS_HOT,
    {
      variables: {skip: 0, limit: 100},
      fetchPolicy: 'cache-and-network',
    },
  );

  const {data: farmData, refetch: refetchFarms} = useQuery<IFarmData>(FARMS, {
    variables: {skip: 0, limit: 100},
    fetchPolicy: 'cache-and-network',
  });

  const {data: gaugeData, refetch: refetchGauges} = useQuery<IGaugeData>(GAUGES, {
    variables: {skip: 0, limit: 100},
    fetchPolicy: 'cache-and-network',
  });

  const {data: grizlyData, refetch: refetchGrizzly} = useQuery<IGrizzlyFiFarmData>(GRIZLYFI_FARM, {
    variables: {skip: 0, limit: 100},
    fetchPolicy: 'cache-and-network',
  });

  const refreshHotFarms = useCallback(() => {
    refetchHotFarms();
    refetchFarms();
    refetchGauges();
    refetchGrizzly();
  }, [refetchHotFarms, refetchFarms, refetchGauges, refetchGrizzly]);

  const hotMasterchefFarms: IFarmingPool[] | [] = useMemo(() => {
    if (masterChefContractsData) {
      const hotMasterChefContracts = masterChefContractsData.masterChefContracts;

      const hotFarmingPools: IFarmingPool[] = [];
      let abis: object = {};
      hotMasterChefContracts.forEach((hotMcc) => {
        const abi = JSON.parse(hotMcc.config.masterChefAbi || '');
        abis = {
          ...abis,
          [hotMcc.platform.name]: abi,
        };
        hotMcc.whitelistedFarmingPools.forEach((wlfp) => {
          const farmPool = {
            ...wlfp,
            type: FarmType.FARM_MASTERCHEF,
            platform: hotMcc.platform,
            poolId: wlfp.poolId,
            rewardTokenChainSpecifics: hotMcc.rewardTokenChainSpecifics,
            pendingRewardFn: hotMcc.config.pendingRewardFn,
            harvestFn: hotMcc.config.harvestFn,
            depositFn: hotMcc.config.depositFn,
            withdrawFn: hotMcc.config.withdrawFn,
            masterChefAddress: hotMcc.address,
            liquidityPool: wlfp.liquidityPool,
            apy: wlfp.apy,
            baseAPR: wlfp.baseAPR,
            tvlNumber: calculatePrice(wlfp?.tvl, 0),
          };
          if (wlfp.hotness) {
            hotFarmingPools.push(farmPool);
          }
        });
      });

      return hotFarmingPools;
    }
    return [];
  }, [masterChefContractsData]);

  const hotFarms: IFarm[] = useMemo(() => {
    if (farmData?.farms) {
      const farms: IFarm[] = [];
      farmData?.farms.forEach((item) => {
        const _farmPool = {
          ...item,
          type: item.liquidityPool ? FarmType.FARM_LP : FarmType.FARM_SINGLE,
          tvlNumber: calculatePrice(item?.tvl, 0),
        };
        farms.push(_farmPool);
      });
      return farms.filter((farm) => farm.hotness && farm.hotness > 0);
    }
    return [];
  }, [farmData]);

  const hotGauges: IGauge[] = useMemo(() => {
    if (gaugeData?.whitelistedGauges) {
      const gauges: IGauge[] = [];
      gaugeData.whitelistedGauges?.forEach((item) => {
        const gauge = {
          ...item,
          tvlNumber: calculatePrice(item.liquidityPool?.lpValueUSD),
          pendingRewardFn: item.voterContract?.config.rewardsFn,
          harvestFn: item.voterContract?.config.harvestFn,
          depositFn: item.voterContract?.config.depositFn,
          withdrawFn: item.voterContract?.config.withdrawFn,
          gaugeAbi: item.voterContract?.config.gaugeAbi,
          balanceFn: item.voterContract?.config.balanceFn,
          type: FarmType.FARM_GAUGE,
        };
        gauges.push(gauge);
      });
      return gauges.filter((farm) => farm.hotness && farm.hotness > 0);
    }
    return [];
  }, [gaugeData]);

  const hotGrizzlyFarms: IGrizzlyFiFarm[] = useMemo(() => {
    if (grizlyData?.grizlyfiFarms) {
      const grizzlyFarms: IGrizzlyFiFarm[] = [];
      grizlyData.grizlyfiFarms.forEach((item) => {
        const grizzlyFarm = {
          ...item,
          type: FarmType.FARM_GRIZLYFI,
          liquidityPool: item,
          tvlNumber: calculatePrice(item?.tvl, 0),
        };
        grizzlyFarms.push(grizzlyFarm);
      });
      return grizzlyFarms.filter((farm) => farm.hotness && farm.hotness > 0);
    }
    return [];
  }, [grizlyData]);

  const hotFarmsSorted = useMemo(() => {
    const mergedFarms: IYieldFarm[] = [
      ...(hotFarms ?? []),
      ...(hotMasterchefFarms ?? []),
      ...(hotGauges ?? []),
      ...(hotGrizzlyFarms ?? []),
    ];
    const _hotFarms = mergedFarms?.sort((a, b) => (b.hotness ?? 0) - (a.hotness ?? 0));
    return _hotFarms;
  }, [hotFarms, hotMasterchefFarms, hotGauges, hotGrizzlyFarms]);

  return {hotFarmsSorted, refreshHotFarms};
};
