import React from 'react';

import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import {calculatePrice} from 'src/utils/token-util';
import styled from 'styled-components';

type ConfirmSwapProps = {
  inputToken?: IWhitelistToken;
  outputToken?: IWhitelistToken;
  inputAmount?: string;
  outputAmount?: string;
  slippageAdjustedAmount?: string;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
  isError?: boolean;
  errorMsg?: string;
  errorSuggestion?: string;
  errorClick?: () => void;
  hasRejected?: boolean;
};

export const ConfirmSwap = ({
  inputToken,
  outputToken,
  inputAmount,
  outputAmount,
  //gasFeePrice,
  slippageAdjustedAmount,
  loading,
  disabled,
  onConfirm,
  isError,
  errorMsg,
  errorSuggestion,
  errorClick,
  hasRejected,
}: ConfirmSwapProps) => {
  const outputUSDPriceUnit = calculatePrice(outputToken?.priceUSD, outputToken?.priceDecimals);
  const outputUSDPrice = Number(outputAmount) * outputUSDPriceUnit;
  return (
    <Body>
      <TokenInfoBox>
        <TokenItem token={inputToken} value={inputAmount} />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledTokenItem token={outputToken} value={outputAmount} />
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(outputUSDPrice, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      {/* <StyledRowBetween>
    <BodyParagraph color={COLORS.GRAY_LIGHT}>Estimated gas fee</BodyParagraph>
    <BodyParagraph color={COLORS.SECONDARY}>${toFixed(gasFeePrice, 2)}</BodyParagraph>
  </StyledRowBetween> */}
      <BodyParagraph color={COLORS.GRAY_LIGHT}>
        Output is estimated. You will receive at least {slippageAdjustedAmount} {outputToken.symbol}.
      </BodyParagraph>
      {isError && hasRejected == false ? (
        <>
          <StyledParagraph color={COLORS.WARNING}>{errorMsg}</StyledParagraph>
          <StyledParagraphMsg color={COLORS.PRIMARY} onClick={errorClick}>
            {errorSuggestion}
          </StyledParagraphMsg>
        </>
      ) : (
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title='Confirm Swap'
          onClick={() => {
            onConfirm();
          }}
        />
      )}
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 16px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
`;

const StyledParagraphMsg = styled(StyledParagraph)`
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
`;
