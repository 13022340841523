import {toast} from 'react-toastify';

export const defaultSlippageTolerance = 1 * 100;

export const fetchAbiUrl = (url: string, address: string, apiKey: string) => {
  if (!address || !apiKey || !url) {
    toast.error('Failed to fetch required data');
    throw new Error('address, apiKey and url are all required');
  } else return `${url}/api?module=contract&action=getabi&address=${address}&apikey=${apiKey}`;
};
