import * as React from 'react';

import {ColorsTypes} from 'src/styles/colors';

export type CheckedBackgroundIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const CheckedBackgroundIcon = ({size = 20, ...props}: CheckedBackgroundIconType) => (
  <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='10' cy='10' r='10' fill='#17E7D6' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.6252 11.9968L6.31517 9.88322L5 11.3201L8.68484 14.6918L15 8.37668L13.6233 7L8.6252 11.9968Z'
      fill='white'
    />
  </svg>
);

export default CheckedBackgroundIcon;
