import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, ethers} from 'ethers';
import {useSearchParams} from 'react-router-dom';
import WarningIcon from 'src/assets/images/warning.png';
import {Button} from 'src/components/Buttons';
import {ContactItem} from 'src/components/ContactItem';
import {TokenSelectModal} from 'src/components/Modals';
import {SendTokenModal} from 'src/components/Modals/SendToken';
import {ArrowRightIcon, CloseIcon, TickIcon} from 'src/components/Svgs';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {useModals} from 'src/contexts/modals';
import {useToken} from 'src/hooks';
import {useOnClickOutside} from 'src/hooks/useOnClickOutside';
import {farmSlice} from 'src/state/farm/reducer';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import {Field, IWhitelistToken} from 'src/types';
import {formatBigNumber, parseBigNumber} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import {simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

export const Send = () => {
  const {account} = useWeb3React();
  const [searchParms] = useSearchParams();
  const tokenAddress = searchParms.get('tokenAddress');
  const address = searchParms.get('address');
  const modalContext = useModals();
  const appDispatch = useAppDispatch();
  const farmState = useAppSelector((state) => state.farm);
  const {addressBook} = useAppSelector((state) => state.user);
  const {check} = useAgreementCheck();
  const [showInputTokenSelectModal, setShowInputTokenSelectModal] = useState(false);
  const [showSendTokenModal, setShowSendTokenModal] = useState(false);
  const {getTokenByGlobalName, getTokenByAddress} = useToken();
  const inputToken = getTokenByGlobalName(farmState.INPUT.tokenGlobalName, true);
  const balance = formatBigNumber(inputToken?.balance, inputToken?.decimals);

  const [inputValue, setInputTyped] = useState('');
  const [recipientAddress, setRecipientAddress] = useState(address ?? '');
  const [isEditing, setIsEditing] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(dropDownRef, isEditing ? () => setIsEditing(false) : undefined);

  const isValidAddress = ethers.utils.isAddress(recipientAddress);
  const inputAmount = useMemo(() => {
    if (isMax) {
      return inputToken?.balance;
    } else {
      return parseBigNumber(inputValue, inputToken?.decimals);
    }
  }, [inputToken, inputValue, isMax]);

  const isInsufficientBalance = inputAmount?.gt(inputToken?.balance ?? BigNumber.from(0));
  const disabled = !inputToken || !inputValue || isInsufficientBalance || !isValidAddress;
  const errorMessage = isInsufficientBalance
    ? 'Insufficient Balance'
    : recipientAddress && !isValidAddress
    ? 'Invalid wallet address'
    : '';

  const filteredAddressBook = useMemo(
    () =>
      addressBook?.filter(
        (item) =>
          item.address.includes(recipientAddress) || item.name?.toLowerCase().includes(recipientAddress?.toLowerCase()),
      ),
    [addressBook, recipientAddress],
  );
  const existingContact = useMemo(
    () => addressBook?.find((item) => item.address === recipientAddress),
    [addressBook, recipientAddress],
  );
  const shouldShowAddContact = isValidAddress && !existingContact;

  useEffect(() => {
    if (tokenAddress) {
      const selectedToken = getTokenByAddress(tokenAddress, false); // TODO: Make sure this works also with native tokens
      appDispatch(farmSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: selectedToken.globalName}));
    }
  }, [appDispatch, getTokenByAddress, tokenAddress]);

  useEffect(() => {
    if (address) {
      setRecipientAddress(address);
    }
  }, [address]);

  const handleInputTokenSelect = useCallback(
    (token?: IWhitelistToken) => {
      setIsMax(false);
      appDispatch(farmSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token?.globalName}));
    },
    [appDispatch],
  );

  const onInputAmountChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setIsMax(false);
    setInputTyped(removedComma);
  };

  const handleApplyMaxBalance = () => {
    setIsMax(true);
    const _tokenBalance = formatBigNumber(inputToken.balance, inputToken.decimals);
    setInputTyped(_tokenBalance.toFixed(inputToken.interfaceDecimals));
  };

  const onRecipientAddressChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIsEditing(true);
    setRecipientAddress(value);
  };

  const onViewContactBook = () => {
    const payload = {isOpen: true};
    modalContext.dispatch({type: 'updateContactBookModal', payload});
  };

  const onAddNewContact = () => {
    const payload = {isOpen: true, newWalletAddress: recipientAddress};
    modalContext.dispatch({type: 'updateContactBookModal', payload});
  };

  const onClearRecipientAddress = () => {
    setRecipientAddress('');
    setIsEditing(false);
  };

  const onConfirm = () => {
    // confirm
    check(() => setShowSendTokenModal(true));
  };

  return (
    <Wrapper>
      <Container>
        <BodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.LARGE} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
          Send
        </BodyVariant>
        <TokenPairWrapper>
          <TokenPairContainer>
            <StyledPanel>
              <StyledFullRow>
                <BodyVariant color={COLORS.GRAY_LIGHT}>Token</BodyVariant>
                <StyledBalanceWrapper onClick={handleApplyMaxBalance}>
                  <StyledBalanceLabel color={COLORS.GRAY_LIGHT}>
                    Balance: {balance.toFixed(inputToken?.interfaceDecimals)}
                  </StyledBalanceLabel>
                  <StyledMaxBalance color={COLORS.PRIMARY}>Max Balance</StyledMaxBalance>
                </StyledBalanceWrapper>
              </StyledFullRow>
              <TokenSelectWrapper>
                <TokenSelectItem token={inputToken} onSelect={() => setShowInputTokenSelectModal(true)} />
                <StyledInput
                  autoComplete='off'
                  align='right'
                  placeholder='0.00'
                  inputMode='decimal'
                  pattern='^[0-9]*[.,]?[0-9]*$'
                  value={inputValue}
                  onChange={onInputAmountChanged}
                />
              </TokenSelectWrapper>
              {account && errorMessage !== '' && (
                <StyledWarningBox>
                  <StyledWarningIcon src={WarningIcon} />
                  <BodyParagraph color={COLORS.WARNING}>{errorMessage}</BodyParagraph>
                </StyledWarningBox>
              )}
            </StyledPanel>
            <ArrowWrapper>
              <StyledArrowRightIcon size={14} color={COLORS.WHITE} />
              <StyledArrowDownIcon size={14} color={COLORS.WHITE} />
            </ArrowWrapper>
            <StyledPanel>
              <StyledFullRow>
                <BodyVariant color={COLORS.GRAY_LIGHT}>To</BodyVariant>
                <StyledButton onClick={onViewContactBook}>
                  <StyledRow>
                    <BodyParagraph color={COLORS.GRAY_LIGHT}>View contacts:</BodyParagraph>
                    <BodyParagraph color={COLORS.GRAY_LIGHT}>({addressBook?.length})</BodyParagraph>
                  </StyledRow>
                </StyledButton>
              </StyledFullRow>
              <TokenSelectWrapper>
                {existingContact ? (
                  <ContactItem contact={existingContact} />
                ) : isValidAddress ? (
                  <StyledRow gap={8}>
                    <BodyVariant color={COLORS.GRAY_LIGHT}>{simplifyAddress(recipientAddress)}</BodyVariant>
                    <TickIcon />
                  </StyledRow>
                ) : (
                  <StyledInput
                    autoComplete='off'
                    placeholder='Recipient Address'
                    value={recipientAddress}
                    onFocus={() => setIsEditing(true)}
                    onChange={onRecipientAddressChanged}
                    type={'text'}
                  />
                )}
                <StyledButton onClick={onClearRecipientAddress}>
                  <CloseIcon size={16} />
                </StyledButton>
              </TokenSelectWrapper>
              {shouldShowAddContact && (
                <StyledButton onClick={onAddNewContact}>
                  <BodyParagraph style={{marginLeft: '12px', textAlign: 'left'}} color={COLORS.PRIMARY}>
                    Add this address to your contacts
                  </BodyParagraph>
                </StyledButton>
              )}
              {isEditing && filteredAddressBook?.length > 0 && (
                <AutoCompleteWrapper ref={dropDownRef}>
                  {filteredAddressBook.map((item) => (
                    <ContactItem
                      contact={item}
                      border={true}
                      key={item.address}
                      onPress={() => {
                        setRecipientAddress(item.address);
                        setIsEditing(false);
                      }}
                    />
                  ))}
                </AutoCompleteWrapper>
              )}
            </StyledPanel>
          </TokenPairContainer>
        </TokenPairWrapper>
        <ButtonWrapper>
          <Button color={COLORS.PRIMARY} disabled={disabled} title='Continue' onClick={onConfirm} />
        </ButtonWrapper>
      </Container>
      {handleInputTokenSelect && (
        <TokenSelectModal
          selectedToken={inputToken}
          showClearToken
          showBalanceOnly
          onTokenSelect={handleInputTokenSelect}
          isOpen={showInputTokenSelectModal}
          onDismiss={() => setShowInputTokenSelectModal(false)}
        />
      )}
      <SendTokenModal
        isOpen={showSendTokenModal}
        onDismiss={() => setShowSendTokenModal(false)}
        selectedToken={inputToken}
        amount={inputAmount}
        recipientAddress={recipientAddress}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 80px 0;

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 0;
  }
`;

const Container = styled.div`
  width: 712px;
  border-radius: 24px;
  filter: drop-shadow(4px 4px 20px rgba(17, 36, 85, 0.06));

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
    padding: 0 16px;
  }
`;

const TokenPairWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 20px;
  background-color: ${COLORS.WHITE};
  border-radius: 12px;
`;

const TokenPairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    flex-direction: column;
  }
`;

const StyledPanel = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: 100%;
  }
`;

const TokenSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 18px;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BASE_40};

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    width: auto;
  }
`;

const StyledInput = styled.input<{align?: string}>`
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.PRIMARY};
  background-color: transparent;
  text-align: ${(props) => props.align ?? 'left'};
  border: none;
  outline: none;
  padding: 0;
`;

const StyledBalanceLabel = styled(BodyParagraph)`
  display: block;
`;

const StyledMaxBalance = styled(BodyParagraph)`
  display: none;
`;

const StyledBalanceWrapper = styled.div<{maxBalance?: boolean}>`
  cursor: pointer;

  &:hover ${StyledBalanceLabel} {
    display: none;
  }

  &:hover ${StyledMaxBalance} {
    display: block;
  }
`;

const StyledButton = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const StyledFullRow = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const AutoCompleteWrapper = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  background-color: white;
  z-index: 10;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const ArrowWrapper = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.GRAY_BASE_40};
  border-radius: 22px;
  margin-top: 52px;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    margin-top: 16px;
    margin-bottom: 16px;
    align-self: center;
  }
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  display: block;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    display: none;
  }
`;

const StyledArrowDownIcon = styled(ArrowRightIcon)`
  display: none;

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    display: block;
    rotate: -270deg;
  }
`;
