import * as React from 'react';

import {
  ContactBookModal,
  ImportPoolModal,
  PlatformSettingsModal,
  RenameAccountModal,
  SwapSettingsModal,
  SwitchNetworkModal,
  TokenSortingModal,
  UserSignature,
  WalletConnectModal,
  YieldInfoModal,
  ZapSettingsModal,
} from 'src/components/Modals';
import AddCustomTokenModal from 'src/components/Modals/AddCustomToken';
import {TokenReceiveModal} from 'src/components/Modals/Receive';

import {modalsInitialState, modalsReducer} from './reducer';
import {ModalsDispatch, ModalsState} from './types';

const ModalsContext = React.createContext<
  | {
      state: ModalsState;
      dispatch: ModalsDispatch;
    }
  | undefined
>(undefined);

function ModalsProvider({children}: {children: React.ReactNode}) {
  const [state, dispatch] = React.useReducer(modalsReducer, modalsInitialState);
  const value = {state, dispatch};
  return (
    <ModalsContext.Provider value={value}>
      {children}
      <ContactBookModal {...state.contactBookModal} />
      <RenameAccountModal {...state.renameAccountModal} />
      <PlatformSettingsModal {...state.platformSettingsModal} />
      <SwapSettingsModal {...state.swapSettingsModal} />
      <ZapSettingsModal {...state.zapSettingsModal} />
      <TokenReceiveModal {...state.tokenReceiveModal} />
      <TokenSortingModal {...state.tokenSortingModal} />
      <AddCustomTokenModal {...state.addCustomTokenModal} />
      <WalletConnectModal {...state.walletConnectModal} />
      <ImportPoolModal {...state.importPoolModal} />
      <YieldInfoModal {...state.yieldInfoModal} />
      <UserSignature {...state.userSignature} />
      <SwitchNetworkModal {...state.switchNetworkModal} />
    </ModalsContext.Provider>
  );
}

function useModals() {
  const context = React.useContext(ModalsContext);
  if (context === undefined) {
    throw new Error('useModals must be used within a ModalsProvider');
  }
  return context;
}

export {ModalsProvider, useModals};
