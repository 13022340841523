import hackenAuditor from 'src/assets/images/auditors/hackenAuditor.png';

export const auditList = [
  {
    auditorName: 'hacken',
    icon: hackenAuditor,
    text: 'AUDIT BY',
    link: 'https://hacken.io/wp-content/uploads/2021/12/Liquidus_20122021SCAudit_Report.pdf',
  },
];
