import {useState} from 'react';

import {useModals} from 'src/contexts/modals';

export const useModalState = (initialAddress?: string) => {
  const [contractAddress, setContractAddress] = useState(initialAddress || '');
  const modalContext = useModals();

  const openModal = (address: string) => {
    setContractAddress(address);
    modalContext.dispatch({type: 'updateAddCustomTokenModal', payload: {isOpen: true, tokenAddress: address}});
  };

  const closeModal = () => {
    modalContext.dispatch({type: 'updateAddCustomTokenModal', payload: {isOpen: false, tokenAddress: ''}});
  };

  return {
    contractAddress,
    setContractAddress,
    openModal,
    closeModal,
  };
};
