import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph} from '../Typography';

type LiquidityReceiveItemProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  lpReceived: number;
  poolShare: number;
  poolShareDecimals: number;
};

export const LiquidityReceiveItem = ({
  token0,
  token1,
  lpReceived,
  poolShare,
  poolShareDecimals,
}: LiquidityReceiveItemProps) => {
  const share = `${poolShare?.toFixed(poolShareDecimals)}% share`;

  const liquidity = lpReceived?.toFixed(7);
  return (
    <StyledTokenBox>
      <StyledLeftWrapper>
        <TokenIconWrapper size='default'>
          <TokenIcon width={24} height={24} token={token0} />
          <SecondTokenIcon width={24} height={24} token={token1} />
        </TokenIconWrapper>
        <StyledTokenDetailsWrapper>
          <TokenDetailsContainer>
            {liquidity && (
              <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                {liquidity} LP
              </BodyParagraph>
            )}
            {poolShare && (
              <StyledShare color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                {share}
              </StyledShare>
            )}
          </TokenDetailsContainer>
          <StyledPlatformLabel>
            <BodyParagraph
              color={COLORS.GRAY_LIGHT}
            >{`${token0?.symbol} - ${token1?.symbol} Pool Tokens`}</BodyParagraph>
          </StyledPlatformLabel>
        </StyledTokenDetailsWrapper>
      </StyledLeftWrapper>
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: ${COLORS.WHITE};
  border-radius: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
`;

const TokenIconWrapper = styled.div<{size?: 'default' | 'small'}>`
  display: flex;
  margin-right: ${(props) => (props.size == 'default' ? 24 : 12)}px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    margin-right: 16px;
  }
`;

const SecondTokenIcon = styled(TokenIcon)<{size?: 'default' | 'small'}>`
  margin-left: -${(props) => (props.size == 'default' ? 20 : 10)}px;
  margin-top: ${(props) => (props.size == 'default' ? 20 : 10)}px;
`;

const StyledLeftWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const StyledTokenDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: space-between;
  width: 100%;
  gap: 6px;
`;

const StyledPlatformLabel = styled.div`
  display: flex;
  text-align: left;
`;

const TokenDetailsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledShare = styled(BodyParagraph)`
  margin-left: auto;
`;
