import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type HamburgerMenuIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const HamburgerMenuIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: HamburgerMenuIconType) => (
  <svg width={size} height={size} viewBox='0 0 30 30' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.0806 18.3999C17.0806 18.1495 16.9845 17.9092 16.8143 17.7334C16.6441 17.5576 16.4132 17.462 16.175 17.4666H7.8879C7.39734 17.4666 7 17.8843 7 18.3999C7 18.9156 7.39734 19.3332 7.8879 19.3332H16.175C16.4132 19.3379 16.6441 19.2422 16.8143 19.0665C16.9845 18.8907 17.0806 18.6504 17.0806 18.3999ZM23 10.9335C23 10.683 22.9038 10.4427 22.7336 10.2669C22.5635 10.0912 22.3326 9.99548 22.0943 10.0002H7.8879C7.39734 10.0002 7 10.4178 7 10.9335C7 11.4491 7.39734 11.8668 7.8879 11.8668H22.0943C22.3326 11.8714 22.5635 11.7758 22.7336 11.6C22.9038 11.4242 23 11.1839 23 10.9335Z'
      fill={color}
    />
  </svg>
);

export default HamburgerMenuIcon;
