// import setAuthToken from '../utils/set-auth-token';

import {createSlice} from '@reduxjs/toolkit';

export const actionTypes = {
  RefreshCount: '[RefreshCount] Action',
  Web3Client: '[Web3Client] Action',
  SetNetId: '[SetNetId] Action',
};

const initialState = {
  refreshCount: 0,
};

export const tokenMigrationSlice = createSlice({
  name: 'tokenMigration',
  initialState,
  reducers: {
    changeRefresh(state) {
      return {
        ...state,
        refreshCount: state.refreshCount + 1,
      };
    },
  },
});
