import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type SuccessTopupProps = {
  inputToken1?: IWhitelistToken;
  inputToken2?: IWhitelistToken;
  outputToken1?: IWhitelistToken;
  outputToken2?: IWhitelistToken;
  inputTokenAmount1?: number;
  inputTokenAmount2?: number;
  outputToken1EstimatedAmount?: number;
  outputToken2EstimatedAmount?: number;
  totalUsdAmount?: number;
  onDone?: () => void;
};

export const SuccessTopup = ({
  inputToken1,
  inputToken2,
  outputToken1,
  outputToken2,
  inputTokenAmount1,
  inputTokenAmount2,
  outputToken1EstimatedAmount,
  outputToken2EstimatedAmount,
  totalUsdAmount,
  onDone,
}: SuccessTopupProps) => {
  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        <TokenItem token={inputToken1} value={inputTokenAmount1?.toFixed(inputToken1?.interfaceDecimals)} />
        {inputToken2 && (
          <TokenItem token={inputToken2} value={inputTokenAmount2?.toFixed(inputToken2?.interfaceDecimals)} />
        )}

        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledTokenItem
          token={outputToken1}
          value={outputToken1EstimatedAmount?.toFixed(outputToken1?.interfaceDecimals)}
        />
        {outputToken2 && (
          <StyledTokenItem
            token={outputToken2}
            value={outputToken2EstimatedAmount?.toFixed(outputToken2?.interfaceDecimals)}
          />
        )}
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Done' onClick={onDone} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
