import React from 'react';

import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import styled from 'styled-components';

import {BodyParagraph} from '../Typography';

interface TokenItemProps {
  name?: string;
  token?: IWhitelistToken;
  rightTopLabel?: string;
  rightBottomLabel?: string;
  label?: string;
  subLabel?: string;
  type?: string;
  selected?: boolean;
  onSelect?: (token: IWhitelistToken) => void;
  disabled?: boolean;
}

const RewardTokenItem = ({
  name,
  label,
  token,
  rightTopLabel,
  rightBottomLabel,
  selected,
  type,
  onSelect,
  disabled,
  ...props
}: TokenItemProps) => {
  const tokenUrl = token?.iconUrl || getTokenIconByGlobalName(token?.globalName);
  return (
    <StyledTokenBox
      selected={selected}
      onClick={() => onSelect && !disabled && onSelect(token)}
      {...props}
      disabled={disabled}
    >
      <StyledLeftWrapper>
        <StyledImage src={tokenUrl} />
        <StyledTokenNameWrapper>
          <StyledTokenNameLabel color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            {name}
          </StyledTokenNameLabel>
          <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {label}
          </BodyParagraph>
        </StyledTokenNameWrapper>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <StyledTokenLabel color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          {rightTopLabel}
        </StyledTokenLabel>
        <StyledTokenPrice color={type ? COLORS.GRAY_LIGHT : COLORS.GREEN} size={PARAGRAPH_FONT_ENUM.SMALL}>
          {rightBottomLabel}
        </StyledTokenPrice>
      </StyledRightWrapper>
    </StyledTokenBox>
  );
};

const StyledTokenBox = styled.div<{selected?: boolean; disabled?: boolean}>`
  flex: 1;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-radius: 20px;
  overflow: hidden;
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  ${(props) =>
    props.disabled &&
    `
    filter: opacity(0.5)
  `}
  ${(props) =>
    props.selected &&
    !props.disabled &&
    `
    border: 1px solid ${COLORS.SECONDARY};
  `}
`;

const StyledImage = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;

const StyledLeftWrapper = styled.div`
  flex: 0.65;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRightWrapper = styled.div`
  flex: 0.35;
  display: flex;
  flex-direction: column;
  align-items: 'flex-end';
`;

const StyledTokenNameWrapper = styled.div`
  flex: 1;
`;

const StyledTokenNameLabel = styled(BodyParagraph)`
  flex-wrap: wrap;
`;

const StyledTokenLabel = styled(BodyParagraph)`
  text-align: right;
`;

const StyledTokenPrice = styled(BodyParagraph)`
  text-align: right;
`;

export default RewardTokenItem;
