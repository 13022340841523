import {useEffect, useState} from 'react';

import {useLazyQuery, useMutation} from '@apollo/client';
import {useWeb3React} from '@web3-react/core';
import {CREATE_T_AND_C_AGREEMENT} from 'src/graphql/mutations/createTandC';
import {GET_T_AND_C_AGREEMENT} from 'src/graphql/query';
import {userServicesClient} from 'src/providers/GraphqlProvider';

export const useTandCAgreement = () => {
  const {account} = useWeb3React();
  const [agreementExists, setAgreementExists] = useState(null);
  const [createAgreement] = useMutation(CREATE_T_AND_C_AGREEMENT, {client: userServicesClient});

  // Function to request a signature from the user's wallet
  const requestSignature = async (message: string) => {
    try {
      const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [message, account],
      });
      return signature;
    } catch (error) {
      console.error('Error requesting signature:', error);
      return error;
    }
  };

  // Function to send the signed agreement to the backend via GraphQL mutation
  const sendAgreement = async (signature: string, message: string) => {
    try {
      const result = await createAgreement({
        variables: {
          data: {
            accountHash: account,
            signature: signature,
            originalMessage: message,
          },
        },
      });
      return result.data;
    } catch (err) {
      console.error('Error sending T-and-C agreement:', err);
      return err;
    }
  };

  const [getTandCAgreement] = useLazyQuery(GET_T_AND_C_AGREEMENT, {
    client: userServicesClient,
    variables: {accountAddress: account},
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAgreementExists(data.getTandCsAgreement !== null);
    },
    onError: (error) => {
      console.error('Error fetching T-and-C Agreement:', error);
    },
  });

  // Call getTandCAgreement when the account is available
  useEffect(() => {
    if (account) {
      getTandCAgreement();
    }
  }, [account, getTandCAgreement]);

  return {
    requestSignature,
    sendAgreement,
    getTandCAgreement,
    agreementExists,
  };
};
