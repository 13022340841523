import styled, {keyframes} from 'styled-components';

const spin = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{size?: number; color?: string}>`
  width: ${(props) => props.size ?? 16}px;
  height: ${(props) => props.size ?? 16}px;
  animation: ${spin} 1s linear infinite;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: ${(props) => (props.color ? `0.2em solid ${props.color}` : '0.2em solid #767676')};
  border-radius: 50%;
`;
