import {COLORS} from 'src/styles';
import {IItemType} from 'src/types';
import styled from 'styled-components';

interface ItemPickerProps {
  items: IItemType[];
  selectedItem: IItemType;
  customValue?: string;
  displayMode?: string;
  warning?: boolean;
  onChangeCustomValue?: (value: string) => void;
  onPicked: (value: IItemType) => void;
}

export const ItemPicker = ({
  items,
  selectedItem,
  customValue,
  displayMode,
  warning,
  onPicked,
  onChangeCustomValue,
}: ItemPickerProps) => {
  return (
    <Wrapper>
      {items.map((item, index) => (
        <ItemWrapper
          key={index}
          type={selectedItem.name === item.name ? (warning ? 'warning' : 'active') : undefined}
          onClick={() => onPicked(item)}
        >
          {displayMode === 'value' && <TextLabel isActive={selectedItem.name === item.name}>{item.value}</TextLabel>}
          {item.name === 'Custom' && selectedItem.name === 'Custom' ? (
            <>
              <TextInput
                inputMode='decimal'
                pattern='^[0-9]*[.,]?[0-9]*$'
                value={customValue}
                onChange={(event) => onChangeCustomValue(event.target.value)}
              />
              <TextLabel isActive={selectedItem.name === item.name}>%</TextLabel>
            </>
          ) : (
            <TextLabel isActive={selectedItem.name === item.name}>{item.name}</TextLabel>
          )}
        </ItemWrapper>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-width: 1px;
  border-color: ${COLORS.GRAY_BORDER};
  border-radius: 26px;
  padding: 1px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const ItemWrapper = styled.div<{type?: 'active' | 'warning' | undefined}>`
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.type &&
    `
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: ${props.type === 'active' ? COLORS.SECONDARY : COLORS.WARNING}
  `}
`;

const TextLabel = styled.p<{isActive?: boolean}>`
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  color: ${(props) => (props.isActive ? COLORS.PRIMARY : COLORS.GRAY_LIGHTER)};
`;

const TextInput = styled.input`
  width: 30px;
  font-size: 15px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
  text-align: center;
`;
