import * as React from 'react';

import {COLORS} from 'src/styles/colors';
import {IconType} from 'src/types';

export const SecurityRatingIcon = ({size = 22, color = COLORS.SECONDARY, ...props}: IconType) => (
  <svg width={size} height={size} viewBox='0 0 18 22' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 12.2821V3.82051L8.85714 1L16.7143 3.82051V12.2821C16.7143 15.9059 12.54 18.4359 8.85714 21C5.17424 18.4359 1 15.9059 1 12.2821Z'
      stroke={color}
      strokeLinejoin='round'
    />
    <path
      d='M10.7663 11.4203H12.252V12.6661H10.6177L10.3891 14.4946H9.09771L9.32628 12.6661H7.64628L7.41771 14.4946H6.13771L6.36628 12.6661H4.86914V11.4203H6.51486L6.75486 9.56891H5.25771V8.3232H6.90343L7.132 6.49463H8.412L8.18343 8.3232H9.86343L10.092 6.49463H11.372L11.1434 8.3232H12.6291L12.6406 9.56891H10.9949L10.7663 11.4203ZM9.47485 11.4203L9.71486 9.56891H8.03486L7.79486 11.4203H9.47485Z'
      fill={color}
    />
  </svg>
);

export default SecurityRatingIcon;
