import {useRef} from 'react';

import ChevronIcon from 'src/assets/images/icons/icon-chevron-down.svg';
import {useOnClickOutside} from 'src/hooks/useOnClickOutside';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

import {DropDown} from './Dropdown';
import {BodyParagraph} from '../Typography';

type DropdownMenuProps = {
  title?: string;
  icon?: string;
  children: React.ReactNode;
  openState: boolean;
  toggleDropdown: () => void;
};

export const DropdownMenu = ({title, icon, children, openState, toggleDropdown}: DropdownMenuProps) => {
  const node = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(node, openState ? toggleDropdown : undefined);
  return (
    <DropDown ref={node}>
      <StyledMenu onClick={toggleDropdown}>
        {icon && <StyledLogo src={icon} />}
        <StyledBodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          {title}
        </StyledBodyParagraph>
        <StyledImage src={ChevronIcon} />
      </StyledMenu>
      <StyledContent isOpened={openState}>{children}</StyledContent>
    </DropDown>
  );
};

const StyledBodyParagraph = styled(BodyParagraph)`
  margin: 0;
`;

const StyledMenu = styled.button`
  display: flex;
  height: 32px;
  align-items: center;
  background-color: transparent;
  border: 1px solid ${COLORS.SECONDARY};
  border-radius: 23px;
  outline: none;
  gap: 12px;
  cursor: pointer;

  &:hover ${StyledBodyParagraph} {
    color: black;
  }
`;

const StyledContent = styled.div<{isOpened: boolean}>`
  position: absolute;
  right: 0;
  margin-top: 10px;
  transition: max-height 0.6s ease;
  z-index: 10;
  display: ${(props) => (props.isOpened ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  white-space: nowrap;
`;

const StyledImage = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledLogo = styled.img`
  width: 20px;
  height: 20px;
`;
