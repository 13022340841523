import {useWeb3React} from '@web3-react/core';
import {toast} from 'react-toastify';
import {ArrowLeftIcon, CopyIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {getChainInfo} from 'src/constants';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import {IPlatform, ITokenAddress} from 'src/types';
import {getTokenIcon} from 'src/utils/icon-util';
import {simplifyAddress} from 'src/utils/utils';
import styled from 'styled-components';

type ContractItemProps = {
  contract: ITokenAddress;
  symbol?: string;
};

const ContractItem = ({contract, symbol}: ContractItemProps) => {
  const iconUrl = getTokenIcon(symbol);
  const onCopyClipboard = () => {
    navigator.clipboard.writeText(contract.hash);
    toast.success('Contract address copied to the clipboard', {autoClose: 2000, hideProgressBar: true});
  };

  return (
    <ContractItemWrapper>
      <TokenIconContainer>
        <StyledImage src={iconUrl} />
      </TokenIconContainer>
      <BodyParagraph color={COLORS.PRIMARY}>{simplifyAddress(contract.hash, 20)}</BodyParagraph>
      <IconButton onClick={onCopyClipboard}>
        <CopyIcon />
      </IconButton>
    </ContractItemWrapper>
  );
};

type PlatformContractProps = {
  platform: IPlatform;
  onBack?: () => void;
};

export const PlatformContract = ({platform, onBack}: PlatformContractProps) => {
  const {chainId} = useWeb3React();
  const chainInfo = getChainInfo(chainId);

  return (
    <Wrapper>
      <Header>
        <IconButton onClick={onBack}>
          <ArrowLeftIcon />
        </IconButton>
        <BodyVariant color={COLORS.PRIMARY}>View platform contracts</BodyVariant>
        <div />
      </Header>
      <StyledCol marginTop={16}>
        <SectionTitle size={BODY_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          Router Contract
        </SectionTitle>
        {platform?.routerAddresses?.map((item) => (
          <ContractItem key={item.hash} contract={item} symbol={chainInfo.symbol} />
        ))}
      </StyledCol>
      <StyledCol marginTop={12}>
        <SectionTitle size={BODY_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          Factory Contract
        </SectionTitle>
        {platform?.factoryAddresses?.map((item) => (
          <ContractItem key={item.hash} contract={item} symbol={chainInfo.symbol} />
        ))}
      </StyledCol>
      <StyledCol marginTop={12}>
        <SectionTitle size={BODY_FONT_ENUM.SMALL} color={COLORS.PRIMARY}>
          Masterchef Contract
        </SectionTitle>
        {platform?.masterChefAddresses?.map((item) => (
          <ContractItem key={item.hash} contract={item} symbol={chainInfo.symbol} />
        ))}
      </StyledCol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconButton = styled.div`
  cursor: pointer;
`;

const StyledCol = styled.div<{marginTop?: number}>`
  margin-top: ${(props) => props.marginTop ?? 0}px;
`;

const SectionTitle = styled(BodyVariant)`
  text-align: center;
`;

const ContractItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 30px;
  padding: 8px 16px 8px 8px;
  margin-top: 8px;
`;

const TokenIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ebeff3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled.img`
  width: 24px;
  height: 24px;
`;
