import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type ArrowDownIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const ArrowDownIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: ArrowDownIconType) => (
  <svg width={size} height={size} viewBox='0 0 30 30' fill='none' {...props}>
    <path d='M15.1112 7L15.1112 22.5556' stroke={color} strokeWidth='2' strokeLinecap='round' />
    <path d='M20 18.1096L15.1111 23L10.2222 18.1096' stroke={color} strokeWidth='2' strokeLinecap='round' />
  </svg>
);

export default ArrowDownIcon;
