import React from 'react';

import {useWeb3React} from '@web3-react/core';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import YieldScannerBg from 'src/assets/images/banners/yield-scanner.png';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import styled from 'styled-components';

import {paths} from '../../constants';
import {BodyVariant} from '../Typography';

export const YieldScanner = ({...props}) => {
  const {account} = useWeb3React();
  const gotoYieldScanner = () => {
    if (!account) {
      toast('Please connect your wallet');
    }
  };
  return (
    <Wrapper {...props}>
      <LinkContainer to={account && paths.yieldScanner} onClick={gotoYieldScanner}>
        <BodyVariant
          style={{width: '160px'}}
          color={COLORS.WHITE}
          size={BODY_FONT_ENUM.LARGE}
          mobile={BODY_FONT_ENUM.LARGE_MOBILE}
        >
          Yield Scanner
        </BodyVariant>
      </LinkContainer>
    </Wrapper>
  );
};

const LinkContainer = styled(Link)`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  height: 110px;
  padding: 20px;
  box-sizing: border-box;
  background: url(${YieldScannerBg}), ${COLORS.PRIMARY};
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
  border-radius: 12px;
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  cursor: pointer;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    height: auto;
    margin-right: 16px;
    padding: 20px 16px;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }
`;
