import {Button} from 'src/components/Buttons';
import {ArrowRightIcon, ContactIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IPlatform} from 'src/types';
import {getPlatformIcon} from 'src/utils/icon-util';
import styled from 'styled-components';

import {SecurityRatingBar} from './SecurityRatingBar';

type SecurityRatingProps = {
  platform: IPlatform;
  onContracts?: () => void;
  onLearnMore?: () => void;
};

export const SecurityRating = ({platform, onContracts, onLearnMore}: SecurityRatingProps) => {
  const iconUrl = platform?.iconUrl || getPlatformIcon(platform.name);
  const securityRatingValue = platform?.securityRating?.securityRating;

  return (
    <Wrapper>
      <Header>
        <BodyVariant color={COLORS.PRIMARY}>Security Rating</BodyVariant>
        <StyledRow gap={8}>
          <StyledImage src={iconUrl} />
          <BodyParagraph size={PARAGRAPH_FONT_ENUM.SMALL}>{platform.name}</BodyParagraph>
        </StyledRow>
      </Header>
      <SecurityRatingBar value={securityRatingValue} />
      <ButtonWrapper>
        <Button
          LeftIcon={ContactIcon}
          title='Contracts'
          color={COLORS.PRIMARY}
          borderColor={COLORS.PRIMARY}
          backgroundColor={COLORS.WHITE}
          onClick={onContracts}
        />
        <Button RightIcon={ArrowRightIcon} title='Learn more' color={COLORS.PRIMARY} onClick={onLearnMore} />
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledRow = styled.div<{gap?: number}>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;
`;

const StyledImage = styled.img`
  width: 22px;
  height: 22px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
