import {BalanceItem} from 'src/components/BalanceItem';
import {BodyParagraph} from 'src/components/Typography';
import Heading from 'src/components/Typography/Heading';
import {headerPos} from 'src/constants/home';
import {usePortfolioBalance} from 'src/hooks';
import {COLORS, DEVICE_ENUM, HEADING_FONT_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import styled from 'styled-components';

export const Header = () => {
  const {
    totalBalance,
    totalWhitelistBalance,
    totalFarmingBalance,
    totalRewards,
    totalLiquidityBalance,
    totalSingleTokenBalance,
  } = usePortfolioBalance();
  const totalYieldFarming = totalLiquidityBalance + totalSingleTokenBalance + totalFarmingBalance;
  return (
    <StyledWrapper gap={16}>
      <StyledCol gap={5}>
        <Title color={COLORS.PRIMARY} size={HEADING_FONT_ENUM.H3}>
          Welcome to Liquidus
        </Title>
        <SubTitle color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          Your Home for DeFi & Farming
        </SubTitle>
      </StyledCol>
      <BalancePanel>
        <BalanceItem label='Total Tokens' value={totalWhitelistBalance} />
        <StyledSeparator />
        <BalanceItem label='Total Yield Farming' value={totalYieldFarming} />
        <StyledSeparator />
        <BalanceItem label='Total Portfolio' value={totalBalance} />
        <StyledSeparator />
        <BalanceItem label='Accrued Yield' value={totalRewards} customBG pos={headerPos.LAST} posRadius={12} />
      </BalancePanel>
      <MobileBalancePanelContainer>
        <MobileFirstBalancePanel>
          <BalanceItem label='Total Tokens' value={totalWhitelistBalance} />
          <StyledSeparator />
          <BalanceItem label='Total Yield Farming' value={totalYieldFarming} />
        </MobileFirstBalancePanel>
        <MobileSecondBalancePanel>
          <BalanceItem label='Total Portfolio' value={totalBalance} />
          <StyledSeparator />
          <BalanceItem label='Accrued Yield' value={totalRewards} customBG pos={headerPos.LAST} posRadius={12} />
        </MobileSecondBalancePanel>
      </MobileBalancePanelContainer>
    </StyledWrapper>
  );
};

const StyledCol = styled.div<{marginTop?: number; gap?: number; itemsCenter?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;

  ${(props) =>
    props.itemsCenter &&
    `
    align-items: center;
  `}

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    text-align: center;
  }
`;

const StyledSeparator = styled.div`
  width: 1px;
  background-color: ${COLORS.GRAY_BASE_40};
`;

const Title = styled(Heading)`
  font-weight: 700;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    font-size: 25px;
  }
`;

const SubTitle = styled(BodyParagraph)`
  font-size: 16px;
`;

const StyledWrapper = styled.div<{gap?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.gap ?? 0}px;

  @media (max-width: ${DEVICE_ENUM.exlg}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    flex-direction: column;
    align-items: center;
  }
`;

const BalancePanel = styled.div`
  display: flex;
  height: 92px;
  max-wight: 697px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px 0px rgba(17, 36, 85, 0.06);
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    display: none;
  }
`;

const MobileFirstBalancePanel = styled(BalancePanel)`
  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    display: flex;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid ${COLORS.GRAY_BASE_40};
  }
`;

const MobileSecondBalancePanel = styled(BalancePanel)`
  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    display: flex;
    border-radius: 0 0 12px 12px;
  }
`;

const MobileBalancePanelContainer = styled.div`
  display: none;
  flex-direction: column;

  @media (max-width: ${DEVICE_ENUM.MOBILE}) {
    display: flex;
  }
`;
