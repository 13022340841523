import {useMemo} from 'react';

import {Token} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract} from 'ethers';
import KyberSwapABI from 'src/abis/KyberSwapABI.json';
import {ETHER_ADDRESS, getChainInfo} from 'src/constants';
import {Aggregator} from 'src/utils/swap/aggregator';
import {calculateGasMargin} from 'src/utils/swap/kyber-swap';

export interface FeeConfig {
  chargeFeeBy: 'currency_in' | 'currency_out';
  feeReceiver: string;
  isInBps: boolean;
  feeAmount: string;
}

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID,
}

export default function isZero(hexNumberString: string) {
  return /^0x0*$/.test(hexNumberString);
}

export const useSwap = () => {
  const {provider, account, chainId} = useWeb3React();
  const chainInfo = getChainInfo(chainId);

  const getSwapEstimatedGas = async (trade: Aggregator) => {
    const walletSigner = provider.getSigner(account).connectUnchecked();
    if (!chainInfo) {
      throw new Error('Can not get chain info.');
    }
    const kyberSwapLiqAddress = chainInfo.kyberSwapLiqAddress;
    const contract = new Contract(kyberSwapLiqAddress, KyberSwapABI, walletSigner);
    const currencyIn = trade.inputAmount.currency;
    const currencyOut = trade.outputAmount.currency;
    const tokenIn = currencyIn.isNative ? ETHER_ADDRESS : (currencyIn as Token).address;
    const tokenOut = currencyOut.isNative ? ETHER_ADDRESS : (currencyOut as Token).address;
    const amount = trade.inputAmount.quotient.toString();
    const transferAmount = BigNumber.from(amount);
    if (tokenIn !== ETHER_ADDRESS) {
      const gasLimit = await contract.estimateGas.useKyberApiData(
        tokenIn,
        tokenOut,
        transferAmount,
        trade.routerAddress,
        trade.encodedSwapData,
      );
      return gasLimit;
    } else {
      const gasLimit = await contract.estimateGas.useKyberApiData(
        tokenIn,
        tokenOut,
        transferAmount,
        trade.routerAddress,
        trade.encodedSwapData,
        {
          value: transferAmount,
        },
      );
      return gasLimit;
    }
  };

  const swapTokens = async (trade: Aggregator, gasLimit?: number) => {
    const walletSigner = provider.getSigner(account).connectUnchecked();
    if (!chainInfo) {
      return null;
    }
    const kyberSwapLiqAddress = chainInfo.kyberSwapLiqAddress;

    const contract = new Contract(kyberSwapLiqAddress, KyberSwapABI, walletSigner);
    const currencyIn = trade.inputAmount.currency;
    const currencyOut = trade.outputAmount.currency;
    const tokenIn = currencyIn.isNative ? ETHER_ADDRESS : (currencyIn as Token).address;
    const tokenOut = currencyOut.isNative ? ETHER_ADDRESS : (currencyOut as Token).address;
    const amount = trade.inputAmount.quotient.toString();
    const transferAmount = BigNumber.from(amount);

    let _gasLimit;
    if (!gasLimit) {
      _gasLimit = await getSwapEstimatedGas(trade);
    }
    const payload = {gasLimit: gasLimit ?? calculateGasMargin(_gasLimit)};
    if (tokenIn !== ETHER_ADDRESS) {
      const result = await contract.useKyberApiData(
        tokenIn,
        tokenOut,
        transferAmount,
        trade.routerAddress,
        trade.encodedSwapData,
        payload,
      );
      return result;
    } else {
      const result = await contract.useKyberApiData(
        tokenIn,
        tokenOut,
        transferAmount,
        trade.routerAddress,
        trade.encodedSwapData,
        {
          value: transferAmount,
          ...payload,
        },
      );
      return result;
    }
  };

  return {getSwapEstimatedGas, swapTokens};
};

export function useSwapCallback(trade?: Aggregator | null): {
  state: SwapCallbackState;
  estimateGas: null | (() => Promise<BigNumber | null>);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: null | (() => Promise<any>); // wait is not present on unkown
  error: string | null;
} {
  const {account} = useWeb3React();
  const {getSwapEstimatedGas, swapTokens} = useSwap();
  return useMemo(() => {
    if (!trade || !account) {
      return {
        state: SwapCallbackState.INVALID,
        estimateGas: null,
        callback: null,
        error: 'Missing dependencies',
      };
    }

    const estimateGas = async () => {
      const gasLimit = await getSwapEstimatedGas(trade);
      return gasLimit;
    };
    const onSwapWithBackendEncode = async (): Promise<string> => {
      const hash = await swapTokens(trade);
      if (hash === undefined) {
        throw new Error('sendTransaction returned undefined.');
      }
      return hash;
    };

    return {
      state: SwapCallbackState.VALID,
      estimateGas,
      callback: onSwapWithBackendEncode,
      error: null,
    };
  }, [trade, account, getSwapEstimatedGas, swapTokens]);
}
