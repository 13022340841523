import React from 'react';

import {Link} from 'react-router-dom';
import {
  BUTTON_FONT_ENUM,
  ButtonFontScale,
  ButtonFontScaleType,
  COLORS,
  HEADING_FONT_ENUM,
  HeadingFontScale,
  HeadingFontScaleType,
  PARAGRAPH_FONT_ENUM,
  ParagraphFontScale,
  ParagraphFontScaleType,
} from 'src/styles';
import styled from 'styled-components';

import {SIDEBAR_DATA} from '../Sidebar/data';

interface NoHoldingsProps {
  heading: string;
}

const NoHoldings: React.FC<NoHoldingsProps> = ({heading}) => {
  const selectedContent = SIDEBAR_DATA.find((content) => content.name === 'Farm');

  if (!selectedContent) {
    return null;
  }

  return (
    <Container>
      <Heading fontType={HeadingFontScale[HEADING_FONT_ENUM.H4]}>{heading}</Heading>
      <CenterText fontType={ParagraphFontScale[PARAGRAPH_FONT_ENUM.BIG_Paragraph]}>
        You don’t have any farms, liquidity pools, or single token holdings yet.
      </CenterText>
      <Link to={selectedContent.path}>
        <DiscoverButton fontType={ButtonFontScale[BUTTON_FONT_ENUM.MEDIUM_BTN_TXT]}>Discover Farms</DiscoverButton>
      </Link>
    </Container>
  );
};

export default NoHoldings;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
`;

const Heading = styled.h4<{fontType: HeadingFontScaleType}>`
  font-family: ${(props) => props.fontType.fontFamily};
  font-size: ${(props) => props.fontType.fontSize}px;
  line-height: ${(props) => props.fontType.lineHeight}px;
  font-weight: ${(props) => props.fontType.fontWeight};
  color: ${(props) => props.fontType.color};
  margin-bottom: 10px;
`;

const CenterText = styled.p<{fontType: ParagraphFontScaleType}>`
  text-align: center;
  font-family: ${(props) => props.fontType.fontFamily};
  font-size: ${(props) => props.fontType.fontSize}px;
  line-height: ${(props) => props.fontType.lineHeight}px;
  font-weight: ${(props) => props.fontType.fontWeight};
  color: ${COLORS.PRIMARY};
  margin-bottom: 20px;
`;

const DiscoverButton = styled.div<{fontType: ButtonFontScaleType}>`
  background-color: ${COLORS.SECONDARY};
  font-family: ${(props) => props.fontType.fontFamily};
  font-size: ${(props) => props.fontType.fontSize}px;
  line-height: ${(props) => props.fontType.lineHeight}px;
  font-weight: ${(props) => props.fontType.fontWeight};
  color: ${COLORS.PRIMARY};
  text-align: center;
  width: 342px;
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  }
`;
