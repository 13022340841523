import EstimateIcon from 'src/assets/images/icons/estimate-icon.svg';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import styled from 'styled-components';

import {BodyVariant} from './Typography';

export const EstimateBox = ({value, ...props}: {value?: number}) => {
  return (
    <Wrapper {...props}>
      <StyledImage src={EstimateIcon} />
      <StyledCol>
        <BodyVariant color={COLORS.GRAY_LIGHT} size={BODY_FONT_ENUM.SMALL}>
          Estimated yearly return
        </BodyVariant>
        <StyledBodyVariant color={COLORS.PRIMARY} size={BODY_FONT_ENUM.SMALL}>
          ${value?.toFixed(2) || 0}
        </StyledBodyVariant>
      </StyledCol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 8px;
`;

const StyledImage = styled.img`
  width: 50px;
`;

const StyledCol = styled.div`
  margin-left: 12px;
`;

const StyledBodyVariant = styled(BodyVariant)`
  font-size: 18px;
  font-weight: 500;
`;
