import {COLORS} from 'src/styles';
import {NetworkType} from 'src/types';
import {getTokenIcon} from 'src/utils/icon-util';
import styled from 'styled-components';

import {ChevronDownIcon} from './Svgs';
import {BodyVariant} from './Typography';

type Size = 'default' | 'small';

interface NetworkSelectItemProps {
  network?: NetworkType;
  disabled?: boolean;
  size?: Size;
  onSelect?: () => void;
}

export const NetworkSelectItem = ({network, disabled, size = 'default', onSelect}: NetworkSelectItemProps) => {
  const tokenUrl = getTokenIcon(network?.symbol);
  return (
    <Wrapper disabled={disabled} onClick={onSelect}>
      {network ? (
        <>
          <StyledImage src={tokenUrl} size={size} />
          <BodyVariant style={{fontSize: size === 'default' ? 16 : 12}} color={COLORS.PRIMARY}>
            {network?.displayName}
          </BodyVariant>
        </>
      ) : (
        <StyledBodyVariant style={{fontSize: size === 'default' ? 16 : 14}} color={COLORS.PRIMARY}>
          Select
        </StyledBodyVariant>
      )}
      {!disabled && <ChevronDownIcon />}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledImage = styled.img<{size?: Size}>`
  width: ${(props) => (props.size === 'default' ? 30 : 20)}px;
  height: ${(props) => (props.size === 'default' ? 30 : 20)}px;
  margin-right: 8px;
`;

const StyledBodyVariant = styled(BodyVariant)`
  white-space: nowrap;
`;
