import {PlatformSettingsMode} from 'src/types';

import {ModalsState} from './types';

type ActionType<S, T> = {
  type: S;
  payload?: T;
};

export const modalsInitialState: ModalsState = {
  userSignature: {
    isOpen: false,
    name: undefined,
    documents: undefined,
    countries: undefined,
    additionalConditions: undefined,
    openNextModal: undefined,
  },
  contactBookModal: {
    isOpen: false,
    newWalletAddress: '',
  },
  platformSettingsModal: {
    isOpen: false,
    type: PlatformSettingsMode.PLATFORM_SETTINGS_FARM,
    section: undefined,
  },
  renameAccountModal: {
    isOpen: false,
  },
  swapSettingsModal: {
    isOpen: false,
  },
  zapSettingsModal: {
    isOpen: false,
  },
  tokenReceiveModal: {
    isOpen: false,
  },
  tokenSortingModal: {
    isOpen: false,
  },
  importPoolModal: {
    isOpen: false,
  },
  walletConnectModal: {
    isOpen: false,
    next: undefined,
  },
  yieldInfoModal: {
    isOpen: false,
    farm: {},
    lp: undefined,
    isPool: false,
  },
  switchNetworkModal: {
    isOpen: false,
  },
  addCustomTokenModal: {
    isOpen: false,
    tokenAddress: '',
  },
};

type UpdateUserSignature = ActionType<'updateUserSignature', ModalsState>;
type UpdateContactModal = ActionType<'updateContactBookModal', ModalsState>;
type UpdateWalletConnectModal = ActionType<'updateWalletConnectModal', ModalsState>;
type UpdatetPlatformSettingsModal = ActionType<'updatePlatformSettingsModal', ModalsState>;
type UpdatetRenameAccountModal = ActionType<'updateRenameAccountModal', ModalsState>;
type UpdatetSwapSettingsModal = ActionType<'updateSwapSettingsModal', ModalsState>;
type UpdatetZapSettingsModal = ActionType<'updateZapSettingsModal', ModalsState>;
type UpdatetTokenReceiveModal = ActionType<'updateTokenReceiveModal', ModalsState>;
type UpdatetTokenSortingModal = ActionType<'updateTokenSortingModal', ModalsState>;
type UpdatetImportPoolModal = ActionType<'updateImportPoolModal', ModalsState>;
type YieldInfoModal = ActionType<'updateYieldInfoModal', ModalsState>;
type ResetModals = ActionType<'resetModals', ModalsState>;
type UpdateSwitchNetworkModal = ActionType<'updateSwitchNetworkModal', ModalsState>;
type UpdateAddCustomTokenModal = ActionType<'updateAddCustomTokenModal', ModalsState>;

type Actions =
  | UpdateUserSignature
  | UpdateContactModal
  | UpdateWalletConnectModal
  | UpdatetPlatformSettingsModal
  | UpdatetRenameAccountModal
  | UpdatetSwapSettingsModal
  | UpdatetZapSettingsModal
  | UpdatetTokenReceiveModal
  | UpdatetTokenSortingModal
  | UpdatetImportPoolModal
  | ResetModals
  | YieldInfoModal
  | UpdateSwitchNetworkModal
  | UpdateAddCustomTokenModal;

export function modalsReducer(state: ModalsState, action: Actions) {
  switch (action.type) {
    case 'updateUserSignature': {
      return {
        ...modalsInitialState,
        userSignature: {
          ...state.walletConnectModal,
          ...action.payload,
        },
      };
    }
    case 'updateContactBookModal': {
      return {
        ...modalsInitialState,
        contactBookModal: {
          ...state.walletConnectModal,
          ...action.payload,
        },
      };
    }
    case 'updateWalletConnectModal': {
      return {
        ...modalsInitialState,
        walletConnectModal: {
          ...state.walletConnectModal,
          ...action.payload,
        },
      };
    }
    case 'updatePlatformSettingsModal': {
      return {
        ...modalsInitialState,
        platformSettingsModal: {
          ...state.platformSettingsModal,
          ...action.payload,
        },
      };
    }
    case 'updateRenameAccountModal': {
      return {
        ...modalsInitialState,
        renameAccountModal: {
          ...state.platformSettingsModal,
          ...action.payload,
        },
      };
    }
    case 'updateSwapSettingsModal': {
      return {
        ...modalsInitialState,
        swapSettingsModal: {
          ...state.swapSettingsModal,
          ...action.payload,
        },
      };
    }
    case 'updateZapSettingsModal': {
      return {
        ...modalsInitialState,
        zapSettingsModal: {
          ...state.zapSettingsModal,
          ...action.payload,
        },
      };
    }
    case 'updateTokenReceiveModal': {
      return {
        ...modalsInitialState,
        tokenReceiveModal: {
          ...state.tokenReceiveModal,
          ...action.payload,
        },
      };
    }
    case 'updateImportPoolModal': {
      return {
        ...modalsInitialState,
        importPoolModal: {
          ...state.importPoolModal,
          ...action.payload,
        },
      };
    }
    case 'updateTokenSortingModal': {
      return {
        ...modalsInitialState,
        tokenSortingModal: {
          ...state.tokenSortingModal,
          ...action.payload,
        },
      };
    }
    case 'updateYieldInfoModal': {
      return {
        ...modalsInitialState,
        yieldInfoModal: {
          ...state.yieldInfoModal,
          ...action.payload,
        },
      };
    }
    case 'updateSwitchNetworkModal': {
      return {
        ...modalsInitialState,
        switchNetworkModal: {
          ...state.switchNetworkModal,
          ...action.payload,
        },
      };
    }
    case 'resetModals': {
      return {
        ...modalsInitialState,
      };
    }
    case 'updateAddCustomTokenModal': {
      return {
        ...state,
        addCustomTokenModal: {
          ...state.addCustomTokenModal,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
