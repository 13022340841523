import {useCallback, useContext, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import {debounce} from 'lodash';
import {toast} from 'react-toastify';
import WarningIcon from 'src/assets/images/warning.png';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {ConnectWalletButton} from 'src/components/ConnectWalletButton';
import {SwapModal} from 'src/components/Modals/Swap';
import {ArrowDownIcon} from 'src/components/Svgs';
import {SwapInfoItem} from 'src/components/SwapInfoItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {NEW_LIQ_GLOBAL_NAME, aggregatorReturnValue, getChainInfo} from 'src/constants';
import {AppContext} from 'src/contexts/AppContext';
import {useLiqHoldings} from 'src/contexts/LiqHoldingsContext';
import {useToken} from 'src/hooks';
import {ApprovalState, WrapType, useApproveCallbackFromTrade, useSwapInfo, useWrapCallback} from 'src/hooks/kyber-swap';
import {useAppDispatch, useAppSelector} from 'src/state/hooks';
import {swapSlice} from 'src/state/swap/reducer';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {Field, IWhitelistToken} from 'src/types';
import {computeSlippageAdjustedAmounts} from 'src/utils/swap/kyber-swap';
import {calculatePrice, formatBigNumber, parseBigNumber} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import {handleInputValue} from 'src/utils/utils';
import styled from 'styled-components';

export const Swap = ({...props}) => {
  const {swapBool, articleToken} = props;
  const {refreshTokens} = useContext(AppContext);
  const {chainId, account} = useWeb3React();
  const {feePercent} = useLiqHoldings();
  const chainInfo = getChainInfo(chainId);
  const appDispatch = useAppDispatch();
  const swapState = useAppSelector((state) => state.swap);
  const {check} = useAgreementCheck();
  const {swapSettings} = useAppSelector((state) => state.user);
  const {getTokenByGlobalName} = useToken();
  const inputToken = getTokenByGlobalName(swapState.INPUT.tokenGlobalName);
  const outputToken = getTokenByGlobalName(swapState.OUTPUT.tokenGlobalName);
  const initialOutputToken = getTokenByGlobalName(swapState.ARTICLEINITIAL_INPUT.tokenGlobalName);

  const slippageAmount = (swapSettings?.slippage?.value || 0.1) * 100;
  const [processing, setProcessing] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [initialToken, setInitialToken] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [inputMode, setInputMode] = useState<Field>(Field.INPUT);
  const [typedValue, setTypedValue] = useState('');
  const [tradeInputValue, setTradeInputValue] = useState<BigNumber>(BigNumber.from(0));
  const [usdMode, setUsdMode] = useState(false);
  const {convertedTypeValue, inputCurrency, outputCurrency, v2Trade, onUpdateCallback, aggregatorResponse} =
    useSwapInfo(false, tradeInputValue, inputToken, outputToken, slippageAmount);
  const {wrapType, wrapCallback} = useWrapCallback(
    inputCurrency,
    outputCurrency,
    usdMode ? convertedTypeValue : tradeInputValue,
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;
  const slippageAdjustedAmounts = trade && computeSlippageAdjustedAmounts(trade, slippageAmount, feePercent);
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, slippageAmount, feePercent);
  const outputAmount = trade?.outputAmount.subtract(trade?.outputAmount.multiply(feePercent * 100).divide(10000));
  const usdUnitPrice = calculatePrice(inputToken?.priceUSD, inputToken?.priceDecimals);

  const formattedFromField = showWrap
    ? typedValue
    : inputMode === Field.INPUT
    ? typedValue
    : trade
    ? trade.inputAmount.toSignificant(6)
    : '';
  const formattedToField = showWrap
    ? typedValue
    : inputMode === Field.OUTPUT
    ? typedValue
    : trade
    ? outputAmount?.toSignificant(6)
    : '';
  const usdInputPrice = useMemo(() => {
    const _tokenPrice = formatBigNumber(tradeInputValue, inputToken?.decimals);
    const usdUnitPrice = calculatePrice(inputToken?.priceUSD, inputToken?.priceDecimals);
    const usdPrice = usdUnitPrice * _tokenPrice;
    return usdPrice;
  }, [inputToken, tradeInputValue]);
  const price = trade?.executionPrice;
  const priceImpact = trade?.priceImpact;
  const priceImpactInNumber = parseFloat(priceImpact?.toFixed(2) || '0');
  const swapDisabled = errorMessage !== '' || !trade || priceImpactInNumber > 10;
  const wrapDisabled = errorMessage !== '' || Number(typedValue) === 0 || isNaN(Number(typedValue));
  const disabledMode = usdUnitPrice <= 0;

  useEffect(() => {
    if (chainInfo) {
      appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: chainInfo.name}));
      appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: NEW_LIQ_GLOBAL_NAME})); // TODO: Maybe pre-select classic LIQ if new LIQ is not available currently selected chain
      swapBool &&
        appDispatch(swapSlice.actions.selectToken({field: Field.ARTICLEINITIAL_INPUT, tokenGlobalName: articleToken}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDispatch, chainInfo]);

  useEffect(() => {
    const _coinAmount = tradeInputValue && tradeInputValue;
    if (inputToken?.balance !== undefined && _coinAmount.gt(inputToken?.balance)) {
      setErrorMessage('Insufficient Balance');
    } else {
      setErrorMessage('');
    }
  }, [inputToken, tradeInputValue, convertedTypeValue, usdMode, inputMode, slippageAmount]);

  const handleSwitchTokens = useCallback(() => {
    appDispatch(swapSlice.actions.switchTokens());
  }, [appDispatch]);

  const handleInputTokenSelect = useCallback(
    (token: IWhitelistToken) => {
      appDispatch(swapSlice.actions.selectToken({field: Field.INPUT, tokenGlobalName: token.globalName}));
    },
    [appDispatch],
  );

  const handleOutputTokenSelect = useCallback(
    (token: IWhitelistToken) => {
      setInitialToken(false);
      appDispatch(swapSlice.actions.selectToken({field: Field.OUTPUT, tokenGlobalName: token.globalName}));
    },
    [appDispatch],
  );

  const onWrap = async () => {
    try {
      if (wrapCallback) {
        setProcessing(true);
        const tx = await wrapCallback();
        console.log(tx);
        toast.success('Transaction successful.');
        refreshTokens(true);
      }
    } catch (e) {
      toast.error('Transaction failed. Please try again later.');
      console.log(e);
    }
    setProcessing(false);
  };

  const onSwap = useCallback(async () => {
    if (inputToken) {
      try {
        setProcessing(true);
        if (approval !== ApprovalState.APPROVED) {
          try {
            await approveCallback();
          } catch (e) {
            toast.error('Failed to approve transaction.');
            setProcessing(false);
            return;
          }
        }
        check(() => setShowSwapModal(true));
      } catch (e) {
        console.log(e);
        toast.error('Cannot estimate gas for swap transaction.');
      }
      setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approval, approveCallback, inputToken]);

  const reportDebouncedChance = debounce((value) => setTradeInputValue(value), 500);

  const inputValue = useCallback(
    (value: string) => {
      const removedComma = handleInputValue(value);
      setInputMode(Field.INPUT);
      setTypedValue(removedComma);
      const checkRemovedComma = usdMode
        ? (Number(removedComma) / usdUnitPrice).toFixed(inputToken?.decimals)
        : removedComma;
      reportDebouncedChance(parseBigNumber(checkRemovedComma, inputToken?.decimals));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputToken, usdMode],
  );

  const handleApplyMaxBalance = () => {
    const _tokenBalance = formatBigNumber(inputToken.balance, inputToken.decimals);
    const _tokenUsdBalance = _tokenBalance * usdUnitPrice;
    const outputValue = usdMode ? _tokenUsdBalance.toFixed(2) : _tokenBalance.toFixed(inputToken.interfaceDecimals);
    setTypedValue(outputValue);
    setTradeInputValue(inputToken.balance);
  };

  useEffect(() => {
    if (disabledMode) setUsdMode(false);
    else inputValue(typedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputToken, outputToken]);

  useEffect(() => {
    if (disabledMode) inputValue(typedValue);
    else {
      const formattedInputValue = formatBigNumber(tradeInputValue, inputToken?.decimals);
      if (typedValue !== '')
        setTypedValue(
          usdMode ? usdInputPrice?.toFixed(2) : formattedInputValue?.toFixed(inputToken?.interfaceDecimals),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usdMode]);

  useEffect(() => {
    if (!showWrap)
      if (tradeInputValue.gt(BigNumber.from(0))) onUpdateCallback(false, 0);
      else onUpdateCallback(true, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeInputValue]);

  useEffect(() => {
    if (aggregatorResponse === aggregatorReturnValue.FAILED_REQUEST) {
      toast.error('Input value is out of bounds, please adjust to a value with a price impact lower than 10%');
      inputValue('');
    }
  }, [aggregatorResponse, inputValue]);

  const Btn = () => {
    return (
      <>
        {account ? (
          showWrap ? (
            <StyledButton
              disabled={processing || wrapDisabled}
              color={COLORS.PRIMARY}
              size={BUTTON_SIZE_ENUM.DEFAULT}
              title={wrapType === WrapType.WRAP ? 'Wrap' : 'UnWrap'}
              onClick={onWrap}
            />
          ) : (
            <StyledButton
              disabled={swapDisabled || processing || approval == ApprovalState.PENDING}
              color={COLORS.PRIMARY}
              size={BUTTON_SIZE_ENUM.DEFAULT}
              title={approval === ApprovalState.NOT_APPROVED ? 'Approve & Swap' : 'Swap'}
              isLoading={processing || approval == ApprovalState.PENDING}
              onClick={onSwap}
            />
          )
        ) : (
          <ConnectWalletButton />
        )}
      </>
    );
  };

  const Title = () => {
    return (
      <>
        <StyledHeaderContainer marginBottom={10}>
          <Header color={COLORS.PRIMARY} size={BODY_FONT_ENUM.BUTTON} mobile={BODY_FONT_ENUM.LARGE_MOBILE}>
            Quick Swap
          </Header>
        </StyledHeaderContainer>
        <StyledSeparator />
      </>
    );
  };

  const PriceImpactContent = () => {
    return (
      <StyledSwapInfoWrapper>
        <StyledRowContainer marginTop={10}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            Price
          </BodyParagraph>
          <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            1 {price?.baseCurrency.symbol} = {price?.toSignificant(6)}{' '}
          </BodyParagraph>
        </StyledRowContainer>
        <StyledRowContainer marginTop={10}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            {inputMode === Field.INPUT ? 'Minimum Received' : 'Minimum Sold'}
          </BodyParagraph>
          <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {inputMode === Field.INPUT
              ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)
              : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)}{' '}
            {inputMode === Field.INPUT ? outputCurrency?.symbol : inputCurrency?.symbol}
          </BodyParagraph>
        </StyledRowContainer>
        <StyledRowContainer marginTop={10}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            Price impact
          </BodyParagraph>
          <BodyParagraph
            color={
              priceImpactInNumber > 1.0 && priceImpactInNumber <= 3.0
                ? COLORS.ORANGE
                : priceImpactInNumber > 3.0
                ? COLORS.WARNING
                : COLORS.SECONDARY
            }
            size={PARAGRAPH_FONT_ENUM.SMALL}
          >
            {priceImpact ? (priceImpact < 0.01 ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
          </BodyParagraph>
        </StyledRowContainer>
        {priceImpactInNumber > 10 && (
          <StyledWarningBox1>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>
              Price impact too high for this swap. Try swapping a smaller amount.
            </BodyParagraph>
          </StyledWarningBox1>
        )}
      </StyledSwapInfoWrapper>
    );
  };

  return (
    <Wrapper {...props}>
      <HeaderOuterWrapper>
        <Title />
      </HeaderOuterWrapper>
      <Container>
        <InputWrapper>
          <InnerBlock>
            <Title />
          </InnerBlock>
          <SwapInfoItem
            name='From'
            selectedToken={inputToken}
            inputValue={usdMode && inputMode === Field.OUTPUT ? usdInputPrice.toFixed(2) : formattedFromField}
            usdMode={usdMode}
            size='small'
            onChangeInputValue={inputValue}
            onTokenSelect={handleInputTokenSelect}
            onMaxBalance={handleApplyMaxBalance}
            homeSwap
          />
          {account && errorMessage !== '' && (
            <StyledWarningBox>
              <StyledWarningIcon src={WarningIcon} />
              <BodyParagraph color={COLORS.WARNING}>{errorMessage}</BodyParagraph>
            </StyledWarningBox>
          )}
        </InputWrapper>
        <StyledSwitchButton onClick={handleSwitchTokens}>
          <ArrowDownIcon />
        </StyledSwitchButton>
        <InputWrapper>
          <SwapInfoItem
            name='To'
            selectedToken={swapBool && initialToken ? initialOutputToken : outputToken}
            inputValue={formattedToField}
            disabled={true}
            size='small'
            onTokenSelect={handleOutputTokenSelect}
            homeSwap
          />
          {trade && slippageAdjustedAmounts && (
            <InnerBlock>
              <PriceImpactContent />
            </InnerBlock>
          )}
          <ButtonInnerWrapper>
            <Btn />
          </ButtonInnerWrapper>
        </InputWrapper>
        {trade && slippageAdjustedAmounts && (
          <SwapModal
            isOpen={showSwapModal}
            onDismiss={() => setShowSwapModal(false)}
            inputToken={inputToken}
            outputToken={outputToken}
            trade={trade}
            feePercent={feePercent}
            slippageAmount={slippageAmount}
            slippageAdjustedAmount={slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)}
          />
        )}
      </Container>
      {trade && slippageAdjustedAmounts && (
        <PriceImpactContentOuter>
          <PriceImpactContent />
        </PriceImpactContentOuter>
      )}
      <ButtonOuterWrapper>
        <Btn />
      </ButtonOuterWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    flex-direction: column;
    background-color: ${COLORS.WHITE};
    border-radius: 12px;
    padding: 18px 0;
    align-items: center;
  }

  @media (max-width: 576px) {
    flex-direction: row;
    background-color: #f9f9f9;
    border-radius: 0;
    padding: 0;
  }
`;

const Header = styled(BodyVariant)`
  font-weight: 500;
`;

const InnerBlock = styled.div`
  display: block;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    display: none;
  }

  @media (max-width: 576px) {
    display: block;
  }
`;

const OuterBlock = styled.div`
  display: none;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    display: block;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

const HeaderOuterWrapper = styled(OuterBlock)`
  padding: 0 16px;
  width: 100%;
`;

const StyledSeparator = styled.div`
  height: 1px;
  background-color: ${COLORS.GRAY_BASE_40};
  margin: 18px 16px 14px;
`;

const Container = styled.div`
  width: 100%;
  border-radius: 12px;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.xl}) {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;

const InputWrapper = styled(Container)`
  background: ${COLORS.WHITE};
  padding: 18px 0;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    flex-direction: column;
  }
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledHeaderContainer = styled(StyledRowContainer)`
  padding-left: 18px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const ButtonInnerWrapper = styled(InnerBlock)`
  margin: 18px 16px 0;
`;

const ButtonOuterWrapper = styled(OuterBlock)`
  padding: 0 16px;
  width: 100%;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningBox1 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledSwitchButton = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: -10px 0;

  @media (max-width: ${DEVICE_ENUM.xl}) {
    margin: 50px 0 0;
    align-items: center;
    rotate: 270deg;
  }

  @media (max-width: 576px) {
    margin: -10px 0;
    justify-content: center;
    rotate: 0deg;
  }
`;

const PriceImpactContentOuter = styled(OuterBlock)`
  width: 100%;
`;

const StyledSwapInfoWrapper = styled.div`
  margin: 20px 16px;
`;
