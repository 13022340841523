import React from 'react';

import {ErrorContent} from 'src/pages/Error';
export class ErrorBoundary extends React.Component {
  constructor(props: unknown) {
    super(props);
    this.state = {didCatch: false, error: null, redirect: false};
  }

  static getDerivedStateFromError(error: Error) {
    return {didCatch: true, error};
  }

  componentDidCatch(error: unknown) {
    this.setState({
      didCatch: true,
      error: error,
    });
    console.log({error});
  }

  render() {
    //@ts-expect-error: property is present
    if (this.state.didCatch === true) {
      return <ErrorContent />;
    }
    //@ts-expect-error: property is present
    return this.props.children;
  }
}
