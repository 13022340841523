import {useMemo} from 'react';

import {ChainId, Currency, WETH} from '@kyberswap/ks-sdk-core';
import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract} from 'ethers';
import WETH_ABI from 'src/abis/weth.json';
import {calculateGasMargin, tryParseAmount} from 'src/utils/swap/kyber-swap';

import {WrapType} from './constant';
import {useGasFee} from '../useGasFee';

const useWBNBContract = () => {
  const {account, chainId, provider} = useWeb3React();
  const address = WETH[chainId as ChainId]?.address;
  if (address) {
    const walletSigner = provider?.getSigner(account).connectUnchecked();
    const contract = new Contract(address, WETH_ABI, walletSigner);
    return contract;
  }
};

export const useWrapCallback = (inputCurrency?: Currency, outputCurrency?: Currency, typedValue?: BigNumber) => {
  const {chainId} = useWeb3React();
  const wbnbContract = useWBNBContract();
  const {getGasFee} = useGasFee();

  const inputAmount = useMemo(() => tryParseAmount(typedValue, inputCurrency, false), [inputCurrency, typedValue]);

  return useMemo(() => {
    if (!wbnbContract || !inputCurrency || !outputCurrency) {
      return {
        wrapType: WrapType.NOT_APPLICABLE,
      };
    }
    if (inputCurrency.isNative && WETH[chainId as ChainId].equals(outputCurrency)) {
      return {
        wrapType: WrapType.WRAP,
        wrapCallback: async () => {
          const extraFee = await getGasFee();
          const amount = {value: `0x${inputAmount?.quotient.toString(16)}`};
          const estimatedGas = await wbnbContract.estimateGas.deposit(amount);
          const gasLimit = calculateGasMargin(estimatedGas);
          const payload = {gasLimit, ...amount, ...extraFee};
          const tx = await wbnbContract.deposit(payload);
          return await tx.wait();
        },
      };
    } else if (WETH[chainId as ChainId].equals(inputCurrency) && outputCurrency.isNative) {
      return {
        wrapType: WrapType.UNWRAP,
        wrapCallback: async () => {
          const extraFee = await getGasFee();
          const amount = `0x${inputAmount?.quotient.toString(16)}`;
          const estimatedGas = await wbnbContract.estimateGas.withdraw(amount);
          const gasLimit = calculateGasMargin(estimatedGas);
          const payload = {gasLimit, ...extraFee};
          const tx = await wbnbContract.withdraw(amount, payload);
          return await tx.wait();
        },
      };
    } else {
      return {
        wrapType: WrapType.NOT_APPLICABLE,
      };
    }
  }, [wbnbContract, inputCurrency, outputCurrency, chainId, getGasFee, inputAmount]);
};
