import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {LiquidityInputItem} from 'src/components/Liquidity/LiquidityInputItem';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type RemoveLiquiditySuccessfulProps = {
  token0: IWhitelistToken;
  token1: IWhitelistToken;
  liquidity: number;
  token0Amount: number;
  token1Amount: number;
  totalInputValue: number;
  loading?: boolean;
  disabled?: boolean;
  errorMsg?: string;
  onConfirm?: () => void;
  onFail?: () => void;
};

export const RemoveLiquiditySuccessful = ({
  token0,
  token1,
  liquidity,
  token0Amount,
  token1Amount,
  totalInputValue,
  loading,
  disabled,
  errorMsg,
  onFail,
  onConfirm,
}: RemoveLiquiditySuccessfulProps) => {
  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
          You received
        </BodyParagraph>
        <LiquidityInputItem
          token0={token0}
          token1={token1}
          token0BigNumberFormatted={token0Amount}
          token1BigNumberFormatted={token1Amount}
        />{' '}
        <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
          = ${totalInputValue.toFixed(2)}
        </BodyParagraph>
        <StyledRowContainer marginTop={40}>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
            {token0?.symbol} / {token1?.symbol} LP Tokens Burned
          </BodyParagraph>
          <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
            {liquidity.toFixed(7)}
          </BodyParagraph>
        </StyledRowContainer>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title='Done'
          onClick={errorMsg ? onFail : onConfirm}
        />
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const TokenInfoBox = styled.div`
  padding: 16px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;
