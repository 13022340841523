import React, {ChangeEvent, useMemo, useState} from 'react';

import {Button} from 'src/components/Buttons';
import {SearchIcon} from 'src/components/Svgs';
import {TokenItem} from 'src/components/WhitelistTokens/TokenItem';
import {useToken} from 'src/hooks';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type TokenSelectViewProps = {
  onConfirm?: (token: IWhitelistToken) => void;
  onDismiss?: () => void;
};

export const TokenSelectView = ({onConfirm, onDismiss}: TokenSelectViewProps) => {
  const {sortedTokens} = useToken();
  const [value, setValue] = useState('');

  const filteredTokens = useMemo(() => {
    return sortedTokens.filter(
      (token) =>
        token.symbol.toLowerCase().includes(value.toLowerCase()) ||
        token.name.toLowerCase().includes(value.toLowerCase()),
    );
  }, [sortedTokens, value]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <>
      <SearchView>
        <StyledSearchIcon />
        <Input value={value} onChange={onChange} placeholder='Search token' />
      </SearchView>
      <Body>
        {filteredTokens?.map((token) => (
          <TokenItem key={token.globalName} token={token} onSelect={onConfirm} />
        ))}
      </Body>
      <ButtonWrapper>
        <Button color={COLORS.PRIMARY} title='Done' onClick={onDismiss} />
      </ButtonWrapper>
    </>
  );
};

const SearchView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 12px 24px;
  height: 32px;
  border: 1px solid ${COLORS.GRAY_BORDER};
  border-radius: 23px;
  padding: 0 8px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = styled.input`
  flex: 1;
  margin-left: 8px;
  color: ${COLORS.BLACK_TEXT};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
`;

const Body = styled.div`
  padding: 0 16px 16px;
  flex: 1;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;
