import {JSBI} from '@kyberswap/ks-sdk-classic';
import {ChainId, Currency, CurrencyAmount, Fraction, Percent, Token, WETH} from '@kyberswap/ks-sdk-core';
import {BigNumber, ethers} from 'ethers';
import {DEFAULT_GAS_LIMIT_MARGIN} from 'src/constants/gas';

import {Aggregator} from './aggregator';

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const BIPS_BASE = JSBI.BigInt(10000);
export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE);
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

// try to parse a user entered amount for a given token
export const tryParseAmount = <T extends Currency>(
  value?: string | BigNumber,
  currency?: T,
  shouldParse = true,
): CurrencyAmount<T> | undefined => {
  if (!value || !currency) {
    return undefined;
  }
  try {
    const typedValueParsed = shouldParse
      ? ethers.utils.parseUnits(value as string, currency.decimals).toString()
      : value;
    if (typedValueParsed !== '0') {
      return CurrencyAmount.fromRawAmount(currency, JSBI.BigInt(typedValueParsed));
    }
  } catch (e) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, e);
  }
  // necessary for all paths to return a value
  return undefined;
};

export const wrappedCurrency = (currency: Currency | undefined, chainId: ChainId | undefined): Token | undefined => {
  return chainId && currency === WETH[chainId] ? WETH[chainId] : currency instanceof Token ? currency : undefined;
};

export const unwrappedToken = (token: Token): Currency => {
  if (token.equals(WETH[token.chainId])) {
    return WETH[token.chainId];
  }
  return token;
};

export const basisPointsToPercent = (num: number): Percent => {
  return new Percent(JSBI.BigInt(num), BIPS_BASE);
};

export const computeSlippageAdjustedAmounts = (
  trade: Aggregator | undefined,
  allowedSlippage: number,
  feePercent: number,
): {[field in Field]?: CurrencyAmount<Currency>} => {
  const pct = basisPointsToPercent(allowedSlippage);
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct, feePercent),
  };
};

export function calculateGasMargin(value: BigNumber): BigNumber {
  const defaultGasLimitMargin = BigNumber.from(DEFAULT_GAS_LIMIT_MARGIN);
  const gasMargin = value.mul(BigNumber.from(2000)).div(BigNumber.from(10000));

  return gasMargin.gte(defaultGasLimitMargin) ? value.add(gasMargin) : value.add(defaultGasLimitMargin);
}

export function formatCurrencyAmount(amount: CurrencyAmount<Currency> | undefined, sigFigs: number) {
  if (!amount) {
    return '-';
  }

  if (JSBI.equal(amount.quotient, JSBI.BigInt(0))) {
    return '0';
  }

  if (amount.divide(amount.decimalScale).lessThan(new Fraction(1, 100000))) {
    return '<0.00001';
  }

  return amount.toSignificant(sigFigs);
}
