import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber} from 'ethers';
import WarningIcon from 'src/assets/images/warning.png';
import {BUTTON_SIZE_ENUM, Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {TokenSelectModal} from 'src/components/Modals';
import {WithdrawModal, WithdrawModalProps} from 'src/components/Modals/Withdraw';
import {ArrowDownIcon, CloseIcon} from 'src/components/Svgs';
import {TokenSelectItem} from 'src/components/Tokens/TokenSelectItem';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {useToken, useWithdraw} from 'src/hooks';
import {COLORS, DEVICE_ENUM, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {
  formatBigNumber,
  getLpTokenAmountFromUsdInBigNumber,
  getTokenAmountFromUSD,
  parseBNumber,
} from 'src/utils/token-util';
import {useAgreementCheck} from 'src/utils/transaction-manager-utils';
import styled from 'styled-components';

import {ContentProps} from '..';

export const Withdraw = ({token0Detail, token1Detail, yourWorth, isPool, liquidityPool, farmItem}: ContentProps) => {
  const {chainId} = useWeb3React();
  const {check} = useAgreementCheck();
  const {getTokenByAddress, getWrappedNativeToken} = useToken();
  const [receiveTokenAddress, setReceiveTokenAddress] = useState('');
  const [showReceiveTokenSelectModal, setShowReceiveTokenSelectModal] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const [keepTokensMode, setKeepTokensMode] = useState(false);
  const [usdAmount, setUsdAmount] = useState<string>('');
  const [showWarning, setShowWarning] = useState(false);
  const [priceImpact, setPriceImpact] = useState<number>(undefined);
  const [withdrawModalProps, setWithdrawModalProps] = useState<WithdrawModalProps>();
  const selectedToken1 = useMemo(() => {
    const token = getTokenByAddress(keepTokensMode ? token0Detail?.address : receiveTokenAddress, false);
    if (token.isNative) {
      return getWrappedNativeToken(chainId);
    }
    return token;
  }, [chainId, getTokenByAddress, getWrappedNativeToken, keepTokensMode, receiveTokenAddress, token0Detail?.address]);
  const farmBalance = isPool ? liquidityPool.balance : farmItem?.balance;
  const lpTokens = useMemo(() => {
    if (isMax) {
      return farmBalance;
    } else if (liquidityPool?.lpTokenPriceUSD && usdAmount) {
      return getLpTokenAmountFromUsdInBigNumber(Number(usdAmount), liquidityPool);
    } else {
      return BigNumber.from(0);
    }
  }, [farmBalance, isMax, liquidityPool, usdAmount]);
  const token0Balance = useMemo(() => {
    if (liquidityPool?.totalSupply) {
      const totalSupply = parseBNumber(liquidityPool?.totalSupply, liquidityPool?.decimals) || 1;
      const withdrawRatioPool = formatBigNumber(lpTokens, liquidityPool.decimals) / totalSupply;
      const _token0Balance = withdrawRatioPool * parseBNumber(liquidityPool?.reserve0, token0Detail?.decimals);

      return _token0Balance;
    }
  }, [lpTokens, liquidityPool, token0Detail]);
  const token1Balance = useMemo(() => {
    if (liquidityPool?.totalSupply) {
      const totalSupply = parseBNumber(liquidityPool?.totalSupply, liquidityPool?.decimals) || 1;
      const withdrawRatioPool = formatBigNumber(lpTokens, liquidityPool.decimals) / totalSupply;
      const _token0Balance = withdrawRatioPool * parseBNumber(liquidityPool?.reserve1, token1Detail?.decimals);

      return _token0Balance;
    }
  }, [lpTokens, liquidityPool, token1Detail]);

  const withdrawValueInSelectedToken1 = useMemo(() => {
    if (usdAmount) {
      return getTokenAmountFromUSD(Number(usdAmount), selectedToken1?.priceUSD, selectedToken1?.priceDecimals);
    }
  }, [selectedToken1, usdAmount]);

  const {swapInfo} = useWithdraw(
    token0Detail,
    token1Detail,
    token0Balance,
    token1Balance,
    liquidityPool,
    farmItem,
    lpTokens,
    keepTokensMode ? undefined : selectedToken1,
  );

  const getSwapInfo = useCallback(async () => {
    if (Number(usdAmount) <= 0) {
      setPriceImpact(undefined);
      return;
    }
    try {
      const result = await swapInfo();
      const swapsInfo = result.swapsInfo.reduce((prev, current) => {
        return prev && prev.priceImpact > current.priceImpact ? prev : current;
      });
      setPriceImpact(swapsInfo.priceImpact);
    } catch (e) {
      console.error(e);
    }
  }, [swapInfo, usdAmount]);

  const disabled =
    !usdAmount || Number(usdAmount) <= 0 || showWarning || (!keepTokensMode && (!priceImpact || priceImpact >= 10));

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsMax(false);
    const numericValue = e.target.value.replace(/[^0-9.,]/g, '');
    const removedComma = numericValue.replace(/,/g, '.');
    const checkDots = removedComma.split('.');
    if (checkDots.length > 2) {
      return;
    }
    setUsdAmount(removedComma);
  };

  const handleMax = () => {
    setIsMax(true);
    setUsdAmount(yourWorth.toString());
  };

  const handleReceiveTokenSelect = (token?: IWhitelistToken) => {
    setReceiveTokenAddress(token?.address);
  };

  const handleWithdraw = () => {
    const payload = {
      isOpen: true,
      lpToken1: token0Detail,
      lpToken2: token1Detail,
      lpToken1Amount: token0Balance,
      lpToken2Amount: token1Balance,
      outputToken: selectedToken1,
      outputTokenEstimatedAmount: withdrawValueInSelectedToken1,
      lpTokensAmount: lpTokens,
      totalUsdAmount: Number(usdAmount),
      lp: liquidityPool,
      farm: farmItem,
      keepTokensMode: keepTokensMode,
    };
    check(() => setWithdrawModalProps(payload));
  };

  useEffect(() => {
    usdAmount && Number(usdAmount) > yourWorth ? setShowWarning(true) : setShowWarning(false);
  }, [yourWorth, usdAmount]);

  useEffect(() => {
    if (!keepTokensMode) getSwapInfo();
  }, [getSwapInfo, keepTokensMode]);

  const onWithdrawSuccessCallback = () => {
    setIsMax(false);
    setUsdAmount('');
  };

  const handleDismissWithdrawModal = () => {
    setWithdrawModalProps({...withdrawModalProps, isOpen: false});
  };

  return (
    <ContentlWrapper>
      <StyledCol gap={10}>
        <BodyVariant color={COLORS.PRIMARY}>Amount to withdraw</BodyVariant>
        <StyledInputWrapper>
          <StyledFullRow>
            <StyledRow flex alignCenter>
              <BodyVariant color={COLORS.PRIMARY}>$</BodyVariant>
              <StyledInput
                value={usdAmount}
                placeholder='0.0'
                inputMode='decimal'
                pattern='^[0-9]*[.,]?[0-9]*$'
                onChange={onChange}
              />
            </StyledRow>
            <StyledMaxButton onClick={handleMax}>
              <BodyParagraph color={COLORS.SECONDARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
                MAX
              </BodyParagraph>
            </StyledMaxButton>
          </StyledFullRow>
        </StyledInputWrapper>
        {showWarning && usdAmount && (
          <StyledWarningBox>
            <StyledWarningIcon src={WarningIcon} />
            <BodyParagraph color={COLORS.WARNING}>Amount to withdraw exceeds your deposit.</BodyParagraph>
          </StyledWarningBox>
        )}
      </StyledCol>
      <StyledCol marginTop={24} gap={10}>
        {token0Detail && (
          <StyledTokenItem token={token0Detail} value={token0Balance?.toFixed(token0Detail?.interfaceDecimals)} />
        )}
        {token1Detail && (
          <StyledTokenItem token={token1Detail} value={token1Balance?.toFixed(token1Detail?.interfaceDecimals)} />
        )}
      </StyledCol>
      <ArrowWrapper>
        <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
      </ArrowWrapper>
      <StyledCol gap={4}>
        <BodyVariant color={COLORS.PRIMARY}>Receive</BodyVariant>
        <StyledRow margin={12}>
          <StyledCol gap={8}>
            <BorderWrapper>
              <TokenSelectItem
                token={selectedToken1}
                disabled={keepTokensMode}
                onSelect={() => setShowReceiveTokenSelectModal(true)}
              />
            </BorderWrapper>
            <BodyParagraph color={COLORS.GRAY_LIGHT}>
              = {selectedToken1?.symbol}{' '}
              {keepTokensMode
                ? token0Balance?.toFixed(token0Detail?.interfaceDecimals)
                : withdrawValueInSelectedToken1 &&
                  withdrawValueInSelectedToken1.toFixed(selectedToken1?.interfaceDecimals)}
            </BodyParagraph>
          </StyledCol>
          {keepTokensMode ? (
            <StyledCol gap={8}>
              <BorderWrapper>
                <TokenSelectItem token={token1Detail} disabled={keepTokensMode} />
                <CloseButton onClick={() => setKeepTokensMode(false)}>
                  <CloseIcon size={16} />
                </CloseButton>
              </BorderWrapper>
              <BodyParagraph color={COLORS.GRAY_LIGHT}>
                = {token1Detail.symbol} {token1Balance?.toFixed(token1Detail?.interfaceDecimals)}
              </BodyParagraph>
            </StyledCol>
          ) : (
            <StyledButton
              size={BUTTON_SIZE_ENUM.SMALL}
              color={COLORS.SECONDARY}
              borderColor={COLORS.SECONDARY}
              backgroundColor={COLORS.WHITE}
              title='Keep Original Tokens'
              onClick={() => setKeepTokensMode(true)}
            />
          )}
        </StyledRow>
      </StyledCol>
      {priceImpact && !keepTokensMode && (
        <>
          <StyledCol marginTop={15}>
            <StyledRowContainer marginTop={0}>
              <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.LARGE}>
                Price impact
              </BodyParagraph>
              <BodyParagraph
                color={
                  parseFloat(priceImpact?.toFixed(2)) > 1.0 && parseFloat(priceImpact?.toFixed(2)) <= 3.0
                    ? COLORS.ORANGE
                    : parseFloat(priceImpact?.toFixed(2)) > 3.0
                    ? COLORS.WARNING
                    : COLORS.SECONDARY
                }
                size={PARAGRAPH_FONT_ENUM.SMALL}
              >
                {priceImpact ? (priceImpact < 0.01 ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
              </BodyParagraph>
            </StyledRowContainer>
          </StyledCol>
          {priceImpact >= 10 && (
            <StyledCol marginTop={20}>
              <StyledParagraph color={COLORS.WARNING}>
                The price impact of your transaction is too high. Consider reducing the amount to withdraw.
              </StyledParagraph>
            </StyledCol>
          )}
        </>
      )}
      <StyledCol marginTop={30}>
        <Button disabled={disabled} title='Withdraw' onClick={handleWithdraw} />
      </StyledCol>
      <TokenSelectModal
        selectedToken={selectedToken1}
        showClearToken={true}
        hideNativeToken={true}
        onTokenSelect={handleReceiveTokenSelect}
        isOpen={showReceiveTokenSelectModal}
        onDismiss={() => setShowReceiveTokenSelectModal(false)}
      />
      <WithdrawModal
        {...withdrawModalProps}
        onWithdrawSuccess={onWithdrawSuccessCallback}
        onDismiss={handleDismissWithdrawModal}
      />
    </ContentlWrapper>
  );
};

const ContentlWrapper = styled.div`
  flex: 1;
  padding: 31px;
  background-color: ${COLORS.WHITE};
  box-shadow: 4px 4px 20px rgba(17, 36, 85, 0.06);
  border-radius: 12px;

  @media (max-width: ${DEVICE_ENUM.md}) {
    width: 100%;
  }

  @media (max-width: ${DEVICE_ENUM.md}) {
    padding: 24px 12px;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 4px;
`;

const StyledInput = styled.input`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLORS.PRIMARY};
  border: none;
  outline: none;
`;

const StyledRow = styled.div<{flex?: boolean; margin?: number; alignCenter?: boolean}>`
  ${(props) =>
    props.flex &&
    `
    flex: 1;
  `}
  display: flex;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'flex-start')};
  gap: ${(props) => props.margin ?? 0}px;
`;

const StyledFullRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-tween;
`;

const StyledMaxButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

const StyledCol = styled.div<{marginTop?: number; gap?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const ArrowWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BorderWrapper = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${COLORS.GRAY_BASE_40};
  border-radius: 12px;
  padding: 8px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 8px;
  margin-top: 4px;
`;

const CloseButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 0;
  border: 1px solid ${COLORS.GRAY};
  border-radius: 4px;
  background-color: ${COLORS.WHITE};
  cursor: pointer;
`;

const StyledWarningBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 12px;
`;

const StyledWarningIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;

const StyledRowContainer = styled.div<{marginTop?: number; marginBottom?: number}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
`;
