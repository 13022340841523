/* eslint-disable @typescript-eslint/no-explicit-any */
import {useContext, useEffect, useMemo, useRef, useState} from 'react';

import {useWeb3React} from '@web3-react/core';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import LIQLogoIcon from 'src/assets/images/tokens/LIQIcon.svg';
import {Spinner} from 'src/components/Spinner';
import {ArrowLeftIcon} from 'src/components/Svgs';
import {TokenIcon} from 'src/components/TokenIcon';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {EarnWithIdleTokenItem, SingleFarmItemForYield} from 'src/components/YieldScanner';
import {paths} from 'src/constants';
import {minTVL} from 'src/constants/farms';
import {AppContext} from 'src/contexts/AppContext';
import {useToken} from 'src/hooks';
import {useAppSelector} from 'src/state/hooks';
import {BODY_FONT_ENUM, COLORS, DEVICE_ENUM} from 'src/styles';
import {FarmType, IFarm, IWhitelistToken, IYieldFarm, TOKEN_BOUNDARY_BALANCE, WRAPPED_TOKEN_LIST} from 'src/types';
import {formatBigNumber, getTokenUSDPrice} from 'src/utils/token-util';
import styled from 'styled-components';

import {EarnMoreOnHoldings} from './earnMoreOnHolding';

// import {testToken} from './data'; // This is dummy data for testing manually

const getUniqueTokenPairsFromFarm = (pools: IYieldFarm[] | undefined) => {
  const uniquePairs: any = {};
  if (pools) {
    for (const pool of pools) {
      const {liquidityPool, apy} = pool;
      const tokenPair = `${liquidityPool?.token1Symbol}-${liquidityPool?.token0Symbol}`;

      // eslint-disable-next-line no-prototype-builtins
      if (apy !== undefined && (!uniquePairs.hasOwnProperty(tokenPair) || apy > uniquePairs[tokenPair])) {
        uniquePairs[tokenPair] = apy;
      }
    }

    const filteredAndSorted = pools
      .filter((pool) => {
        const {liquidityPool, apy} = pool;
        const tokenPair = `${liquidityPool?.token1Symbol}-${liquidityPool?.token0Symbol}`;
        return uniquePairs[tokenPair] === apy;
      })
      .sort((a, b) => (b.apy || 0) - (a.apy || 0));
    return filteredAndSorted;
  } else {
    return [];
  }
};

const getUniqueSingleTokenFarm = (farmData: IFarm[]) => {
  const highestApyBySymbol: any = {};

  for (const farm of farmData) {
    const apy = farm.apy;
    const symbol = farm.stakedTokenChainSpecifics.symbol;

    if (!(symbol in highestApyBySymbol) || apy > highestApyBySymbol[symbol].apy) {
      highestApyBySymbol[symbol] = farm;
    }
  }

  const highestApyFarms = Object.values(highestApyBySymbol);

  return highestApyFarms;
};

export const YieldResult = () => {
  const tokenRef = useRef(null);
  const {farmLoading, farmingPools, farms, grizzlyFarms} = useContext(AppContext);
  const {chainId, account} = useWeb3React();
  const {connectedWallets} = useAppSelector((state) => state.wallets);
  const currentWallet = useMemo(
    () => connectedWallets.find((item) => item.account === account),
    [account, connectedWallets],
  );

  useEffect(() => {
    const handleResize = () => {
      const tokenHeight = tokenRef.current.clientHeight;
      setTopMargin(tokenHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [topMargin, setTopMargin] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        const currentPath = window.location.pathname;
        if (currentPath === paths.yieldResult) navigate(paths.yieldScanner);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {sortedTokens} = useToken();
  const [tokensAbove25, setTokensAbove25] = useState<IWhitelistToken[]>([]);
  const [filteredToken, setFilteredToken] = useState([]);
  const [userOwnPool, setUserOwnPool] = useState<IYieldFarm[]>([]);
  const [newFarms, setNewFarms] = useState<IYieldFarm[]>([]);
  const [currentFarms, setCurrentFarms] = useState<IYieldFarm[]>([]);

  const filteredFarmingPools = useMemo(() => {
    if (farmingPools && farms && grizzlyFarms) {
      const _lpFarms = farms?.filter((item) => item.contractAddress?.chainId === chainId && item.liquidityPool);

      const v2FarmingPools = farmingPools.filter((item: any) => {
        if (
          item?.masterChefAddress?.chainId === chainId &&
          item.version !== 1 &&
          item?.platform?.name !== 'MM Finance'
        ) {
          const {...newItem} = item;
          return newItem;
        }
        return null;
      });

      const _grizzlyFarms = grizzlyFarms?.filter((item: any) => {
        return item.contractAddress.chainId === chainId;
      });

      const mergedFarms: IYieldFarm[] = [...v2FarmingPools, ..._lpFarms, ..._grizzlyFarms];
      mergedFarms.filter((farm) => farm?.tvlNumber > minTVL);
      return mergedFarms;
    }
  }, [farms, grizzlyFarms, farmingPools, chainId]);

  //single Farm data
  const tokenDepositData = useMemo(() => {
    const _singleTokens = farms?.filter((item) => item.contractAddress?.chainId === chainId && !item.liquidityPool);
    return _singleTokens;
  }, [chainId, farms]);

  const fullFarmingPool = useMemo(() => {
    if (farmingPools && farms && grizzlyFarms) {
      const _lpFarms = farms?.filter((item) => item.contractAddress?.chainId === chainId && item.liquidityPool);
      const v2FarmingPools = farmingPools.filter((item) => {
        if (
          item?.masterChefAddress?.chainId === chainId &&
          item.version !== 1 &&
          item?.platform?.name !== 'MM Finance'
        ) {
          const {...newItem} = item;
          return newItem;
        }
        return null;
      });
      const _grizzlyFarms = grizzlyFarms?.filter((item: any) => {
        return item.contractAddress.chainId === chainId;
      });
      const mergedFarms: IYieldFarm[] = [...v2FarmingPools, ..._lpFarms, ..._grizzlyFarms];
      mergedFarms.filter((farm) => farm?.tvlNumber > minTVL);
      return mergedFarms;
    }
  }, [farms, grizzlyFarms, farmingPools, chainId]);

  useEffect(() => {
    const defaultFarms: IYieldFarm[] = [];
    if (filteredFarmingPools && filteredToken.length > 0) {
      // 1. Earn with your idle tokens
      const filteredPoolWithBothToken = filteredFarmingPools?.filter(
        (item: any) =>
          item.balance &&
          filteredToken.some((token: any) => item?.liquidityPool?.token1Symbol === token) && // TODO: Do not filter with symbol, use address or globalName everywhere!!!
          filteredToken.some((token: any) => item?.liquidityPool?.token0Symbol === token) &&
          item.apy > 0,
      );
      const filteredAndSorted = getUniqueTokenPairsFromFarm(filteredPoolWithBothToken).slice(0, 10);
      //1 - 1 filter the single token
      const singleFarmWithIdleToken = tokenDepositData.filter(
        (item: any) => item.balance && filteredToken.includes(item.stakedTokenChainSpecifics.symbol),
      );

      const filteredSingleFarm = getUniqueSingleTokenFarm(singleFarmWithIdleToken).slice(0, 5);

      const mergedIdleFarms = [...filteredAndSorted, ...filteredSingleFarm].sort(
        (a, b) => ((b as IYieldFarm).apy || 0) - ((a as IYieldFarm).apy || 0),
      );
      setUserOwnPool(mergedIdleFarms);
    }

    // 3. Discover new farms to earn even more
    const filteredPoolWithOneToken: IYieldFarm[] | undefined = fullFarmingPool?.filter(
      (item: any) =>
        item.balance &&
        item.apy > 0 &&
        filteredToken.some(
          (token: any) => item?.liquidityPool?.token1Symbol === token || item?.liquidityPool?.token0Symbol === token,
        ) &&
        !(
          filteredToken.some((token: string) => item?.liquidityPool?.token1Symbol === token) &&
          filteredToken.some((token: string) => item?.liquidityPool?.token0Symbol === token)
        ),
    );

    const top10dNewFarms = getUniqueTokenPairsFromFarm(filteredPoolWithOneToken).slice(0, 10);

    setNewFarms(top10dNewFarms || defaultFarms);

    // 4. The highest paying farms
    const poolWithOutUserToken: IYieldFarm[] | undefined = fullFarmingPool
      ?.filter(
        (item: any) =>
          item.balance &&
          item.apy > 0 &&
          filteredToken.every(
            (token: any) => item?.liquidityPool?.token1Symbol !== token && item?.liquidityPool?.token0Symbol !== token,
          ),
      )
      .sort((a: any, b: any) => (b.apy || 0) - (a.apy || 0));

    const top10HighPayingFarm = getUniqueTokenPairsFromFarm(poolWithOutUserToken).slice(0, 10);

    setCurrentFarms(top10HighPayingFarm || defaultFarms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredFarmingPools, filteredToken, fullFarmingPool]);

  useMemo(() => {
    if (sortedTokens) {
      const topPriceTokens = sortedTokens.filter((item) => {
        const tokenUSDPrice = getTokenUSDPrice(formatBigNumber(item.balance, item.decimals), item.priceUSD);
        return tokenUSDPrice >= TOKEN_BOUNDARY_BALANCE;
      });

      const updatedTopPriceTokens = [...topPriceTokens];
      // const updatedTopPriceTokens = [...testToken]; //testing

      setTokensAbove25(updatedTopPriceTokens);
      const filteredTokenSymbolList = updatedTopPriceTokens.map((token) => token.symbol);
      const newTokenArray: string[] = [];

      filteredTokenSymbolList.forEach((token) => {
        if (Object.values(WRAPPED_TOKEN_LIST).includes(token)) {
          const correspondingKey = Object.keys(WRAPPED_TOKEN_LIST).find((key) => WRAPPED_TOKEN_LIST[key] === token);
          if (correspondingKey) {
            newTokenArray.push(correspondingKey);
          }
        } else if (Object.keys(WRAPPED_TOKEN_LIST).includes(token)) {
          const correspondingKey = WRAPPED_TOKEN_LIST[token];
          newTokenArray.push(correspondingKey);
        }
      });
      const filteredList = [...filteredTokenSymbolList, ...newTokenArray];
      setFilteredToken([...filteredList]);
    }
  }, [sortedTokens]);

  const earnidleTokens = () => {
    if (userOwnPool.length > 0 && !farmLoading) {
      return (
        <FarmWrapper>
          <FarmWrapperHeader>Earn with your idle tokens</FarmWrapperHeader>
          <FarmWrapperSubTitle>You have both tokens of the pair in your wallet.</FarmWrapperSubTitle>
          <FarmItemWrapper>
            {userOwnPool?.map((item, index) => {
              if (item.type !== FarmType.FARM_SINGLE) {
                return (
                  <EarnWithIdleTokenItem
                    key={index}
                    data={item}
                    isFromFarmingScreen={true}
                    isHighPayingFarm={false}
                    isDiscoverFarm={false}
                  />
                );
              } else {
                return (
                  <SingleFarmItemForYield
                    key={index}
                    data={item}
                    // isFromYieldScanner={true}
                    // isFromFarmingScreen={true}
                    // isHighPayingFarm={false}
                  />
                );
              }
            })}
          </FarmItemWrapper>
        </FarmWrapper>
      );
    } else {
      return null;
    }
  };

  const discoverFarm = () => {
    if (newFarms.length > 0 && !farmLoading) {
      return (
        <FarmWrapper>
          <FarmWrapperHeader>Discover new farms to earn even more</FarmWrapperHeader>
          <FarmWrapperSubTitle>You have one token of the pair in your wallet.</FarmWrapperSubTitle>
          <FarmItemWrapper>
            {newFarms?.map((item, index) => {
              if (item.type !== FarmType.FARM_SINGLE) {
                return (
                  <EarnWithIdleTokenItem
                    key={index}
                    data={item}
                    isFromFarmingScreen={true}
                    isHighPayingFarm={true}
                    isDiscoverFarm={true}
                  />
                );
              } else {
                return (
                  <SingleFarmItemForYield
                    key={index}
                    data={item}
                    // isFromYieldScanner={true}
                    // isFromFarmingScreen={true}
                    // isHighPayingFarm={false}
                  />
                );
              }
            })}
          </FarmItemWrapper>
        </FarmWrapper>
      );
    } else {
      return null;
    }
  };

  const highPayingFarm = () => {
    if (currentFarms.length > 0 && !farmLoading) {
      return (
        <FarmWrapper>
          <FarmWrapperHeader>The highest paying farms currently</FarmWrapperHeader>
          <FarmWrapperSubTitle>Swap to these pairs to supercharge your earnings.</FarmWrapperSubTitle>
          <FarmItemWrapper>
            {currentFarms?.map((item, index) => {
              if (item.type !== FarmType.FARM_SINGLE) {
                return (
                  <EarnWithIdleTokenItem
                    key={index}
                    data={item}
                    isFromFarmingScreen={true}
                    isHighPayingFarm={true}
                    isDiscoverFarm={false}
                  />
                );
              } else {
                return (
                  <SingleFarmItemForYield
                    key={index}
                    data={item}
                    // isFromYieldScanner={true}
                    // isFromFarmingScreen={true}
                    // isHighPayingFarm={true}
                  />
                );
              }
            })}
          </FarmItemWrapper>
        </FarmWrapper>
      );
    } else {
      return null;
    }
  };

  const loadingScreen = () => {
    if (farmLoading) {
      return (
        <StyledFullWrapper>
          <Spinner size={32} color={COLORS.SECONDARY} />
        </StyledFullWrapper>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Container>
        <Header>
          <StyledLink to={paths.home}>
            <ArrowLeftIcon size={24} color={COLORS.WHITE} />
          </StyledLink>
          <HeaderContent>
            <WalletName>{currentWallet?.accountName ?? 'Your account'}</WalletName>
            <LogoContainer>
              <StyledImage src={LIQLogoIcon} />
            </LogoContainer>
            <YieldCountText>{`${userOwnPool?.length} Yield${
              userOwnPool?.length > 1 ? 's' : ''
            } Matches`}</YieldCountText>
          </HeaderContent>
        </Header>
        <FarmContainer>
          <TokenContainer ref={tokenRef}>
            <FarmHeader>User tokens</FarmHeader>

            {tokensAbove25.length === 0 ? (
              <NoTokens>{`Found no tokens with a balance greater than $${TOKEN_BOUNDARY_BALANCE}.`}</NoTokens>
            ) : (
              <UserTokensWrapper>
                {tokensAbove25.map((userToken) => (
                  <TokenItemWrapper key={userToken.globalName}>
                    <ITokenIcon token={userToken} />
                    <BodyVariant color={COLORS.BLACK_TEXT} size={BODY_FONT_ENUM.MEDIUM}>
                      {userToken.symbol}
                    </BodyVariant>
                  </TokenItemWrapper>
                ))}
              </UserTokensWrapper>
            )}
          </TokenContainer>
          <FlexContainer topMargin={topMargin}>
            <FarmContent>
              {loadingScreen()}
              {earnidleTokens()}
              <EarnMoreOnHoldings allFarms={filteredFarmingPools} />
              {discoverFarm()}
              {highPayingFarm()}
            </FarmContent>
          </FlexContainer>
        </FarmContainer>
      </Container>
      <Outlet />
    </>
  );
};

const Container = styled.div`
  background-color: ${COLORS.PRIMARY};
  position: relative;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 20px 10px;
  background: transparent;
`;

const StyledLink = styled(Link)`
  height: 24px;
  flex-shrink: 0;
  text-align: left;
`;

const HeaderContent = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translateX(20px);
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    transform: none;
    gap: 15px;
  }
`;

const WalletName = styled.p`
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  color: ${COLORS.WHITE};
  margin: 0;
`;

const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  background: ${COLORS.SECONDARY};
  border-radius: 100%;
  margin: 0 20px;
  padding: 15px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const YieldCountText = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: ${COLORS.SECONDARY};
  margin: 0;
`;

const FarmContainer = styled.div`
  display: flex;
  padding: 30px 0 0;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  background-color: ${COLORS.GRAY_BG};
  border-radius: 30px 30px 0 0;
  height: 100%;
  overflow: auto;

  @media screen and (max-width: ${DEVICE_ENUM.MOBILE}) {
    overflow-x: hidden;
  }
`;

const FarmHeader = styled.p`
  font-size: 32px;
  font-weight: 600;
  line-height: 36.57px;
  font-family: Montserrat;
  text-align: center;
  margin-top: 0;
  margin-bottom: 28px;
  color: ${COLORS.PRIMARY};
`;

const NoTokens = styled.p`
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 500;
  font-family: Montserrat;
  text-align: center;
  color: ${COLORS.PRIMARY};
`;

const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  left: 0;
  right: 0;
`;
const FlexContainer = styled.div<{topMargin: number}>`
  display: flex;
  overflow-x: scroll;
  margin: auto;
  // margin-top: ${(props) => props.topMargin}px;
  padding: 0 30px;

  @media screen and (max-width: ${DEVICE_ENUM.MOBILE}) {
    padding: 0 0 30px;
    align-self: center;
  }
`;

const FarmContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: fit-content;
  flex-wrap: inherit;
  flex: none;
  max-width: 1920px;
  margin: 0;
  gap: 120px;

  @media screen and (max-width: 700px) {
    flex: auto;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1712px) {
    gap: 100px;
  }

  @media screen and (max-width: 1712px) and (min-width: 1367px) {
    gap: 85px;
  }

  /* Mid-wide screens: 2 columns */
  @media screen and (max-width: 1367px) and (min-width: 464px) {
    gap: 60px;
  }
  @media screen and (max-width: 768px) {
    margin: 0 35px;
    width: fit-content;
  }
  @media screen and (max-width: 465px) {
    gap: 40px;
    margin: 0;
  }
`;

const FarmWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc((100% - 90px) / 4);
  min-width: 360px;
  @media screen and (min-width: 1920px) {
    flex-basis: calc((100% - 360px) / 4);
    max-width: calc(25% - 1rem);
  }

  @media screen and (max-width: 1712px) and (min-width: 1367px) {
    flex-basis: calc((100% - 300px) / 3);
    min-width: 360px;
    max-width: calc(33.33% - 1rem);
  }
  /* Mid-wide screens: 2 columns */
  @media screen and (max-width: 1368px) and (min-width: 1023px) {
    flex-basis: calc((100% - 240px) / 3);
    min-width: 360px;
    max-width: calc(50% - 1rem);
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    flex-basis: calc((100% - 240px) / 2);
    min-width: 360px;
    max-width: calc(50% - 1rem);
  }

  /* Small screens and mobile: 1 column */
  @media screen and (max-width: 767px) {
    flex-basis: 100%;
    min-width: 360px;
    max-width: 450px;
  }

  @media screen and (max-width: 465px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;

const UserTokensWrapper = styled.div`
  flex-wrap: wrap;
  row-gap: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const TokenItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${COLORS.SECONDARY};
  background-color: ${COLORS.SECONDARY_TRANSPARENT};
  padding: 7px 10px;
  border-radius: 50px;
  margin: 0 10px;
`;

const ITokenIcon = styled(TokenIcon)`
  margin-right: 9px;
`;

const FarmWrapperHeader = styled(BodyVariant)`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  line-height: 29.26px;
  color: ${COLORS.PRIMARY};
`;

const FarmWrapperSubTitle = styled(BodyParagraph)`
  text-align: center;
  font-weight: 400;
  margin-top: 7px;
  margin-bottom: 16px;
  color: ${COLORS.PRIMARY};
`;

const FarmItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;
const StyledFullWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (max-width: ${DEVICE_ENUM.TABLET}) {
    min-height: 300px;
  }
`;
