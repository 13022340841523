import React from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import RewardTokenItem from 'src/components/Tokens/RewardTokenItem';
import {BodyParagraph} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken, IYieldFarm} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import {getTokenUSDPrice, getVestingInMonth} from 'src/utils/token-util';
import styled from 'styled-components';

type SuccessTopupProps = {
  inputToken?: IWhitelistToken;
  outputToken1?: IWhitelistToken;
  inputTokenAmount?: number;
  outputToken1EstimatedAmount?: number;
  totalUsdAmount?: number;
  farm?: IYieldFarm;
  onDone?: () => void;
};

export const SuccessTopup = ({
  inputToken,
  outputToken1,
  inputTokenAmount,
  outputToken1EstimatedAmount,
  totalUsdAmount,
  farm,
  onDone,
}: SuccessTopupProps) => {
  const outputUsdPrice = getTokenUSDPrice(
    outputToken1EstimatedAmount,
    outputToken1.priceUSD,
    outputToken1.priceDecimals,
  );
  const singleTokenSymbol = farm?.rewardTokenChainSpecifics?.symbol;
  const vestingInMonth = getVestingInMonth(farm?.vestingTimeInDays);
  const singleTokenName = `${singleTokenSymbol} - ${vestingInMonth} Month`;
  const apyLabel = `${farm.apy?.toFixed(2)}%`;
  const aprPerYear = ((farm.apy || 0) * outputUsdPrice) / 100;
  return (
    <Body>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <TokenInfoBox>
        <TokenItem token={inputToken} value={inputTokenAmount?.toFixed(inputToken?.interfaceDecimals)} />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledTokenItem
          token={outputToken1}
          value={outputToken1EstimatedAmount.toFixed(outputToken1.interfaceDecimals)}
        />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        <StyledRewardTokenItem
          token={outputToken1}
          name={singleTokenName}
          label={farm?.platform?.name}
          rightTopLabel={apyLabel}
          rightBottomLabel={`+$${aprPerYear.toFixed(2)} / year`}
        />
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButton color={COLORS.PRIMARY} title='Done' onClick={onDone} />
    </Body>
  );
};

const Body = styled.div`
  padding: 0 24px 24px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 14px;
`;

const TokenInfoBox = styled.div`
  padding: 12px 16px 16px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;

const StyledRewardTokenItem = styled(RewardTokenItem)`
  margin: 8px -12px 0;
`;
