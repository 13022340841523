import React from 'react';

import {Button} from 'src/components/Buttons';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon, TickIcon} from 'src/components/Svgs';
import {BodyParagraph, BodyVariant} from 'src/components/Typography';
import {COLORS} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import {toFixed} from 'src/utils/text-util';
import styled from 'styled-components';

type SwapTokensProps = {
  inputToken?: IWhitelistToken;
  outputToken1?: IWhitelistToken;
  outputToken2?: IWhitelistToken;
  inputTokenAmount?: number;
  outputToken1EstimatedAmount?: number;
  outputToken2EstimatedAmount?: number;
  totalUsdAmount?: number;
  loading?: boolean;
  disabled?: boolean;
  onConfirm?: () => void;
};

export const SwapTokens = ({
  inputToken,
  outputToken1,
  outputToken2,
  inputTokenAmount,
  outputToken1EstimatedAmount,
  outputToken2EstimatedAmount,
  totalUsdAmount,
  loading,
  disabled,
  onConfirm,
}: SwapTokensProps) => {
  return (
    <Body>
      <StpesContainer>
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>1</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Tokens</BodyVariant>
          <TickIcon color={COLORS.SECONDARY} />
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox active>
            <BodyVariant color={COLORS.PRIMARY}>2</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.PRIMARY}>Swap Tokens</BodyVariant>
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox>
            <BodyVariant color={COLORS.GRAY_LIGHT}>3</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Approve Tokens</BodyVariant>
        </StyledRow>
        <StyledVerticalLine />
        <StyledRow>
          <RoundedBox>
            <BodyVariant color={COLORS.GRAY_LIGHT}>4</BodyVariant>
          </RoundedBox>
          <BodyVariant color={COLORS.GRAY_LIGHT}>Deposit to Farm</BodyVariant>
        </StyledRow>
      </StpesContainer>
      <TokenInfoBox>
        <TokenItem token={inputToken} value={inputTokenAmount?.toFixed(inputToken?.interfaceDecimals)} />
        <ArrowBox>
          <ArrowDownIcon />
        </ArrowBox>
        <SteyldTokenItem
          token={outputToken1}
          value={outputToken1EstimatedAmount.toFixed(outputToken1.interfaceDecimals)}
        />
        {outputToken2 && (
          <SteyldTokenItem
            token={outputToken2}
            value={outputToken2EstimatedAmount.toFixed(outputToken2.interfaceDecimals)}
          />
        )}
        <StyledOutputUsdPrice color={COLORS.GRAY_BASE_40}>=${toFixed(totalUsdAmount, 2)}</StyledOutputUsdPrice>
      </TokenInfoBox>
      <StyledButtonContainer>
        <StyledButton
          color={COLORS.PRIMARY}
          isLoading={loading}
          disabled={disabled}
          title='Swap Tokens'
          onClick={onConfirm}
        />
        <StyledParagraph color={COLORS.PRIMARY}>Please confirm the transaction in your wallet</StyledParagraph>
      </StyledButtonContainer>
    </Body>
  );
};

const Body = styled.div`
  padding: 0 0 24px;
`;

const StpesContainer = styled.div`
  padding: 12px 25px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLORS.GRAY_BORDER};
`;

const RoundedBox = styled.div<{active?: boolean}>`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: ${(props) => (props.active ? COLORS.SECONDARY : 'transparent')};
  border: 1px solid ${(props) => (!props.active ? COLORS.SECONDARY : 'transparent')};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledVerticalLine = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${COLORS.SECONDARY};
  margin-left: 15px;
`;

const TokenInfoBox = styled.div`
  padding: 22px 25px 16px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const StyledOutputUsdPrice = styled(BodyParagraph)`
  margin-top: 8px;
`;

const StyledButtonContainer = styled.div`
  padding: 0 17px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
`;

const SteyldTokenItem = styled(TokenItem)`
  margin-top: 12px;
`;
