import {gql} from '@apollo/client';

export const MASTER_CHEF_CONTRACTS = gql`
  query masterChefContracts($platformSlug: String, $chainId: Float) {
    masterChefContracts(platformSlug: $platformSlug, chainId: $chainId) {
      address {
        hash
        chainId
      }
      version
      platform {
        name
        slug
        securityRating {
          projectStart
          projectAgeInDays
          governanceTokenMarketCap
          tvl
          rugdocRiskLevel
          certikTrustScore
          cerRating
          updatedAt
          securityRating
          projectAgeRating
          tvlRating
          marketCapRating
        }
        iconUrl
        governanceTokenChainSpecifics {
          name
          symbol
          decimals
        }
        factoryAddresses {
          hash
          chainId
        }
        masterChefAddresses {
          hash
          chainId
        }
        routerAddresses {
          hash
          chainId
        }
        chainSpecifics {
          chainId
        }
      }
      rewardTokenChainSpecifics {
        symbol
        decimals
        priceUSD {
          value
          decimals
        }
        address {
          hash
          chainId
        }
      }
      config {
        LPDecimals
        pendingRewardFn
        userInfoFn
        masterChefAbi
        harvestFn {
          signature
          arguments {
            name
            type
          }
        }
        depositFn {
          signature
          arguments {
            name
            type
          }
        }
        withdrawFn {
          signature
          arguments {
            name
            type
          }
        }
      }
      whitelistedFarmingPools {
        poolId
        liquidityPool {
          poolRewards
          poolApy
          pastVolumes
          platform {
            name
            slug
            iconUrl
            securityRating {
              projectStart
              projectAgeInDays
              governanceTokenMarketCap
              tvl
              rugdocRiskLevel
              certikTrustScore
              cerRating
              updatedAt
              securityRating
              projectAgeRating
              tvlRating
              marketCapRating
            }
            governanceTokenChainSpecifics {
              name
              symbol
              decimals
            }
            factoryAddresses {
              hash
              chainId
            }
            masterChefAddresses {
              hash
              chainId
            }
            routerAddresses {
              hash
              chainId
            }
            chainSpecifics {
              chainId
            }
          }
          reserve0
          reserve1
          token0Symbol
          token1Symbol
          token0Hash
          token1Hash
          totalSupply
          decimals
          lpTokenPriceUSD {
            value
            decimals
          }
          address {
            hash
            chainId
          }
        }
        tvl {
          value
          decimals
        }
        baseAPR
        apy
        hotness
      }
    }
  }
`;
