import EstimateIcon from 'src/assets/images/icons/estimate-icon.svg';
import {BODY_FONT_ENUM, COLORS} from 'src/styles';
import styled from 'styled-components';

import {BodyVariant} from './Typography';

export const YieldBox = ({value}: {value?: number}) => {
  return (
    <Wrapper>
      <StyledImage src={EstimateIcon} />
      <StyledCol>
        <BodyVariant color={COLORS.GRAY_LIGHT} size={BODY_FONT_ENUM.SMALL}>
          Your total yield
        </BodyVariant>
        <StyledBodyVariant color={COLORS.SECONDARY} size={BODY_FONT_ENUM.SMALL}>
          ${value?.toFixed(2) || 0}
        </StyledBodyVariant>
      </StyledCol>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;
`;

const StyledImage = styled.img`
  width: 66px;
`;

const StyledCol = styled.div`
  margin-left: 46px;
`;

const StyledBodyVariant = styled(BodyVariant)`
  font-size: 18px;
  font-weight: 500;
`;
