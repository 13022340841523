import {useCallback} from 'react';

import {useWeb3React} from '@web3-react/core';
import {BigNumber, Contract} from 'ethers';
import {MulticallWrapper} from 'ethers-multicall-provider';
import ERC20_ABI from 'src/abis/ERC20.json';
import FarmContractForSingleToken from 'src/abis/FarmContractForSingleToken.json';
import FarmContractVesting from 'src/abis/FarmContractVesting.json';
import GRIZZLY_VAULT_ABI from 'src/abis/GrizzlyVaultABI.json';
import {IFarm, IFarmingPool, IGauge, IGrizzlyFiFarm, ILiquidityPool, IWhitelistToken} from 'src/types';

export const useGetBalance = () => {
  const {account, provider} = useWeb3React();

  const getTokenBalance = useCallback(
    async (tokenList: IWhitelistToken[]): Promise<BigNumber[] | undefined> => {
      if (account) {
        const getBalancePromises = [];
        const multiCallProvider = MulticallWrapper.wrap(provider);
        const signer = multiCallProvider?.getSigner(account).connectUnchecked();
        for (const token of tokenList) {
          if (token.address) {
            const ercContract = new Contract(token.address, ERC20_ABI, signer);
            const getBalanceCall = ercContract.balanceOf(account);
            getBalancePromises.push(getBalanceCall);
          }
        }
        return await Promise.all(getBalancePromises);
      }
    },
    [account, provider],
  );

  const generateFarmPoolContractFunctionList = useCallback(
    (pools: IFarmingPool[], abis: string, mode: number) => {
      if (account) {
        const multiCallProvider = MulticallWrapper.wrap(provider);
        const signer = multiCallProvider?.getSigner(account).connectUnchecked();
        const contractCalls = [];
        const _abis = JSON.parse(abis);
        for (const item of pools) {
          if (item.masterChefAddress?.hash !== undefined) {
            const platform = item.platform.name;
            const version = item.version;
            const abi = _abis[`${platform}${version ?? ''}`];
            const contract = new Contract(item.masterChefAddress?.hash, abi, signer);
            const userInfoFn = item.userInfoFn ?? 'userInfo';
            const pendingRewardFn = item.pendingRewardFn ?? 'pendingCake';
            if (mode === 0) {
              contractCalls.push(contract[userInfoFn](item.poolId, account));
            } else {
              contractCalls.push(contract[pendingRewardFn](item.poolId, account));
            }
          }
        }
        return contractCalls;
      }
    },
    [account, provider],
  );

  const generateFarmContractFunctionList = useCallback(
    (pools: IFarm[], mode: number) => {
      const multiCallProvider = MulticallWrapper.wrap(provider);
      const signer = multiCallProvider?.getSigner(account).connectUnchecked();
      const contractCalls = [];
      for (const item of pools) {
        if (item.contractAddress?.hash !== undefined) {
          let farmAbi = FarmContractForSingleToken;
          if (item.liquidityPool) {
            farmAbi = FarmContractVesting;
          }
          const contract = new Contract(item.contractAddress?.hash, farmAbi, signer);
          if (mode === 0) {
            contractCalls.push(contract.userInfo(account));
          } else {
            contractCalls.push(contract.pendingReward(account));
          }
        }
      }
      return contractCalls;
    },
    [account, provider],
  );

  const generateGaugeContractFunctionList = useCallback(
    (gauges: IGauge[], mode: number) => {
      const multiCallProvider = MulticallWrapper.wrap(provider);
      const signer = multiCallProvider?.getSigner(account).connectUnchecked();
      const contractCalls = [];
      for (const item of gauges) {
        if (item.address && item.gaugeAbi) {
          const abi = JSON.parse(item.gaugeAbi);
          const contract = new Contract(item.address?.hash, abi, signer);
          const balanceFn = item.balanceFn;
          const pendingRewardFn = item.pendingRewardFn;
          if (mode === 0) {
            contractCalls.push(contract[balanceFn](account));
          } else {
            contractCalls.push(contract[pendingRewardFn](account));
          }
        }
      }
      return contractCalls;
    },
    [account, provider],
  );

  const generateGrizzlyContractFunctionList = (grizzlyFarms: IGrizzlyFiFarm[], mode: number) => {
    const multiCallProvider = MulticallWrapper.wrap(provider);
    const signer = multiCallProvider?.getSigner(account).connectUnchecked();
    const contractCalls = [];
    for (const item of grizzlyFarms) {
      if (item.contractAddress?.hash) {
        const contract = new Contract(item.contractAddress?.hash, GRIZZLY_VAULT_ABI, signer);
        if (mode === 0) {
          contractCalls.push(contract.balanceOf(account));
        } else if (mode === 1) {
          contractCalls.push(contract.totalSupply());
        } else {
          contractCalls.push(contract.getUnderlyingBalances());
        }
      }
    }
    return contractCalls;
  };

  const getFarmPoolBalances = useCallback(
    async (pools: IFarmingPool[], abis: string) => {
      if (account) {
        const contractCalls1 = generateFarmPoolContractFunctionList(pools, abis, 0);
        const contractCalls2 = generateFarmPoolContractFunctionList(pools, abis, 1);
        const tokenBalances = await Promise.all(contractCalls1);
        const tokenRewards = await Promise.all(contractCalls2);
        return [tokenBalances, tokenRewards];
      }
    },
    [account, generateFarmPoolContractFunctionList],
  );

  const getLiquidityPoolBalances = useCallback(
    async (pools: ILiquidityPool[]) => {
      const multiCallProvider = MulticallWrapper.wrap(provider);
      const signer = multiCallProvider?.getSigner(account).connectUnchecked();
      const contractCalls = [];
      for (const item of pools) {
        if (item.address !== undefined) {
          const contract = new Contract(item.address.hash, ERC20_ABI, signer);
          contractCalls.push(contract.balanceOf(account));
        }
      }
      const tokenBalances = await Promise.all(contractCalls);
      return tokenBalances;
    },
    [account, provider],
  );

  const getFarmBalances = useCallback(
    async (farms: IFarm[]) => {
      const contractCalls1 = generateFarmContractFunctionList(farms, 0);
      const contractCalls2 = generateFarmContractFunctionList(farms, 1);
      const tokenBalances = await Promise.all(contractCalls1);
      const tokenRewards = await Promise.all(contractCalls2);
      return [tokenBalances, tokenRewards];
    },
    [generateFarmContractFunctionList],
  );

  const getGaugeBalances = async (gauges: IGauge[]) => {
    const contractCalls1 = generateGaugeContractFunctionList(gauges, 0);
    const contractCalls2 = generateGaugeContractFunctionList(gauges, 1);
    const tokenBalances = await Promise.all(contractCalls1);
    const tokenRewards = await Promise.all(contractCalls2);
    return [tokenBalances, tokenRewards];
  };

  const getGrizzlyFarmsBalances = async (grizzlyFarms: IGrizzlyFiFarm[]) => {
    const contractCalls1 = generateGrizzlyContractFunctionList(grizzlyFarms, 0);
    const contractCalls2 = generateGrizzlyContractFunctionList(grizzlyFarms, 1);
    const contractCalls3 = generateGrizzlyContractFunctionList(grizzlyFarms, 2);
    const tokenBalances = await Promise.all(contractCalls1);
    const totalSupplies = await Promise.all(contractCalls2);
    const underlyingBalances = await Promise.all(contractCalls3);
    return [tokenBalances, totalSupplies, underlyingBalances];
  };

  const getLpTokenBalance = async (tokenAddress?: string) => {
    const signer = provider?.getSigner(account).connectUnchecked();
    const ercContract = new Contract(tokenAddress, ERC20_ABI, signer);
    return await ercContract.balanceOf(account);
  };

  return {
    getTokenBalance,
    getFarmPoolBalances,
    getLiquidityPoolBalances,
    getFarmBalances,
    getGaugeBalances,
    getGrizzlyFarmsBalances,
    getLpTokenBalance,
  };
};
