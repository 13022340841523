import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type SendIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const SendIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: SendIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M17.9922 10.8887L6.61719 6.01367C6.51562 5.96289 6.41406 5.9375 6.3125 5.9375C6.08398 5.9375 5.85547 6.01367 5.70312 6.19141C5.47461 6.44531 5.42383 6.82617 5.57617 7.13086L7.9375 11.625L5.57617 16.1445C5.42383 16.4492 5.47461 16.8301 5.70312 17.0586C5.85547 17.2363 6.08398 17.3379 6.3125 17.3379C6.41406 17.3379 6.51562 17.3125 6.61719 17.2617L17.9922 12.3867C18.2969 12.2598 18.5 11.9551 18.5 11.625C18.4746 11.3203 18.2969 11.0156 17.9922 10.8887ZM7.17578 7.58789L15.2246 11.0156H8.97852L7.17578 7.58789ZM8.97852 12.2344H15.2246L7.17578 15.6875L8.97852 12.2344Z'
      fill={color}
    />
  </svg>
);

export default SendIcon;
