import {useWeb3React} from '@web3-react/core';
import {useNavigate} from 'react-router';
import {paths} from 'src/constants';
import {useNetwork, useToken} from 'src/hooks';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IYieldFarm, POOL_TYPE} from 'src/types';
import {getVestingInMonth} from 'src/utils/token-util';
import styled from 'styled-components';

import {TokenIcon} from '../TokenIcon';
import {BodyParagraph} from '../Typography';

interface HotSingleFarmItemProps {
  data: IYieldFarm;
}

export const HotSingleFarmItem = ({data}: HotSingleFarmItemProps) => {
  const {chainId} = useWeb3React();
  const {switchNetwork} = useNetwork();
  const {getGlobalTokenItemByAddress} = useToken();
  const navigate = useNavigate();
  const vestingInMonth = getVestingInMonth(data.vestingTimeInDays);
  const symbol = data.stakedTokenChainSpecifics?.symbol;
  const address = data.stakedTokenChainSpecifics.address;
  const token = getGlobalTokenItemByAddress(address?.hash, address?.chainId);
  const name = `${symbol} ${vestingInMonth} Month`;
  const platform = data.platform.name;
  const apy = data.apy;

  const handleNavigation = async () => {
    if (chainId !== data.contractAddress.chainId) {
      await switchNetwork(data.contractAddress.chainId);
    }
    navigate(`${paths.poolDetail}?type=${POOL_TYPE.SINGLE_FARM}&address=${data.contractAddress.hash}`);
  };

  return (
    <Wrapper onClick={handleNavigation}>
      <BodyParagraph color={COLORS.GRAY_LIGHT} size={PARAGRAPH_FONT_ENUM.SMALL}>
        {platform}
      </BodyParagraph>
      <TokenIconWrapper>
        <TokenIcon width={40} height={40} token={token} />
      </TokenIconWrapper>
      <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.LARGE}>
        {name}
      </BodyParagraph>
      <BodyParagraph color={COLORS.PRIMARY} size={PARAGRAPH_FONT_ENUM.SMALL}>
        {apy.toFixed(1)}% APY
      </BodyParagraph>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  min-width: 137px;
  width: 186px;
  height: 168px;
  box-sizing: border-box;
  padding: 10px 10px 6px;
  background: white;
  box-shadow: 4px 4px 16px rgba(17, 36, 85, 0.06);
  border-radius: 12px;
`;

const TokenIconWrapper = styled.div`
  display: flex;
`;
