import GoldIcon from 'src/assets/images/tiers/Gold.png';
import SilverIcon from 'src/assets/images/tiers/Silver.png';
import StandardIcon from 'src/assets/images/tiers/Standard.png';
import TitanIcon from 'src/assets/images/tiers/Titan.png';

export const PremiumModes = [
  {
    title: 'Bronze',
    label: 'Bronze Fee',
    tokenStaked: '0 LIQ',
    icon: StandardIcon,
    background: '#FFF7E6',
  },
  {
    title: 'Silver',
    label: 'Silver Fee',
    tokenStaked: '500 LIQ',
    icon: SilverIcon,
    background: '#DEEFFB99',
  },
  {
    title: 'Gold',
    label: 'Gold Fee',
    tokenStaked: '2,500 LIQ',
    icon: GoldIcon,
    background: '#FEFFE7',
  },
  {
    title: 'Titan',
    label: 'Titan Fee',
    tokenStaked: '10,000 LIQ',
    icon: TitanIcon,
    background: '#F1F7FF',
  },
];

export const swapInputAdjustment = 1;
