import {IWhitelistToken, IWhitelistTokenItem} from 'src/types';
import {getTokenIconByGlobalName} from 'src/utils/icon-util';
import styled from 'styled-components';

interface TokenIconProps {
  token?: IWhitelistToken | IWhitelistTokenItem;
  width?: number;
  height?: number;
  icon?: string;
}

export const TokenIcon = ({token, width, height, icon, ...props}: TokenIconProps) => {
  // const {tokens, allTokens} = useContext(AppContext);
  // const iconUrl = globalName
  //   ? allTokens?.find((token) => {
  //       return token.globalName === globalName;
  //     })?.iconUrl
  //   : tokens?.find((token) => {
  //       return token.symbol === symbol;
  //     })?.iconUrl;
  const iconUrl = token?.iconUrl;
  const tokenUrl = iconUrl || getTokenIconByGlobalName(token?.globalName);
  return <StyledImage width={width} height={height} src={!icon ? tokenUrl : icon} {...props} />;
};

const StyledImage = styled.img<{width?: number; height?: number}>`
  width: ${(props) => props.width ?? 30}px;
  height: ${(props) => props.height ?? 30}px;
`;
