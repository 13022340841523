import * as React from 'react';

import {COLORS, ColorsTypes} from 'src/styles/colors';

export type TimerIconType = {
  size?: number;
  color?: ColorsTypes;
};

export const TimerIcon = ({size = 30, color = COLORS.PRIMARY, ...props}: TimerIconType) => (
  <svg width={size} height={size} viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M12 5.125C15.5801 5.125 18.5 8.04492 18.5 11.625C18.5 15.2305 15.5801 18.125 12 18.125C10.6543 18.125 9.43555 17.7441 8.39453 17.0586C8.11523 16.8809 8.03906 16.5 8.2168 16.2207C8.41992 15.9414 8.80078 15.8652 9.08008 16.043C9.91797 16.6016 10.9082 16.9062 12 16.9062C14.8945 16.9062 17.2812 14.5449 17.2812 11.625C17.2812 8.73047 14.8945 6.34375 12 6.34375C10.1211 6.34375 8.4707 7.33398 7.53125 8.78125H8.95312C9.2832 8.78125 9.5625 9.06055 9.5625 9.39062C9.5625 9.74609 9.2832 10 8.95312 10H6.10938C5.75391 10 5.5 9.74609 5.5 9.39062V6.54688C5.5 6.2168 5.75391 5.9375 6.10938 5.9375C6.43945 5.9375 6.71875 6.2168 6.71875 6.54688V7.8418C7.88672 6.2168 9.81641 5.125 12 5.125ZM12 8.375C12.3301 8.375 12.6094 8.6543 12.6094 8.98438V11.3965L14.2344 13.0215C14.4883 13.2754 14.4883 13.6562 14.2344 13.8848C14.0059 14.1387 13.625 14.1387 13.3965 13.8848L11.5684 12.0566C11.4414 11.9551 11.3906 11.8027 11.3906 11.625V8.98438C11.3906 8.6543 11.6445 8.375 12 8.375Z'
      fill={color}
    />
  </svg>
);

export default TimerIcon;
