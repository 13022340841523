import {TransactionResponse} from '@ethersproject/providers';
import {useWeb3React} from '@web3-react/core';
import {Contract} from 'ethers';
import LP_Migrator_ABI from 'src/abis/LP_Migrator_ABI.json';
import {migrationAddress} from 'src/constants/migrator';

export function useMigrateCallback(
  oldRouter: string,
  newRouter: string,
  oldRouterLpToken: string,
  oldStable: string,
  newStable: string,
  stableRouter: string,
): [() => Promise<TransactionResponse>] {
  const {account, provider} = useWeb3React();
  const args = [oldRouter, newRouter, oldRouterLpToken, oldStable, newStable, stableRouter];

  const migrateTx: () => Promise<TransactionResponse> = async () => {
    const walletSigner = provider?.getSigner(account);
    const contract = new Contract(migrationAddress, LP_Migrator_ABI, walletSigner);
    const estimatedGasFee = await contract.estimateGas.migrate(...args);
    try {
      const tx = await contract?.migrate(...args, {
        gasLimit: estimatedGasFee,
      });
      return tx;
    } catch (e) {
      console.error('Migrate failed', e);
    }
  };

  return [migrateTx];
}
