import React from 'react';

import {ImportPoolTokenItem} from 'src/components/ImportPoolTokenItem';
import {PlusIcon} from 'src/components/Svgs';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type ExistingPoolCheckProps = {
  token0: IWhitelistToken;
  token1?: IWhitelistToken;
  onToken0Select?: (token: IWhitelistToken) => void;
  onToken1Select?: (token: IWhitelistToken) => void;
};

export const ExistingPoolCheck = ({token0, onToken0Select, onToken1Select, token1}: ExistingPoolCheckProps) => {
  return (
    <Body>
      <StyledCol>
        <ImportPoolTokenItem selectedToken={token0} onTokenSelect={onToken0Select} />
        <StyledSwitchButton>
          <PlusIcon />
        </StyledSwitchButton>
        <ImportPoolTokenItem selectedToken={token1} onTokenSelect={onToken1Select} />
      </StyledCol>
    </Body>
  );
};

const StyledSwitchButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 12px 0px;
`;

const StyledCol = styled.div<{gap?: number; marginTop?: number}>`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop ?? 0}px;
  gap: ${(props) => props.gap ?? 0}px;
`;

const Body = styled.div`
  padding: 16px 24px;
`;
