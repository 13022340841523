// SendTokenSuccessful.tsx
import {useMemo} from 'react';

import TransactionSuccess from 'src/assets/images/transaction/transaction-success.gif';
import {Button} from 'src/components/Buttons';
import {ContactItem} from 'src/components/ContactItem';
import {TokenItem} from 'src/components/Holdings';
import {ArrowDownIcon} from 'src/components/Svgs';
import {BodyParagraph} from 'src/components/Typography';
import {useModals} from 'src/contexts/modals';
import {useAppSelector} from 'src/state/hooks';
import {COLORS, PARAGRAPH_FONT_ENUM} from 'src/styles';
import {IWhitelistToken} from 'src/types';
import styled from 'styled-components';

type SendTokenSuccessfulProps = {
  selectedToken: IWhitelistToken;
  amount: number;
  usdAmount: number;
  recipientAddress: string;
  onConfirm: () => void;
};

export const SendTokenSuccessful = ({
  selectedToken,
  amount,
  usdAmount,
  recipientAddress,
  onConfirm,
}: SendTokenSuccessfulProps) => {
  const modalContext = useModals();
  const {addressBook} = useAppSelector((state) => state.user);
  const existingContact = useMemo(
    () => addressBook.find((item) => item.address === recipientAddress),
    [addressBook, recipientAddress],
  );

  const onAddContactBook = () => {
    const payload = {isOpen: true, newWalletAddress: recipientAddress};
    modalContext.dispatch({type: 'updateContactBookModal', payload});
  };

  return (
    <Wrapper>
      <StatusWrapper>
        <StyledImg src={TransactionSuccess} />
      </StatusWrapper>
      <ItemsWrapper>
        <TokenItem token={selectedToken} value={amount.toFixed(selectedToken?.interfaceDecimals)} />
        <ArrowBox>
          <ArrowDownIcon color={COLORS.GRAY_LIGHT} />
        </ArrowBox>
        {existingContact ? (
          <ContactItem contact={existingContact} />
        ) : (
          <WalletAddress size={PARAGRAPH_FONT_ENUM.LARGE} color={COLORS.GRAY_LIGHT}>
            {recipientAddress}
          </WalletAddress>
        )}
        <BodyParagraph color={COLORS.GRAY_LIGHT}>=${usdAmount?.toFixed(2)}</BodyParagraph>
      </ItemsWrapper>
      <ButtonWrapper>
        <Button color={COLORS.PRIMARY} title='Done' onClick={onConfirm} />
        {!existingContact && (
          <StyledParagraph color={COLORS.PRIMARY} onClick={onAddContactBook}>
            Add to address book
          </StyledParagraph>
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 8px;
`;

const StyledImg = styled.img`
  width: 100px;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 10px;
`;

const ArrowBox = styled.div`
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledParagraph = styled(BodyParagraph)`
  margin-top: 12px;
  text-align: center;
  cursor: pointer;
`;

const WalletAddress = styled(BodyParagraph)`
  margin-bottom: 6px;
`;
