import React from 'react';

import {CloseIcon} from 'src/components/Svgs';
import {NETWORKS} from 'src/constants';
import {COLORS} from 'src/styles';
import {NetworkType} from 'src/types';
import {getTokenIcon} from 'src/utils/icon-util';
import styled from 'styled-components';

import {Modal} from './Modal';
import {BodyVariant} from '../Typography';

type NetworkSelectModalProps = {
  isOpen: boolean;
  selectedNetwork?: NetworkType;
  onDismiss: () => void;
  onNetworkSelect: (network?: NetworkType) => void;
};

export const NetworkSelectModal = ({isOpen, selectedNetwork, onDismiss, onNetworkSelect}: NetworkSelectModalProps) => {
  const handleNetworkSelect = (network: NetworkType) => {
    onNetworkSelect(network);
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>
        <Header>
          <StyledRow>
            <StyledTitle>Select Network</StyledTitle>
            <IconButton onClick={onDismiss}>
              <CloseIcon color={COLORS.PRIMARY} />
            </IconButton>
          </StyledRow>
        </Header>
        <Body>
          {Object.keys(NETWORKS)?.map((key: string) => {
            const networkId = parseInt(key);
            const tokenUrl = getTokenIcon(NETWORKS[networkId]?.symbol);
            return (
              <NetworkWrapper
                key={key}
                onClick={() => {
                  handleNetworkSelect(NETWORKS[networkId]);
                }}
                style={{backgroundColor: selectedNetwork === NETWORKS[networkId] ? 'mediumturquoise' : 'transparent'}}
              >
                <StyledImage src={tokenUrl} />
                <NetWorkName>{NETWORKS[networkId].displayName}</NetWorkName>
              </NetworkWrapper>
            );
          })}
        </Body>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 76vh;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  z-index: 300;
  overflow: hidden;
`;

const Header = styled.div`
  padding: 24px 24px 12px;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.h4`
  color: ${COLORS.PRIMARY};
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

const Body = styled.div`
  padding: 0 16px 16px;
  flex: 1;
  overflow: auto;
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const NetworkWrapper = styled.button`
  display: flex;
  border: 1px solid darkgrey;
  border-radius: 5px;
  padding: 10px 20px;
  outline: none;
  margin: 15px 0px;
  align-items: center;
  background: transparent;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border: 1px solid green;
  border-radius: 30px;
`;

const NetWorkName = styled(BodyVariant)`
  font-size: 14px;
  margin: 0px;
`;
